import React, { useCallback, useRef } from 'react';

import { MenuItem, makeStyles } from '@material-ui/core';
import CallMergeIcon from '@material-ui/icons/CallMerge';
import { MergeClientsDialog } from '../../dialogs';

const useStyles = makeStyles(() => ({
  root: {
    fontSize: 16,
    lineHeight: '1.4',
    height: 40,
    padding: '25px 25px',
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      marginRight: 15,
    },
  },
}));

export const MergeClientsMenuItem = ({ record, handleCloseRef }) => {
  const classes = useStyles();

  const changeOpenRef = useRef();

  const handleOpenDialog = useCallback(() => {
    changeOpenRef.current(true);
  }, []);

  return (
    <>
      <MenuItem className={classes.root} onClick={handleOpenDialog}>
        <CallMergeIcon width={24} height={24} /> Объединить пользователей
      </MenuItem>
      <MergeClientsDialog
        data={record}
        changeOpenRef={changeOpenRef}
        handleCloseRef={handleCloseRef}
      />
    </>
  );
};
