import React, { useMemo } from 'react';
import { LineDateChart } from '../LineDate';

const datasetLabels = {
  totalOwners: 'Общее количество собственников',
  doNotDisturbOwners: 'Собственников - не беспокоить',
  activeOwners: 'Собственников - в работе',
  refusedOwners: 'Собственников - отказались',
  deferredOwners: 'Собственников - отложенных',
  doNotBuySellOwners: 'Собственников - не покупает/не продаёт',
};

export const SectorOwnersChart = ({ record }) => {
  const filter = useMemo(() => {
    return { SectorId: record.id };
  }, [record.id]);

  return (
    <LineDateChart datasetLabels={datasetLabels} resource="realtysectors/stats" filter={filter} />
  );
};
