import React, { useMemo } from 'react';

import {
  RemoveCircle as RemoveCircleIcon,
  DoneAll as DoneAllIcon,
  DeleteOutline as DeleteOutlineIcon,
} from '@material-ui/icons';
import { makeStyles, Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '16px',
    color: (props) => {
      const status = props.sysStatus;

      if (status === 200) {
        return theme.palette.success['main'];
      }

      if (status === 100) {
        return theme.palette.error['main'];
      }

      return theme.palette.error['main'];
    },
  },
}));

export const ListItemPublicStatus = ({ record }) => {
  const sysStatus = useMemo(() => {
    return Number(record.sysStatus);
  }, [record.sysStatus]);

  const classes = useStyles({ sysStatus });

  let tooltipText = 'Удален';

  if (sysStatus === 100) {
    tooltipText = 'Не опубликован';
  }

  if (sysStatus === 200) {
    tooltipText = 'Опубликован';
  }

  const icon = useMemo(() => {
    if (!sysStatus) {
      return null;
    }

    if (sysStatus === 100) {
      return <RemoveCircleIcon fontSize="inherit" />;
    }

    if (sysStatus === 200) {
      return <DoneAllIcon fontSize="inherit" />;
    }

    return <DeleteOutlineIcon fontSize="inherit" />;
  }, [sysStatus]);

  if (!record.sysStatus) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Tooltip title={tooltipText} arrow>
        {icon}
      </Tooltip>
    </div>
  );
};
