import React from 'react';
import { MenuItem, Select, FormControl, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import MaterialPagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles(() =>
  createStyles({
    pagination: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    paginationStatus: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '30px',
    },
    paginationStatusTitle: {
      marginRight: '12px',
    },
    '@media (max-width: 960px)': {
      pagination: {
        flexDirection: 'column',
        alignItems: 'center',
        paddingRight: '12px',
        paddingLeft: '12px',
      },
      paginationStatus: {
        marginBottom: '12px',
      },
    },
  }),
);

export const Pagination = (props) => {
  const classes = useStyles();
  const { state, api } = props;

  return (
    <div className={classes.pagination}>
      <div className={classes.paginationButtons}>
        <MaterialPagination
          count={state.pagination.pageCount}
          page={state.pagination.page + 1}
          pageSize={state.pagination.pageSize}
          onChange={(_, value) => api.current.setPage(value - 1)}
          variant="outlined"
          shape="rounded"
          color="primary"
        />
      </div>
      <div className={classes.paginationStatus}>
        <Typography variant="body2" component="h2" className={classes.paginationStatusTitle}>
          На странице:
        </Typography>
        <FormControl className={classes.paginationPerPage}>
          <Select
            value={state.pagination.pageSize}
            onChange={(e) => {
              api.current.setPageSize(e.target.value);
              api.current.setPage(0);
            }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  );
};
