import React from 'react';

import { FormCMSContacts } from '../../../../components/forms';
import { Edit } from '../../../../components/common';

export const EditCMSContacts = (props) => {
  return (
    <Edit {...props}>
      <FormCMSContacts {...props} redirect={false} />
    </Edit>
  );
};
