import React, { useEffect, useMemo } from 'react';
import { FormTab } from 'react-admin';

import { TextInput } from '../../TextInput/TextInput';
import { FormTitle, Map, TabbedForm, RichText } from '../../common';

import { validates } from '../../../utils';
import { SeoTab } from '../../formTabs';
import { FormRow } from '../../FormRow';
import { ConstantSelect, AutocompleteWithFormRow } from '../../app';
import { publicationStatuses } from '../../../constants';
import { FormCard } from '../../FormCard';
import { ListItem } from '../../app/ListItems';

const TreeItem = ({ item }) => {
  return (
    <div style={{ marginBottom: '16px' }}>
      <ListItem record={item} hasButtons={false} resource="GeoSeeds" />
    </div>
  );
};

export const GeoTree = ({ parents = [], childrens = [], current }) => {
  const parentElements = useMemo(() => {
    return parents.map((item) => {
      return <TreeItem item={item} />;
    });
  }, [parents]);

  const childrenElements = useMemo(() => {
    return childrens.map((item) => {
      return <TreeItem item={item} />;
    });
  }, [childrens]);

  return (
    <>
      <FormCard>
        {parents.length > 0 && (
          <>
            <FormTitle>Родители</FormTitle>
            {parentElements}
          </>
        )}
        <FormTitle>Текущий элемент</FormTitle>
        <TreeItem item={current} />
        {childrens.length > 0 && (
          <>
            <FormTitle>Дети</FormTitle>
            {childrenElements}
          </>
        )}
      </FormCard>
    </>
  );
};

export const GeoTreeTexts = ({ parents = [], childrens = [], current }) => {
  const parentElements = useMemo(() => {
    return parents.map((item) => {
      return <>{`${item?.type?.shortName || ''} ${item.name}, `}</>;
    });
  }, [parents]);

  const childrenElements = useMemo(() => {
    return childrens.map((item) => {
      return <>{`, ${item?.type?.shortName || ''} ${item.name}`}</>;
    });
  }, [childrens]);

  return (
    <>
      {parents.length > 0 && <>{parentElements}</>}
      {
        <mark style={{ backgroundColor: 'transparent', textDecoration: 'underline' }}>
          {current?.type?.shortName || ''} {current.name}
        </mark>
      }
      {childrens.length > 0 && <>{childrenElements}</>}
    </>
  );
};

export const FormGeoSeeds = (props) => {
  const { id, location, record } = props;

  useEffect(() => {
    // fix for map
    window.dispatchEvent(new Event('resize'));
  }, [location]);

  return (
    <TabbedForm {...props}>
      <FormTab label="Основное">
        <FormCard>
          {id && (
            <FormRow>
              <TextInput source="id" label="Id" disabled />
            </FormRow>
          )}
          <FormRow>
            <TextInput source="slug" label="Слаг" validate={validates.slug} />
          </FormRow>
          <AutocompleteWithFormRow
            {...props}
            source="parent"
            resourceName="GeoSeeds"
            resourceId="parentId"
            componentProps={{
              label: 'Родительская география',
              needLoadDefaultValue: true,
              defaultValue: record.parentId,
            }}
          />
          <AutocompleteWithFormRow
            {...props}
            source="type"
            resourceName="GeoSeedTypes"
            resourceId="typeId"
            componentProps={{
              label: 'Тип',
            }}
            validate={validates.required}
          />
          <AutocompleteWithFormRow
            {...props}
            source="geoTags"
            resourceName="GeoTags"
            resourceId="geoTagId"
            additionalSource="geoTag"
            componentProps={{
              label: 'Локация',
              multiple: true,
              filterSelectedOptions: true,
            }}
          />
          <FormRow>
            <ConstantSelect
              source="sysStatus"
              objectChoices={publicationStatuses}
              label="Статус публикации"
              defaultValue={100}
            />
          </FormRow>
          <FormRow>
            <TextInput
              source="sortOrder"
              label="Порядок сортировки"
              type="number"
              defaultValue={1000}
            />
          </FormRow>
          <FormRow>
            <TextInput source="name" label="Название" validate={validates.name} />
          </FormRow>
          <FormRow>
            <TextInput source="shortName" label="Краткое название" validate={validates.shortName} />
          </FormRow>
          <FormTitle>Описание</FormTitle>
          <FormRow maxWidth={800} isLast>
            <RichText source="description" />
          </FormRow>
        </FormCard>
      </FormTab>
      <FormTab label="Локация">
        <FormCard>
          <Map {...props} />
        </FormCard>
      </FormTab>
      <FormTab label="SEO">
        <FormCard>
          <SeoTab />
        </FormCard>
      </FormTab>
      <FormTab label="Дерево">
        <GeoTree
          parents={record.parentGeoSeeds}
          childrens={record.childGeoSeeds}
          current={record}
        />
      </FormTab>
    </TabbedForm>
  );
};
