// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useCallback } from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { ListBase as RAListBase, useListContext, getResources } from 'react-admin';

import { Preloader, CreateButton } from '..';

import ListBaseHeader from './Header';
import ListBasePagination from './Pagination';
import { SelectedItemsMenu } from '../app/SelectedItemsMenu';
import { DefaultSelectedMenu } from '../app/SelectedMenus';
import { Checkbox } from '../common/Checkbox';
// import { ExportButton } from '../buttons';
// import { checkRoleAdminOrSupervisor } from '../../utils';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      boxSizing: 'border-box',
      position: 'relative',
    },
    content_container: {
      flex: '1 1 0',
      boxSizing: 'border-box',
      zIndex: 0,
      position: 'relative',
      minHeight: '300px',
    },
    content: {
      overflow: 'auto',
      height: '100%',
      position: 'relative',
    },
    footer: {
      display: 'flex',
      alignItems: 'center',
      minHeight: 56,
      flex: '0 1 auto',
      boxSizing: 'border-box',
      padding: 12,
    },
    title: {
      display: 'flex',
      alignItems: 'baseline',
      flex: '0 1 auto',
      boxSizing: 'border-box',
      justifyContent: 'space-between',
      padding: (props) => (props.hasCheckbox ? '4px 12px 4px 8px' : '12px 12px 20px 12px'),
    },
    titleText: {
      fontSize: '30px',
      lineHeight: '14px',
    },
    total: {
      fontSize: '18px',
      lineHeight: '14px',
    },
    groupButton: {
      marginLeft: 24,
    },
    exportContainer: {
      marginLeft: 24,
    },
    listBaseHeader: {
      zIndex: 1,
      padding: 12,
      boxSizing: 'border-box',
    },
    main: {
      height: '100%',
      paddingTop: '20px',
      boxSizing: 'border-box',
    },
    '@media (max-width: 1024px)': {
      footer: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
      groupButton: {
        marginTop: 24,
        marginLeft: 0,
      },
      exportContainer: {
        marginTop: 12,
      },
    },
    '@media (max-width: 960px)': {
      footer: {
        alignItems: 'center',
        paddingBottom: 12,
      },
      groupButton: {},
    },
  }),
);

const getChecked = (ids = [], selectedIds = []) => {
  const idsLength = ids.length;
  let increment = 0;
  ids.forEach((id) => {
    if (selectedIds.includes(id)) {
      increment++;
    }
  });

  if (idsLength === increment) {
    return true;
  }

  return false;
};

const ListBaseContainer = (props) => {
  const {
    hasToggleView,
    hasSearchVariants,
    selectedMenuOptions = DefaultSelectedMenu,
    hasPagination = true,
    searchBy,
    hasFilters,
    customHeaderContent,
    tableView,
    cardRender,
    tableRender,
    children,
    permissions,
    hasDelete = true,
    basePath,
    hasCheckbox: hasCheckboxProp = true,
    // hasExport = false,
    // filter,
    hasKanban,
  } = props;
  const [isCardView, setIsCardView] = useState(!tableView);
  const listContext = useListContext();
  const {
    loading,
    ids = [],
    hasCreate,
    filterValues,
    selectedIds,
    resource,
    data,
    total,
    onSelect,
    onToggleItem,
  } = listContext;

  // const isAdmin = useMemo(() => {
  //   return checkRoleAdminOrSupervisor(permissions);
  // }, [permissions]);

  const filtersLength = useMemo(() => {
    return Object.keys(filterValues).length;
  }, [filterValues]);

  const hasCheckbox = useMemo(() => {
    return hasCheckboxProp && ids.length > 0;
  }, [hasCheckboxProp, ids.length]);

  const classes = useStyles({ hasCheckbox });
  const resources = useSelector(getResources);

  const titleText = useMemo(() => {
    const path = basePath.replace('/', '');

    const foundItem = resources.find((resourceItem) => {
      return resourceItem.name === path;
    });

    if (foundItem) {
      return foundItem.options?.label;
    }

    return path;
  }, [basePath, resources]);

  const checked = useMemo(() => {
    return getChecked(ids, selectedIds);
  }, [ids, selectedIds]);

  const handleChangeChecked = useCallback(
    (value) => {
      if (value) {
        onSelect([...new Set(selectedIds.concat(ids))]);
        return;
      }

      ids.forEach((id) => {
        onToggleItem(id);
      });
    },
    [ids, onSelect, onToggleItem, selectedIds],
  );

  return (
    <div className={classes.root}>
      <ListBaseHeader
        classes={{ root: classes.listBaseHeader }}
        isCardView={isCardView}
        onSetCardView={setIsCardView}
        hasToggleView={hasToggleView}
        filtersLength={filtersLength}
        hasSearchVariants={hasSearchVariants}
        searchBy={searchBy}
        hasFilters={hasFilters}
        customHeaderContent={customHeaderContent}
        basePath={basePath}
        hasKanban={hasKanban}
      />
      <div className={classes.title}>
        <span className={classes.titleText}>
          {hasCheckbox && <Checkbox checked={checked} onChange={handleChangeChecked} />}
          {titleText}
        </span>
        <span className={classes.total}>Всего: {total || '0'}</span>
      </div>
      <div className={classes.content_container}>
        {(loading || ids.length === 0) && <Preloader loading={loading} />}
        <div className={classes.content}>
          {ids.length > 0
            ? hasToggleView
              ? isCardView
                ? cardRender
                : tableRender
              : children
            : null}
          {ids.length === 0 && <span>По выбранному запросу ничего не найдено!</span>}
        </div>
      </div>
      <div className={classes.footer}>
        {hasCreate ? <CreateButton /> : null}
        {/* {hasActions && <Actions />} */}
        {hasPagination && ids.length > 0 && <ListBasePagination />}
        {selectedMenuOptions && selectedIds.length > 0 && (
          <div className={classes.groupButton}>
            <SelectedItemsMenu
              menuOptions={selectedMenuOptions}
              selectedIds={selectedIds}
              resource={resource}
              data={data}
              permissions={permissions}
              hasDelete={hasDelete}
            />
          </div>
        )}
        {/* {hasExport && ids.length > 0 && isAdmin && (
          <div className={classes.exportContainer}>
            <ExportButton filter={filter} filterValues={filterValues} resource={resource} />
          </div>
        )} */}
      </div>
    </div>
  );
};

export const ListBase = (props) => {
  const classes = useStyles();

  return (
    <RAListBase
      perPage={25}
      classes={{ main: classes.main, root: classes.main }}
      hasActions={false}
      {...props}
      sort={{}}
    >
      <ListBaseContainer {...props} />
    </RAListBase>
  );
};
