import React, { useContext, useState, useEffect, useMemo, useCallback } from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { useDataProvider, useRedirect } from 'react-admin';
import { ClientsWebsocketContext, TouchesWebsocketContext } from '../../providers';
import { getValueByDepthKey } from '../../../utils';
import { currentUser } from '../../../authProvider';

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    width: '314px',
    top: 50,
    right: 10,
    height: 'calc(100% - 50px)',
    boxSizing: 'border-box',
    zIndex: 9999,
    background: '#fff',
    boxShadow: '0px 4px 11px rgb(0 0 0 / 10%)',
  },
  notificationItem: {
    padding: '16px',
    borderRadius: '8px',
    background: '#fff',
    boxShadow: '0px 4px 11px rgba(0, 0, 0, 0.1)',
    marginBottom: '16px',
    cursor: 'pointer',

    '&:last-child': {
      marginBottom: 0,
    },
  },
  info: {
    display: 'flex',
    justifyContent: 'space-between',
    color: 'rgba(33, 33, 33, 0.54)',
    fontSize: '12px',
    alignItems: 'center',
    marginTop: '8px',

    '&:first-child': {
      marginTop: 0,
    },
  },
  label: {
    flex: '0 1 50%',
  },
  text: {
    flex: '0 1 50%',
    textAlign: 'center',
    background: 'rgba(33, 33, 33, 0.12)',
    borderRadius: '2px',
    padding: '4px',
    color: '#000',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#000',
    padding: '0 16px 0 16px',
  },
  activeCall: {
    position: 'fixed',
    bottom: '50px',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 99999,
    background: '#fff',
    boxShadow: '0px 4px 11px rgb(0 0 0 / 10%)',
    width: '314px',
    boxSizing: 'border-box',
  },
  activeCallHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#000',
    paddingLeft: '16px',
  },
  content: {
    overflow: 'auto',
    padding: '8px 16px 16px 16px',
    height: 'calc(100% - 50px)',
    boxSizing: 'border-box',
  },
}));

const fields = [
  {
    label: 'Номер телефона:',
    key: 'externalPhoneNumber',
    id: 1,
  },
  {
    label: 'Имя:',
    key: 'client.name',
    id: 2,
  },
  {
    label: 'Статус:',
    key: 'client.isClient',
    text: 'Клиент',
    id: 3,
  },
  {
    label: 'Статус:',
    key: 'client.isOwner',
    text: 'Собственник',
    id: 4,
  },
  {
    label: 'Статус:',
    key: 'client.isRepresentative',
    text: 'Представитель',
    id: 5,
  },
];

const clientFields = [
  {
    label: 'Номер телефона:',
    key: 'phone',
    id: 1,
  },
  {
    label: 'Имя:',
    key: 'name',
    id: 2,
  },
  {
    label: 'Статус:',
    key: 'isClient',
    text: 'Клиент',
    id: 3,
  },
  {
    label: 'Статус:',
    key: 'isOwner',
    text: 'Собственник',
    id: 4,
  },
  {
    label: 'Статус:',
    key: 'isRepresentative',
    text: 'Представитель',
    id: 5,
  },
];

export const TouchNotifications = ({ visible, onChangeCount, changeVisible }) => {
  const classes = useStyles();
  const [clients, setClients] = useState([]);

  const dataProvider = useDataProvider();
  const newClient = useContext(ClientsWebsocketContext);

  useEffect(() => {
    const fetch = async () => {
      try {
        const result = await dataProvider.getNotificationClients();
        if (result.data.length > 0) {
          setClients(result.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = useCallback(() => {
    if (changeVisible) {
      changeVisible(false);
    }
  }, [changeVisible]);

  useEffect(() => {
    if (newClient?.user) {
      const newUser = newClient.user;
      setClients((prevClients) => {
        if (newUser.processStatus === 100) {
          const foundItem = prevClients.find((client) => {
            return client.id === newUser.id;
          });
          if (!foundItem) {
            return [newUser, ...prevClients];
          }
        } else {
          return prevClients.filter((client) => {
            return client.id !== newUser.id;
          });
        }

        return [...prevClients];
      });
    }
  }, [newClient]);

  useEffect(() => {
    if (onChangeCount) {
      onChangeCount(clients.length);
    }
  }, [clients, onChangeCount]);

  const clientElements = useMemo(() => {
    return clients.map((client) => {
      return <ClientNotificationItem client={client} key={client.id} />;
    });
  }, [clients]);

  if (clients.length === 0 || !visible) {
    return null;
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <span>Необработанные клиенты</span>
        <div className={classes.closeButton}>
          <IconButton color="inherit" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div className={classes.content}>{clientElements}</div>
    </div>
  );
};

const ClientNotificationItem = ({ client }) => {
  const classes = useStyles();
  const redirect = useRedirect();

  const handleClick = useCallback(() => {
    redirect(`/Clients/${client.id}`);
  }, [client.id, redirect]);

  const elements = useMemo(() => {
    return clientFields.map((field) => {
      const text = getValueByDepthKey(client, field.key);

      if (!text) {
        return null;
      }

      return (
        <div className={classes.info} key={field.id}>
          <span className={classes.label}>{field.label}</span>
          <span className={classes.text}>
            {field.text || getValueByDepthKey(client, field.key)}
          </span>
        </div>
      );
    });
  }, [classes.info, classes.label, classes.text, client]);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div className={classes.notificationItem} onClick={handleClick}>
      {elements}
    </div>
  );
};

const TouchNotificationItem = ({ touch }) => {
  const classes = useStyles();
  const redirect = useRedirect();

  const handleClick = useCallback(() => {
    redirect(`/Clients/${touch.client.id}`);
  }, [redirect, touch.client.id]);

  const elements = useMemo(() => {
    return fields.map((field) => {
      const text = getValueByDepthKey(touch, field.key);

      if (!text) {
        return null;
      }

      return (
        <div className={classes.info} key={field.id}>
          <span className={classes.label}>{field.label}</span>
          <span className={classes.text}>{field.text || getValueByDepthKey(touch, field.key)}</span>
        </div>
      );
    });
  }, [classes.info, classes.label, classes.text, touch]);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div className={classes.notificationItem} onClick={handleClick}>
      {elements}
    </div>
  );
};

export const ActiveCall = () => {
  const classes = useStyles();

  const [activeCall, setActiveCall] = useState();

  const handleClose = useCallback(() => {
    setActiveCall(null);
  }, []);

  const newTouch = useContext(TouchesWebsocketContext);

  useEffect(() => {
    if (
      newTouch &&
      newTouch.eventType === 200 &&
      newTouch.clientTouch?.isInDirection === true &&
      newTouch.clientTouch?.employeeId === currentUser.id
    ) {
      setActiveCall(newTouch.clientTouch);
    }
  }, [newTouch]);

  useEffect(() => {
    let timerId;
    if (activeCall) {
      timerId = setTimeout(() => {
        handleClose();
      }, 10000);
    }

    if (timerId && !activeCall) {
      clearTimeout(timerId);
    }
  }, [handleClose, activeCall]);

  if (!activeCall) {
    return null;
  }

  return (
    <div className={classes.activeCall}>
      <div className={classes.activeCallHeader}>
        <span>Активный звонок</span>
        <div className={classes.closeButton}>
          <IconButton color="inherit" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <TouchNotificationItem touch={activeCall} />
    </div>
  );
};
