import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ListItemMainBlock, SortOrderListItem } from '../..';
import { getDiscountDates } from '../../../../utils';
import { DiscountValueField } from '../../../RAFields';
import { ListItemRentStatus, ListItemSellStatus } from '../../ListItemStatuses';

const useStyles = makeStyles((theme) => ({
  buttons: {
    flex: '0 0 auto',
    padding: '0 12px',
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('1024')]: {
      flexWrap: 'wrap',
    },
  },
}));

export const ListItemDiscount = ({ record, hasButtons }) => {
  const classes = useStyles();

  const descriptions = [<DiscountValueField record={record} />];

  if (record.start || record.end) {
    descriptions.push(getDiscountDates(record.start, record.end));
  }

  const statuses = [];

  if (record.forSale) {
    statuses.push(<ListItemSellStatus />);
  }

  if (record.forRent) {
    statuses.push(<ListItemRentStatus />);
  }

  const link = `/RealtyObjectDiscounts/${record.id}`;

  return (
    <>
      <ListItemMainBlock
        record={record}
        imagePath={record.photo}
        descriptions={descriptions}
        statuses={statuses}
        hasButtons={hasButtons}
        link={link}
      />
      <div className={classes.buttons}>
        <div className={classes.buttonsItem}>
          <SortOrderListItem sortOrder={record.sortOrder} />
        </div>
      </div>
    </>
  );
};
