import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import { ListItemMainBlock, SortOrderListItem } from '../..';
import { getFormattedNumber, getMillions, getVillageLink } from '../../../../utils';
import { GoodSwitch } from '../../Switches';
import { Button } from '../../../buttons';

const useStyles = makeStyles((theme) => ({
  buttons: {
    flex: '0 0 auto',
    padding: '0 12px',
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('1024')]: {
      flexWrap: 'wrap',
    },
  },
  buttonsItem: {
    padding: '4px 12px',
    borderLeft: '1px solid #E0E0E0',

    '&:first-child': {
      paddingLeft: 0,
      borderLeft: 0,
    },

    '&:last-child': {
      paddingRight: 0,
    },
  },
}));

export const ListItemVillage = ({
  record,
  depthKey,
  originalRecord,
  hasButtons,
  onChangeItems,
  page,
}) => {
  const classes = useStyles();

  const imagePath = record.images?.[0]?.path;
  const link = `/RealtyGroups/${record.id}`;

  const contentStatuses = [];

  if (record.idYu) {
    contentStatuses.push(`YUcrm`);
  }

  const mainTexts = [];

  if (record.isVillagio) {
    mainTexts.push(`Villagio`);
  }

  const descriptions = [];

  if (record.stats?.priceMin && record.stats?.priceMax) {
    descriptions.push(
      `${getFormattedNumber(getMillions(record.stats.priceMin))} - ${getFormattedNumber(
        getMillions(record.stats.priceMax),
      )} (М/₽)`,
    );
  }

  if (record.area) {
    descriptions.push(`${record.area} (сот.)`);
  }

  if (record.distance) {
    descriptions.push(`${record.distance} (км. МКАД)`);
  }

  const handleChange = useCallback(
    (value, name) => {
      const newItem = { ...originalRecord };
      newItem[name] = value;
      newItem.clientStatus = 'edit';
      if (onChangeItems) {
        onChangeItems(newItem);
      }
    },
    [onChangeItems, originalRecord],
  );

  const linkToSite = getVillageLink(record);

  let comment;

  if (page === 'shows') {
    comment = originalRecord.comment;
  }

  return (
    <>
      <ListItemMainBlock
        record={record}
        descriptions={descriptions}
        hasTaskStatus
        imagePath={imagePath}
        link={link}
        depthKey={depthKey}
        hasButtons={hasButtons}
        comment={comment}
        contentStatuses={contentStatuses}
      />
      <div className={classes.buttons}>
        {/* {page === 'shows' && (
          <div className={classes.buttonsItem}>
            <DemonstratedSwitch onChange={handleChange} checked={originalRecord.isDemonstrated} />
          </div>
        )} */}
        {page !== 'shows' && linkToSite && (
          <div className={classes.buttonsItem}>
            <Button href={linkToSite} target="_blank" size="small">
              Сайт
            </Button>
          </div>
        )}
        {page === 'shows' && (
          <div className={classes.buttonsItem}>
            <GoodSwitch onChange={handleChange} checked={originalRecord.isGood} />
          </div>
        )}
        <div className={classes.buttonsItem}>
          <SortOrderListItem sortOrder={originalRecord.sortOrder} />
        </div>
      </div>
    </>
  );
};
