import React from 'react';

import { FormSectors } from '../../../components/forms';
import { Create } from '../../../components/common';

export const CreateSectors = (props) => {
  return (
    <Create {...props}>
      <FormSectors {...props} redirect="list" />
    </Create>
  );
};
