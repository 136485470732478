import React from 'react';
import { Edit } from '../../../components/common';
import { FormUsers } from '../../../components/forms';

export const EditUsers = (props) => {
  return (
    <Edit {...props}>
      <FormUsers {...props} redirect={false} />
    </Edit>
  );
};
