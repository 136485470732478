import React, { useMemo } from 'react';
import { LineDateChart } from '../LineDate';

const datasetLabels = {
  totalTaasks: 'Общее количество задач',
  approvedTaasksToday: 'Всего задач, подтверждённых, в эти сутки',
  createdTaasksToday: 'Созданных задач в эти сутки',
  inProgressTaasks: 'Созданных ранее этих суток, но ещё не выполненных и не просроченных',
  outdatedTaasks: 'Просроченных незаконченных задач всего',
  outdatedTaasksToday: 'Просроченных задач в эти сутки',
  completedTaasks: 'Завершённых, но не подтверждённых всего',
  completedTaasksToday: 'Завершённых, но не подтверждённых в эти сутки',
};

export const SectorTasksChart = ({ record }) => {
  const filter = useMemo(() => {
    return { SectorId: record.id };
  }, [record.id]);

  return (
    <LineDateChart datasetLabels={datasetLabels} resource="realtysectors/stats" filter={filter} />
  );
};
