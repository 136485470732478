import React from 'react';
import { Edit as RAEdit, useRefresh } from 'react-admin';

export const Edit = (props) => {
  const refresh = useRefresh();
  const { children } = props;

  return (
    <RAEdit
      actions={null}
      mutationMode="pessimistic"
      title="Редактирование"
      onSucces={() => {
        refresh();
      }}
      {...props}
    >
      {children}
    </RAEdit>
  );
};
