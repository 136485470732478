import React, { useState } from 'react';

import {
  Dialog as MaterialDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';

import { Button } from '../../buttons';

const useStyles = makeStyles((theme) => ({
  dialogTitleRoot: {
    borderBottom: '1px solid #E0E0E0;',
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
  },
  dialogActionsRoot: {
    borderTop: '1px solid #E0E0E0;',
    padding: '16px 24px',
  },
  dialogContentRoot: {
    padding: '20px 24px',
  },
}));

export const Dialog = ({
  title,
  changeOpenRef,
  children,
  buttons,
  dialogProps,
  onClose,
  closeButtonText = 'Закрыть',
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  if (changeOpenRef) {
    changeOpenRef.current = setOpen;
  }

  const handleClose = () => {
    setOpen(false);
    onClose?.();
  };

  return (
    <MaterialDialog {...dialogProps} open={open} onClose={handleClose}>
      <DialogTitle classes={{ root: classes.dialogTitleRoot }}>{title}</DialogTitle>
      <DialogContent classes={{ root: classes.dialogContentRoot }}>{children}</DialogContent>
      <DialogActions classes={{ root: classes.dialogActionsRoot }}>
        <Button color="default" onClick={handleClose}>
          {closeButtonText}
        </Button>
        {buttons}
      </DialogActions>
    </MaterialDialog>
  );
};
