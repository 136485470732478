import React from 'react';
import { makeStyles } from '@material-ui/core';
import { getFormattedDate } from '../../../utils';

const useStyles = makeStyles(() => ({
  root: {
    lineHeight: '18px',
  },
  author: {
    display: 'block',
    whiteSpace: 'nowrap',
    color: '#9E9E9E',
    fontSize: '11px',
  },
  // eslint-disable-next-line no-dupe-keys
  author: (props) => {
    return (
      props.inline && {
        fontSize: '11px',
        color: '#9E9E9E',
      }
    );
  },
  additionalText: {
    display: 'block',
    color: '#9E9E9E',
    fontSize: '11px',
    whiteSpace: 'nowrap',

    '&:last-child': {
      paddingLeft: (props) => (props.inline ? '5px' : '0'),
    },
  },
  rows: {
    display: 'flex',
    flexDirection: (props) => (props.inline ? 'row' : 'column'),
  },
}));

const addBrackets = (string, need = false) => {
  if (!need) {
    return string;
  }

  return `(${string})`;
};

export const AuthorField = (props) => {
  const { record, source, inline } = props;
  const classes = useStyles(props);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.rows}>
          <span className={classes.author}>
            {inline && 'Создано: '}
            {record[source]}
          </span>
          <span className={classes.additionalText}>
            {addBrackets(
              getFormattedDate(record.createdDate, {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }),
              inline,
            )}
          </span>
        </div>
        {record.lastModifiedDate && (
          <div className={classes.rows}>
            <span className={classes.additionalText}>Изменено: {record.lastModifiedBy}</span>
            <span className={classes.additionalText}>
              {addBrackets(
                getFormattedDate(record.lastModifiedDate, {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }),
                inline,
              )}
            </span>
          </div>
        )}
      </div>
    </>
  );
};
