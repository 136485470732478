import React from 'react';
import { ClearSelectedMenuItem, DeleteAllSelectedMenuItem } from '../../../menuItems';
// import { SelectAllMenuItem } from '../../../menuItems/SelectAll';

export const DefaultSelectedMenu = ({ selectedIds, resource, onClose, hasDelete }) => {
  return (
    <>
      {/* <SelectAllMenuItem resource={resource} handleClose={handleClose}/> */}
      <ClearSelectedMenuItem resource={resource} onClose={onClose} />
      {hasDelete && (
        <DeleteAllSelectedMenuItem
          selectedIds={selectedIds}
          resource={resource}
          onClose={onClose}
        />
      )}
    </>
  );
};
