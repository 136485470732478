import React, { useMemo } from 'react';
import { InputHelperText } from 'react-admin';

import { makeStyles, FormHelperText } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    marginLeft: '14px',
    marginRight: '14px',
    margin: ({ styleAdditionalTable }) => (styleAdditionalTable ? '8px 0 0 0' : '0 14px'),
  },
}));

export const HelperText = ({ touched, error, submitError, helperText, styleAdditionalTable }) => {
  const classes = useStyles({ styleAdditionalTable });

  const hasError = useMemo(() => {
    return !!(touched && (error || submitError));
  }, [error, submitError, touched]);

  return (
    <>
      {(helperText || hasError) && (
        <FormHelperText error={hasError} classes={{ root: classes.root }}>
          <InputHelperText touched={touched} error={error || submitError} helperText={helperText} />
        </FormHelperText>
      )}
    </>
  );
};
