import React, { useMemo, useRef, useCallback } from 'react';
import { FormWithRedirect } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { FormRow } from '../../FormRow';
import { Dialog } from '../Dialog';

import { Button } from '../../buttons';
import { BooleanInput } from '../../common';
import { TextInput } from '../../TextInput';

const useStyles = makeStyles(() => ({
  dialogPaper: {
    maxWidth: 465,
  },
}));

export const ObjectShowRelationDialog = (props) => {
  const classes = useStyles();
  const { changeOpenRef, record = {}, onChange } = props;

  const formRef = useRef();

  const handleSave = useCallback(
    (values) => {
      const newValues = { ...values };
      newValues.clientStatus = 'edit';
      if (onChange) {
        onChange(newValues);
      }
      if (changeOpenRef.current) {
        changeOpenRef.current(false);
      }
    },
    [changeOpenRef, onChange],
  );

  const handleSubmit = useCallback(() => {
    const submit = formRef.current.handleSubmit;
    if (submit) {
      submit();
    }
  }, []);

  const buttons = useMemo(() => {
    return <Button onClick={handleSubmit}>Сохранить</Button>;
  }, [handleSubmit]);

  return (
    <Dialog
      {...props}
      buttons={buttons}
      dialogProps={{ fullWidth: true, classes: { paper: classes.dialogPaper } }}
    >
      <FormWithRedirect
        save={handleSave}
        record={record}
        render={(formProps) => {
          formRef.current = formProps;

          return (
            <>
              <FormRow>
                <BooleanInput label="Понравился?" source="isGood" />
              </FormRow>
              <FormRow maxWidth={800} isLast>
                <TextInput source="comment" label="Комментарий" multiline rows={6} />
              </FormRow>
            </>
          );
        }}
      />
    </Dialog>
  );
};
