import React from 'react';

import { FormObjects } from '../../../components/forms';
import { Create } from '../../../components/common';

export const CreateObjects = (props) => {
  return (
    <Create {...props}>
      <FormObjects {...props} redirect="list" />
    </Create>
  );
};
