import React, { useState, useMemo } from 'react';

export const TouchesWebsocketContext = React.createContext();

export const TouchesWebsocketProvider = ({ children, onSetTouchRef }) => {
  const [touchInfo, setTouchInfo] = useState();

  useMemo(() => {
    if (onSetTouchRef) {
      onSetTouchRef.current = setTouchInfo;
    }
  }, [onSetTouchRef]);

  return (
    <TouchesWebsocketContext.Provider value={touchInfo}>
      {children}
    </TouchesWebsocketContext.Provider>
  );
};
