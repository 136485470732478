import React, { useState, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Cards } from '../../app';
import { Pagination } from '../../common';
import { SelectedItemsMenu } from '../../app/SelectedItemsMenu';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: ({ isRelative }) => (isRelative ? 'relative' : 'absolute'),
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    padding: '0',
    boxSizing: 'border-box',
  },
  content: {
    flex: ({ isRelative }) => (isRelative ? '1 1 auto' : '1 1 0'),
    minHeight: 0,
    overflow: 'auto',
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 56,
    flex: '0 1 auto',
    boxSizing: 'border-box',
    paddingTop: '20px',
  },
  groupButton: {
    marginLeft: 24,
  },
  '@media (max-width: 1024px)': {
    footer: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    groupButton: {
      marginTop: 24,
      marginLeft: 0,
    },
  },
  '@media (max-width: 960px)': {
    footer: {
      alignItems: 'center',
      paddingBottom: 12,
    },
    groupButton: {},
  },
}));

const getPaginationItems = (items, page, perPage) => {
  const start = (page - 1) * perPage;
  const end = page * perPage;

  return items.slice(start, end);
};

export const StaticList = ({
  items: propItems = [],
  contentOptions = {},
  contentElement: contentElementProp = Cards,
  selectedOptions = {},
  hasPagination = true,
  isRelative = false,
}) => {
  const classes = useStyles({ isRelative });

  const { selectedIds = [] } = contentOptions;
  const { selectedMenuOptions, onChangeItems } = selectedOptions;

  const total = useMemo(() => {
    return propItems.length;
  }, [propItems.length]);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  useEffect(() => {
    if (hasPagination) {
      setPage(1);
    }
  }, [perPage, hasPagination]);

  const [items, setItems] = useState(() => {
    if (propItems && hasPagination) {
      return getPaginationItems(propItems, page, perPage);
    }

    return [];
  });

  useEffect(() => {
    if (propItems && hasPagination) {
      setItems(getPaginationItems(propItems, page, perPage));
    }
  }, [hasPagination, page, perPage, propItems]);

  const hasItems = useMemo(() => {
    return propItems.length > 0;
  }, [propItems]);

  const contentElement = useMemo(() => {
    const Element = contentElementProp;

    return (
      <Element
        {...contentOptions}
        items={hasPagination ? items : propItems}
        onChangeItems={onChangeItems}
      />
    );
  }, [contentElementProp, contentOptions, hasPagination, items, onChangeItems, propItems]);

  return (
    <div className={classes.root}>
      <div className={classes.content}>{hasItems ? contentElement : 'Список пуст!'}</div>
      {total !== 0 && (hasPagination || (selectedIds.length > 0 && selectedMenuOptions)) && (
        <div className={classes.footer}>
          {hasPagination && (
            <Pagination
              page={page}
              perPage={perPage}
              total={total}
              setPage={setPage}
              setPerPage={setPerPage}
            />
          )}
          {selectedIds.length > 0 && selectedMenuOptions && (
            <div className={classes.groupButton}>
              <SelectedItemsMenu
                data={propItems}
                selectedIds={selectedIds}
                menuOptions={selectedMenuOptions}
                {...selectedOptions}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
