import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { checkRoleOdp } from '../utils';

const sockets = ['ClientTouches', 'ClientProcessStatuses'];

const WS_URL = process.env.REACT_APP_WS_API_URL;

export const initWebsockets = async (oidc, callbacks) => {
  if (!checkRoleOdp(oidc.profile.role)) {
    return;
  }

  const connections = [];

  sockets.forEach(async (socketName) => {
    if (!(socketName in callbacks)) {
      // eslint-disable-next-line no-alert
      alert('Callback для сокета не найден...');
      return;
    }
    const connection = new HubConnectionBuilder()
      .withUrl(`${WS_URL}${socketName}`, {
        accessTokenFactory: () => oidc.access_token,
      })
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    connections.push({ [socketName]: connection });

    connection.on(socketName, (data) => {
      console.log(data);
      callbacks[socketName].current(JSON.parse(data));
    });

    connection.onclose(() => {
      console.log('ws closed');
    });

    try {
      await connection.start();
      console.log('SignalR Connected.');
    } catch (err) {
      console.log(err);
    }
  });
};
