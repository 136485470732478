import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ListItemMainBlock, SortOrderListItem } from '../..';
import { getSetLink } from '../../../../utils';
import { Button } from '../../../buttons';

const useStyles = makeStyles((theme) => ({
  buttons: {
    flex: '0 0 auto',
    padding: '0 12px',
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('1024')]: {
      flexWrap: 'wrap',
    },
  },
  buttonsItem: {
    padding: '4px 12px',
    borderLeft: '1px solid #E0E0E0',

    '&:first-child': {
      paddingLeft: 0,
      borderLeft: 0,
    },

    '&:last-child': {
      paddingRight: 0,
    },
  },
}));

export const ListItemSet = ({ record, hasButtons }) => {
  const classes = useStyles();

  const descriptions = [];

  const link = `/RealtyObjectSets/${record.id}`;

  if (record.realtyObjects && record.realtyObjects.length > 0) {
    descriptions.push(`Объекты: ${record.realtyObjects.length} (шт.)`);
  }

  const textStatuses = [];

  if (record.realtyObjectSetTypeId === 2) {
    textStatuses.push({ text: `Private`, color: 'red' });
  }

  if (record.realtyObjectSetTypeId === 1) {
    textStatuses.push({ text: `Public`, color: 'green' });
  }

  const linkToSite = getSetLink(record);

  return (
    <>
      <ListItemMainBlock
        record={record}
        descriptions={descriptions}
        textStatuses={textStatuses}
        hasTaskStatus
        link={link}
        imagePath={record.photo}
        hasButtons={hasButtons}
      />
      <div className={classes.buttons}>
        {linkToSite && (
          <div className={classes.buttonsItem}>
            <Button href={linkToSite} target="_blank" size="small">
              Сайт
            </Button>
          </div>
        )}
        <div className={classes.buttonsItem}>
          <SortOrderListItem sortOrder={record.sortOrder} />
        </div>
      </div>
    </>
  );
};
