import React from 'react';

import { FormBaseObjects } from '../../../components/forms';
import { Edit } from '../../../components/common';

export const EditBaseObjects = (props) => {
  return (
    <Edit {...props}>
      <FormBaseObjects {...props} redirect={false} />
    </Edit>
  );
};
