import React from 'react';

import { makeStyles } from '@material-ui/core';
import { DefaultMenu } from '../../Menus';
import { Card } from '../..';
import { DiscountDatesField, DiscountValueField } from '../../../RAFields';
import { StatusFieldRent, StatusFieldSell } from '../../../common/StatusFields';

const useStyles = makeStyles(() => ({
  dates: {
    marginTop: '12px',
    color: '#9E9E9E',
    fontSize: '12px',
  },
  discount: {
    marginTop: '12px',
    fontSize: '14px',

    '& *': {
      fontSize: '14px',
    },
  },
}));

export const DiscountCard = (props) => {
  const styles = useStyles();
  const { data: item, statusFields: statusFieldsProp, resource } = props;

  const statusFields = [...statusFieldsProp];

  const fieldNameProps = {
    name: item.name,
  };

  const menuElement = <DefaultMenu record={item} resource={resource} />;

  if (item.forSale) {
    statusFields.push(<StatusFieldSell />);
  }

  if (item.forRent) {
    statusFields.push(<StatusFieldRent />);
  }

  const imagePath = item.photo;

  return (
    <Card
      {...props}
      fieldNameProps={fieldNameProps}
      statusFields={statusFields}
      menuElement={menuElement}
      imagePath={imagePath}
    >
      <div className={styles.discount}>
        <DiscountValueField record={item} />
      </div>
      <div className={styles.dates}>
        <DiscountDatesField record={item} />
      </div>
    </Card>
  );
};
