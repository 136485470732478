import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { mergeClasses } from '../../../utils';

import defaultPhoto from '../../../assets/images/defaultPhotoSet.png';

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: '4px',
    fontSize: 0,
    lineHeight: 0,
    width: '104px',

    '& img': {
      position: 'relative',
      zIndex: '3',
      borderRadius: 'inherit',
    },
  },
  rootWithCount: {
    position: 'relative',
    background: '#FFFFFF',
    boxSizing: 'border-box',

    '&:before, &:after': {
      position: 'absolute',
      content: '""',
      top: '0',
      left: '4px',
      width: '100%',
      height: '100%',
      background: '#FFFFFF',
      border: '1px solid #BDBDBD',
      boxSizing: 'border-box',
      borderRadius: '4px',
      zIndex: '2',
    },
    '&:after': {
      left: '8px',
      zIndex: '1',
    },
  },
  count: {
    position: 'absolute',
    bottom: '4px',
    right: '4px',
    width: '24px',
    height: '24px',
    fontWeight: 'bold',
    fontSize: '11px',
    lineHeight: '1',
    boxSizing: 'border-box',
    zIndex: '4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(30, 30, 30, 0.6)',
    borderRadius: '2px',
    color: '#fff',
  },
}));

export const PhotoField = ({ hasCounter = false, count = 0, path }) => {
  const classes = useStyles();

  const image = useMemo(() => {
    if (path) {
      return `${process.env.REACT_APP_STATIC_API_URL}cache-new/104x72/${path}`;
    }

    return defaultPhoto;
  }, [path]);

  return (
    <div className={mergeClasses(classes.root, hasCounter && classes.rootWithCount)}>
      <img src={image} alt="#" />
      {hasCounter && <span className={classes.count}>{count}</span>}
    </div>
  );
};
