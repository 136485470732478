import React from 'react';

import { PeopleSelect } from '../People';

const componentProps = {
  label: 'Собственник',
  fullDynamic: 'AllNames',
  providerParams: { filter: { isOwner: true } },
};

export const OwnerSelect = (props) => {
  return (
    <PeopleSelect {...props} source="owner" resourceId="ownerId" componentProps={componentProps} />
  );
};
