import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { FormTab, FormDataConsumer } from 'react-admin';
import { useTheme } from '@material-ui/core';

import { TextInput } from '../../TextInput/TextInput';
import { DateTimePickerInput } from '../../RAInputs';
import { TabbedForm, BooleanInput, RichText } from '../../common';

import {
  validates,
  checkExpired,
  checkRoleAdminOrSupervisor,
  getExpiredCount,
} from '../../../utils';
import { FormRow } from '../../FormRow';
import { AutocompleteWithFormRow, ConstantSelect } from '../../app';
import { taskObjectTypes } from '../../../constants';
import { AssignedBySelect, AssignedToSelect } from '../../RASelects';
import { TaskTypedSelect } from '../../Selects';
import { StatusField, StatusFieldExpired } from '../../common/StatusFields';
import { FormCard } from '../../FormCard';
import { dataProvider } from '../../../dataProvider';
import { Preloader } from '../../Preloader';

export const FormTasks = (props) => {
  const { id, record, location, permissions } = props;

  const [taskType, setTaskType] = useState(() => {
    if (location.state?.type) {
      return location.state.type;
    }

    if (record.taaskObjects) {
      return record.taaskObjects[0].taaskObjectType;
    }
  });

  const [isChangedTaskType, setIsChangedTaskType] = useState(false);

  useEffect(() => {
    if (id) {
      setIsChangedTaskType(false);
      let taskObjectType;
      if (record.taaskObjects) {
        taskObjectType = record.taaskObjects[0].taaskObjectType;
      }
      setTaskType(taskObjectType);
    }
  }, [id, record]);

  const defaultTaskItem = useMemo(() => {
    if (isChangedTaskType) {
      return [];
    }

    if (location.state?.item) {
      return [location.state.item];
    }
  }, [isChangedTaskType, location.state]);

  const handleChangeTaskType = useCallback((event) => {
    setTaskType(event.target.value);
    setIsChangedTaskType(true);
  }, []);

  const isExpired = useMemo(() => {
    return checkExpired(record);
  }, [record]);

  const hasRoleAdminOrSupervisor = useMemo(() => {
    return checkRoleAdminOrSupervisor(permissions);
  }, [permissions]);

  const defaultValueDate = useMemo(() => {
    return new Date(new Date().setHours(23, 55)).toISOString();
  }, []);

  const theme = useTheme();

  const [loadingHasTaskResults, setLoadingHasTaskResults] = useState(false);
  const [hasTaskResults, setHasTaskResults] = useState(false);
  const [type, setType] = useState(record.taaskType);
  const [completed, setCompleted] = useState(record.completedDate);

  const handleChangeType = useCallback(
    (newValue) => {
      const fetch = async () => {
        setLoadingHasTaskResults(true);
        const result = await dataProvider.getList('TaaskResults', {
          pagination: { page: 1, perPage: 1 },
          filter: { TaaskTypes: newValue?.id },
        });
        setHasTaskResults(result.total > 0);
        setLoadingHasTaskResults(false);
      };

      if (newValue?.id || !newValue) {
        setType(newValue);
      }

      if (completed && newValue?.id) {
        fetch();
      }

      if (!newValue) {
        setHasTaskResults(false);
      }
    },
    [completed],
  );

  const handleChangeCompleted = useCallback((newValue) => {
    setCompleted(newValue);
  }, []);

  useEffect(() => {
    if (record.taaskResultId) {
      setHasTaskResults(true);
    }
  }, [record]);

  useEffect(() => {
    if (completed) {
      handleChangeType(type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completed]);

  return (
    <TabbedForm {...props} hideSave={loadingHasTaskResults}>
      <FormTab label="Основное">
        <FormCard>
          <FormRow>
            <StatusField
              styleProps={{
                backgroundColor: record.completedDate
                  ? theme.palette.green['accentFont']
                  : theme.palette.primaryNew['accentFont'],
                fontSize: 16,
              }}
            >
              {record.completedDate ? 'Выполнена' : 'В работе'}
            </StatusField>
            {isExpired && <StatusFieldExpired fontSize={15} count={getExpiredCount(record)} />}
          </FormRow>
          {id && (
            <FormRow>
              <TextInput source="id" label="Id" disabled />
            </FormRow>
          )}
          {/* <FormRow>
            <TextInput source="name" label="Название" validate={validates.name} />
          </FormRow> */}
          <FormRow>
            <DateTimePickerInput
              source="plannedDate"
              label="Планируемая дата выполнения"
              validate={validates.taskPlannedDate}
              defaultValue={defaultValueDate}
              disabled={record.completedDate}
            />
          </FormRow>
          <AssignedBySelect {...props} />
          <AssignedToSelect {...props} />
          <FormRow>
            <ConstantSelect
              source="taaskObjectType"
              objectChoices={taskObjectTypes}
              label="Тип объекта"
              allowEmpty
              onChange={handleChangeTaskType}
              defaultValue={taskType}
            />
          </FormRow>
          <FormDataConsumer>
            {({ formData }) => {
              if (!taskType) {
                delete formData.taaskObjects;
              }

              // Костыль для бэка...
              if (formData?.taaskObjects) {
                formData.taaskObjects = formData.taaskObjects.map((taaskObject) => {
                  return {
                    name: taaskObject.name,
                    taaskObjectId: taaskObject.taaskObjectId,
                    taaskObjectType: taaskObject.taaskObjectType,
                  };
                });
              }

              const taskObjectType = formData.taaskObjectType;

              return (
                taskObjectType && (
                  <FormRow>
                    <TaskTypedSelect
                      selectType={taskObjectType}
                      record={record}
                      defaultValue={defaultTaskItem}
                    />
                  </FormRow>
                )
              );
            }}
          </FormDataConsumer>
          <AutocompleteWithFormRow
            {...props}
            source="taaskType"
            resourceName="TaaskTypes"
            resourceId="taaskTypeId"
            onChange={handleChangeType}
            componentProps={{
              label: 'Тип задачи',
              providerParams: {
                filter: { ...(taskType && { TaaskObjectTypes: taskType }) },
              },
            }}
            // validate={validates.required}
          />
          <FormRow>
            <RichText source="description" />
          </FormRow>
          <AutocompleteWithFormRow
            {...props}
            source="taaskTags"
            resourceName="TaaskTags"
            resourceId="taaskTagId"
            additionalSource="taaskTag"
            componentProps={{
              label: 'Тэги задач',
              multiple: true,
              filterSelectedOptions: true,
            }}
          />
          {!record.completedDate && (
            <FormRow>
              <BooleanInput
                label="Выполнена?"
                source="isCompleted"
                disabled={record.completedDate}
                onChange={handleChangeCompleted}
              />
            </FormRow>
          )}
          <FormDataConsumer>
            {({ formData }) => {
              return (
                (formData.isCompleted || formData.completedDate) && (
                  <>
                    <FormRow>
                      <TextInput
                        source="result"
                        label="Результат выполнения"
                        multiline
                        rows={6}
                        validate={validates.required}
                      />
                    </FormRow>
                  </>
                )
              );
            }}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData }) => {
              return (
                (formData.isCompleted || formData.completedDate) &&
                hasTaskResults && (
                  <>
                    <AutocompleteWithFormRow
                      {...props}
                      source="taaskResult"
                      resourceName="TaaskResults"
                      resourceId="taaskResultId"
                      componentProps={{
                        label: 'Результат выполнения задачи',
                        providerParams: { filter: { TaaskTypes: formData.taaskTypeId } },
                      }}
                      validate={validates.required}
                    />
                  </>
                )
              );
            }}
          </FormDataConsumer>
          {loadingHasTaskResults && (
            <>
              <div style={{ position: 'relative', minHeight: '40px', maxWidth: '40px' }}>
                <Preloader loading={loadingHasTaskResults} size={25} />
              </div>
            </>
          )}
          {hasRoleAdminOrSupervisor && record.completedDate && (
            <FormRow>
              <DateTimePickerInput source="completedDate" label="Дата выполнения" disabled />
            </FormRow>
          )}
        </FormCard>
      </FormTab>
    </TabbedForm>
  );
};
