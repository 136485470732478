import React from 'react';

import { Create } from '../../../components/common';
import { FormPromos } from '../../../components/forms';

export const CreatePromos = (props) => {
  return (
    <Create {...props}>
      <FormPromos {...props} redirect="list" />
    </Create>
  );
};
