import React from 'react';
import { FormTab } from 'react-admin';

import { TextInput } from '../../TextInput/TextInput';
import { RichText, TabbedForm } from '../../common';

import { validates } from '../../../utils';
import { FormRow } from '../../FormRow';
import { AutocompleteWithFormRow, ConstantSelect } from '../../app';
import { publicationStatuses } from '../../../constants';
import { FormCard } from '../../FormCard';

export const FormCMSWidgetTemplates = (props) => {
  const { id } = props;

  return (
    <TabbedForm {...props}>
      <FormTab label="Основное">
        <FormCard>
          {id && (
            <FormRow>
              <TextInput source="id" label="Id" disabled />
            </FormRow>
          )}
          <FormRow>
            <TextInput source="name" label="Название" validate={validates.name} />
          </FormRow>
          <FormRow>
            <ConstantSelect
              source="sysStatus"
              objectChoices={publicationStatuses}
              label="Статус публикации"
              defaultValue={100}
            />
          </FormRow>
          <FormRow>
            <TextInput
              source="sortOrder"
              label="Порядок сортировки"
              type="number"
              defaultValue={1000}
            />
          </FormRow>
          <FormRow maxWidth={800}>
            <RichText source="description" />
          </FormRow>
          <AutocompleteWithFormRow
            {...props}
            source="cmsWidgetTemplateType"
            resourceName="cmsAdmin/widgetTemplateTypes"
            resourceId="cmsWidgetTemplateTypeId"
            componentProps={{
              label: 'Тип шаблона виджета',
            }}
          />
          <AutocompleteWithFormRow
            {...props}
            source="widgetTags"
            resourceName="cmsAdmin/widgetTags"
            resourceId="cmsWidgetTagId"
            additionalSource="cmsWidgetTag"
            componentProps={{
              label: 'Тэги виджетов',
              multiple: true,
              filterSelectedOptions: true,
            }}
          />
        </FormCard>
      </FormTab>
    </TabbedForm>
  );
};
