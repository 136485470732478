import React, { useMemo, useRef } from 'react';
import { FormDataConsumer, FormTab } from 'react-admin';
import { TextInput } from '../../TextInput/TextInput';
import { FormTitle, TabbedForm, BooleanInput } from '../../common';

import { FormRow } from '../../FormRow';
import { DynamicList } from '../../additionalLists';
import {
  AutocompleteWithFormRow,
  CommentsTab,
  ConstantSelect,
  ImageInputWithFormRow,
  List,
  TabWithFooter,
  TasksTab,
} from '../../app';
import { DateTimePickerInput } from '../../RAInputs';
import {
  luxuryClasses,
  clientTouchStatuses,
  clientTouchTargetTypes,
  clientTouchNotTargetTypes,
  clientTouchStatusFilledColors,
  clientTouchTypeFilledColors,
  ownerStatuses,
  clientProcessStatusFilledColors,
  clientProcessStatuses,
} from '../../../constants';
import { FormCard } from '../../FormCard';
import {
  AddCommentDialogButton,
  CreateContractButton,
  CreateTaskDialogButton,
} from '../../buttons';
import { parseBoolean, validates } from '../../../utils';
import { PeopleSelect } from '../../RASelects';
import { dataProvider } from '../../../dataProvider';

const initValues = {
  isClient: true,
};

export const FormClients = (props) => {
  const { id, record, location } = props;

  const contactsTabFooter = useMemo(() => {
    return <CreateContractButton record={record} />;
  }, [record]);

  const oldStatusRef = useRef(
    record?.isTargeted || record.target === 100 || record.target === 200 || record.target === 300,
  );

  const logTabFooter = useMemo(() => {
    return (
      <>
        <CreateTaskDialogButton
          style={{ marginRight: '12px', marginBottom: '8px' }}
          type={600}
          defaultItem={record}
        />
        <AddCommentDialogButton style={{ marginBottom: '8px' }} type={300} id={id} />
      </>
    );
  }, [id, record]);

  return (
    <TabbedForm {...props} initialValues={initValues}>
      <FormTab label="Действия">
        <FormCard>
          <PeopleSelect
            source="expert"
            resourceId="expertId"
            {...props}
            componentProps={{
              label: 'Эксперт клиента',
              providerParams: { filter: { isExpert: true } },
            }}
          />
          <PeopleSelect
            source="odp"
            resourceId="odpId"
            {...props}
            componentProps={{
              label: 'Сотрудник ОДП клиента',
              providerParams: { filter: { isOdp: true } },
            }}
          />
          <FormTitle>Запланировать звонок</FormTitle>
          <FormRow>
            <DateTimePickerInput
              source="deferredEventDate"
              label="Дата запланированного звонка"
              helperText="Дата и время запланированного отложенного звонка"
              pickerType="date"
            />
          </FormRow>
          <FormRow>
            <TextInput
              source="deferredEventComment"
              label="Комментарий к запланированному звонку"
              multiline
              rows={6}
            />
          </FormRow>
          <AutocompleteWithFormRow
            source="clientTags"
            resourceName="clientTags"
            resourceId="clientTagId"
            additionalSource="clientTag"
            {...props}
            componentProps={{
              label: 'Клиентские тэги',
              multiple: true,
              filterSelectedOptions: true,
            }}
          />
          <FormRow>
            <BooleanInput label="Клиент не доступен?" source="isClosed" />
          </FormRow>
          <FormRow>
            <ConstantSelect
              source="luxuryClass"
              objectChoices={luxuryClasses}
              label="Класс недвижимости"
              allowEmpty
            />
          </FormRow>
          <FormRow>
            <ConstantSelect
              source="processStatus"
              objectChoices={clientProcessStatuses}
              label="Статус обработки"
              filledColors={clientProcessStatusFilledColors}
              defaultValue={300}
            />
          </FormRow>
          <FormRow>
            <ConstantSelect
              source="isTargeted"
              objectChoices={clientTouchStatuses}
              label="Статус клиента"
              filledColors={clientTouchStatusFilledColors}
              defaultValue={record.target === 100 || record.target === 200 || record.target === 300}
              parse={parseBoolean}
            />
          </FormRow>
          <FormDataConsumer>
            {({ formData }) => {
              const status = formData?.isTargeted;
              const types = status === true ? clientTouchTargetTypes : clientTouchNotTargetTypes;

              if (status !== oldStatusRef.current) {
                oldStatusRef.current = status;
                formData.target = '';
                setTimeout(() => {
                  delete formData.target;
                }, 1);
              }

              return (
                <FormRow>
                  <ConstantSelect
                    source="target"
                    objectChoices={types}
                    label="Причина обработки"
                    filledColors={clientTouchTypeFilledColors}
                    validate={validates.clientTouchType}
                    defaultValue={10000}
                  />
                </FormRow>
              );
            }}
          </FormDataConsumer>
          <FormRow>
            <TextInput source="realtyComment" label="Комментарий" multiline rows={6} />
          </FormRow>
        </FormCard>
      </FormTab>
      <FormTab label="Данные о клиенте">
        <FormCard>
          {id && (
            <>
              <FormRow>
                <TextInput source="id" label="Id" disabled />
              </FormRow>
              <FormRow>
                <DateTimePickerInput source="createdDate" label="Дата создания" disabled />
              </FormRow>
            </>
          )}
          <ImageInputWithFormRow {...props} source="photo" />
          <FormRow>
            <TextInput source="name" label="Имя" validate={validates.peopleName} />
          </FormRow>
          <FormRow>
            <TextInput source="middleName" label="Отчество" />
          </FormRow>
          <FormRow>
            <TextInput source="lastName" label="Фамилия" />
          </FormRow>
          <FormRow>
            <TextInput source="eMail" label="Эл. почта" />
          </FormRow>
          <FormRow>
            <TextInput
              source="phone"
              label="Телефоны"
              validate={validates.phone}
              helperText="Вводить по маске +7(999)888-77-66. Можно ввести несколько: +7(999)888-77-66, +7(999)888-77-66, +7(999)888-77-66"
            />
          </FormRow>
          <AutocompleteWithFormRow
            {...props}
            source="userActivityType"
            resourceName="userActivityTypes"
            resourceId="userActivityTypeId"
            componentProps={{
              label: 'Род деятельности',
              requestAdd: (value) => {
                return dataProvider.create('UserActivityTypes', {
                  data: { name: value, sortOrder: 1000, sysStatus: 200 },
                });
              },
              getOptionLabel: (option) => {
                if (!option) {
                  return '';
                }

                return `${option.id}: ${option.name || ''}`;
              },
              minSearchLenght: 1,
            }}
          />
          <FormRow>
            <DateTimePickerInput pickerType="date" source="birthDate" label="День рождения" />
          </FormRow>
          <FormRow>
            <TextInput source="telegramId" label="Ссылка на Телеграм" />
          </FormRow>
          <FormRow>
            <TextInput source="carPlates" label="Номера машин" />
          </FormRow>
          <FormRow maxWidth={800}>
            <TextInput source="comment" label="Комментарий" multiline rows={6} />
          </FormRow>
          <FormDataConsumer>
            {({ formData }) => {
              const isOwner = formData.isOwner;
              if (!isOwner) {
                delete formData.ownerStatus;
              }

              return (
                isOwner && (
                  <FormRow>
                    <ConstantSelect
                      source="ownerStatus"
                      objectChoices={ownerStatuses}
                      label="Статус собственника"
                      allowEmpty
                    />
                  </FormRow>
                )
              );
            }}
          </FormDataConsumer>
          <FormTitle>Роли</FormTitle>
          <FormRow>
            <BooleanInput label="Собственник" source="isOwner" />
          </FormRow>
          <FormRow>
            <BooleanInput label="Представитель" source="isRepresentative" />
          </FormRow>
          <FormRow>
            <BooleanInput label="Житель" source="isResident" />
          </FormRow>
          <FormRow>
            <BooleanInput label="Гость" source="isGuest" />
          </FormRow>
        </FormCard>
      </FormTab>
      {id && (
        <FormTab label="Касания">
          <DynamicList
            fetchOptions={{
              resource: 'ClientTouches',
              filter: { ClientId: record.id },
            }}
            contentElement={List}
            contentOptions={{
              type: 'touch',
              hasButtons: false,
              isNewItem: true,
            }}
          />
        </FormTab>
      )}
      {id && (
        <FormTab label="Комментарии">
          <CommentsTab id={id} type={300} />
        </FormTab>
      )}
      {id && (
        <FormTab label="Задачи">
          <TasksTab record={record} type={600} location={location} />
        </FormTab>
      )}
      {id && (
        <FormTab label="Лиды">
          <TabWithFooter footerContent={contactsTabFooter}>
            <DynamicList
              fetchOptions={{
                resource: 'Contracts',
                filter: { MainOrOtherClientId: record.id },
              }}
              contentElement={List}
              contentOptions={{
                type: 'contract',
                hasButtons: false,
                isNewItem: true,
              }}
            />
          </TabWithFooter>
        </FormTab>
      )}
      {id && (
        <FormTab label="Лог">
          <TabWithFooter footerContent={logTabFooter}>
            <DynamicList
              fetchOptions={{
                resource: `peoples/${id}/mergedLog`,
              }}
              contentElement={List}
              contentOptions={{
                hasButtons: false,
                dynamicType: true,
              }}
              perPage={25}
            />
          </TabWithFooter>
        </FormTab>
      )}
    </TabbedForm>
  );
};
