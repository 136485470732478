import React from 'react';
import { FormTab } from 'react-admin';

import { TextInput } from '../../TextInput/TextInput';
import { TabbedForm, RichText, FormTitle, BooleanInput } from '../../common';
import { validates } from '../../../utils';
import { FormRow } from '../../FormRow';
import { FormCard } from '../../FormCard';
import { ConstantSelect } from '../../app';
import { publicationStatuses } from '../../../constants';
import { DateTimePickerInput } from '../../RAInputs';

const initValues = {
  listType: 'OurNews',
};

export const FormOurNews = (props) => {
  const { id } = props;

  return (
    <TabbedForm {...props} initialValues={initValues}>
      <FormTab label="Основное">
        <FormCard>
          {id && (
            <FormRow>
              <TextInput source="id" label="Id" disabled />
            </FormRow>
          )}
          <FormRow>
            <TextInput source="name" label="Название" validate={validates.name} />
          </FormRow>
          <FormRow>
            <ConstantSelect
              source="sysStatus"
              objectChoices={publicationStatuses}
              label="Статус публикации"
              defaultValue={100}
            />
          </FormRow>
          <FormRow>
            <TextInput
              source="sortOrder"
              label="Порядок сортировки"
              type="number"
              defaultValue={1000}
            />
          </FormRow>
          <FormRow>
            <TextInput source="anyJsonDictionary.direction" label="Направление" />
          </FormRow>
          <FormRow>
            <DateTimePickerInput
              pickerType="date"
              source="anyJsonDictionary.date"
              label="Дата"
              validate={validates.ourNewsDate}
            />
          </FormRow>
          <FormRow>
            <TextInput source="anyJsonDictionary.editionName" label="Название издания" />
          </FormRow>
          <FormRow>
            <TextInput source="anyJsonDictionary.articleLink" label="Ссылка на статью" />
          </FormRow>
          <FormTitle>Тело статьи</FormTitle>
          <FormRow maxWidth={800} isLast>
            <RichText source="anyText" hasImage validate={validates.instructionHtml} />
          </FormRow>
          <FormRow>
            <BooleanInput label="Показать на главной" source="anyJsonDictionary.showOnHome" />
          </FormRow>
          <FormRow>
            <BooleanInput label="Показать в комплексах" source="anyJsonDictionary.showOnVillages" />
          </FormRow>
          <FormRow>
            <BooleanInput label="Показать на лендинге МП-8" source="anyJsonDictionary.showOnMP8" />
          </FormRow>
        </FormCard>
      </FormTab>
      <FormTab label="SEO">
        <FormCard>
          <FormRow>
            <TextInput
              source="anyJsonDictionary.seoTitle"
              label="SEO заголовок"
              validate={validates.seoTitle}
            />
          </FormRow>
          <FormRow>
            <TextInput
              source="anyJsonDictionary.seoDescription"
              label="SEO описание"
              multiline
              rows={6}
              validate={validates.seoDescription}
            />
          </FormRow>
        </FormCard>
      </FormTab>
    </TabbedForm>
  );
};
