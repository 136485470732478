import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import { TextField } from '../../common';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '15px',
  },
}));

export const SortOrderCard = ({ onChangeSortOrder, id, defaultValue }) => {
  const classes = useStyles();

  const handleChange = useCallback(
    (value) => {
      if (onChangeSortOrder) {
        onChangeSortOrder(id, value);
      }
    },
    [id, onChangeSortOrder],
  );

  return (
    <div className={classes.root}>
      <TextField
        label="Порядок сортировки"
        type="number"
        InputProps={{ inputProps: { min: '0' } }}
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    </div>
  );
};
