import React, { useMemo } from 'react';
import { Datagrid } from 'react-admin';

import { ListBase } from '../../../components/ListBase';

import { StatusesField } from '../../../components/RAFields/StatusesField';

import { NameField, AuthorField, PhotoField } from '../../../components/RAFields';
import { RAList } from '../../../components/app';
import { DefaultMenu } from '../../../components/app/Menus';
import { currentUser } from '../../../authProvider';
import { checkRoleAdminOrSupervisorOrOdp } from '../../../utils';
import { SectorsSelectedMenu } from '../../../components/app/SelectedMenus/Sectors';

const WrapperPhotoField = ({ record }) => {
  const path = useMemo(() => {
    return record?.images?.[0]?.path;
  }, [record]);

  return <PhotoField path={path} />;
};

const WrapperNameField = ({ record }) => {
  const { id, name } = record;

  return <NameField name={name} id={id} />;
};

export const TableSectors = () => {
  return (
    <Datagrid hasBulkActions>
      <WrapperPhotoField label="Фото" sortable={false} />
      <WrapperNameField source="name" label="Название" sortable={false} />
      <StatusesField source="sysStatus" label="Статус публикации" sortable={false} />
      <AuthorField source="createdBy" label="Автор" sortable={false} />
      <DefaultMenu />
    </Datagrid>
  );
};

const CardsSectors = () => {
  return <RAList type="sector" />;
};

export const ListSectors = (props) => {
  const { permissions } = props;

  const isAdminOrOdp = useMemo(() => {
    return checkRoleAdminOrSupervisorOrOdp(permissions);
  }, [permissions]);

  const filter = useMemo(() => {
    if (!isAdminOrOdp) {
      return { ManagerOrServiceManager: String(currentUser?.id) };
    }

    return {};
  }, [isAdminOrOdp]);

  return (
    <ListBase
      {...props}
      hasToggleView
      tableRender={<TableSectors {...props} />}
      cardRender={<CardsSectors {...props} />}
      selectedMenuOptions={SectorsSelectedMenu}
      filter={filter}
    />
  );
};
