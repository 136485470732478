import React, { useMemo } from 'react';
import { Toolbar, useNotify, SaveButton } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  toolbar: {
    background: '#fff',
    padding: '12px',
    minHeight: 36,
    marginTop: 'auto',
    borderTop: '1px solid #E0E0E0',
  },
  button: {
    fontSize: 14,
    marginRight: 15,
    padding: '6px 12px',
    '& svg': {
      fontSize: '20px',
    },
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
  },
}));

export const TableToolbar = (props) => {
  const classes = useStyles();
  const notify = useNotify();
  const { hideSave, getToolbarButtons, location } = props;

  const ToolbarButtons = useMemo(() => {
    if (getToolbarButtons) {
      return getToolbarButtons(props);
    }

    return null;
  }, [getToolbarButtons, props]);

  const backPath = useMemo(() => {
    return location.state?.backPath;
  }, [location]);

  return (
    <Toolbar {...props} className={classes.toolbar}>
      <div className={classes.buttonsContainer}>
        {!hideSave && (
          <SaveButton
            {...props}
            label="Сохранить / обновить"
            submitOnEnter={true}
            variant="contained"
            size="medium"
            className={classes.button}
            onFailure={(error) => {
              notify(`Error: ${error.message}`, 'warning');
            }}
            redirect={backPath}
          />
        )}
        {ToolbarButtons}
      </div>
    </Toolbar>
  );
};
