import React from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 32,
    height: 32,
    borderRadius: '100%',
    background: theme.palette.primaryNew['0.24'],
    color: theme.palette.primaryNew['main'],
    fontSize: '10px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const SortOrderListItem = ({ sortOrder }) => {
  const classes = useStyles();

  return (
    <Tooltip title="Порядок сортировки" arrow>
      <div className={classes.root}>
        <span>{sortOrder}</span>
      </div>
    </Tooltip>
  );
};
