import React, { useCallback, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { AddButton } from '../../buttons';

import { staticProvider } from '../../../staticApi';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    display: 'inline-block',
  },
  input: {
    display: 'none',
  },
});

export const MultipleAddImages = ({ onChange, imageType }) => {
  const classes = useStyles();
  const inputRef = useRef(null);

  const [loading, setLoading] = useState(false);

  const handleClick = useCallback(() => {
    inputRef.current.click();
  }, []);

  const handleClearFile = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  }, []);

  const handleChangeFile = useCallback(
    async (event) => {
      if (event.target.files) {
        setLoading(true);
        const formData = new FormData();
        Array.from(event.target.files).forEach((file) => {
          formData.append('image', file);
        });
        try {
          const result = await staticProvider.uploadResizable(formData);
          const newImages = result.data.data.map((image, index) => {
            return {
              path: image.destination,
              name: 'new',
              sysStatus: 200,
              id: `client:${new Date().getTime()}${index}`,
              clientStatus: 'create',
              ...(Number(imageType) && { imageType }),
            };
          });
          onChange(newImages);
        } catch (error) {
          console.log(error);
        } finally {
          handleClearFile();
          setLoading(false);
        }
      }
    },
    [handleClearFile, imageType, onChange],
  );

  return (
    <div className={classes.root}>
      <input
        className={classes.input}
        multiple
        accept="image/*"
        ref={inputRef}
        type="file"
        onChange={handleChangeFile}
      />
      <AddButton onClick={handleClick} multiple loading={loading} />
    </div>
  );
};
