import React from 'react';
import { Datagrid } from 'react-admin';
import { ListBase } from '../../../components/ListBase';
import { StatusesField } from '../../../components/RAFields/StatusesField';
import { NameField, AuthorField, FeedLinksField } from '../../../components/RAFields';
import { DefaultMenu } from '../../../components/app/Menus';
import { RAList } from '../../../components/app';

const WrapperNameField = ({ record }) => {
  const { id, name } = record;

  return <NameField name={name} id={id} />;
};

export const TableFeeds = () => {
  return (
    <Datagrid hasBulkActions>
      <WrapperNameField source="name" label="Название" sortable={false} />
      <StatusesField source="sysStatus" label="Статус публикации" sortable={false} />
      <AuthorField source="createdBy" label="Автор" sortable={false} />
      <FeedLinksField source="feedTargets" label="Ссылки" sortable={false} />
      <DefaultMenu />
    </Datagrid>
  );
};

const CardsFeeds = () => {
  return <RAList type="feed" />;
};

export const ListFeeds = (props) => {
  return (
    <ListBase
      {...props}
      hasToggleView
      tableRender={<TableFeeds {...props} />}
      cardRender={<CardsFeeds {...props} />}
      filterDefaultValues={{ IsSystem: false }}
    />
  );
};
