import React, { useMemo, useEffect, useCallback, useReducer, useRef } from 'react';
import { Collapse, useTheme, makeStyles } from '@material-ui/core';
import { useRefresh } from 'react-admin';

import { getFormattedDateAndTime, getFullName, isObject, parseBoolean } from '../../../../utils';
import {
  clientTouchNotTargetTypes,
  clientTouchStatuses,
  clientTouchTargetTypes,
  clientTouchTypes,
  touchesTypes,
} from '../../../../constants';
import { AudioBlockButton } from '../../../buttons/AudioBlockButton';
import { ListItemHeader } from '../ItemHeader';
import { ConstantSelectField } from '../../ConstantSelectField';
import { AutocompleteDynamic } from '../../../common';
import { dataProvider } from '../../../../dataProvider';
import { useDebounce } from '../../../../hooks/useDebounce';
import { CreateContractButton } from '../../../buttons';

// export const ListItemTouch = ({ record, hasButtons }) => {
//   const { techSource, id, isInDirection, path, clientTags } = record;
//   const touchType = touchesTypes[techSource];
//   const isCall = touchType === 'Звонок';

//   const mainTexts = [];

//   if (record.createdDate) {
//     mainTexts.push(`${getFormattedDateAndTime(record.createdDate)}`);
//   }

//   let name = record.clientName || '';
//   if (record.client) {
//     name = getFullName(record.client);
//   }

//   if (record.externalPhoneNumber) {
//     name += ` | ${record.externalPhoneNumber}`;
//   }

//   const link = `/ClientTouches/${record.id}`;

//   const textStatuses = [];

//   if (touchType) {
//     textStatuses.push(touchType);
//   }

//   if (touchType === 'Звонок') {
//     if (isInDirection) {
//       textStatuses.push('Входящий');
//     } else {
//       textStatuses.push('Исходящий');
//     }

//     if (!path && isInDirection) {
//       textStatuses.push({ text: 'Пропущенный', color: 'red' });
//     } else if (!path && !isInDirection) {
//       textStatuses.push({ text: 'Недозвон', color: 'red' });
//     } else {
//       textStatuses.push({ text: 'Принятый', color: 'green' });
//     }
//   }

//   const contentStatuses = [];

//   if (record.target) {
//     contentStatuses.push({
//       text: clientTouchTypes[record.target],
//       color:
//         record.target === 100 || record.target === 200 || record.target === 300 ? 'green' : 'red',
//     });
//   }

//   if (clientTags?.length > 0) {
//     clientTags.forEach((clientTag) => {
//       if (clientTag?.clientTag?.name) {
//         contentStatuses.push(clientTag.clientTag.name);
//       }
//     });
//   }

//   if (record.client?.isOwner) {
//     contentStatuses.push(`Собственник`);
//   }

//   if (record.client?.isRepresentative) {
//     contentStatuses.push(`Представитель`);
//   }

//   return (
//     <>
//       <ListItemMainBlock
//         record={record}
//         hasImage={false}
//         additionalId="friendlyId"
//         mainTexts={mainTexts}
//         name={name}
//         hasButtons={hasButtons}
//         link={link}
//         comment={record.comment}
//         textStatuses={textStatuses}
//         contentStatuses={contentStatuses}
//       />
//       {isCall && path && (
//         <AudioBlockButton
//           recordId={id}
//           path={path}
//           isInDirection={isInDirection}
//           isDisabled={path === undefined}
//         />
//       )}
//     </>
//   );
// };

const reducer = (state, action) => {
  if (action.type === 'isTargeted' && state[action.type] !== action.payload) {
    return { ...state, [action.type]: action.payload, target: null };
  }

  return { ...state, [action.type]: action.payload };
};

const useStyles = makeStyles((theme) => ({
  buttons: {
    marginTop: '12px',
    borderTop: '1px dashed rgba(0, 0, 0, 0.3)',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  buttonsRow: {
    width: '32%',
    boxSizing: 'border-box',
    paddingTop: '16px',
    flex: '0 0 auto',

    [theme.breakpoints.down('1024')]: {
      width: '49%',
    },

    [theme.breakpoints.down('600')]: {
      width: '100%',
    },
  },
}));

export const NewListItemTouch = ({ record, hasButtons, onChangeBgColor, headerProps }) => {
  const classes = useStyles();
  const { techSource, id, isInDirection, path, clientTags } = record;
  const { expanded } = headerProps;
  const touchType = touchesTypes[techSource];
  let isCall;
  if (touchType) {
    isCall = touchType.includes('Звонок');
  }

  let name = id;

  if (record.externalPhoneNumber) {
    name += ` | ${record.externalPhoneNumber}`;
  }

  if (record.client) {
    name += ` | ${getFullName(record.client)}`;
  }

  if (record.createdDate) {
    name += ` | ${getFormattedDateAndTime(record.createdDate)}`;
  }

  const link = `/ClientTouches/${record.id}`;

  const tags = [];

  if (touchType) {
    tags.push(touchType);
  }

  if (isCall) {
    if (isInDirection) {
      tags.push('Входящий');
    } else {
      tags.push('Исходящий');
    }

    if (!path && isInDirection) {
      tags.push({ text: 'Пропущенный', color: 'red' });
    } else if (!path && !isInDirection) {
      tags.push({ text: 'Недозвон', color: 'red' });
    } else {
      tags.push({ text: 'Принятый', color: 'green' });
    }
  }

  if (record.target) {
    tags.push({
      text: clientTouchTypes[record.target],
      color:
        record.target === 100 || record.target === 200 || record.target === 300 ? 'green' : 'red',
    });
  }

  if (
    !record.utmSource &&
    !record.utmMedium &&
    !record.utmTerm &&
    !record.utmContent &&
    !record.utmCampaign &&
    !record.sitePage
  ) {
    tags.push({
      text: 'НЕТ UTM',
      color: 'red',
    });
  }

  if (clientTags?.length > 0) {
    clientTags.forEach((clientTag) => {
      if (clientTag?.clientTag?.name) {
        tags.push(clientTag.clientTag.name);
      }
    });
  }

  if (record.client?.isOwner) {
    tags.push(`Собственник`);
  }

  if (record.client?.isRepresentative) {
    tags.push(`Представитель`);
  }

  const theme = useTheme();

  const color = useMemo(() => {
    if (record.target === 100 || record.target === 200 || record.target === 300) {
      return theme.palette.green['background'];
    }

    if (touchType === 'Звонок' && !path) {
      return theme.palette.red['background'];
    }
  }, [path, record.target, theme, touchType]);

  useEffect(() => {
    onChangeBgColor(color);
  }, [color, onChangeBgColor]);

  const [inputValues, dispatch] = useReducer(reducer, {
    isTargeted:
      record?.isTargeted || record.target === 100 || record.target === 200 || record.target === 300,
    target: record?.target || 10000,
    initialRealtyObjectId: record?.initialRealtyObjectId,
    initialRealtyObject: record?.initialRealtyObject,
  });

  const refresh = useRefresh();

  const { clearTimeoutDebounce, timeoutDebounce } = useDebounce();

  const hasChangesRef = useRef(false);

  const onChangeInputValues = useCallback((newValue, event) => {
    let resultValue = newValue;
    const fieldName = isObject(event) ? event.target.name : event;
    if (fieldName === 'isTargeted') {
      resultValue = parseBoolean(resultValue);
    }
    if (isObject(resultValue)) {
      dispatch({ type: `${fieldName}Id`, payload: resultValue.id });
    }
    dispatch({ type: fieldName, payload: resultValue });
    hasChangesRef.current = true;
  }, []);

  const onBlurInputValues = useCallback(() => {
    if (hasChangesRef.current) {
      const fetch = async () => {
        await dataProvider.update('ClientTouches', {
          id: record.id,
          data: {
            ...record,
            ...inputValues,
          },
          previousData: record,
        });

        refresh();
      };

      timeoutDebounce(() => {
        fetch();
        hasChangesRef.current = false;
      });
    }
  }, [inputValues, record, refresh, timeoutDebounce]);

  const onFocusInputValues = useCallback(() => {
    clearTimeoutDebounce();
  }, [clearTimeoutDebounce]);

  return (
    <>
      <ListItemHeader
        record={record}
        link={link}
        name={name}
        hasButtons={hasButtons}
        tags={tags}
        tagName="Касание"
        {...headerProps}
      >
        {isCall && path && (
          <AudioBlockButton
            recordId={id}
            path={path}
            isInDirection={isInDirection}
            isDisabled={path === undefined}
            talkSeconds={record.talkSeconds}
          />
        )}
      </ListItemHeader>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <div className={classes.buttons}>
          <div className={classes.buttonsRow}>
            <ConstantSelectField
              items={clientTouchStatuses}
              size="small"
              value={inputValues.isTargeted}
              onChange={onChangeInputValues}
              name="isTargeted"
              label="Статус обработки"
              isControlled
              onBlur={onBlurInputValues}
              onFocus={onFocusInputValues}
            />
          </div>
          <div className={classes.buttonsRow}>
            <ConstantSelectField
              items={
                inputValues.isTargeted === true ? clientTouchTargetTypes : clientTouchNotTargetTypes
              }
              size="small"
              value={inputValues.target}
              onChange={onChangeInputValues}
              name="target"
              label="Причина обработки"
              isControlled
              onBlur={onBlurInputValues}
              onFocus={onFocusInputValues}
            />
          </div>
          <div className={classes.buttonsRow}>
            <AutocompleteDynamic
              filterSelectedOptions
              resource="RealtyObjects"
              fullDynamic="QueryWithFid"
              label="Объект обращения"
              name="initialRealtyObject"
              onChange={onChangeInputValues}
              size="small"
              valueProp={inputValues.initialRealtyObject}
              onBlur={onBlurInputValues}
              onFocus={onFocusInputValues}
              getOptionLabel={(option) => {
                if (!option) {
                  return '';
                }

                return `${option.id}: ${option.name || ''}`;
              }}
            />
          </div>
          <div className={classes.buttonsRow}>
            <CreateContractButton record={record} type="touch" size="small" />
          </div>
        </div>
      </Collapse>
    </>
  );
};
