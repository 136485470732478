import React from 'react';

import { Create } from '../../../components/common';

import { FormSets } from '../../../components/forms';

export const CreateSets = (props) => {
  return (
    <Create {...props}>
      <FormSets {...props} redirect="list" />
    </Create>
  );
};
