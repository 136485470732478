import React, { useMemo } from 'react';

import { WorkOutlineOutlined as WorkOutlineOutlinedIcon } from '@material-ui/icons';
import { makeStyles, Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '16px',
    color: theme.palette.primaryNew['main'],
  },
}));

export const ListItemRentStatus = () => {
  const classes = useStyles();

  const icon = useMemo(() => {
    return <WorkOutlineOutlinedIcon fontSize="inherit" />;
  }, []);

  return (
    <div className={classes.root}>
      <Tooltip title="Аренда" arrow>
        {icon}
      </Tooltip>
    </div>
  );
};
