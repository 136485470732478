import React from 'react';

import { ListBase } from '../../../../components/ListBase';

import { RAList } from '../../../../components/app';

const MainList = () => {
  return <RAList />;
};

export const ListCMSProjects = (props) => {
  return (
    <ListBase {...props}>
      <MainList {...props} />
    </ListBase>
  );
};
