import * as React from 'react';

import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    background: (props) => props.bgColor,
    zIndex: '10',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const Preloader = ({ loading, size = 40, bgColor = 'rgba(255, 255, 255, 0.5)' }) => {
  const classes = useStyles({ bgColor });

  return (
    <Fade
      in={loading}
      style={{
        transitionDelay: loading ? '200ms' : '0ms',
      }}
      unmountOnExit
    >
      <div className={classes.root}>
        <CircularProgress size={size} />
      </div>
    </Fade>
  );
};

export default Preloader;
