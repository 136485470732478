import React, { useMemo } from 'react';
import { Datagrid } from 'react-admin';

import { ListBase } from '../../../components/ListBase';

import { StatusesField } from '../../../components/RAFields/StatusesField';

import { PhotoField, NameField, AuthorField } from '../../../components/RAFields';
import { DefaultMenu } from '../../../components/app/Menus';
import { RAList } from '../../../components/app';

const WrapperPhotoField = ({ record }) => {
  const path = useMemo(() => {
    return record.cover;
  }, [record]);

  return <PhotoField path={path} />;
};

const WrapperNameField = ({ record }) => {
  const { id, name, slug } = record;

  return <NameField name={name} id={id} additionalId={slug} />;
};

export const TableArticles = () => {
  return (
    <Datagrid hasBulkActions>
      <WrapperPhotoField label="Фото" sortable={false} />
      <WrapperNameField source="name" label="Название" sortable={false} />
      <StatusesField source="sysStatus" label="Статус публикации" sortable={false} />
      <AuthorField source="createdBy" label="Автор" sortable={false} />
      <DefaultMenu />
    </Datagrid>
  );
};

const CardsArticles = () => {
  return <RAList imgKey="cover" />;
};

export const ListArticles = (props) => {
  return (
    <ListBase
      {...props}
      hasToggleView
      tableRender={<TableArticles {...props} />}
      cardRender={<CardsArticles {...props} />}
    />
  );
};
