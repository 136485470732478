import React from 'react';

import { ImageInput } from '../../ImageInput/ImageInput';
import { FormRow } from '../../FormRow';

export const ImageInputWithFormRow = ({ formRowProps, ...props }) => {
  return (
    <FormRow {...formRowProps}>
      <ImageInput {...props} />
    </FormRow>
  );
};
