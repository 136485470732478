import React, { useMemo } from 'react';
import {
  NotificationsOutlined as NotificationsOutlinedIcon,
  NotificationsOffOutlined as NotificationsOffOutlinedIcon,
  DeleteOutline as DeleteOutlineIcon,
} from '@material-ui/icons';
import { StatusField } from '../StatusField';

export const StatusFieldSysStatus = ({ record }) => {
  const componentProps = useMemo(() => {
    if (Number(record.sysStatus) === 200) {
      return {
        styleProps: { backgroundColor: '#64DD17', fontSize: '16px' },
        content: <NotificationsOutlinedIcon fontSize="inherit" />,
      };
    }

    if (Number(record.sysStatus) === 100) {
      return {
        styleProps: { backgroundColor: '#FF3D00', fontSize: '16px' },
        content: <NotificationsOffOutlinedIcon fontSize="inherit" />,
      };
    }

    return {
      styleProps: { backgroundColor: '#FF3D00', fontSize: '16px' },
      content: <DeleteOutlineIcon fontSize="inherit" />,
    };
  }, [record]);

  if (!record.sysStatus) {
    return null;
  }

  return <StatusField styleProps={componentProps.styleProps}>{componentProps.content}</StatusField>;
};
