export const taskObjectTypes = {
  100: 'Посёлок',
  200: 'Недвижимость',
  300: 'Сектор',
  400: 'Подборка',
  500: 'Инфраструктура',
  600: 'Человек',
  700: 'Лид',
};

export const taskStatuses = {
  100: 'Новая',
  200: 'Выполняется',
  300: 'Выполнена',
};

export const taskStatusesAdmin = {
  ...taskStatuses,
  400: 'Подтверждена',
};

export const taskImportance = {
  100: 'Extra',
  200: 'High',
  300: 'Mid',
  400: 'Low',
};
