import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { mergeClasses } from '../../utils';

const useStyles = makeStyles(() => ({
  formRow: {
    width: '100% !important',
    maxWidth: 'none !important',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 24,
    boxSizing: 'border-box',
  },
  // last: {
  //   marginBottom: 0,
  // },
  first: {
    marginTop: 25,
  },
  formRowTitle: {
    width: '50%',
    paddingRight: 24,
    boxSizing: 'border-box',
  },
  formRowInput: {
    width: '50%',
    flexShrink: 0,
    boxSizing: 'border-box',
  },
  formRowInputFullwidth: {
    width: '100%',
    flexShrink: 0,
    boxSizing: 'border-box',
  },
  '@media (max-width: 1024px)': {
    formRow: {
      flexDirection: 'column',
      width: '100%',
      alignItems: 'flex-start',
    },
    formRowTitle: {
      width: '100%',
      marginBottom: 4,
    },
    formRowInput: {
      width: '100%',
    },
    formRowInputFullwidth: {
      width: '100%',
    },
  },
}));

export const FormRow = ({ children, formRowTitle, isLast, isFirst, className }) => {
  const classes = useStyles();

  return (
    <div
      className={mergeClasses(
        classes.formRow,
        isLast && classes.last,
        isFirst && classes.first,
        className,
      )}
    >
      {formRowTitle && (
        <Typography variant="overline" className={classes.formRowTitle}>
          {formRowTitle}
        </Typography>
      )}
      <div className={formRowTitle ? classes.formRowInput : classes.formRowInputFullwidth}>
        {children}
      </div>
    </div>
  );
};
