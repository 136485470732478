export const getNumberFilters = (activeFilters, multipleFilters, filtersToCheck) => {
  let number = 0;
  let arrayFilters = Object.keys(activeFilters);
  if (filtersToCheck) {
    arrayFilters = filtersToCheck;
  }

  arrayFilters.forEach((filter) => {
    if (filter in activeFilters) {
      if (multipleFilters.has(filter)) {
        const numberValues = activeFilters[filter].split(',').length;
        number += numberValues;

        return;
      }

      if (filter === 'SortBy') {
        return;
      }

      number++;
    }
  });

  return number;
};
