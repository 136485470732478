import React from 'react';
import { Edit } from '../../../components/common';
import { FormClients } from '../../../components/forms';

export const EditClients = (props) => {
  return (
    <Edit {...props}>
      <FormClients {...props} redirect={false} />
    </Edit>
  );
};
