import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Datagrid, TextField, useListContext } from 'react-admin';
import { ButtonGroup, Button } from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';

import { ListBase } from '../../../components/ListBase';

import {
  AuthorField,
  RolesField,
  PeopleNameField,
  TextByConstantField,
} from '../../../components/RAFields';
import { RAList } from '../../../components/app';
import { UsersMenu } from '../../../components/app/Menus';
import { ownerStatuses } from '../../../constants';

export const TableUsers = (props) => {
  const { permissions } = props;

  return (
    <Datagrid hasBulkActions>
      <PeopleNameField source="name" label="Имя" sortable={false} />
      <TextField source="phone" label="Телефон" sortable={false} />
      <TextField source="eMail" label="Эл. почта" sortable={false} />
      <TextByConstantField
        source="ownerStatus"
        label="Статус"
        sortable={false}
        constant={ownerStatuses}
      />
      <RolesField source="isExpert" label="Роли" sortable={false} />
      <AuthorField source="createdBy" label="Автор" sortable={false} />
      <UsersMenu permissions={permissions} />
    </Datagrid>
  );
};

const CardsUsers = ({ menuItem }) => {
  return <RAList type="people" menuItem={menuItem} />;
};

const customHeaderContentItems = {
  IsStaff: 'Сотрудники',
  IsOwner: 'Собственники',
  IsRepresentative: 'Представители',
};

const getFilterValues = (currentValue) => {
  const newFilters = {};

  Object.keys(customHeaderContentItems).forEach((item) => {
    if (item === currentValue) {
      newFilters[item] = 'true';

      return;
    }

    newFilters[item] = '';
  });

  return newFilters;
};

const CustomHeaderContent = () => {
  const { filterValues, setFilters } = useListContext();
  const [value, setValue] = useState();

  useEffect(() => {
    let count = 0;
    let newValue;
    const filtersKeys = Object.keys(filterValues);
    if (filtersKeys.length > 0) {
      filtersKeys.forEach((filterKey) => {
        if (
          Object.keys(customHeaderContentItems).includes(filterKey) &&
          filterValues[filterKey] === 'true'
        ) {
          newValue = filterKey;
          count++;
        }
      });
    }

    if (count > 1) {
      setValue();

      return;
    }

    setValue(newValue);
  }, [filterValues]);

  const handleChange = useCallback(
    (active) => (event) => {
      const currentValue = event.currentTarget.value;
      let newValue;
      if (!active) {
        newValue = currentValue;
      }
      const newFilterValues = { ...filterValues, ...getFilterValues(newValue) };

      setFilters(newFilterValues);
      setValue(newValue);
    },
    [filterValues, setFilters],
  );

  const elements = useMemo(() => {
    return Object.keys(customHeaderContentItems).map((item) => {
      const active = value === item;

      return (
        <Button variant={active ? 'contained' : false} value={item} onClick={handleChange(active)}>
          {customHeaderContentItems[item]}
        </Button>
      );
    });
  }, [value, handleChange]);

  return (
    <ButtonGroup color="primary" aria-label="outlined primary button group">
      {elements}
    </ButtonGroup>
  );
};

export const ListUsers = (props) => {
  const { resource, permissions } = props;

  const menuItem = useCallback(
    (record) => {
      return (
        <UsersMenu
          record={record}
          resource={resource}
          permissions={permissions}
          icon={<MoreVertIcon />}
        />
      );
    },
    [permissions, resource],
  );

  return (
    <ListBase
      {...props}
      hasSearchVariants={false}
      searchBy="AllNames"
      hasToggleView
      tableRender={<TableUsers {...props} />}
      cardRender={<CardsUsers {...props} menuItem={menuItem} />}
      customHeaderContent={<CustomHeaderContent />}
      resource="Peoples"
      hasExport
    />
  );
};
