// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-unused-vars */
import React, { useState, useCallback, useRef, useContext, useMemo } from 'react';

import { SwipeableDrawer, makeStyles, Tooltip, IconButton } from '@material-ui/core';
import { MenuBook as MenuBookIcon, ViewColumn as ViewColumnIcon } from '@material-ui/icons';

import { useListContext, useRedirect } from 'react-admin';
import SortIcon from '@material-ui/icons/Sort';
import { Search } from '../Search';
import { FiltersButton } from '../FiltersButton';
import { Filters } from '../Filters';
import { InstructionsContext } from '../../providers';
import { Dialog } from '../../dialogs';
import { MenuButton } from '../../buttons/MenuButton';
import { clientSortOrders, objectSortOrders, sortOrders, taskSortOrders } from '../../../constants';

const useStyles = makeStyles((customTheme) => ({
  rows: {
    display: 'flex',
  },
  row: {
    flex: '1 1 auto',
  },
  lastRow: {
    flex: '0 0 auto',
    paddingLeft: '16px',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'stretch',
  },
  button: {
    border: '1px solid #BDBDBD',
    color: customTheme.palette.common.black,
    borderRadius: 6,
    height: 56,
    width: 60,
    marginRight: '4px',
  },
  htmlContainer: {
    textAlign: 'center',
    maxWidth: '800px',
    margin: '0 auto',

    '& img': {
      maxWidth: '100%',
    },
  },
  viewIcon: {
    width: '35px',
    height: '35px',
  },
  '@media (max-width: 500px)': {
    rows: {
      flexWrap: 'wrap',
    },
    row: {
      width: '100%',
    },
    lastRow: {
      marginTop: '10px',
      paddingLeft: 0,
    },
  },
}));

function createMarkup(html) {
  return { __html: html };
}

export const SearchAndFilters = ({ searchBy, hasFilters = true, basePath, hasKanban }) => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const searchClearRef = useRef();
  const redirect = useRedirect();
  const { filterValues, setFilters } = useListContext();

  const toggleDrawer = useCallback(
    (open) => (event) => {
      if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }

      setDrawerOpen(open);
    },
    [],
  );

  const instructions = useContext(InstructionsContext);
  const currentInstruction = useMemo(() => {
    if (instructions) {
      return instructions.find((instruction) => {
        return instruction.name === basePath.slice(1);
      });
    }
  }, [instructions, basePath]);

  const changeOpenRef = useRef();

  const handleOpenDialog = useCallback((currentRecord) => {
    if (changeOpenRef.current) {
      changeOpenRef.current(true);
    }
  }, []);

  const handleOpenKanban = useCallback(() => {
    redirect(`${basePath}/Kanban`);
  }, [basePath, redirect]);

  const handleChangeSort = useCallback(
    (option) => {
      const newFilterValues = { ...filterValues };
      newFilterValues.SortBy = option.id;
      if (option.id === '-1') {
        delete newFilterValues.SortBy;
      }
      setFilters(newFilterValues);
    },
    [filterValues, setFilters],
  );

  const sortItems = useMemo(() => {
    let items = sortOrders;

    if (basePath === '/RealtyObjects') {
      items = objectSortOrders;
    }

    if (basePath === '/Clients') {
      items = clientSortOrders;
    }

    if (basePath === '/Taasks') {
      items = taskSortOrders;
    }

    const newItems = Object.keys(items).map((key) => {
      return { id: key, text: items[key] };
    });

    return newItems;
  }, [basePath]);

  const sortSelected = useMemo(() => {
    return filterValues.SortBy ? String(filterValues.SortBy) : '-1';
  }, [filterValues.SortBy]);

  return (
    <div className={classes.rows}>
      <div className={classes.row}>
        <Search searchBy={searchBy} searchClearRef={searchClearRef} />
      </div>
      {hasFilters && (
        <div className={classes.lastRow}>
          <div className={classes.buttons}>
            {currentInstruction && (
              <>
                <Tooltip title="Инструкция" arrow>
                  <div>
                    <IconButton className={classes.button} disableRipple onClick={handleOpenDialog}>
                      <MenuBookIcon width={24} height={24} />
                    </IconButton>
                  </div>
                </Tooltip>
                <Dialog
                  title="Инструкция"
                  dialogProps={{ fullScreen: true }}
                  changeOpenRef={changeOpenRef}
                >
                  <div
                    className={classes.htmlContainer}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={createMarkup(currentInstruction.anyText)}
                  />
                </Dialog>
              </>
            )}
            {hasKanban && (
              <>
                <Tooltip title="Канбан" arrow>
                  <div>
                    <IconButton className={classes.button} disableRipple onClick={handleOpenKanban}>
                      <ViewColumnIcon width={30} height={30} className={classes.viewIcon} />
                    </IconButton>
                  </div>
                </Tooltip>
              </>
            )}
            <div>
              <MenuButton
                buttonClasses={classes.button}
                icon={<SortIcon width={24} height={24} />}
                onChange={handleChangeSort}
                options={sortItems}
                allowEmpty
                selected={sortSelected}
              />
            </div>
            <Tooltip title="Фильтры" arrow>
              <div>
                <FiltersButton onClick={toggleDrawer(true)} />
              </div>
            </Tooltip>
          </div>
          <SwipeableDrawer
            anchor="right"
            open={drawerOpen}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
          >
            <Filters onHideDrawer={toggleDrawer(false)} searchClearRef={searchClearRef} />
          </SwipeableDrawer>
        </div>
      )}
    </div>
  );
};
