import React, { useMemo, useCallback } from 'react';
import { getStaticImage } from '../../../utils';

export const StaticImage = ({
  imagePath,
  size,
  defaultImagePath = getStaticImage(size, '404-not-found-pic.jpg'),
  imgAttributes = {},
  onLoading,
  onError,
}) => {
  const image = useMemo(() => {
    return getStaticImage(size, imagePath);
  }, [imagePath, size]);

  const handleLoad = useCallback(() => {
    if (onLoading) {
      onLoading();
    }
  }, [onLoading]);

  const handleError = useCallback(() => {
    // eslint-disable-next-line no-alert
    alert('Извините произошла ошибка, не удалось загрузить картинку!');
    if (onError) {
      onError();
    }
  }, [onError]);

  return (
    <picture>
      {imagePath && (
        <>
          <source srcSet={`${image}.webp`} type="image/webp" />
          <source srcSet={`${image}.jpeg`} type="image/jpeg" />
        </>
      )}
      <img
        src={defaultImagePath}
        alt="img"
        {...imgAttributes}
        onError={handleError}
        onLoad={handleLoad}
      />
    </picture>
  );
};
