import React from 'react';
import { Datagrid, TextField } from 'react-admin';

import { makeStyles } from '@material-ui/core';
import { ListBase } from '../../../ListBase';
import { StatusesField } from '../../../RAFields/StatusesField';

import { RAList } from '../..';
import { DefaultMenu } from '../../Menus';

const useStyles = makeStyles(() => ({
  main: {
    height: '100%',
    paddingTop: '20px',
    boxSizing: 'border-box',
  },
}));

const CardsEnum = () => {
  return <RAList />;
};

const TableEnum = () => {
  return (
    <Datagrid hasBulkActions>
      <TextField source="id" label="ID" sortable={false} />
      <TextField source="name" label="Название" sortable={false} />
      <TextField source="sortOrder" label="Порядок сортировки" sortable={false} />
      <StatusesField source="sysStatus" label="Статус публикации" sortable={false} />
      <DefaultMenu />
    </Datagrid>
  );
};

export const ListEnum = (props) => {
  const classes = useStyles();

  return (
    <ListBase
      {...props}
      classes={{ main: classes.main, root: classes.main }}
      hasActions={false}
      hasToggleView
      tableRender={<TableEnum {...props} />}
      cardRender={<CardsEnum {...props} />}
    />
  );
};
