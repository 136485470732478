import React from 'react';

import { FormVillages } from '../../../components/forms';
import { Edit } from '../../../components/common';

export const EditVillages = (props) => {
  return (
    <Edit {...props}>
      <FormVillages {...props} redirect={false} />
    </Edit>
  );
};
