import { roleFlags } from '../constants';

export const checkRole = (roles, role) => {
  if (!roles) {
    return false;
  }

  return roles.includes(role);
};

export const checkRoleAdmin = (roles) => {
  return checkRole(roles, roleFlags.isAdmin);
};

export const checkRoleOdp = (roles) => {
  return checkRole(roles, roleFlags.isOdp);
};

export const checkRoleEditor = (roles) => {
  return checkRole(roles, roleFlags.isEditor);
};

export const checkRoleAdminOrSupervisor = (roles) => {
  return checkRole(roles, roleFlags.isSupervisor) || checkRoleAdmin(roles);
};

export const checkRoleAdminOrSupervisorOrOdp = (roles) => {
  return checkRole(roles, roleFlags.isSupervisor) || checkRoleAdmin(roles) || checkRoleOdp(roles);
};
