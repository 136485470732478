/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-unused-vars */
import React, { useCallback } from 'react';

import { makeStyles } from '@material-ui/core';
import { useFormState } from 'react-final-form';
import { SaveButton, useRedirect } from 'react-admin';
import { dataProvider } from '../../../dataProvider';

const useStyles = makeStyles(() => ({
  button: {
    fontSize: 14,
    marginRight: 15,
    padding: '6px 12px',
    '& svg': {
      fontSize: '20px',
    },
  },
}));

export const RescheduleShowButton = (props) => {
  const { toolbarProps } = props;
  const { resource } = toolbarProps;
  const formValues = useFormState().values;
  const redirect = useRedirect();

  const classes = useStyles();

  const handleSuccess = useCallback(() => {
    const fetch = async () => {
      const newData = {
        ...formValues,
        name: `${formValues.name} - перенсенный`,
        date: formValues.shiftDate,
      };
      delete newData.shiftDate;
      delete newData.shiftReason;
      delete newData.cancelReason;
      delete newData.status;
      const result = await dataProvider.create(resource, {
        data: newData,
        params: { copyFromDemoId: formValues.id },
      });
      const newId = result.data.id;
      redirect(`/${resource}/${newId}`);
    };

    fetch();
  }, [formValues, redirect, resource]);

  return (
    <>
      {Number(formValues?.status) === 600 && (
        <SaveButton
          {...toolbarProps}
          label="Перенести показ"
          submitOnEnter={false}
          variant="contained"
          size="medium"
          className={classes.button}
          onSuccess={handleSuccess}
          icon={<></>}
        />
      )}
    </>
  );
};
