import React from 'react';
import { Fab } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    bottom: '20px',
    right: '25px',
    zIndex: '3',
  },
}));

export const CreateButton = ({ basePath }) => {
  const classes = useStyles();

  return (
    <Link to={(location) => (basePath ? `${basePath}/create` : `${location.pathname}/create`)}>
      <Fab color="primary" classes={{ root: classes.root }}>
        <Add />
      </Fab>
    </Link>
  );
};
