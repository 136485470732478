import React, { useCallback } from 'react';
import { useUnselectAll } from 'react-admin';
import { MenuItem } from '@material-ui/core';

export const ClearSelectedMenuItem = ({ onClose, resource }) => {
  const unselectAll = useUnselectAll();

  const handleClick = useCallback(() => {
    unselectAll(resource);
    onClose();
  }, [onClose, resource, unselectAll]);

  return <MenuItem onClick={handleClick}>Очистить все</MenuItem>;
};
