export const yandexVas = {
  100: 'premium',
  200: 'raise',
  300: 'promotion',
};

export const avitoListingFee = {
  100: 'Free',
  200: 'x5_7',
  300: 'x10_7',
};

export const cianExcludedService = {
  200: 'highlight',
  400: 'premium',
  500: 'top3',
};

export const cianService = {
  100: 'free',
  200: 'highlight',
  300: 'paid',
  400: 'premium',
  500: 'top3',
};

export const repairType = {
  100: 'Косметический',
  200: 'Дизайнерский',
  300: 'Евро',
  400: 'Без ремонта',
};

export const heatingType = {
  100: 'autonomousGas',
  200: 'centralCoal',
  300: 'centralGas',
  400: 'diesel',
  500: 'electric',
  600: 'fireplace',
  700: 'no',
  800: 'solidFuelBoiler',
  900: 'stove',
};

export const leaseDeposit = {
  100: 'Без залога',
  200: '0,5 месяца',
  300: '1 месяц',
  400: '1,5 месяца',
  500: '2 месяца',
  600: '2,5 месяца',
  700: '3 месяца',
};

export const utilityMeters = {
  100: 'Оплачивается арендатором',
  200: 'Оплачивается собственником',
};

export const personTypes = {
  100: 'Физическое лицо',
  200: 'Юридическое лицо',
};

export const windowViewTypes = {
  100: 'Во двор',
  200: 'На улицу',
  300: 'На улицу и двор',
};

export const landStatuses = {
  100: 'Участок сельскохозяйственного назначения',
  200: 'Участок промышленности, транспорта, связи и иного не сельхоз. назначения',
  300: 'Поселений',
};

export const vatTypes = {
  100: 'НДС включен',
  200: 'НДС не облагается',
  300: 'УСН (упрощенная система налогообложения)',
};

export const commercialTypes = {
  // 100: 'Гараж (пока закомментировать)',
  // 200: 'Готовый бизнес',
  300: 'Здание',
  400: 'Коммерческая земля',
  500: 'Офис',
  // 600: 'Помещение свободного назначения (пока закомментировать)',
  // 700: 'Производство (пока закомментировать)',
  // 800: 'Склад (пока закомментировать)',
  // 900: 'Торговая площадь (пока закомментировать)',
};
