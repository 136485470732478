// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-unreachable */
import React, { useMemo, useRef, useCallback, useState } from 'react';
import { FormWithRedirect, useRefresh, FormDataConsumer } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { Dialog } from '../Dialog';

import { Button } from '../../buttons';
import { dataProvider } from '../../../dataProvider';
import { ConstantSelect, AutocompleteWithFormRow } from '../../app';
import { FormRow } from '../../FormRow';

import {
  contractStatusesForSell,
  contractStatusesForBuy,
  contractTypes,
  luxuryClasses,
  currencyTypes,
  initialSales,
  contractStatusFilledColors,
} from '../../../constants';
import { PeopleSelect } from '../../RASelects';
import { DateTimePickerInput } from '../../RAInputs';
import { TextInput } from '../../TextInput/TextInput';
import { BooleanInput, FormTitle, NumberInput } from '../../common';

const useStyles = makeStyles(() => ({
  dialogPaper: {
    maxWidth: 465,
  },
}));

export const ContractsMultipleDialog = (props) => {
  const refresh = useRefresh();
  const classes = useStyles();
  const { changeOpenRef, record = {}, resource, data, selectedIds } = props;

  const formRef = useRef();

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState();

  const handleChangeStatus = useCallback((event) => {
    setStatus(parseInt(event.target.value, 10));
  }, []);

  const handleSave = useCallback(
    (values) => {
      setLoading(true);
      const items = selectedIds.map((selectedId) => {
        return data[selectedId];
      });

      const fetch = async () => {
        try {
          await dataProvider.updateMany(resource, items, { ...values });
          if (changeOpenRef.current) {
            changeOpenRef.current(false);
          }
          refresh();
        } finally {
          setLoading(false);
        }
      };

      fetch();
    },
    [changeOpenRef, data, refresh, resource, selectedIds],
  );

  const handleSubmit = useCallback(() => {
    const submit = formRef.current.handleSubmit;
    if (submit) {
      submit();
    }
  }, []);

  const buttons = useMemo(() => {
    return (
      <Button onClick={handleSubmit} loading={loading}>
        Сохранить
      </Button>
    );
  }, [handleSubmit, loading]);

  return (
    <Dialog
      {...props}
      buttons={buttons}
      dialogProps={{ fullWidth: true, classes: { paper: classes.dialogPaper } }}
      title="Редактировать поля контрактов"
    >
      <FormWithRedirect
        save={handleSave}
        record={record}
        render={(formProps) => {
          formRef.current = formProps;

          return (
            <>
              <FormTitle>Основные поля</FormTitle>
              <FormRow>
                <ConstantSelect
                  source="type"
                  objectChoices={contractTypes}
                  label="Сценарий лида"
                  allowEmpty
                />
              </FormRow>
              <FormDataConsumer>
                {({ formData }) => {
                  const type = Number(formData.type);
                  const isBuyAndRent = type === 100 || type === 300;

                  return (
                    <FormRow>
                      <ConstantSelect
                        source="status"
                        objectChoices={
                          isBuyAndRent ? contractStatusesForBuy : contractStatusesForSell
                        }
                        label="Этап воронки"
                        onChange={handleChangeStatus}
                        allowEmpty
                        filledColors={contractStatusFilledColors}
                      />
                    </FormRow>
                  );
                }}
              </FormDataConsumer>
              <PeopleSelect
                source="firstExpert"
                resourceId="firstExpertId"
                {...props}
                componentProps={{
                  label: 'Эксперт принял лид',
                  providerParams: { filter: { isExpert: true } },
                }}
              />
              <PeopleSelect
                source="currentExpert"
                resourceId="currentExpertId"
                {...props}
                componentProps={{
                  label: 'Эксперт текущий',
                  providerParams: { filter: { isExpert: true } },
                }}
              />
              <FormRow maxWidth={800} isLast>
                <TextInput source="comment" label="Комментарий" multiline rows={6} />
              </FormRow>
              <FormRow>
                <ConstantSelect
                  source="isInitialSale"
                  objectChoices={initialSales}
                  label="Первичка или вторичка?"
                  allowEmpty
                />
              </FormRow>
              <FormDataConsumer>
                {({ formData }) => {
                  const type = Number(formData.type);
                  const isBuyAndRent = type === 100 || type === 300;

                  return (
                    <>
                      <AutocompleteWithFormRow
                        {...props}
                        source="realtyObjectType"
                        resourceName="RealtyObjectTypes"
                        resourceId="realtyObjectTypeId"
                        componentProps={{
                          label: isBuyAndRent ? 'Что ищет' : 'Что продает/сдает',
                          filterSelectedOptions: true,
                        }}
                      />
                      <FormRow>
                        <NumberInput
                          source="budget"
                          label={isBuyAndRent ? 'Бюджет' : 'Цена'}
                          min={0}
                          InputProps={{
                            startAdornment: (
                              <span style={{ marginRight: '6px', paddingTop: '15px' }}>
                                {isBuyAndRent ? 'до' : 'от'}
                              </span>
                            ),
                          }}
                        />
                      </FormRow>
                      <FormRow>
                        <ConstantSelect
                          source="luxuryClass"
                          objectChoices={luxuryClasses}
                          label="Класс объекта"
                          allowEmpty
                        />
                      </FormRow>
                      <FormRow>
                        <NumberInput source="floorArea" label="Площадь" />
                      </FormRow>
                      <FormRow>
                        <NumberInput source="plottage" label="Площадь участка" />
                      </FormRow>
                      <FormRow>
                        <BooleanInput label="Комплексы Villagio Estate" source="isVillagio" />
                      </FormRow>
                      <FormRow>
                        <BooleanInput
                          label={isBuyAndRent ? 'Пригласил на показ' : 'Предложил посмотреть дом'}
                          source="isInvited"
                        />
                      </FormRow>
                      {!isBuyAndRent && (
                        <>
                          <AutocompleteWithFormRow
                            {...props}
                            source="realtyObject"
                            resourceName="RealtyObjects"
                            resourceId="realtyObjectId"
                            componentProps={{
                              label: 'Объект продажи/сдачи',
                              fullDynamic: 'QueryWithFid',
                              getOptionLabel: (option) => `${option.name} / Id - ${option.id}`,
                            }}
                          />
                          <AutocompleteWithFormRow
                            {...props}
                            source="realtyGroup"
                            resourceName="RealtyGroups"
                            resourceId="realtyGroupId"
                            componentProps={{
                              label: 'Комплекс',
                              fullDynamic: 'NameContains',
                              getOptionLabel: (option) => `${option.name} / Id - ${option.id}`,
                            }}
                          />
                        </>
                      )}
                    </>
                  );
                }}
              </FormDataConsumer>
              {status === 10000 && (
                <>
                  <FormTitle>Данные по сделке</FormTitle>
                  <FormDataConsumer>
                    {({ formData }) => {
                      const type = Number(formData.type);
                      const isBuyAndRent = type === 100 || type === 300;

                      return (
                        <>
                          {isBuyAndRent && (
                            <>
                              <AutocompleteWithFormRow
                                {...props}
                                source="realtyObject"
                                resourceName="RealtyObjects"
                                resourceId="realtyObjectId"
                                componentProps={{
                                  label: 'Объект в сделке',
                                  fullDynamic: 'QueryWithFid',
                                  getOptionLabel: (option) =>
                                    `${option.id}: ${option.name} | ${option.friendlyId}`,
                                }}
                              />
                              <AutocompleteWithFormRow
                                {...props}
                                source="realtyGroup"
                                resourceName="RealtyGroups"
                                resourceId="realtyGroupId"
                                componentProps={{
                                  label: 'Комплекс',
                                  fullDynamic: 'NameContains',
                                  getOptionLabel: (option) => `${option.name} / Id - ${option.id}`,
                                }}
                              />
                            </>
                          )}
                        </>
                      );
                    }}
                  </FormDataConsumer>
                  <AutocompleteWithFormRow
                    {...props}
                    source="realtyObjectPayment"
                    resourceName="RealtyObjectPayments"
                    resourceId="realtyObjectPaymentId"
                    componentProps={{
                      label: 'Тип оплаты',
                      filterSelectedOptions: true,
                    }}
                  />
                  <PeopleSelect
                    source="oppositeExpert"
                    resourceId="oppositeExpertId"
                    {...props}
                    componentProps={{
                      label: 'Эксперт продавца',
                      providerParams: { filter: { isExpert: true } },
                    }}
                  />
                  <FormTitle>Сроки сделки</FormTitle>
                  <FormRow>
                    <DateTimePickerInput
                      pickerType="date"
                      source="prepayDate"
                      label="Дата аванса"
                    />
                  </FormRow>
                  <FormRow>
                    <DateTimePickerInput
                      pickerType="date"
                      source="dkpDate"
                      label="Дата подписания ДКП"
                    />
                  </FormRow>
                  <FormRow>
                    <DateTimePickerInput
                      pickerType="date"
                      source="dvrDate"
                      label="Дата поступления ДВР"
                    />
                  </FormRow>
                  <FormTitle>Сумма сделки</FormTitle>
                  <FormRow>
                    <ConstantSelect
                      source="dealCurrencyType"
                      objectChoices={currencyTypes}
                      label="Валюта стоимости продажи"
                    />
                  </FormRow>
                  <FormRow>
                    <NumberInput source="dealPrice" label="Стоимость продажи" />
                  </FormRow>
                  <FormRow>
                    <NumberInput
                      source="dealPriceInRub"
                      label="Стоимость продажи в рублях"
                      disabled
                    />
                  </FormRow>
                  <FormTitle>Комиссия сделки</FormTitle>
                  <FormRow>
                    <ConstantSelect
                      source="comissionCurrencyType"
                      objectChoices={currencyTypes}
                      label="Валюта комиссии"
                    />
                  </FormRow>
                  <FormRow>
                    <NumberInput source="comission" label="Комиссия" />
                  </FormRow>
                  <FormRow>
                    <NumberInput source="dealPriceInRub" label="Комиссия в рублях" disabled />
                  </FormRow>
                  <FormRow>
                    <NumberInput
                      source="comissionPercents"
                      label="Комиссия в процентах"
                      InputProps={{
                        endAdornment: '%',
                      }}
                      disabled
                    />
                  </FormRow>
                  <FormRow>
                    <BooleanInput label="Сделка с участием агентства?" source="isWithAgency" />
                  </FormRow>
                  <FormDataConsumer>
                    {({ formData }) => {
                      return (
                        formData.isWithAgency && (
                          <>
                            <FormRow>
                              <NumberInput
                                source="agencyFeeInRub"
                                label="Сумма комиссии агентства в рублях"
                              />
                            </FormRow>
                            <FormRow>
                              <NumberInput
                                source="agencyFeePercents"
                                label="Сумма комиссии агентства в процентах"
                                InputProps={{
                                  endAdornment: '%',
                                }}
                                disabled
                              />
                            </FormRow>
                          </>
                        )
                      );
                    }}
                  </FormDataConsumer>
                </>
              )}
            </>
          );
        }}
      />
    </Dialog>
  );
};
