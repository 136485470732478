import React, { useMemo } from 'react';

import { Avatar as MUIAvatar } from '@material-ui/core';
import { getStaticImage, isObject } from '../../../utils';

export const Avatar = ({
  option,
  className,
  sources = { photo: 'photo', name: 'name', lastName: 'lastName' },
}) => {
  let url = '/broken.jpg';

  const photo = option[sources.photo];
  const name = option[sources.name];
  const lastName = option[sources.lastName];

  if (option && photo) {
    url = getStaticImage('60x60', photo);
  }

  const nameElement = useMemo(() => {
    if (!isObject(option)) {
      return null;
    }

    return `${name ? name[0] : ''}${lastName ? lastName[0] : ''}`;
  }, [lastName, name, option]);

  return (
    <MUIAvatar src={url} className={className}>
      {nameElement}
    </MUIAvatar>
  );
};
