import React from 'react';
import { makeStyles } from '@material-ui/core';
import { TextField } from '../../common';
import { mergeClasses } from '../../../utils';
import { DateTimePickerInput } from '../../RAInputs';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  root_columns: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  row: {
    flex: '1 1 auto',
    boxSizing: 'border-box',
    paddingRight: '10px',

    '&:last-child': {
      paddingRight: 0,
      paddingLeft: 8,
    },
  },
  startAdornment: {
    paddingRight: 8,
  },
  row_column: {
    padding: '0 0 8px 0',
    width: '100%',

    '&:last-child': {
      padding: '8px 0 0 0',
    },
  },
}));

export const FilterFromTo = ({
  type = 'number',
  filter,
  onChange,
  currentFilters,
  inline = true,
}) => {
  const classes = useStyles({ inline });

  const minFilterName = filter[0];
  const maxFilterName = filter[1];

  const defaultMinValue = currentFilters[minFilterName];
  const defaultMaxValue = currentFilters[maxFilterName];

  const onChangeMin = onChange(minFilterName);
  const onChangeMax = onChange(maxFilterName);

  return (
    <div className={mergeClasses(classes.root, !inline && classes.root_columns)}>
      <div className={mergeClasses(classes.row, !inline && classes.row_column)}>
        {type === 'datetime-local' ? (
          <DateTimePickerInput
            isRA={false}
            name="min"
            onChange={onChangeMin}
            label="от"
            defaultValue={defaultMinValue}
          />
        ) : (
          <TextField
            defaultValue={defaultMinValue}
            InputProps={{
              startAdornment: <div className={classes.startAdornment}>от</div>,
            }}
            onChange={onChangeMin}
            type={type}
          />
        )}
      </div>
      <div className={mergeClasses(classes.row, !inline && classes.row_column)}>
        {type === 'datetime-local' ? (
          <DateTimePickerInput
            isRA={false}
            name="max"
            onChange={onChangeMax}
            label="до"
            defaultValue={defaultMaxValue}
          />
        ) : (
          <TextField
            defaultValue={defaultMaxValue}
            InputProps={{
              startAdornment: <div className={classes.startAdornment}>до</div>,
            }}
            onChange={onChangeMax}
            type={type}
          />
        )}
      </div>
    </div>
  );
};
