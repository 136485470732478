// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo, useContext, useCallback } from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { isObject, mergeClasses } from '../../../../utils';
import { FieldName } from '../../../Fields';
import { ListItemPublicStatus, ListItemTasksStatus } from '../../ListItemStatuses';
import { StaticImage } from '../../../common';
import { ImageFullDialogContext } from '../../../providers';

const useStyles = makeStyles((theme) => ({
  container: {},
  tags: {
    padding: '8px 0 0 0',
    marginTop: '12px',
    borderTop: '1px solid rgba(0, 0, 0, 0.3)',
    display: 'flex',
    flexWrap: 'wrap',
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  checkbox: {
    flex: '0 0 auto',
  },
  containerExpand: {
    flex: '0 0 auto',
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  containerName: {
    flex: '1 1 auto',
    display: 'flex',
    alignItems: 'center',
  },
  additionalContent: {
    flex: '0 0 auto',
  },
  icons: {
    flex: '0 0 auto',
    display: 'flex',
    flexWrap: 'wrap',
    paddingRight: '8px',
  },
  statusesItem: {
    flex: '0 0 auto',
    paddingLeft: 8,
    lineHeight: '12px',
  },
  photoContainer: {
    flex: '0 0 auto',
  },
  name: {
    flex: '1 1 auto',
    display: 'flex',
    alignItems: 'baseline',
    flexWrap: 'wrap',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expand__open: {
    transform: 'rotate(180deg)',
  },
}));

export const ListItemHeader = (props) => {
  const {
    checkbox,
    record,
    link,
    name: nameProp,
    menuElement,
    children,
    expanded,
    onExpandClick,
    statuses: statusesProp = [],
    depthKey,
    hasTaskStatus = false,
    hasImage = true,
    imagePath,
    hasButtons = true,
    hasCheckbox = true,
    tags = [],
    tagName,
    hasCollapse = true,
  } = props;
  const classes = useStyles();

  const fieldNameProps = useMemo(() => {
    return {
      name: nameProp || record.name,
      link,
      target: false,
    };
  }, [link, nameProp, record.name]);

  const statuses = useMemo(() => {
    const items = [];

    if (hasTaskStatus && !depthKey) {
      items.push(<ListItemTasksStatus taskStats={record.taaskObjectStats} />);
    }

    items.push(<ListItemPublicStatus record={record} />);

    return items.concat(statusesProp);
  }, [depthKey, hasTaskStatus, record, statusesProp]);

  const statusesElements = useMemo(() => {
    return statuses.map((status) => {
      return <div className={classes.statusesItem}>{status}</div>;
    });
  }, [classes.statusesItem, statuses]);

  const onOpenDialog = useContext(ImageFullDialogContext);

  const handleOpenDialog = useCallback(
    (event) => {
      event.preventDefault();
      onOpenDialog(imagePath);
    },
    [imagePath, onOpenDialog],
  );

  const tagsElements = useMemo(() => {
    return tags.map((tag) => {
      return <TagItem tag={tag} />;
    });
  }, [tags]);

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.containerName}>
          {hasButtons && hasCheckbox && <div className={classes.checkbox}>{checkbox}</div>}
          {hasImage && imagePath && (
            <div className={classes.photoContainer}>
              <a href="#" onClick={handleOpenDialog}>
                <StaticImage
                  imagePath={imagePath}
                  size="72x72"
                  imgAttributes={{
                    alt: record.name,
                  }}
                />
              </a>
            </div>
          )}
          <div className={classes.name}>
            <TagItem tag={tagName} isTagName />
            <FieldName {...fieldNameProps} />
          </div>
        </div>
        <div className={classes.icons}>{statusesElements}</div>
        <div className={classes.additionalContent}>{children}</div>
        <div className={classes.containerExpand}>
          {hasButtons && <div className={classes.menu}>{menuElement}</div>}
          {hasCollapse && (
            <IconButton
              className={mergeClasses(classes.expand, expanded && classes.expand__open)}
              onClick={onExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          )}
        </div>
      </div>
      {tagsElements.length > 0 && <div className={classes.tags}>{tagsElements}</div>}
    </div>
  );
};

const useStylesTag = makeStyles((theme) => ({
  root: {
    flex: '0 0 auto',
    margin: (props) => (props.isTagName ? '4px 8px 4px 0' : '4px 8px 0 0'),
    padding: (props) => (props.isTagName ? 8 : 4),
    borderRadius: 4,
    lineHeight: '12px',
    textTransform: (props) => (props.isTagName ? 'none' : 'uppercase'),
    fontSize: (props) => (props.isTagName ? 12 : 10),
    fontWeight: 'bold',
    color: (props) => {
      const { color } = props;

      if (color === 'red') {
        return theme.palette.red['accentFont'];
      }

      if (color === 'green') {
        return theme.palette.green['accentFont'];
      }

      return theme.palette.primaryNew['main'];
    },
    backgroundColor: (props) => {
      const { color } = props;

      if (color === 'red') {
        return theme.palette.red['background'];
      }

      if (color === 'green') {
        return theme.palette.green['background'];
      }

      return theme.palette.primaryNew['background'];
    },
  },
}));

const TagItem = ({ tag, isTagName }) => {
  let color = 'default';
  let text = tag;
  if (isObject(tag)) {
    text = tag.text;
    color = tag.color;
  }

  const classes = useStylesTag({ color, isTagName });

  return <div className={classes.root}>{text}</div>;
};
