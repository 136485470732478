import React, { useRef, useState, useCallback, useMemo, useEffect } from 'react';

import {
  IconButton,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
} from '@material-ui/core';

const defaultOptions = [{ id: 1, text: 'string' }];

export const MenuButton = ({
  buttonClasses,
  icon,
  options = defaultOptions,
  selected: selectedProp,
  onChange,
  allowEmpty,
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selected, setSelected] = useState(selectedProp);

  useEffect(() => {
    setSelected(selectedProp);
  }, [selectedProp]);

  const handleMenuItemClick = useCallback(
    (event, option) => {
      setSelected(option.id);
      setOpen(false);
      onChange?.(option);
    },
    [onChange],
  );

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  const handleClose = useCallback((event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  }, []);

  const menuItems = useMemo(() => {
    return [...(allowEmpty ? [{ id: '-1', text: 'По умолчанию' }] : []), ...options];
  }, [allowEmpty, options]);

  return (
    <>
      <IconButton className={buttonClasses} disableRipple onClick={handleToggle} ref={anchorRef}>
        {icon}
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        style={{ zIndex: 10000 }}
        placement="bottom"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {menuItems.map((option) => (
                    <MenuItem
                      key={option.id}
                      selected={option.id === selected}
                      onClick={(event) => handleMenuItemClick(event, option)}
                    >
                      {option.text}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
