import React, { useRef, useEffect } from 'react';
import { Chart as ChartJs, registerables } from 'chart.js';
import 'chartjs-adapter-moment';
import { makeStyles } from '@material-ui/core/styles';
import { Preloader } from '../..';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
  },
}));

ChartJs.register(...registerables);

export const Chart = ({ loading, config }) => {
  const classes = useStyles();

  const chartContainerRef = useRef(null);
  const chartRef = useRef();

  useEffect(() => {
    chartRef.current = new ChartJs(chartContainerRef.current, config);

    return () => {
      if (chartRef.current) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        chartRef.current.destroy();
      }
    };
  }, [config]);

  return (
    <div className={classes.root}>
      <Preloader loading={loading} bgColor="rgba(0, 0, 0, 0.1)" />
      <canvas ref={chartContainerRef} />
    </div>
  );
};
