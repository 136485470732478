import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useNotify } from 'react-admin';
import { IconButton, makeStyles, Typography, Menu, MenuItem, Badge } from '@material-ui/core';
import {
  PlayCircleOutline,
  PauseCircleOutline,
  PhoneCallback,
  PhoneForwarded,
  CheckCircle,
  HighlightOff,
  HourglassEmptyRounded,
  AccessTime,
  Stop,
  GetApp as GetAppIcon,
} from '@material-ui/icons';
import { customTheme } from '../../../theme';
import { dataProvider } from '../../../dataProvider';
import { seconds2time } from '../../../utils/audio';
import { downloadFileFromBlob } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  startIcon: {
    marginRight: 0,
    marginLeft: 0,
  },
  spinner: {
    marginRight: '10px',
    color: 'grey',
  },
  audioButtonWrapper: {
    borderRadius: 2,
    padding: '6px 12px',
    // background: customTheme.palette.grey.light,
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    // [theme.breakpoints.down('1024')]: {
    //   width: '100%',
    //   marginTop: 8,
    //   marginBottom: 4,
    // },
    // [theme.breakpoints.down('600')]: {
    //   margin: '4px 8px',
    // },
  },
  downloadButton: {
    [theme.breakpoints.down('1024')]: {
      marginLeft: 'auto',
    },
  },
  statusMissed: {
    background: customTheme.palette.red.background,
    color: `${customTheme.palette.red.accentFont} !important`,
  },
  duration: {
    marginRight: 6,
    fontSize: 11,
    lineHeight: 1,
    paddingTop: 2,
    fontWeight: 700,
  },
  iconsWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 6,
  },
  statusIcon: {
    width: 18,
    height: 18,
    color: customTheme.palette.grey.fadedFont,
    '&:not(:last-child)': {
      marginRight: 10,
    },
  },
}));

export const AudioBlockButton = ({ recordId, isDisabled, path, isInDirection }) => {
  const classes = useStyles();
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioCurrentTime, setAudioCurrentTime] = useState('00:00:00');
  const [audioDurationTime, setAudioDurationTime] = useState('00:00:00');
  const [isLoading, setIsLoading] = useState(false);
  const [isAudioSpeedMenuOpened, setIsAudioSpeedMenuOpened] = useState(null);
  const [selectedAudioSpeed, setSelectedAudioSpeed] = useState(1);
  const audioRef = useRef();
  const audioBlobRef = useRef();
  const anchorSpeedButtonRef = useRef();

  const notify = useNotify();

  const loadAudioContent = async () => {
    if (audioRef.current.src) return;
    setIsLoading(true);
    try {
      const audioResult = await dataProvider.getCallRecord(recordId);
      audioBlobRef.current = audioResult.data;
      audioRef.current.src = URL.createObjectURL(audioResult.data);
      audioRef.current.playbackRate = selectedAudioSpeed;
    } catch (error) {
      notify(`Произошла ошибка загрузки файла`, 'error');
    }
  };

  const playAudio = async () => {
    await loadAudioContent();

    const allAudioElements = document.querySelectorAll('[data-is-playing="true"]');
    if (allAudioElements.length > 0) {
      allAudioElements.forEach((item) => {
        item.pause();
        item.currentTime = 0;
      });
    }

    setIsLoading(false);
    setIsPlaying(true);
    audioRef.current.play();
    // setAudioCurrentTime('00:00:00');
  };
  const pauseAudio = () => {
    setIsPlaying(false);
    audioRef.current.pause();
  };
  const stopAudio = () => {
    setIsPlaying(false);
    audioRef.current.pause();
    audioRef.current.currentTime = 0;
  };
  const setAudioTime = () => {
    if (!audioRef.current) return;
    const trackCurrentTime = Math.round(audioRef.current.currentTime);

    setAudioCurrentTime(seconds2time(trackCurrentTime));
  };
  const onEnded = () => setIsPlaying(false);
  useEffect(() => {
    audioRef.current.addEventListener('timeupdate', setAudioTime);
  }, []);

  const downloadFile = useCallback(() => {
    const fetch = async () => {
      setIsLoading(true);
      try {
        const audioResult = await dataProvider.getCallRecord(recordId);
        audioRef.current.src = URL.createObjectURL(audioResult.data);
        audioBlobRef.current = audioResult.data;
        downloadFileFromBlob(audioBlobRef.current, `audio-${recordId}`);
        setIsLoading(false);
      } catch (error) {
        notify(`Произошла ошибка загрузки файла`, 'error');
      }
    };

    if (!audioBlobRef.current) {
      fetch();
    } else {
      downloadFileFromBlob(audioBlobRef.current, `audio-${recordId}`);
    }
  }, [notify, recordId]);

  const handleAudioSpeedMenuClose = useCallback(() => {
    setIsAudioSpeedMenuOpened(null);
  }, []);

  const handleAudioSpeedMenuClick = useCallback(() => {
    setIsAudioSpeedMenuOpened((propOpen) => !propOpen);
  }, []);

  const handleCloseSpeedMenu = useCallback(
    (selectedMenuOption) => () => {
      setIsAudioSpeedMenuOpened(null);
      setSelectedAudioSpeed(selectedMenuOption);
      if (audioRef.current) {
        audioRef.current.playbackRate = selectedMenuOption;
      }
    },
    [],
  );

  return (
    <div className={`${classes.audioButtonWrapper} ${!path && classes.statusMissed}`}>
      <Typography
        variant="overline"
        className={`${classes.duration} ${!path && classes.statusMissed}`}
      >
        {/* {isPlaying ? audioCurrentTime : seconds2time(audioDurationTime)} */}
        {audioCurrentTime} / {seconds2time(audioDurationTime)}
      </Typography>
      {/* eslint-disable-next-line no-nested-ternary */}
      {isLoading ? (
        <IconButton size="small" color={customTheme.palette.grey.fadedFont} disabled={true}>
          <HourglassEmptyRounded />
        </IconButton>
      ) : !isPlaying ? (
        <IconButton
          size="small"
          color={customTheme.palette.grey.fadedFont}
          className={`${!path && classes.statusMissed}`}
          aria-label="add an alarm"
          onClick={playAudio}
          aria-describedby="test"
          disabled={isDisabled}
        >
          <PlayCircleOutline />
        </IconButton>
      ) : (
        <IconButton
          size="small"
          color={customTheme.palette.grey.fadedFont}
          aria-label="add an alarm"
          onClick={pauseAudio}
          aria-describedby="test"
        >
          <PauseCircleOutline />
        </IconButton>
      )}
      <IconButton
        size="small"
        color={customTheme.palette.grey.fadedFont}
        aria-label="add an alarm"
        onClick={stopAudio}
        aria-describedby="test"
      >
        <Stop />
      </IconButton>
      <Badge
        badgeContent={`x${selectedAudioSpeed}`}
        className={classes.audioSpeedBadge}
        color="primary"
        overlap="circular"
      >
        <IconButton
          size="small"
          color={customTheme.palette.grey.fadedFont}
          aria-label="add an alarm"
          onClick={handleAudioSpeedMenuClick}
          aria-describedby="test"
          ref={anchorSpeedButtonRef}
        >
          <AccessTime />
        </IconButton>
      </Badge>
      <Menu
        anchorEl={anchorSpeedButtonRef.current}
        keepMounted
        open={isAudioSpeedMenuOpened}
        onClose={handleAudioSpeedMenuClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleCloseSpeedMenu(1)}>x1</MenuItem>
        <MenuItem onClick={handleCloseSpeedMenu(1.5)}>x1.5</MenuItem>
        <MenuItem onClick={handleCloseSpeedMenu(2)}>x2</MenuItem>
        <MenuItem onClick={handleCloseSpeedMenu(3)}>x3</MenuItem>
      </Menu>
      {isLoading ? (
        <IconButton size="small" color={customTheme.palette.grey.fadedFont} disabled={true}>
          <HourglassEmptyRounded />
        </IconButton>
      ) : (
        <IconButton
          className={classes.downloadButton}
          size="small"
          color={customTheme.palette.grey.fadedFont}
          onClick={downloadFile}
        >
          <GetAppIcon />
        </IconButton>
      )}
      <div className={classes.iconsWrapper}>
        {isInDirection && (
          <PhoneCallback className={`${classes.statusIcon} ${!path && classes.statusMissed}`} />
        )}
        {!isInDirection && (
          <PhoneForwarded className={`${classes.statusIcon} ${!path && classes.statusMissed}`} />
        )}
        {path && (
          <CheckCircle className={`${classes.statusIcon} ${!path && classes.statusMissed}`} />
        )}
        {!path && (
          <HighlightOff className={`${classes.statusIcon} ${!path && classes.statusMissed}`} />
        )}
      </div>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio
        data-is-playing={isPlaying}
        ref={audioRef}
        onEnded={onEnded}
        onPause={onEnded}
        onLoadedMetadata={() => {
          setAudioDurationTime(audioRef.current.duration);
        }}
      />
    </div>
  );
};
