import React, { useCallback } from 'react';
import { MenuItem, makeStyles } from '@material-ui/core';
import { Chat } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  root: {
    fontSize: 16,
    lineHeight: '1.4',
    height: 40,
    padding: '25px 25px',
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      marginRight: 15,
    },
  },
}));

export const ChatDeskMenuItem = () => {
  const classes = useStyles();

  const handleEdit = useCallback(() => {
    window.open('https://web.chat2desk.com/chat/', '_blank');
  }, []);

  return (
    <MenuItem className={classes.root} onClick={handleEdit}>
      <Chat width={24} height={24} />
      Chat2Desk
    </MenuItem>
  );
};
