import React from 'react';

import { FormFeatures } from '../../../components/forms';
import { Edit } from '../../../components/common';

export const EditFeatures = (props) => {
  return (
    <Edit {...props}>
      <FormFeatures {...props} redirect={false} />
    </Edit>
  );
};
