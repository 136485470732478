import React, { useRef } from 'react';

import { MenuCell } from '../../../tables';
import { DeleteMenuItem, EditMenuItem } from '../../../menuItems';
import { ChatDeskMenuItem } from '../../../menuItems/ChatDesk';

export const DemandsMenu = ({ record, resource }) => {
  const handleCloseRef = useRef();

  return (
    <MenuCell handleCloseRef={handleCloseRef}>
      <ChatDeskMenuItem />
      <EditMenuItem resource={resource} record={record} />
      <DeleteMenuItem resource={resource} record={record} handleCloseRef={handleCloseRef} />
    </MenuCell>
  );
};
