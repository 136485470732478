import React, { useMemo, useCallback, useState, useRef } from 'react';
import { FormWithRedirect, useRefresh, TabbedFormView, FormTab } from 'react-admin';
import { makeStyles, useTheme } from '@material-ui/core';
import { Dialog } from '../Dialog';

import { Button } from '../../buttons';

import { TextInput } from '../../TextInput/TextInput';

import { FormRow } from '../../FormRow';
import { AutocompleteWithFormRow, ConstantSelect, List } from '../../app';
import { FormCard } from '../../FormCard';

import { DateTimePickerInput } from '../../RAInputs';

import { validates } from '../../../utils';
import { StatusField } from '../../common/StatusFields';
import { dataProvider } from '../../../dataProvider';
import { showCalcStatuses, showResults, visitShowStatuses } from '../../../constants';
import { FormTitle } from '../../common';
import { ListItem } from '../../app/ListItems';
import { PeopleSelect } from '../../RASelects';
import { currentUser } from '../../../authProvider';
import { DynamicList, ListObjects, ListVillages } from '../../additionalLists';

const useStyles = makeStyles(() => ({
  dialogPaper: {
    // maxWidth: 600,
  },
}));

const defaultRecord = {};

export const CreateShowDialog = (props) => {
  const classes = useStyles();
  const {
    changeOpenRef,
    record = defaultRecord,
    handleCloseRef,
    defaultContract,
    onReload,
    onClose,
    onSave,
    defaultDate,
  } = props;
  const { id } = record;

  const isEdit = useMemo(() => {
    if (Object.keys(record).length > 0) {
      return true;
    }

    return false;
  }, [record]);

  const refresh = useRefresh();
  const formRef = useRef();

  const [loading, setLoading] = useState(false);

  const closeMenu = useCallback(() => {
    handleCloseRef?.current?.();
  }, [handleCloseRef]);

  const handleSave = useCallback(
    (values) => {
      const fetch = async () => {
        setLoading(true);

        const resource = 'Demos';

        try {
          if (isEdit) {
            await dataProvider.update(resource, {
              id: record.id,
              data: values,
              previousData: record,
            });
          } else {
            await dataProvider.create(resource, { data: { ...values } });
          }

          if (changeOpenRef.current) {
            changeOpenRef.current(false);
          }

          if (onReload) {
            onReload();
            return;
          }

          refresh();
          onSave?.();
          closeMenu();
        } finally {
          setLoading(false);
        }
      };

      fetch();
    },
    [changeOpenRef, closeMenu, isEdit, onReload, onSave, record, refresh],
  );

  const handleSubmit = useCallback(() => {
    const submit = formRef.current.handleSubmit;
    if (submit) {
      submit();
    }
  }, []);

  const handleClose = useCallback(() => {
    closeMenu();
    onClose?.();
  }, [closeMenu, onClose]);

  const buttons = useMemo(() => {
    return (
      <Button onClick={handleSubmit} loading={loading}>
        Сохранить
      </Button>
    );
  }, [handleSubmit, loading]);

  const defaultValueDate = useMemo(() => {
    if (defaultDate) {
      return defaultDate;
    }

    return new Date().toISOString();
  }, [defaultDate]);

  const theme = useTheme();

  return (
    <Dialog
      {...props}
      buttons={buttons}
      dialogProps={{ fullScreen: true, classes: { paper: classes.dialogPaper } }}
      title={isEdit ? 'Редактирование показа' : 'Создание показа'}
      onClose={handleClose}
    >
      <FormWithRedirect
        save={handleSave}
        record={record}
        render={(formProps) => {
          formRef.current = formProps;

          return (
            <TabbedFormView {...formProps} syncWithLocation={false} toolbar={null}>
              <FormTab label="Основное">
                <FormCard>
                  {id && (
                    <FormRow>
                      <StatusField
                        styleProps={{
                          backgroundColor:
                            // eslint-disable-next-line no-nested-ternary
                            record.calcStatus === 100
                              ? theme.palette.primaryNew['accentFont']
                              : record.calcStatus === 200
                              ? theme.palette.green['accentFont']
                              : theme.palette.red['accentFont'],
                          fontSize: 16,
                        }}
                      >
                        {showCalcStatuses[record.calcStatus]}
                      </StatusField>
                    </FormRow>
                  )}
                  <AutocompleteWithFormRow
                    {...props}
                    source="contract"
                    resourceName="Contracts"
                    resourceId="contractId"
                    validate={validates.contract}
                    componentProps={{
                      label: 'Лид',
                      getOptionLabel: (option) => `${option.name} / Id - ${option.id}`,
                      defaultValue: defaultContract,
                    }}
                  />
                  <FormRow>
                    <ConstantSelect
                      source="isNotFirst"
                      objectChoices={visitShowStatuses}
                      label="Статус визита"
                      allowEmpty
                      validate={validates.required}
                    />
                  </FormRow>
                  <FormRow>
                    <DateTimePickerInput
                      source="date"
                      label="Дата показа (план)"
                      pickerType="date"
                      validate={validates.required}
                      defaultValue={defaultValueDate}
                    />
                  </FormRow>
                  <FormRow>
                    <DateTimePickerInput
                      source="actualDate"
                      label="Дата показа"
                      pickerType="date"
                      validate={validates.showActualDate}
                    />
                  </FormRow>
                  <FormTitle>Результат показа</FormTitle>
                  <FormRow>
                    <ConstantSelect
                      source="result"
                      objectChoices={showResults}
                      label="Как прошел показ"
                      allowEmpty
                    />
                  </FormRow>
                  <FormRow maxWidth={800} isLast>
                    <TextInput
                      source="description2"
                      label="Комментарий результата"
                      multiline
                      rows={6}
                      validate={validates.description2Shows}
                    />
                  </FormRow>
                  {id && record?.contract?.mainClient && (
                    <>
                      <FormTitle>Клиент</FormTitle>
                      <FormRow>
                        <ListItem
                          type="client"
                          record={record.contract.mainClient}
                          hasButtons={false}
                          isNewItem
                        />
                      </FormRow>
                    </>
                  )}
                  <PeopleSelect
                    source="expert"
                    resourceId="expertId"
                    {...props}
                    validate={validates.expertShows}
                    componentProps={{
                      label: 'Эксперт на показе',
                      providerParams: { filter: { isExpert: true } },
                      defaultValue: defaultContract?.currentExpert,
                    }}
                  />
                  <FormRow maxWidth={800} isLast>
                    <TextInput source="description1" label="Комментарий" multiline rows={6} />
                  </FormRow>
                  <PeopleSelect
                    source="createdUser"
                    resourceId="createdUserId"
                    {...props}
                    componentProps={{
                      label: 'Создатель показа',
                      providerParams: { filter: { isClient: true } },
                      disabled: true,
                      defaultValue: currentUser,
                    }}
                  />
                </FormCard>
              </FormTab>
              <FormTab label="Объекты">
                <ListObjects
                  page="shows"
                  isCreate={!id}
                  validate={validates.showObjectsOrVillages}
                />
              </FormTab>
              <FormTab label="Обзорная экскурсия">
                <ListVillages page="shows" validate={validates.showObjectsOrVillages} />
              </FormTab>
              <FormTab label="Важные объекты">
                <DynamicList
                  fetchOptions={{
                    resource: 'RealtyObjects',
                    filter: { IsImportant: true },
                  }}
                  contentElement={List}
                  contentOptions={{
                    type: 'object',
                    hasButtons: false,
                  }}
                />
              </FormTab>
            </TabbedFormView>
          );
        }}
      />
    </Dialog>
  );
};
