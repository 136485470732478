import React from 'react';
import { getFormattedNumber, getMillions, getVillageLink } from '../../../../utils';
import { StatusField } from '../../../common/StatusFields';

import { VillagesMenu } from '../../Menus';
import { Card } from '../..';

export const VillageCard = (props) => {
  const { data: item, statusFields: statusFieldsProp, resource } = props;

  const statusFields = [...statusFieldsProp];
  const link = getVillageLink(item);

  const fieldNameProps = {
    name: item.name,
    link,
  };
  const imagePath = item.images?.[0]?.path;

  if (item.isVillagio) {
    statusFields.push(
      <StatusField styleProps={{ backgroundColor: '#64DD17' }}>Villagio</StatusField>,
    );
  }

  const descriptions = [];

  if (item.stats?.priceMin && item.stats?.priceMax) {
    descriptions.push(
      `${getFormattedNumber(getMillions(item.stats.priceMin))} - ${getFormattedNumber(
        getMillions(item.stats.priceMax),
      )} (М/₽)`,
    );
  }

  if (item.area) {
    descriptions.push(`${item.area} (сот.)`);
  }

  if (item.distance) {
    descriptions.push(`${item.distance} (км. МКАД)`);
  }

  const menuElement = <VillagesMenu record={item} resource={resource} />;

  return (
    <Card
      {...props}
      fieldNameProps={fieldNameProps}
      imagePath={imagePath}
      statusFields={statusFields}
      descriptions={descriptions}
      menuElement={menuElement}
    />
  );
};
