import React from 'react';

import { makeStyles } from '@material-ui/core';
import { UsersMenu } from '../../Menus';
import { Card } from '../..';
import { StatusField } from '../../../common/StatusFields';
import { RolesField } from '../../../RAFields';
import { ownerStatuses } from '../../../../constants';

const useStyles = makeStyles(() => ({
  containerRoles: {
    marginTop: '20px',
  },
}));

export const UserCard = (props) => {
  const classes = useStyles();
  const { data: item, permissions, statusFields: statusFieldsProp, resource, hasButtons } = props;

  const statusFields = [...statusFieldsProp];

  let name = '';

  if (item.name) {
    name += item.name;
  }
  if (item.lastName) {
    name += ` ${item.lastName}`;
  }
  if (item.middleName) {
    name += ` ${item.middleName}`;
  }

  let link;

  if (!hasButtons) {
    link = `/Peoples/${item.id}`;
  }

  const fieldNameProps = {
    name,
    link,
    target: false,
  };

  const imagePath = item.photo;

  const menuElement = <UsersMenu record={item} resource={resource} permissions={permissions} />;

  if (item.isStaff) {
    statusFields.push(
      <StatusField styleProps={{ backgroundColor: '#64DD17' }}>Villagio</StatusField>,
    );
  }

  if (item.isNew) {
    statusFields.push(<StatusField styleProps={{ backgroundColor: '#64DD17' }}>Новый</StatusField>);
  }

  if (item.isClient) {
    statusFields.push(
      <StatusField styleProps={{ backgroundColor: '#64DD17' }}>Клиент</StatusField>,
    );
  }

  if (item.isOwner) {
    statusFields.push(
      <StatusField styleProps={{ backgroundColor: '#64DD17' }}>Собственник</StatusField>,
    );
  }

  if (item.isGodExpert) {
    statusFields.push(
      <StatusField styleProps={{ backgroundColor: '#64DD17' }}>Ведущий эксперт</StatusField>,
    );
  }

  const descriptions = [];

  if (item.eMail) {
    descriptions.push(item.eMail);
  }

  if (item.phone) {
    descriptions.push(item.phone);
  }

  if (item.ownerStatus) {
    descriptions.push(`Статус: ${ownerStatuses[item.ownerStatus]}`);
  }

  return (
    <Card
      {...props}
      fieldNameProps={fieldNameProps}
      statusFields={statusFields}
      menuElement={menuElement}
      imagePath={imagePath}
      descriptions={descriptions}
    >
      <div className={classes.containerRoles}>
        <RolesField record={item} />
      </div>
    </Card>
  );
};
