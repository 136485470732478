import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import { RichText } from '../../common';
import { AddCommentButton } from '../../buttons';
import { DynamicList } from '../../additionalLists';
import { Comments } from '../Comments';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    padding: '0',
    boxSizing: 'border-box',
  },
  content: {
    flex: '1 1 0',
    minHeight: 0,
    overflow: 'auto',
    position: 'relative',
  },
  footer: {
    padding: '16px 16px 0 16px',
    flex: '0 0 auto',
  },
}));

export const CommentsTab = ({ id, type }) => {
  const classes = useStyles();

  const [comment, setComment] = useState();

  const handleChange = useCallback((value) => {
    setComment(value);
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <DynamicList
          fetchOptions={{
            resource: 'Comments',
            filter: { CommentObjectId: id, CommentObjectType: type },
          }}
          contentElement={Comments}
        />
      </div>
      <div className={classes.footer}>
        <RichText
          label="Комментарий"
          source="newComment"
          onChange={handleChange}
          hasImage
          maxHeight="150px"
        />
        {/* <TextField label="Комментарий" multiline rows={4} onChange={handleChange} /> */}
        <AddCommentButton
          id={id}
          type={type}
          comment={comment}
          style={{ marginTop: '16px' }}
          disabled={!comment}
        />
      </div>
    </div>
  );
};
