import React, { useCallback } from 'react';
import { useCreate, useRefresh, useNotify } from 'react-admin';

import { MenuItem, makeStyles } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';

const useStyles = makeStyles(() => ({
  root: {
    fontSize: 16,
    lineHeight: '1.4',
    height: 40,
    padding: '25px 25px',
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      marginRight: 15,
    },
  },
}));

export const ClonePromoMenuItem = ({ record, resource, handleCloseRef }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const notify = useNotify();

  const [create] = useCreate(null, null, {
    onSuccess: () => {
      refresh();
    },
    onFailure: (error) => notify(`Error: ${error.message}`, 'warning'),
  });

  const handleClick = useCallback(() => {
    const newRecord = { ...record };
    newRecord.name += String(new Date().getTime());
    newRecord.slug += String(new Date().getTime());

    if (handleCloseRef.current) {
      handleCloseRef.current();
    }
    create(resource, newRecord);
  }, [create, handleCloseRef, record, resource]);

  return (
    <MenuItem className={classes.root} onClick={handleClick}>
      <FileCopyIcon width={22} height={24} /> Дублировать
    </MenuItem>
  );
};
