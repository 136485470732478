import React from 'react';
import { getFormattedNumber, getMillions, getSetLink } from '../../../../utils';
import { StatusField } from '../../../common/StatusFields';

import { SetsMenu } from '../../Menus';
import { Card } from '../..';

export const SetCard = (props) => {
  const { data: item, statusFields: statusFieldsProp, resource } = props;

  const statusFields = [...statusFieldsProp];
  const link = getSetLink(item);

  const fieldNameProps = {
    name: item.name,
    link,
  };
  const imagePath = item.photo;

  if (item.realtyObjects && item.realtyObjects.length > 0) {
    statusFields.push(<StatusField>{item.realtyObjects.length} (шт.)</StatusField>);
  }

  if (item.realtyObjectSetTypeId === 2) {
    statusFields.push(
      <StatusField styleProps={{ backgroundColor: '#64DD17' }}>Private</StatusField>,
    );
  }

  if (item.realtyObjectSetTypeId === 1) {
    statusFields.push(
      <StatusField styleProps={{ backgroundColor: '#64DD17' }}>Public</StatusField>,
    );
  }

  const descriptions = [];

  if (item.stats?.priceMin && item.stats?.priceMax) {
    descriptions.push(
      `${getFormattedNumber(getMillions(item.stats.priceMin))} - ${getFormattedNumber(
        getMillions(item.stats.priceMax),
      )} (М/₽)`,
    );
  }

  if (item.area) {
    descriptions.push(`${item.area} (сот.)`);
  }

  if (item.distance) {
    descriptions.push(`${item.distance} (км. МКАД)`);
  }

  const menuElement = <SetsMenu record={item} resource={resource} />;

  return (
    <Card
      {...props}
      fieldNameProps={fieldNameProps}
      imagePath={imagePath}
      statusFields={statusFields}
      descriptions={descriptions}
      menuElement={menuElement}
    />
  );
};
