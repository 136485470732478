import React, { useMemo } from 'react';

import { AutocompleteDynamic } from '../../common';
import { PeopleNameField } from '../../RAFields';
import { getFullNameWithId } from '../../../utils';

// const useStyles = makeStyles(() => ({
//   avatar: {
//     width: '24px',
//     height: '24px',
//     marginRight: '6px',
//     fontSize: '10px',
//   },
//   photo: {
//     paddingRight: '20px',
//   },
// }));

// const WrapperPhotoField = ({ record }) => {
//   const path = useMemo(() => {
//     return record.photo;
//   }, [record]);

//   return <PhotoField path={path} />;
// };

const WrapperNameField = ({ record }) => {
  return <PeopleNameField record={record} hasId />;
};

const Option = ({ option }) => {
  // const classes = useStyles();

  return (
    <>
      {/* <div className={classes.photo}>
        <WrapperPhotoField record={option} />
      </div> */}
      <WrapperNameField record={option} />
    </>
  );
};

export const PeoplesSelect = (props) => {
  const { onlyExperts } = props;

  const params = useMemo(() => {
    const defaultParams = {
      filter: {
        FlatDetails: 300,
      },
    };

    if (onlyExperts) {
      defaultParams.filter.isExpert = true;
    }

    return defaultParams;
  }, [onlyExperts]);

  return (
    <AutocompleteDynamic
      multiple
      filterSelectedOptions
      resource="peoples"
      limitTags={4}
      renderOption={(option) => <Option option={option} />}
      providerParams={params}
      getOptionLabel={(option) => getFullNameWithId(option)}
      fullDynamic="NameContains"
      filterOptions={(options) => options}
      {...props}
    />
  );
};
