import React from 'react';

import { ListBase } from '../../../../components/ListBase';

import { RAList } from '../../../../components/app';

const MainList = () => {
  return <RAList type="cmsPage" hasDelete={false} />;
};

export const ListMagazineMainPages = (props) => {
  return (
    <ListBase {...props} filter={{ Ids: '10,11' }} hasDelete={false}>
      <MainList {...props} />
    </ListBase>
  );
};
