import React from 'react';

import { Create } from '../../../../components/common';
import { FormCMSProjects } from '../../../../components/forms';

export const CreateCMSProjects = (props) => {
  return (
    <Create {...props}>
      <FormCMSProjects {...props} redirect="list" />
    </Create>
  );
};
