import React from 'react';
import { FormEnum } from '../../../forms';
import { Edit } from '../../../common';

export const EditEnum = (props) => {
  return (
    <Edit {...props}>
      <FormEnum {...props} redirect={false} />
    </Edit>
  );
};
