import React from 'react';

import { FormCMSWidgetTemplates } from '../../../../components/forms';
import { Edit } from '../../../../components/common';

export const EditCMSWidgetTemplates = (props) => {
  return (
    <Edit {...props}>
      <FormCMSWidgetTemplates {...props} redirect={false} />
    </Edit>
  );
};
