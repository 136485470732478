// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { DefaultMenu } from '../../Menus';
import { imageSeasons, imageTypes } from '../../../../constants';
import { Card, SortOrderCard } from '../..';
import { LinkField } from '../../../RAFields';

const useStyles = makeStyles(() => ({
  linkContainer: {
    fontSize: '12px',
    marginTop: '20px',
    marginBottom: '30px',
  },
}));

export const VideoCard = (props) => {
  const { data: item, statusFields: statusFieldsProp, resource, onChangeSortOrder } = props;
  const classes = useStyles();

  const statusFields = [...statusFieldsProp];

  const fieldNameProps = {
    name: item.name,
  };

  const menuElement = <DefaultMenu record={item} resource={resource} />;

  const chipTexts = [];

  if (item.imageSeason) {
    chipTexts.push(imageSeasons[item.imageSeason]);
  }

  if (item.imageType) {
    chipTexts.push(imageTypes[item.imageType]);
  }

  return (
    <Card
      {...props}
      fieldNameProps={fieldNameProps}
      menuElement={menuElement}
      statusFields={statusFields}
      chipTexts={chipTexts}
      hasImage={false}
    >
      <>
        <div className={classes.linkContainer}>
          <LinkField record={item} />
        </div>
        <SortOrderCard
          onChangeSortOrder={onChangeSortOrder}
          id={item.id}
          defaultValue={item.sortOrder}
        />
      </>
    </Card>
  );
};
