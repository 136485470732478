import React, { useEffect, useRef } from 'react';
import { TabbedFormTabs, TabbedForm as RATabbedForm } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import { TableToolbar } from '../TableToolbar';
import { customTheme } from '../../../theme';

const useStyles = makeStyles(() => ({
  tabContent: {
    padding: '16px',
  },
  '@media (max-width: 1024px)': {
    tabContent: {
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
}));

export const TabbedForm = (props) => {
  const { hideSave, children, getToolbarButtons, location } = props;
  const classes = useStyles();

  const tabbedFormRef = useRef();

  useEffect(() => {
    const wrapperDataSelector = document.querySelector("[data-tabbed-form-wrapper='exists']");
    if (wrapperDataSelector !== null) {
      return;
    }
    const tabbedFormSelector = document.querySelector('.tabbed-form > *:nth-child(3)');
    const wrapper = document.createElement('div');
    wrapper.setAttribute('data-tabbed-form-wrapper', 'exists');
    wrapper.style.cssText = `
      flex: 1 1 0;
      display: flex;
      width: 100%;
      overflow: auto;
      position: relative;
      box-sizing: border-box;
      min-height: 400px;
      background-color: ${customTheme.palette.grey.background};
      margin-bottom: 16px;
    `;
    tabbedFormSelector.parentNode.insertBefore(wrapper, tabbedFormSelector);
    wrapper.appendChild(tabbedFormSelector);
  });

  return (
    <RATabbedForm
      {...props}
      ref={tabbedFormRef}
      classes={{ content: classes.tabContent }}
      toolbar={
        <TableToolbar
          hideSave={hideSave}
          getToolbarButtons={getToolbarButtons}
          location={location}
        />
      }
      tabs={<TabbedFormTabs variant="scrollable" />}
    >
      {children}
    </RATabbedForm>
  );
};
