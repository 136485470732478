import React, { useCallback, useState, useEffect } from 'react';
import { Datagrid, useListContext } from 'react-admin';

import { ButtonGroup, Button } from '@material-ui/core';
import { ListBase } from '../../../components/ListBase';

import {
  NameField,
  DiscountDatesField,
  DiscountValueField,
  PhotoField,
} from '../../../components/RAFields';
import { DefaultMenu } from '../../../components/app/Menus';
import { RAList } from '../../../components/app';

const WrapperPhotoField = ({ record }) => {
  return <PhotoField path={record.photo} />;
};

const WrapperNameField = ({ record }) => {
  const { id, name, slug } = record;

  return <NameField name={name} id={id} additionalId={slug} />;
};

const Table = () => {
  return (
    <Datagrid hasBulkActions>
      <WrapperPhotoField label="Фото" />
      <WrapperNameField source="name" label="Название" sortable={false} />
      <DiscountValueField label="Скидка" />
      <DiscountDatesField label="Даты" />
      <DefaultMenu />
    </Datagrid>
  );
};

const Cards = () => {
  return <RAList type="discount" />;
};

const CustomHeaderContent = () => {
  const [value, setValue] = useState();
  const { filterValues, setFilters } = useListContext();

  useEffect(() => {
    setValue(filterValues.IsAction);
  }, [filterValues]);

  const handleChange = useCallback(
    (newValue) => () => {
      let resultValue;
      if (value !== String(newValue)) {
        resultValue = String(newValue);
      }
      const newFilterValues = { ...filterValues, IsAction: resultValue };

      setFilters(newFilterValues);
      setValue(resultValue);
    },
    [filterValues, setFilters, value],
  );

  return (
    <ButtonGroup color="primary" aria-label="outlined primary button group">
      <Button
        variant={value === 'true' ? 'contained' : false}
        value="IsAction"
        onClick={handleChange(true)}
      >
        Акции
      </Button>
      <Button
        variant={value === 'false' ? 'contained' : false}
        value="IsDiscount"
        onClick={handleChange(false)}
      >
        Скидки
      </Button>
    </ButtonGroup>
  );
};

export const ListDiscounts = (props) => {
  return (
    <ListBase
      {...props}
      hasToggleView
      tableRender={<Table {...props} />}
      cardRender={<Cards {...props} />}
      customHeaderContent={<CustomHeaderContent />}
    />
  );
};
