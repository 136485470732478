import React, { useCallback } from 'react';
import { browserHistory } from '../../../App';

import { Button } from '../Button';

export const CreateContractButton = (props) => {
  const { record, type } = props;

  const handleClick = useCallback(() => {
    browserHistory.push('/Contracts/create', {
      item: record,
      backPath: browserHistory.location.pathname,
      type,
    });
  }, [record, type]);

  return (
    <Button {...props} onClick={handleClick}>
      Создать лид
    </Button>
  );
};
