/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-webpack-loader-syntax */
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';
import { MapboxStyleSwitcherControl } from 'mapbox-gl-style-switcher';

import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import 'mapbox-gl-style-switcher/styles.css';

const token = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

mapboxgl.workerClass = MapboxWorker;
mapboxgl.accessToken = token;

const geocodingService = mbxGeocoding({
  accessToken: token,
});

const serachByCoords = async (coords) => {
  // eslint-disable-next-line no-return-await
  return await geocodingService
    .reverseGeocode({
      query: coords,
      types: [
        'country',
        'region',
        'postcode',
        'district',
        'place',
        'locality',
        'neighborhood',
        'address',
      ],
      language: ['ru'],
    })
    .send()
    .then((response) => {
      // GeoJSON document with geocoding matches
      return response.body;
    });
};

export { mapboxgl, MapboxDraw, MapboxGeocoder, serachByCoords, MapboxStyleSwitcherControl };
