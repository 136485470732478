import React, { useMemo } from 'react';
import { FormTab } from 'react-admin';

import { TextInput } from '../../TextInput/TextInput';
import { TabbedForm, FormTitle, RichText, BooleanInput } from '../../common';

import { validates, checkRoleAdminOrSupervisor } from '../../../utils';
import { SeoTab } from '../../formTabs';
import { FormRow } from '../../FormRow';
import {
  ConstantSelect,
  AutocompleteWithFormRow,
  ImageInputWithFormRow,
  TasksTab,
} from '../../app';
import { publicationStatuses } from '../../../constants';

import { ExpertSelect } from '../../RASelects';
import { ListObjects } from '../../additionalLists';
import { FormCard } from '../../FormCard';

export const FormSets = (props) => {
  const { permissions, id, location, record } = props;

  const defaultObjects = useMemo(() => {
    if (location.state?.items) {
      return location.state?.items;
    }
  }, [location.state]);

  const hasRoleAdminOrSupervisor = useMemo(() => {
    return checkRoleAdminOrSupervisor(permissions);
  }, [permissions]);

  return (
    <TabbedForm {...props}>
      <FormTab label="Основное">
        <FormCard>
          {id && (
            <FormRow>
              <TextInput source="id" label="Id" disabled />
            </FormRow>
          )}
          <FormRow>
            <TextInput
              source="slug"
              label="Слаг"
              validate={validates.slug}
              defaultValue="AUTO"
              helperText="Для автоматического создания слага надо написать AUTO"
            />
          </FormRow>
          <AutocompleteWithFormRow
            {...props}
            source="realtyObjectSetType"
            resourceName="realtyObjectSetTypes"
            resourceId="realtyObjectSetTypeId"
            componentProps={{
              label: 'Тип подборки',
              getOptionDisabled: (option) => {
                return option.id === 1 && !hasRoleAdminOrSupervisor;
              },
            }}
          />
          <FormRow>
            <ConstantSelect
              source="sysStatus"
              objectChoices={publicationStatuses}
              label="Статус публикации"
              defaultValue={100}
            />
          </FormRow>
          <FormRow>
            <TextInput
              source="sortOrder"
              label="Порядок сортировки"
              type="number"
              defaultValue={1000}
              disabled={!hasRoleAdminOrSupervisor}
            />
          </FormRow>
          <ExpertSelect {...props} />
          <FormRow>
            <TextInput source="name" label="Название" validate={validates.name} />
          </FormRow>
          <FormRow>
            <TextInput source="shortName" label="Краткое название" validate={validates.shortName} />
          </FormRow>
          <ImageInputWithFormRow {...props} source="photo" />
          <ImageInputWithFormRow
            {...props}
            source="videoPoster"
            buttonText="Добавить постер к видео"
          />
          <ImageInputWithFormRow {...props} source="video" isVideo />
          <FormRow>
            <BooleanInput label="Снять затемнение с обложки?" source="isPhotoEnhance1" />
          </FormRow>
          <FormTitle>Описание</FormTitle>
          <FormRow maxWidth={800}>
            <RichText source="description" />
          </FormRow>
          <FormRow maxWidth={800} isLast>
            <TextInput
              source="introduction"
              label="Вводный текст"
              multiline
              rows={6}
              validate={validates.introduction}
            />
          </FormRow>
        </FormCard>
      </FormTab>
      <FormTab label="SEO">
        <FormCard>
          <SeoTab />
        </FormCard>
      </FormTab>
      <FormTab label="Объекты">
        <ListObjects defaultValue={defaultObjects} />
      </FormTab>
      {id && (
        <FormTab label="Задачи">
          <TasksTab record={record} type={400} location={location} />
        </FormTab>
      )}
    </TabbedForm>
  );
};
