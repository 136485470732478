import React, { useEffect, useMemo, useState } from 'react';
import { useInput } from 'react-admin';
import { Button, ButtonGroup } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { HelperText } from '../common';

const useStyles = makeStyles((theme) => ({
  active: {
    backgroundColor: theme.palette.primaryNew['main'],
    color: theme.palette.primaryNew['contrastText'],
    borderColor: theme.palette.primaryNew['main'],

    '&:hover': {
      backgroundColor: theme.palette.primaryNew['main'],
      color: theme.palette.primaryNew['contrastText'],
    },
  },
}));

export const Toggler = ({ items, helperText = '', ...props }) => {
  const classes = useStyles();
  const { input, meta } = useInput({ ...props });

  const [value, setValue] = useState(input.value);

  useEffect(() => {
    input.onChange(value);
  }, [input, value]);

  const buttons = useMemo(() => {
    return items.map((item) => {
      return (
        <Button
          className={`${value === item.value ? classes.active : ''}`}
          onClick={() => {
            setValue(item.value);
          }}
        >
          {item.text}
        </Button>
      );
    });
  }, [classes.active, items, value]);

  return (
    <div>
      <ButtonGroup size="large" color="inherit" fullWidth>
        {buttons}
      </ButtonGroup>
      <HelperText {...meta} helperText={helperText} />
    </div>
  );
};
