import React, { useMemo } from 'react';
import { useInput } from 'react-admin';

import RichTextInput from 'ra-input-rich-text';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  richText: {
    '& .ql-editor': {
      minHeight: '40px',
      maxHeight: (prop) => prop.maxHeight,
      backgroundColor: (prop) =>
        prop.isFilled ? theme.palette.primaryNew.light : 'rgba(0, 0, 0, 0.04)',
    },
    '& .ql-tooltip': {
      marginLeft: '130px',
    },
  },
}));

const getOptions = ({ hasImage = false, hasVideo = false }) => {
  const elements = ['link'];
  if (hasImage) {
    elements.push('image');
  }

  if (hasVideo) {
    elements.push('video');
  }

  return [
    // [{ font: [] }, { size: [] }],
    // [{ color: [] }, { background: [] }],
    [{ header: ['1', '2', '3', false] }],
    ['bold', 'italic', 'underline'],
    [{ align: [] }],
    elements,
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['clean'],
  ];
};

export const RichText = (props) => {
  const { hasImage, hasVideo, source, maxHeight = '250px' } = props;

  const {
    input: { value },
  } = useInput({
    source,
  });

  const isFilled = useMemo(() => {
    return Boolean(value);
  }, [value]);

  const styles = useStyles({ isFilled, maxHeight });

  return (
    <RichTextInput
      toolbar={getOptions({ hasImage, hasVideo })}
      helperText=""
      {...props}
      label=""
      variant={styles.richText}
    />
  );
};
