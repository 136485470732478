export const checkExpired = (record) => {
  if (!record.plannedDate) {
    return false;
  }

  const plannedDate = new Date(record.plannedDate).getTime();
  const nowDate = new Date().getTime();

  if (!record.completedDate && nowDate >= plannedDate) {
    return true;
  }

  return false;
};

export const getExpiredCount = (record) => {
  if (!record.plannedDate) {
    return false;
  }

  const plannedDate = new Date(record.plannedDate).getTime();
  const nowDate = new Date().getTime();
  let countDays;

  if (!record.completedDate && nowDate >= plannedDate) {
    countDays = Math.ceil(Math.abs(nowDate - plannedDate) / (1000 * 3600 * 24));
    return countDays;
  }
};
