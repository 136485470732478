import React from 'react';
import { Edit } from '../../../components/common';
import { FormTouches } from '../../../components/forms';

export const EditTouches = (props) => {
  return (
    <Edit {...props}>
      <FormTouches {...props} redirect={false} />
    </Edit>
  );
};
