import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ListItemMainBlock, SortOrderListItem } from '../..';
import { getFeedLinks } from '../../../../utils';

const useStyles = makeStyles((theme) => ({
  buttons: {
    flex: '0 0 auto',
    padding: '0 12px',
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('1024')]: {
      flexWrap: 'wrap',
    },
  },
  link: {
    color: theme.palette.primaryNew['main'],
  },
}));

export const ListItemFeed = ({ record, hasButtons }) => {
  const classes = useStyles();

  const descriptions = [];

  if (record.feedTargets && record.feedTargets.length !== 0 && record.sysStatus !== 100) {
    const links = getFeedLinks(record);

    links.forEach((link) => {
      descriptions.push(
        // eslint-disable-next-line react/jsx-no-target-blank
        <a className={classes.link} href={link} target="_blank">
          {link}
        </a>,
      );
    });
  }

  const link = `/RealtyFeeds/${record.id}`;

  return (
    <>
      <ListItemMainBlock
        record={record}
        descriptions={descriptions}
        hasButtons={hasButtons}
        link={link}
      />
      <div className={classes.buttons}>
        <div className={classes.buttonsItem}>
          <SortOrderListItem sortOrder={record.sortOrder} />
        </div>
      </div>
    </>
  );
};
