import React, { useCallback } from 'react';
import { MenuItem } from '@material-ui/core';

export const RelationObjectSelectedMenu = ({ onClose, onReset, onDeleteItems, ...otherProps }) => {
  const { selectedIds } = otherProps;

  const handleClear = useCallback(() => {
    if (onReset) {
      onReset();
    }
  }, [onReset]);

  const handleDelete = useCallback(() => {
    if (onDeleteItems) {
      onDeleteItems(selectedIds);
    }
  }, [onDeleteItems, selectedIds]);

  return (
    <>
      <MenuItem onClick={handleClear}>Очистить все</MenuItem>
      <MenuItem onClick={handleDelete}>Удалить</MenuItem>
    </>
  );
};
