import React from 'react';
import { makeStyles } from '@material-ui/core';

function createMarkup(html) {
  return { __html: html };
}

const useStyles = makeStyles(() => ({
  root: {
    '& p': {
      margin: '10px 0 0 0',

      '&:first-child': {
        marginTop: '0',
      },
    },
  },
}));

export const HTMLContent = ({ html }) => {
  const classes = useStyles();

  // eslint-disable-next-line react/no-danger
  return <div className={classes.root} dangerouslySetInnerHTML={createMarkup(html)} />;
};
