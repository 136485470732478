import React, { useMemo } from 'react';

import { Block as BlockIcon } from '@material-ui/icons';
import { makeStyles, Tooltip } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    fontSize: '16px',
    color: '#FF3D00',
  },
}));

export const ListItemPredefined = () => {
  const classes = useStyles();

  const icon = useMemo(() => {
    return <BlockIcon fontSize="inherit" />;
  }, []);

  return (
    <div className={classes.root}>
      <Tooltip title="Предопределен" arrow>
        {icon}
      </Tooltip>
    </div>
  );
};
