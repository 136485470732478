import React from 'react';

import { FormDiscounts } from '../../../components/forms';
import { Edit } from '../../../components/common';

export const EditDiscounts = (props) => {
  return (
    <Edit {...props}>
      <FormDiscounts {...props} redirect={false} />
    </Edit>
  );
};
