import React from 'react';

import { PeopleSelect } from '../People';

export const ClientSelect = (props) => {
  const { componentProps } = props;

  return (
    <PeopleSelect
      source="client"
      resourceId="clientId"
      {...props}
      componentProps={{
        label: 'Клиент',
        providerParams: { filter: { isClient: true } },
        ...componentProps,
      }}
    />
  );
};
