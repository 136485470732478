import React, { useCallback } from 'react';
import { useDelete, useRefresh, useNotify } from 'react-admin';

import { MenuItem, makeStyles } from '@material-ui/core';
import { DeleteIcon } from '../../Icons';

const useStyles = makeStyles(() => ({
  root: {
    fontSize: 16,
    lineHeight: '1.4',
    height: 40,
    padding: '25px 25px',
    display: 'flex',
    alignItems: 'center',
    borderTop: '1px solid #E0E0E0',

    '& svg': {
      marginRight: 15,
    },
  },
}));

export const DeleteMenuItem = ({ record, resource, handleCloseRef }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const notify = useNotify();

  const [deleteOne] = useDelete(resource, record.id, record, {
    onSuccess: () => {
      refresh();
    },
    onFailure: (error) => notify(`Error: ${error.message}`, 'warning'),
  });

  const handleDelete = useCallback(() => {
    if (handleCloseRef.current) {
      handleCloseRef.current();
    }
    deleteOne();
  }, [deleteOne, handleCloseRef]);

  return (
    <MenuItem className={classes.root} onClick={handleDelete}>
      <DeleteIcon width={22} height={24} /> Удалить
    </MenuItem>
  );
};
