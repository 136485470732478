import React, { useState, useEffect, useRef } from 'react';

import { Dialog as MaterialDialog, makeStyles, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { useNotify } from 'react-admin';
import { Preloader } from '../..';
import { dataProvider } from '../../../dataProvider';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',

    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },
  content: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 12,

    '& button': {
      backgroundColor: 'lightgrey',

      '&:hover': {
        backgroundColor: 'lightgrey',
      },
    },
  },
}));

export const ArticlePreviewDialog = ({ changeOpenRef, formData }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const isImageLoadedRef = useRef(false);
  const iframeRef = useRef();
  const notify = useNotify();

  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await dataProvider.getArticlePreview(formData);
        iframeRef.current.src = URL.createObjectURL(data);
        setLoading(false);
        isImageLoadedRef.current = true;
      } catch (error) {
        setLoading(false);
        isImageLoadedRef.current = false;
        notify(`Не удалось загрузить превью`, 'error');
      }
    };

    if (open === true && isImageLoadedRef.current === false) {
      setLoading(true);
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  if (changeOpenRef) {
    changeOpenRef.current = setOpen;
  }

  const handleClose = () => {
    setOpen(false);
    isImageLoadedRef.current = false;
  };

  return (
    <MaterialDialog fullScreen open={open} onClose={handleClose}>
      <div className={classes.closeButton}>
        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </div>
      <div className={classes.root}>
        <div className={classes.content}>
          {loading && <Preloader loading={loading} size={60} />}
          <iframe ref={iframeRef} style={{ width: '100%', height: '100%' }} title="preview" />
        </div>
      </div>
    </MaterialDialog>
  );
};
