import React from 'react';
import { makeStyles } from '@material-ui/core';
import { customTheme } from '../../../../theme';

const useStyles = makeStyles(() => ({
  root: {
    fontSize: '14px',
    padding: '4px 8px',
    display: 'block',
    flex: '0 0 auto',
    background: customTheme.palette.primary['A200'],
    borderRadius: '2px',
    color: 'white',
  },
}));

export const FilterNumber = ({ number }) => {
  const classes = useStyles();

  if (number === 0) {
    return null;
  }

  return <span className={classes.root}>{number}</span>;
};
