import React, { useCallback } from 'react';
import { Datagrid } from 'react-admin';

import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { ListBase } from '../../../components/ListBase';

import { NameField, DiscountDatesField, PhotoField } from '../../../components/RAFields';
import { PromosMenu } from '../../../components/app/Menus';
import { RAList } from '../../../components/app';

const WrapperPhotoField = ({ record }) => {
  return <PhotoField path={record.photo} />;
};

const WrapperNameField = ({ record }) => {
  const { id, name, slug } = record;

  return <NameField name={name} id={id} additionalId={slug} />;
};

const Table = () => {
  return (
    <Datagrid hasBulkActions>
      <WrapperPhotoField label="Фото" />
      <WrapperNameField source="name" label="Название" sortable={false} />
      <DiscountDatesField label="Даты" />
      <PromosMenu />
    </Datagrid>
  );
};

const Cards = ({ menuItem }) => {
  return <RAList type="promo" menuItem={menuItem} />;
};

export const ListPromos = (props) => {
  const { resource } = props;

  const menuItem = useCallback(
    (record) => {
      return <PromosMenu record={record} resource={resource} icon={<MoreVertIcon />} />;
    },
    [resource],
  );

  return (
    <ListBase
      {...props}
      hasToggleView
      tableRender={<Table {...props} />}
      cardRender={<Cards {...props} menuItem={menuItem} />}
    />
  );
};
