import React from 'react';
import { CheckboxGroupInput, FormTab } from 'react-admin';

import { FormRow } from '../../../components/FormRow';
import { Toggler } from '../../../components/Toggler';
import { Checkbox } from '../../../components/Checkbox';
import { TextInput } from '../../../components/TextInput/TextInput';

import { convertObjectChoicesToArray } from '../../../utils';
import { roles } from '../../../constants';
import { Edit, TabbedForm } from '../../../components/common';
import { FormCard } from '../../../components/FormCard';

const TogglerWithFormRow = ({ formRowProps, ...props }) => {
  return (
    <FormRow {...formRowProps}>
      <Toggler {...props} />
    </FormRow>
  );
};

const CheckboxWithFormRow = ({ formRowProps, ...props }) => {
  return (
    <FormRow {...formRowProps}>
      <Checkbox {...props} />
    </FormRow>
  );
};

const statusItems = [
  {
    text: 'Активен',
    value: true,
  },
  {
    text: 'Не активен',
    value: false,
  },
];

const emailConfirmedItems = [
  {
    text: 'Подтверждена',
    value: true,
  },
  {
    text: 'Не подтверждена',
    value: false,
  },
];

export const EditLogins = (props) => {
  const { id } = props;

  return (
    <Edit {...props}>
      <TabbedForm {...props} redirect={false}>
        <FormTab label="Основное">
          <FormCard>
            {id && (
              <FormRow>
                <TextInput source="id" label="Id" disabled />
              </FormRow>
            )}
            <TogglerWithFormRow items={statusItems} source="isActive" />
            <CheckboxWithFormRow source="isStaff" label="Сотрудник Villagio" />
            <FormRow>
              <TextInput
                source="displayName"
                label="Отображаемое имя в системе"
                variant="outlined"
                fullWidth
              />
            </FormRow>
            <FormRow>
              <TextInput source="firstName" label="Имя" variant="outlined" fullWidth />
            </FormRow>
            <FormRow>
              <TextInput source="otherName" label="Отчество" variant="outlined" fullWidth />
            </FormRow>
            <FormRow>
              <TextInput source="lastName" label="Фамилия" variant="outlined" fullWidth />
            </FormRow>
            <FormRow>
              <TextInput source="userName" label="Логин" variant="outlined" fullWidth />
            </FormRow>
            <FormRow>
              <TextInput source="email" label="Эл. почта" variant="outlined" fullWidth />
            </FormRow>
            <FormRow>
              <TextInput
                source="phoneNumber"
                label="Телефон"
                helperText="Вводить по маске +7(999)888-77-66."
                variant="outlined"
                fullWidth
              />
            </FormRow>
            <FormRow>
              <TextInput
                source="password"
                label="Пароль"
                variant="outlined"
                fullWidth
                helperText="Для изменения пароля, введите новый!"
              />
            </FormRow>
            <TogglerWithFormRow
              items={emailConfirmedItems}
              source="emailConfirmed"
              formRowProps={{ maxWidth: 800 }}
            />
            <FormRow maxWidth={800}>
              <CheckboxGroupInput
                source="roles"
                choices={convertObjectChoicesToArray(roles)}
                optionText="text"
                optionValue="id"
                label="Роли"
              />
            </FormRow>
            <FormRow maxWidth={800} isLast>
              <TextInput
                source="comment"
                label="Комментарий"
                variant="outlined"
                multiline
                rows={6}
                fullWidth
              />
            </FormRow>
          </FormCard>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
