import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { StatusFieldIds, StatusFieldSysStatus, StatusFieldTask } from '../../common/StatusFields';
import { ObjectCard } from './ObjectCard';
import { VillageCard } from './VillageCard';
import { DefaultCard } from './DefaultCard';
import { SetCard } from './SetCard';
import { SectorCard } from './SectorCard';
import { InfrastructureCard } from './InfrastructureCard';
import { FeedCard } from './FeedCard';
import { UserCard } from './UserCard';
import { DemandCard } from './DemandCard';
import { TaskCard } from './TaskCard';
import { VideoCard } from './VideoCard';
import { ImageCard } from './ImageCard';
import { DiscountCard } from './DiscountCard';
import { DemandCallCard } from './DemandCallCard';

const CardElements = {
  object: ObjectCard,
  village: VillageCard,
  set: SetCard,
  sector: SectorCard,
  infrastructure: InfrastructureCard,
  feed: FeedCard,
  user: UserCard,
  task: TaskCard,
  video: VideoCard,
  image: ImageCard,
  default: DefaultCard,
  demand: DemandCard,
  discount: DiscountCard,
  demandCall: DemandCallCard,
};

const useStyles = makeStyles(() => ({
  cards: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    // display: 'grid',
    // gridTemplateColumns: 'repeat(auto-fit, minmax(320px, 1fr))',
    // gridGap: '0px',
  },
}));

// display: grid;
// padding: 1rem;
// grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
// grid-gap: 1rem;

export const Cards = ({
  permissions,
  additionalId,
  getSelectable,
  type = 'default',
  hasCheckbox: hasCheckboxProp = true,
  resource,
  onToggleItem,
  selectedIds = [],
  items = [],
  hasButtons = true,
  menuItem: menuItemProp,
  depthKey,
  onChangeSortOrder,
}) => {
  const classes = useStyles();

  if (!(type in CardElements)) {
    alert('Не существующий тип карточки!!!');
    type = 'default';
  }

  const CardElement = CardElements[type];

  const elements = useMemo(() => {
    return items.map((currentItem) => {
      let menuItem;
      if (menuItemProp) {
        menuItem = menuItemProp(currentItem);
      }

      let item = { ...currentItem };

      if (depthKey) {
        item = { ...currentItem[depthKey] };
      }

      const statusFields = [
        <StatusFieldIds record={item} additionalId={additionalId} />,
        <StatusFieldSysStatus record={item} />,
      ];

      if (
        type === 'object' ||
        type === 'village' ||
        type === 'set' ||
        type === 'sector' ||
        type === 'infrastructure' ||
        type === 'user'
      ) {
        statusFields.push(<StatusFieldTask taskStats={item.taaskObjectStats} />);
      }

      const selected = selectedIds.find((selectedId) => {
        return selectedId === item.id;
      });

      let hasCheckbox = hasCheckboxProp;
      if (getSelectable) {
        hasCheckbox = getSelectable(item);
      }

      return (
        <CardElement
          key={item.id}
          data={item}
          statusFields={statusFields}
          onToggleItem={onToggleItem}
          selected={selected}
          hasCheckbox={hasCheckbox}
          permissions={permissions}
          resource={resource}
          hasButtons={hasButtons}
          menuItem={menuItem}
          onChangeSortOrder={onChangeSortOrder}
        />
      );
    });
  }, [
    items,
    menuItemProp,
    depthKey,
    additionalId,
    type,
    selectedIds,
    hasCheckboxProp,
    getSelectable,
    onToggleItem,
    permissions,
    resource,
    hasButtons,
    onChangeSortOrder,
  ]);

  return <div className={classes.cards}>{elements}</div>;
};
