import React from 'react';

import { FormSectors } from '../../../components/forms';
import { Edit } from '../../../components/common';

export const EditSectors = (props) => {
  return (
    <Edit {...props}>
      <FormSectors {...props} redirect={false} />
    </Edit>
  );
};
