import React from 'react';
import { FormEnum } from '../../../forms';
import { Create } from '../../../common';

export const CreateEnum = (props) => {
  return (
    <Create {...props} title={null}>
      <FormEnum {...props} redirect="list" />
    </Create>
  );
};
