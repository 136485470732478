import React from 'react';
import { Create } from '../../../components/common';
import { FormTouches } from '../../../components/forms';

export const CreateTouches = (props) => {
  return (
    <Create {...props}>
      <FormTouches {...props} redirect="list" />
    </Create>
  );
};
