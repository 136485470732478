import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ListItemMainBlock, SortOrderListItem } from '../..';

const useStyles = makeStyles((theme) => ({
  buttons: {
    flex: '0 0 auto',
    padding: '0 12px',
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('1024')]: {
      flexWrap: 'wrap',
    },
  },
}));

export const ListItemInfrastructure = ({ record, depthKey, originalRecord, hasButtons }) => {
  const classes = useStyles();

  const contentStatuses = [];

  const imagePath = record.images?.[0]?.path;

  if (record.infrastructureType) {
    contentStatuses.push(record.infrastructureType.name);
  }

  const link = `/Infrastructures/${record.id}`;

  return (
    <>
      <ListItemMainBlock
        record={record}
        contentStatuses={contentStatuses}
        hasTaskStatus
        imagePath={imagePath}
        link={link}
        depthKey={depthKey}
        hasButtons={hasButtons}
      />
      <div className={classes.buttons}>
        <div className={classes.buttonsItem}>
          <SortOrderListItem sortOrder={originalRecord.sortOrder} />
        </div>
      </div>
    </>
  );
};
