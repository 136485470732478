import React, { useMemo } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { StaticImage } from '../../../common';
import { customTheme } from '../../../../theme';
import { DemandCallsMenu } from '../../Menus/DemandCallsMenu/DemandCallsMenu';
import { AudioBlockButton } from '../../../buttons/AudioBlockButton';
import { getFormattedDate } from '../../../../utils';
import defaultPhoto from '../../../../assets/images/deafultPhoto.png';
import { clientTouchTypes, demandCallTechSources } from '../../../../constants';

const useStyles = makeStyles(() => ({
  card: {
    margin: '10px',
    boxShadow:
      '0px 0px 24px rgba(0, 0, 0, 0.05), 0px 0px 4px rgba(0, 0, 0, 0.05), 0px 0px 8px rgba(0, 0, 0, 0.05)',
    borderRadius: '6px',
    boxSizing: 'border-box',
    background: '#fff',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    position: 'relative',
    padding: '16px',
  },
  descriptionSection: {
    flexGrow: 1,
    paddingRight: 12,
  },
  // cardId: {
  //   color: customTheme.palette.grey.fadedFont,
  // },
  cardOverline: {
    color: customTheme.palette.grey.fadedFont,
    lineHeight: 1.3,
  },
  userStatus: {
    marginRight: 6,
    backgroundColor: customTheme.palette.grey.light,
    padding: 4,
  },
  userDetails: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginTop: 8,
  },
  userName: {
    marginRight: 6,
  },
  audioSection: {
    marginRight: 12,
  },
  managerSection: {
    display: 'flex',
    alignItems: 'center',
  },
  photo: {
    width: 32,
    height: 32,
    borderRadius: '50%',
    background: 'red',
    marginRight: 12,
  },
  menuSection: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 12,
  },
  dateAndTime: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 2,
  },
  date: {
    marginRight: 6,
    fontWeight: 700,
    fontSize: 11,
    color: customTheme.palette.grey.fadedFont,
  },
  time: {
    fontWeight: 700,
    fontSize: 11,
    color: customTheme.palette.grey.fadedFont,
  },
  '@media (max-width: 1024px)': {
    descriptionSection: {
      width: 'calc(100% - 80px)',
      order: 1,
      marginBottom: 24,
    },
    audioSection: {
      order: 3,
    },
    managerSection: {
      order: 4,
      marginLeft: 'auto',
    },
    menuSection: {
      marginBottom: 24,
      width: 50,
      order: 2,
    },
    userStatus: {
      padding: 0,
      backgroundColor: 'transparent',
      width: '100%',
      order: 4,
      color: customTheme.palette.grey.fadedFont,
      marginTop: 4,
    },
    dateAndTime: {
      marginRight: 0,
    },
  },
}));

export const DemandCallCard = (props) => {
  const classes = useStyles();
  const { data: item, resource, hasButtons = true } = props;
  const {
    id,
    client,
    employee,
    externalPhoneNumber,
    initiatedDate,
    path,
    isInDirection,
    techSource,
    createdDate,
  } = item;

  const cardType = useMemo(() => {
    return demandCallTechSources[techSource];
  }, [techSource]);

  const menuElement = <DemandCallsMenu record={item} resource={resource} />;
  const callStatus = () => {
    if (!path && isInDirection) {
      return 'Пропущенный | ';
    }
    if (!path && !isInDirection) {
      return 'Недозвон | ';
    }
    if (path && isInDirection) {
      return 'Входящий | ';
    }
    if (path && !isInDirection) {
      return 'Исходящий | ';
    }
  };

  return (
    <div className={classes.card}>
      <div className={classes.descriptionSection}>
        <div className={classes.cardDetails}>
          <Typography className={classes.cardOverline} variant="overline">
            {`ID - ${id} | `}
          </Typography>
          <Typography className={classes.cardOverline} variant="overline">
            {callStatus()}
          </Typography>
          <Typography className={classes.cardOverline} variant="overline">
            {item.target ? clientTouchTypes[item.target] : 'Неразобранный'}
          </Typography>
        </div>
        <div className={classes.userDetails}>
          {client?.isClient && (
            <Typography className={classes.userStatus} variant="caption">
              Клиент
            </Typography>
          )}
          {client?.isOwner && (
            <Typography className={classes.userStatus} variant="caption">
              Собственник
            </Typography>
          )}
          <Typography className={classes.userName} variant="caption">
            {client?.name ? `${client.name} ${client.lastName}` : 'Новый клиент'}
          </Typography>
          <Typography className={classes.userPhone} variant="caption">
            {externalPhoneNumber}
          </Typography>
        </div>
      </div>
      {cardType === 'Comagic' && (
        <div className={classes.audioSection}>
          <AudioBlockButton
            recordId={id}
            path={path}
            isInDirection={isInDirection}
            isDisabled={path === undefined}
          />
        </div>
      )}
      <div className={classes.managerSection}>
        {employee?.photo && (
          <div className={classes.photo}>
            <StaticImage
              imagePath={employee.photo}
              size="320x197"
              defaultImagePath={defaultPhoto}
              imgAttributes={{
                className: classes.photo,
                alt: employee.name,
              }}
            />
          </div>
        )}
        <div className={classes.dateAndTime}>
          <Typography className={classes.date} variant="body2">
            {getFormattedDate(initiatedDate || createdDate, {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            })}
          </Typography>
        </div>
      </div>
      {hasButtons && <div className={classes.menuSection}>{menuElement}</div>}
    </div>
  );
};
