import React from 'react';
import { makeStyles } from '@material-ui/core';
import { getObjectLink, getFormattedNumber, getMillions } from '../../../../utils';
import { StatusFieldSold, StatusFieldSell, StatusFieldRent } from '../../../common/StatusFields';

import { ObjectsMenu } from '../../Menus';
import { Card } from '../..';

const useStyles = makeStyles(() => ({
  soldImage: {
    '-webkit-filter': 'grayscale(100%)',
    '-moz-filter': 'grayscale(100%)',
    '-ms-filter': 'grayscale(100%)',
    '-o-filter': 'grayscale(100%)',
    filter: 'grayscale(100%)',
    // eslint-disable-next-line no-dupe-keys
    filter: 'url(grayscale.svg)',
    // eslint-disable-next-line no-dupe-keys
    filter: 'gray',
  },
}));

export const ObjectCard = (props) => {
  const classes = useStyles();
  const { data: item, permissions, statusFields: statusFieldsProp, resource, hasButtons } = props;

  const statusFields = [...statusFieldsProp];

  let link = getObjectLink(item);

  if (!hasButtons) {
    link = `/RealtyObjects/${item.id}`;
  }

  const fieldNameProps = {
    name: item.name,
    link,
    target: hasButtons,
  };
  const imagePath = item.images?.[0]?.path;

  if (item.isSold) {
    statusFields.push(<StatusFieldSold />);
  }

  if (!item.isSold && item.sysStatus !== -100) {
    if (item.currentPrice) {
      statusFields.push(<StatusFieldSell />);
    }

    if (item.currentRentPrice) {
      statusFields.push(<StatusFieldRent />);
    }
  }

  const descriptions = [];

  if (item.currentPriceInRub) {
    descriptions.push(`${getFormattedNumber(getMillions(item.currentPriceInRub))} (М/₽)`);
  }

  if (item.currentRentPriceInRub) {
    descriptions.push(`${getFormattedNumber(item.currentRentPriceInRub)} (₽/мес.)`);
  }

  if (item.totalFoorArea) {
    descriptions.push(`${item.totalFoorArea} (м2)`);
  }

  if (item.plottage) {
    descriptions.push(`${item.plottage} (сот.)`);
  }

  if (item.distanceToMkad) {
    descriptions.push(`${item.distanceToMkad} (км. МКАД)`);
  }

  const menuElement = <ObjectsMenu record={item} resource={resource} permissions={permissions} />;

  const chipTexts = [];

  if (item.realtyGroup?.name) {
    chipTexts.push(item.realtyGroup.name);
  }

  if (item.currentObjectState?.name) {
    chipTexts.push(item.currentObjectState.name);
  }

  if (item.contractType === 100) {
    chipTexts.push('Exclusive');
  }

  if (item.owner) {
    chipTexts.push(`Собственник: ${item.owner.name || ''} ${item.owner.lastName || ''}`);
  }

  const newClasses = {};

  if (item.isSold || item.sysStatus === -100) {
    newClasses.image = classes.soldImage;
  }

  return (
    <Card
      {...props}
      fieldNameProps={fieldNameProps}
      imagePath={imagePath}
      statusFields={statusFields}
      descriptions={descriptions}
      menuElement={menuElement}
      chipTexts={chipTexts}
      classes={newClasses}
    />
  );
};
