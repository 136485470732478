import React, { useRef, useCallback } from 'react';

import AddIcon from '@material-ui/icons/Add';
import { Button } from '../Button';
import { CreateTaskDialog } from '../../dialogs';

export const CreateTaskDialogButton = ({ record, defaultItem, type, onReload, ...props }) => {
  const changeOpenRef = useRef();

  const handleOpenDialog = useCallback(() => {
    if (changeOpenRef.current) {
      changeOpenRef.current(true);
    }
  }, []);

  return (
    <>
      <Button size="small" {...props} icon={<AddIcon />} onClick={handleOpenDialog}>
        Добавить задачу
      </Button>
      <CreateTaskDialog
        record={record}
        changeOpenRef={changeOpenRef}
        defaultType={type}
        defaultItem={defaultItem}
        onReload={onReload}
      />
    </>
  );
};
