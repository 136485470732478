import React from 'react';
import { StatusField } from '../StatusField';

export const StatusFieldTask = ({ fontSize = '12px', taskStats }) => {
  if (taskStats && taskStats.isOutdated) {
    return (
      <StatusField styleProps={{ backgroundColor: '#FF3D00', fontSize }}>
        Есть просроченая задача
      </StatusField>
    );
  }

  if ((taskStats && taskStats.isNew) || (taskStats && taskStats.isInProgress)) {
    return (
      <StatusField styleProps={{ backgroundColor: '#64DD17', fontSize }}>Есть задачи</StatusField>
    );
  }

  return <StatusField styleProps={{ fontSize }}>Нет задач</StatusField>;
};
