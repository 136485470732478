/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */

import { UserManager, WebStorageStateStore } from 'oidc-client-ts';
import { browserHistory } from './App';
import { dataProvider } from './dataProvider';
import { convertLoginToPeople } from './utils';

let onGetToken = () => {};
export const setOnGetToken = (callback) => {
  onGetToken = callback;
};
let onGetCurrentUser = () => {};
export const setOnGetCurrentUser = (callback) => {
  onGetCurrentUser = callback;
};

const issuer = process.env.REACT_APP_AUTH_HOST;
const clientId = process.env.REACT_APP_AUTH_CLIENT_ID;
const redirectUri = new URL(
  process.env.REACT_APP_AUTH_CALLBACK_PATH,
  process.env.REACT_APP_PUBLIC_HOST,
).href;
const silentRedirectUri = new URL(
  process.env.REACT_APP_AUTH_SILENT_CALLBACK_PATH,
  process.env.REACT_APP_PUBLIC_HOST,
).href;
const responseType = process.env.REACT_APP_AUTH_RESPONSE_TYPE;
const scope = process.env.REACT_APP_AUTH_SCOPES;
// const apiUri = process.env.REACT_APP_AUTH_API_URL;
//

export const userManager = new UserManager({
  authority: issuer,
  client_id: clientId,
  redirect_uri: redirectUri,
  silent_redirect_uri: silentRedirectUri,
  response_type: responseType,
  scope,
  extraQueryParams: {
    audience: (process.env.REACT_APP_AUTH_AUDIENCE || process.env.REACT_APP_AUTH_CLIENT_ID).split(
      ' ',
    ),
  },
  automaticSilentRenew: true,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
});

// eslint-disable-next-line import/no-mutable-exports
export let currentProfile;
// eslint-disable-next-line import/no-mutable-exports
export let currentUser;

const getUser = async (id) => {
  try {
    const result = await dataProvider.getList('Peoples/current');
    currentUser = result.data;
    onGetCurrentUser(result.data);
    return result.data;
  } catch (error) {
    if (error.response.data.error.message === 'Не найдено') {
      const login = await dataProvider.getOne('users', {
        id,
      });

      if (!login.data) {
        alert('Произошла какая то дичь!!!!!!!!!!!!!!!!!!');
      }

      const newPersonData = convertLoginToPeople(login.data);

      const newPerson = await dataProvider.create('Peoples', {
        data: newPersonData,
      });

      onGetCurrentUser(newPerson.data);
      currentUser = newPerson.data;
      return newPerson.data;
    }

    alert('Не удалось создать пипла!!!');
  }
};

const localStorageKey = `oidc.user:${process.env.REACT_APP_AUTH_HOST}:${process.env.REACT_APP_AUTH_CLIENT_ID}`;

export const getOidc = () => {
  return JSON.parse(localStorage.getItem(localStorageKey));
};

export const removeOidc = () => {
  localStorage.removeItem(localStorageKey);
};

let oidc = getOidc();

if (oidc) {
  currentProfile = oidc.profile;
}

const getFrom = () => {
  if (window.location.pathname === '/loading' || window.location.pathname === '/oidc-callback') {
    return '/';
  }

  return `${window.location.pathname}${window.location.search}`;
};

userManager
  .signinRedirectCallback()
  // eslint-disable-next-line promise/always-return
  .then((newOidc) => {
    oidc = newOidc;
    onGetToken(oidc);
    currentProfile = newOidc.profile;
    // eslint-disable-next-line promise/always-return
    const fromUrl = oidc.state || '/';

    const fetch = async () => {
      currentUser = await getUser(currentProfile.id);
      browserHistory.push(fromUrl);
    };

    fetch();
  })
  .catch(() => {});

export const logout = async (isLogout) => {
  try {
    removeOidc();
    await userManager.clearStaleState();
    await userManager.removeUser();
    if (isLogout) {
      await userManager.signoutRedirect();
    } else {
      await userManager.signinRedirect({
        state: getFrom(),
      });
    }
  } catch (error) {
    console.log(error);
  }
};

// accessTokenExpiring: Raised prior to the access token expiring.
userManager.events.addAccessTokenExpiring(() => {
  console.log('[access token expiring]');
});

// accessTokenExpired: Raised after the access token has expired.
userManager.events.addAccessTokenExpired(() => {
  console.log('[access token expired]');
});

userManager.events.addUserSignedOut(() => {
  logout();
  console.log('[user signed out]');
});

userManager.events.addSilentRenewError(() => {
  console.log('[user silent error]');
  logout();
});

export const authProvider = {
  login: async () => {
    return Promise.resolve();
  },
  logout: (event) => {
    logout(event === null);
    return Promise.resolve();
  },
  checkError: (params) => {
    if (params.response.status === 401) {
      logout();
    }

    if (params.response.status === 403) {
      browserHistory.push('/no-rights');
    }

    return Promise.resolve();
  },
  checkAuth: async () => {
    const pathName = window.location.pathname;
    if (!oidc && !pathName.includes('/oidc-callback')) {
      logout();
    }

    if (oidc) {
      currentUser = await getUser(currentProfile.id);
    }

    return Promise.resolve();
  },
  getPermissions: async () => {
    if (currentProfile) {
      return Promise.resolve(currentProfile.role);
    }

    return Promise.reject();
  },
  getIdentity: async () => {
    if (currentUser) {
      return Promise.resolve(currentUser);
    }

    return Promise.reject();
  },
};

for (const key in authProvider) {
  const originalFunction = authProvider[key];
  authProvider[key] = (arg) => {
    console.log(`[authProvider.${key}]`);
    return originalFunction(arg);
  };
}
