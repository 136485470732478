// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-unreachable */
import React, { useMemo, useRef, useCallback } from 'react';
import { FormWithRedirect } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { Dialog } from '../Dialog';

import { Button } from '../../buttons';
import { ConstantSelect } from '../../app';
import { FormRow } from '../../FormRow';
import { publicationStatuses, booleans, imageSeasons, imageTypes } from '../../../constants';
import { parseBoolean, validates } from '../../../utils';
import { TextInput } from '../../TextInput/TextInput';

const useStyles = makeStyles(() => ({
  dialogPaper: {
    maxWidth: 465,
  },
}));

export const ImagesMultipleDialog = (props) => {
  const classes = useStyles();
  const { changeOpenRef, onChangeItems, data, selectedIds, isVideo } = props;

  const formRef = useRef();

  const handleSave = useCallback(
    (values) => {
      const newValues = { ...values };
      newValues.clientStatus = 'edit';
      selectedIds.forEach((selectedId) => {
        const foundItem = data.find((dataItem) => {
          return dataItem.id === selectedId;
        });

        if (foundItem) {
          const newValue = { ...foundItem, ...newValues };
          if (onChangeItems) {
            onChangeItems(newValue);
          }
        }
      });

      if (changeOpenRef.current) {
        changeOpenRef.current(false);
      }
    },
    [changeOpenRef, data, onChangeItems, selectedIds],
  );

  const handleSubmit = useCallback(() => {
    const submit = formRef.current.handleSubmit;
    if (submit) {
      submit();
    }
  }, []);

  const buttons = useMemo(() => {
    return <Button onClick={handleSubmit}>Сохранить</Button>;
  }, [handleSubmit]);

  return (
    <Dialog
      {...props}
      buttons={buttons}
      dialogProps={{ fullWidth: true, classes: { paper: classes.dialogPaper } }}
      title={isVideo ? 'Редактировать поля видео' : 'Редактировать поля картинок'}
    >
      <FormWithRedirect
        save={handleSave}
        record={{}}
        render={(formProps) => {
          formRef.current = formProps;

          return (
            <>
              <FormRow>
                <TextInput source="name" label="Имя" validate={validates.multipleName} />
              </FormRow>
              <FormRow>
                <ConstantSelect
                  source="imageSeason"
                  objectChoices={imageSeasons}
                  label="Сезон"
                  allowEmpty
                />
              </FormRow>
              <FormRow>
                <ConstantSelect
                  source="imageType"
                  objectChoices={imageTypes}
                  label="Тип"
                  allowEmpty
                />
              </FormRow>
              <FormRow>
                <ConstantSelect
                  source="sysStatus"
                  objectChoices={publicationStatuses}
                  label="Статус публикации"
                  allowEmpty
                />
              </FormRow>
              {!isVideo && (
                <>
                  <FormRow>
                    <ConstantSelect
                      source="isNightTime"
                      objectChoices={booleans}
                      label="Ночное время"
                      allowEmpty
                      parse={parseBoolean}
                    />
                  </FormRow>
                  <FormRow>
                    <ConstantSelect
                      source="isShowInPdf"
                      objectChoices={booleans}
                      label="Показывать в презентации"
                      allowEmpty
                      parse={parseBoolean}
                    />
                  </FormRow>
                </>
              )}
              <FormRow>
                <TextInput source="source" label="Источник" />
              </FormRow>
              <FormRow>
                <TextInput source="author" label="Автор" />
              </FormRow>
              <FormRow>
                <TextInput
                  source="rightsNote"
                  label="Инфа по авторским правам"
                  multiline
                  rows={6}
                />
              </FormRow>
              <FormRow isLast>
                <TextInput source="description" label="Описание" multiline rows={6} />
              </FormRow>
            </>
          );
        }}
      />
    </Dialog>
  );
};
