import React from 'react';

import { AutocompleteDynamicInput } from '../../RAInputs';
import { FormRow } from '../../FormRow';

export const AutocompleteWithFormRow = ({ formRowProps, ...props }) => {
  return (
    <FormRow {...formRowProps}>
      <AutocompleteDynamicInput {...props} />
    </FormRow>
  );
};
