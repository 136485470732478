// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-unreachable */
import React, { useMemo, useRef, useCallback, useState } from 'react';
import { FormWithRedirect, useRefresh, FormDataConsumer } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { Dialog } from '../Dialog';

import { Button } from '../../buttons';
import { dataProvider } from '../../../dataProvider';
import { ConstantSelect } from '../../app';
import { FormRow } from '../../FormRow';

import {
  clientTouchNotTargetTypes,
  clientTouchStatuses,
  clientTouchStatusFilledColors,
  clientTouchTargetTypes,
  clientTouchTypeFilledColors,
  luxuryClasses,
} from '../../../constants';
import { TextInput } from '../../TextInput/TextInput';
import { FormTitle } from '../../common';
import { parseBoolean, validates } from '../../../utils';

const useStyles = makeStyles(() => ({
  dialogPaper: {
    maxWidth: 465,
  },
}));

export const TouchesMultipleDialog = (props) => {
  const refresh = useRefresh();
  const classes = useStyles();
  const { changeOpenRef, record = {}, resource, data, selectedIds } = props;

  const formRef = useRef();

  const [loading, setLoading] = useState(false);
  const oldStatusRef = useRef(record?.isTargeted || false);

  const handleSave = useCallback(
    (values) => {
      setLoading(true);
      const items = selectedIds.map((selectedId) => {
        return data[selectedId];
      });

      const fetch = async () => {
        try {
          await dataProvider.updateMany(resource, items, { ...values });
          if (changeOpenRef.current) {
            changeOpenRef.current(false);
          }
          refresh();
        } finally {
          setLoading(false);
        }
      };

      fetch();
    },
    [changeOpenRef, data, refresh, resource, selectedIds],
  );

  const handleSubmit = useCallback(() => {
    const submit = formRef.current.handleSubmit;
    if (submit) {
      submit();
    }
  }, []);

  const buttons = useMemo(() => {
    return (
      <Button onClick={handleSubmit} loading={loading}>
        Сохранить
      </Button>
    );
  }, [handleSubmit, loading]);

  return (
    <Dialog
      {...props}
      buttons={buttons}
      dialogProps={{ fullWidth: true, classes: { paper: classes.dialogPaper } }}
      title="Редактировать поля касаний"
    >
      <FormWithRedirect
        save={handleSave}
        record={record}
        render={(formProps) => {
          formRef.current = formProps;

          return (
            <>
              <FormTitle>Основные поля</FormTitle>
              <FormRow>
                <ConstantSelect
                  source="luxuryClass"
                  objectChoices={luxuryClasses}
                  label="Класс недвижимости"
                  allowEmpty
                />
              </FormRow>
              <FormRow>
                <ConstantSelect
                  source="isTargeted"
                  objectChoices={clientTouchStatuses}
                  label="Статус обработки"
                  filledColors={clientTouchStatusFilledColors}
                  parse={parseBoolean}
                  allowEmpty
                />
              </FormRow>
              <FormDataConsumer>
                {({ formData }) => {
                  const status = formData?.isTargeted;
                  const types =
                    status === true ? clientTouchTargetTypes : clientTouchNotTargetTypes;

                  if (status !== oldStatusRef.current) {
                    oldStatusRef.current = status;
                    formData.target = '';
                    setTimeout(() => {
                      delete formData.target;
                    }, 1);
                  }

                  return (
                    (status === true || status === false) && (
                      <FormRow>
                        <ConstantSelect
                          source="target"
                          objectChoices={types}
                          label="Причина обработки"
                          filledColors={clientTouchTypeFilledColors}
                          validate={validates.clientTouchType}
                        />
                      </FormRow>
                    )
                  );
                }}
              </FormDataConsumer>
              <FormRow>
                <TextInput source="comment" label="Комментарий" multiline rows={6} />
              </FormRow>
            </>
          );
        }}
      />
    </Dialog>
  );
};
