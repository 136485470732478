import React from 'react';
import { AlarmOff as AlarmOffIcon } from '@material-ui/icons';
import { StatusField } from '../StatusField';

export const StatusFieldExpired = ({ fontSize = '16px', count }) => {
  return (
    <StatusField styleProps={{ backgroundColor: '#FF3D00', fontSize }}>
      <AlarmOffIcon fontSize="inherit" style={{ marginRight: '5px' }} /> Просрочена{' '}
      {count && `[${count}]`}
    </StatusField>
  );
};
