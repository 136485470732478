import React from 'react';

import { Create } from '../../../../components/common';
import { FormCMSPages } from '../../../../components/forms';

const initialValues = {
  cmsPageTypeId: 11,
  cmsPageParentId: 10,
};

export const CreateMagazineCategoryPages = (props) => {
  return (
    <Create {...props}>
      <FormCMSPages {...props} redirect="list" initialValues={initialValues} hasWidgets={false} />
    </Create>
  );
};
