import React from 'react';
import { currentProfile } from '../../../authProvider';

import { FormObjects } from '../../../components/forms';
import { Edit } from '../../../components/common';

export const EditObjects = (props) => {
  return (
    <Edit {...props}>
      <FormObjects {...props} redirect={false} authId={currentProfile?.id} />
    </Edit>
  );
};
