import React, { useMemo, useCallback, useRef, useState } from 'react';
import { makeStyles, IconButton } from '@material-ui/core';
import { Close as CloseIcon, Edit as EditIcon } from '@material-ui/icons';
import { useRefresh } from 'react-admin';
import { PeopleNameField } from '../../RAFields';
import { getFormattedDate } from '../../../utils';
import { HTMLContent } from '../../common';
import { currentProfile } from '../../../authProvider';
import { dataProvider } from '../../../dataProvider';
import { EditCommentDialog } from '../../dialogs';

const useStyles = makeStyles(() => ({
  root: {
    paddingBottom: (props) => (props.isCard ? '12px' : '24px'),
  },
  comment: {
    border: '1px solid grey',
    padding: '16px',
    borderRadius: '10px',
    marginTop: '16px',
    position: 'relative',

    '&:first-child': {
      marginTop: 0,
    },
  },
  content: {},
  header: {
    marginBottom: '8px',
    borderBottom: '1px dashed grey',
    paddingBottom: '8px',
  },
  text: {
    display: 'block',
    whiteSpace: 'pre-wrap',
  },
  date: {
    display: 'block',
    marginTop: '5px',
    color: '#9E9E9E',
    fontSize: '12px',
  },
  deleteButton: {
    position: 'absolute',
    top: '0px',
    right: '5px',
  },
  editButton: {
    position: 'absolute',
    top: '30px',
    right: '5px',
  },
}));

const Comment = ({ item, handleOpenDialog, onReload }) => {
  const classes = useStyles();

  const refresh = useRefresh();

  const isAuthor = item.createdBy === currentProfile.name;

  const handleDelete = useCallback(() => {
    const fetch = async () => {
      await dataProvider.delete('Comments', {
        id: item.id,
        previousData: item,
      });

      if (onReload) {
        onReload();
        return;
      }

      refresh();
    };

    fetch();
  }, [item, onReload, refresh]);

  const handleEdit = useCallback(() => {
    handleOpenDialog(item);
  }, [handleOpenDialog, item]);

  return (
    <div className={classes.comment}>
      <div className={classes.header}>
        <PeopleNameField record={item.author} />
        {isAuthor && (
          <>
            <div className={classes.editButton}>
              <IconButton color="inherit" onClick={handleEdit} size="small">
                <EditIcon />
              </IconButton>
            </div>
            <div className={classes.deleteButton}>
              <IconButton color="inherit" onClick={handleDelete} size="small">
                <CloseIcon />
              </IconButton>
            </div>
          </>
        )}
      </div>
      <div className={classes.content}>
        <span className={classes.text}>
          {/* <TextWithLinks text={item.text} /> */}
          <HTMLContent html={item.text} />
        </span>
        <span className={classes.date}>
          {`${getFormattedDate(item.createdDate, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          })}`}
        </span>
      </div>
    </div>
  );
};

export const Comments = ({ items, isCard, onReload }) => {
  const classes = useStyles({ isCard });

  const changeOpenRef = useRef();
  const [record, setRecord] = useState();

  const handleOpenDialog = useCallback((currentRecord) => {
    setRecord(currentRecord);
    if (changeOpenRef.current) {
      changeOpenRef.current(true);
    }
  }, []);

  const elements = useMemo(() => {
    return items.map((item) => {
      return <Comment item={item} handleOpenDialog={handleOpenDialog} onReload={onReload} />;
    });
  }, [handleOpenDialog, items, onReload]);

  return (
    <>
      <div className={classes.root}>{elements}</div>
      <EditCommentDialog changeOpenRef={changeOpenRef} data={record} onReload={onReload} />
    </>
  );
};
