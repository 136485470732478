import React, { useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { DayTimeSwitch, PdfSwitch, PublicSwitch } from '../../Switches';
import { ListItemMainBlock, SortOrderListItem } from '../..';
import { imageSeasons, imageTypes } from '../../../../constants';
import { LinkField } from '../../../RAFields';

const useStyles = makeStyles((theme) => ({
  buttons: {
    flex: '0 0 auto',
    padding: '0 12px',
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('1024')]: {
      flexWrap: 'wrap',
    },
  },
  buttonsItem: {
    padding: '4px 12px',
    borderLeft: '1px solid #E0E0E0',

    '&:first-child': {
      paddingLeft: 0,
      borderLeft: 0,
    },

    '&:last-child': {
      paddingRight: 0,
    },
  },
  linkField: {
    fontSize: '12px',
    lineHeight: '12px',
    marginTop: '4px',
    marginBottom: '4px',
  },
}));

export const ListItemImage = ({ type, record, onChangeItems, hasButtons }) => {
  const classes = useStyles();

  const hasImage = useMemo(() => {
    if (type === 'video') {
      return false;
    }

    return true;
  }, [type]);

  const handleChange = useCallback(
    (value, name) => {
      const newItem = { ...record };
      newItem[name] = value;
      newItem.clientStatus = 'edit';
      if (onChangeItems) {
        onChangeItems(newItem);
      }
    },
    [onChangeItems, record],
  );

  const descriptions = [];

  if (record.imageType) {
    descriptions.push(imageTypes[record.imageType]);
  }

  if (record.imageSeason) {
    descriptions.push(imageSeasons[record.imageSeason]);
  }

  if ((record.isNightTime === true || record.isNightTime === false) && hasImage) {
    descriptions.push(record.isNightTime === true ? 'Ночь' : 'День');
  }

  if (record.isShowInPdf !== false && hasImage) {
    descriptions.push('PDF');
  }

  return (
    <>
      <ListItemMainBlock
        record={record}
        descriptions={descriptions}
        hasImage={hasImage}
        imagePath={record.path}
      >
        {!hasImage && (
          <div className={classes.linkField}>
            <LinkField record={record} />
          </div>
        )}
      </ListItemMainBlock>
      <div className={classes.buttons}>
        {hasButtons && hasImage && (
          <div className={classes.buttonsItem}>
            <DayTimeSwitch onChange={handleChange} checked={record.isNightTime} />
          </div>
        )}
        {hasButtons && (
          <div className={classes.buttonsItem}>
            <PublicSwitch onChange={handleChange} checked={record.sysStatus} />
          </div>
        )}
        {hasButtons && hasImage && (
          <div className={classes.buttonsItem}>
            <PdfSwitch onChange={handleChange} checked={record.isShowInPdf} />
          </div>
        )}
        <div className={classes.buttonsItem}>
          <SortOrderListItem sortOrder={record.sortOrder} />
        </div>
      </div>
    </>
  );
};
