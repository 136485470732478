import React, { useMemo } from 'react';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {},
  row: {
    paddingBottom: 16,
  },
  text: {
    paddingBottom: 12,
    display: 'block',
  },
}));

export const FilterGroup = ({ items, currentFilters, onChange }) => {
  const classes = useStyles();

  const filterElements = useMemo(() => {
    return items.map((item) => {
      const id = item.id;
      const Component = item.component;
      const label = item.label;
      const componentProps = item.componentProps || {};
      const filterName = item.filter;
      const children = componentProps.children;
      const valueModifier = item.valueModifier;

      let componentOnChange = onChange;
      if (!item.isGroup) {
        componentOnChange = onChange(filterName, valueModifier);
      }

      let defaultValue;

      if (filterName && filterName in currentFilters) {
        const filterValue = currentFilters[filterName];
        if (componentProps.multiple) {
          defaultValue = filterValue.split(',');
        } else {
          defaultValue = filterValue;
        }
      }

      return (
        <div className={classes.row} key={id}>
          <span className={classes.text}>{label}</span>
          <Component
            {...componentProps}
            defaultValue={defaultValue}
            onChange={componentOnChange}
            currentFilters={currentFilters}
          >
            {children}
          </Component>
        </div>
      );
    });
  }, [items, onChange, currentFilters, classes.row, classes.text]);

  return <div className={classes.root}>{filterElements}</div>;
};
