import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'block',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '1.4',
    color: '#1E1E1E',
    marginBottom: '25px',
  },
  '@media (max-width: 1024px)': {
    root: {
      fontSize: '16px',
    },
  },
}));

export const FormTitle = ({ children }) => {
  const classes = useStyles();

  return <span className={classes.root}>{children}</span>;
};
