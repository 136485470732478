import React, { useEffect } from 'react';
import { FormTab } from 'react-admin';

import { makeStyles } from '@material-ui/core';
import { TextInput } from '../../TextInput/TextInput';
import { FormTitle, TabbedForm, Map, RichText } from '../../common';

import { validates } from '../../../utils';
import { FormRow } from '../../FormRow';
import {
  ConstantSelect,
  AutocompleteWithFormRow,
  CommentsTab,
  TasksTab,
  List,
  SectorTasksChart,
} from '../../app';
import { publicationStatuses } from '../../../constants';
import { ExpertSelect, PeopleSelect } from '../../RASelects';
import { SeoTab } from '../../formTabs';
import { ListImagesOrVideos, DynamicList } from '../../additionalLists';
import { FormCard } from '../../FormCard';
import { SectorOwnersChart } from '../../app/Charts/SectorOwners/SectorTasks';
import { CommentsByOwnersDialogButton } from '../../buttons/CommentsByOwners';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    padding: '0',
    boxSizing: 'border-box',
  },
  content: {
    flex: '1 1 0',
    minHeight: 0,
    overflow: 'auto',
    position: 'relative',
  },
  footer: {
    padding: '16px 16px 0 16px',
    flex: '0 0 auto',
  },
}));

export const FormSectors = (props) => {
  const { id, location, record } = props;

  const classes = useStyles();

  useEffect(() => {
    // fix for map!
    window.dispatchEvent(new Event('resize'));
  }, [location]);

  return (
    <TabbedForm {...props}>
      <FormTab label="Основное">
        <FormCard>
          {id && (
            <FormRow>
              <TextInput source="id" label="Id" disabled />
            </FormRow>
          )}
          <FormRow>
            <TextInput source="slug" label="Слаг" validate={validates.slug} />
          </FormRow>
          <FormRow>
            <TextInput source="name" label="Название" validate={validates.name} />
          </FormRow>
          <AutocompleteWithFormRow
            {...props}
            source="realtyGroup"
            resourceName="RealtyGroups"
            resourceId="realtyGroupId"
            validate={validates.objectVillage}
            componentProps={{
              label: 'Комплекс',
              fullDynamic: 'NameContains',
              getOptionLabel: (option) => `${option.name} / Id - ${option.id}`,
            }}
          />
          <FormRow>
            <ConstantSelect
              source="sysStatus"
              objectChoices={publicationStatuses}
              label="Статус публикации"
              defaultValue={100}
            />
          </FormRow>
          <FormRow>
            <TextInput
              source="sortOrder"
              label="Порядок сортировки"
              type="number"
              defaultValue={1000}
            />
          </FormRow>
          <ExpertSelect {...props} />
          <PeopleSelect
            source="serviceManager"
            resourceId="serviceManagerId"
            {...props}
            componentProps={{
              label: 'Сервисный эксперт',
              providerParams: { filter: { isServiceManager: true } },
            }}
          />
          <FormRow>
            <TextInput source="shortName" label="Краткое название" validate={validates.shortName} />
          </FormRow>
          <FormRow maxWidth={800}>
            <RichText source="description" />
          </FormRow>
          <FormRow maxWidth={800} isLast>
            <TextInput
              source="shortDescription"
              label="Краткое описание"
              multiline
              rows={6}
              validate={validates.shortDescription}
            />
          </FormRow>
        </FormCard>
      </FormTab>
      <FormTab label="Локация">
        <FormCard>
          <Map {...props} />
        </FormCard>
      </FormTab>
      <FormTab label="Продвижение">
        <FormCard>
          <FormTitle>Seo</FormTitle>
          <SeoTab isLast={false} />
        </FormCard>
      </FormTab>
      <FormTab label="Изображения">
        <ListImagesOrVideos id={id} hasMultiple />
      </FormTab>
      <FormTab label="Видео">
        <ListImagesOrVideos id={id} isVideo />
      </FormTab>
      {id && (
        <FormTab label="Собственники">
          <div className={classes.root}>
            <div className={classes.content}>
              <DynamicList
                fetchOptions={{
                  resource: 'Peoples/ownersInRealtySearch',
                  filter: { RealtySector: `${id}` },
                }}
                contentOptions={{
                  type: 'people',
                  hasButtons: false,
                }}
                contentElement={List}
              />
            </div>
            <div className={classes.footer}>
              <CommentsByOwnersDialogButton record={record} />
            </div>
          </div>
        </FormTab>
      )}
      {id && (
        <FormTab label="Комментарии">
          <CommentsTab id={id} type={200} />
        </FormTab>
      )}
      {id && (
        <FormTab label="Задачи">
          <TasksTab record={record} type={300} location={location} />
        </FormTab>
      )}
      {id && (
        <FormTab label="Объекты">
          <DynamicList
            fetchOptions={{
              resource: 'RealtyObjects',
              filter: { RealtySector: `${id}` },
            }}
            contentOptions={{
              type: 'object',
              hasButtons: false,
            }}
            contentElement={List}
          />
        </FormTab>
      )}
      {id && (
        <FormTab label="Аналитика">
          <FormCard>
            <FormTitle>Задачи</FormTitle>
            <FormRow>
              <SectorTasksChart {...props} />
            </FormRow>
            <FormTitle>Собственники</FormTitle>
            <FormRow>
              <SectorOwnersChart {...props} />
            </FormRow>
          </FormCard>
        </FormTab>
      )}
    </TabbedForm>
  );
};
