import React from 'react';

import { makeStyles } from '@material-ui/core';
import { DefaultMenu } from '../../Menus';
import { Card } from '../..';
import { PeopleNameField, TaskDatesField } from '../../../RAFields';
import { taskStatusesAdmin, taskImportance, taskObjectTypes } from '../../../../constants';
import { StatusFieldExpired } from '../../../common/StatusFields';
import { checkExpired, getExpiredCount } from '../../../../utils';

const useStyles = makeStyles(() => ({
  containerRoles: {
    marginTop: '20px',
  },
  dates: {
    marginTop: '12px',
    color: '#9E9E9E',
    fontSize: '12px',
  },
  users: {
    marginTop: '12px',
  },
  userText: {
    marginTop: '12px',
    display: 'block',
    fontSize: '14px',
    paddingBottom: '8px',

    '&:first-child': {
      marginTop: 0,
    },
  },
}));

export const TaskCard = (props) => {
  const classes = useStyles();
  const { data: item, statusFields: statusFieldsProp, resource } = props;

  const statusFields = [...statusFieldsProp];

  const link = `/Taasks/${item.id}`;

  const fieldNameProps = {
    name: item.name,
    link,
    target: false,
  };

  if (checkExpired(item)) {
    statusFields.push(<StatusFieldExpired fontSize={13} count={getExpiredCount(item)} />);
  }

  const menuElement = <DefaultMenu record={item} resource={resource} />;

  const chipTexts = [];

  if (item.taaskStatus) {
    chipTexts.push(taskStatusesAdmin[item.taaskStatus]);
  }

  if (item.importance) {
    chipTexts.push(taskImportance[item.importance]);
  }

  if (item.taaskObjects?.[0].taaskObjectType) {
    chipTexts.push(taskObjectTypes[item.taaskObjects[0].taaskObjectType]);
  }

  return (
    <Card
      {...props}
      fieldNameProps={fieldNameProps}
      statusFields={statusFields}
      menuElement={menuElement}
      chipTexts={chipTexts}
      hasImage={false}
    >
      {(item.assignedBy || item.assignedTo) && (
        <div className={classes.users}>
          {item.assignedBy && (
            <>
              <span className={classes.userText}>Кем поставлена:</span>
              <PeopleNameField record={item.assignedBy} />
            </>
          )}
          {item.assignedTo && (
            <>
              <span className={classes.userText}>Кому поставлена:</span>
              <PeopleNameField record={item.assignedTo} />
            </>
          )}
        </div>
      )}

      <div className={classes.dates}>
        <TaskDatesField record={item} hasStatus={false} />
      </div>
    </Card>
  );
};
