const isValidUrl = (url) => {
  try {
    // eslint-disable-next-line no-new
    new URL(url);
  } catch (e) {
    return false;
  }

  return true;
};

export const urlRegExp = /((ftp|http|https):\/\/)www\.([A-z]+)\.([A-z]{2,}(\/\w+)*)/gi;
export const urlWithoutGroupsRegExp =
  /((?:(?:ftp|http|https):\/\/)www\.[A-z]+\.[A-z]{2,}(?:\/\w+)*)/gi;

export const checkValidUrl = (text) => {
  if (urlRegExp.test(text) && isValidUrl(text)) {
    return true;
  }
};
