import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ListItemMainBlock, SortOrderListItem } from '../..';
import { siteContactTypes } from '../../../../constants';

const useStyles = makeStyles((theme) => ({
  buttons: {
    flex: '0 0 auto',
    padding: '0 12px',
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('1024')]: {
      flexWrap: 'wrap',
    },
  },
}));

export const ListItemCMSContacts = ({ record, hasButtons }) => {
  const classes = useStyles();

  const descriptions = [];
  const textStatuses = [siteContactTypes[record.contactType]];

  if (record.phone) {
    descriptions.push(`${record.phone}`);
  }

  if (
    (String(record.contactType) === '100' || String(record.contactType) === '200') &&
    record.email
  ) {
    descriptions.push(`${record.email}`);
  }

  if (
    (String(record.contactType) === '100' || String(record.contactType) === '200') &&
    record.address
  ) {
    descriptions.push(`${record.address}`);
  }

  if (String(record.contactType) === '300' && (record.workHoursFrom || record.workHoursTo)) {
    let string = '';
    if (record.workHoursFrom) {
      string += `c ${record.workHoursFrom} `;
    }
    if (record.workHoursTo) {
      string += `до ${record.workHoursTo}`;
    }

    descriptions.push(`${string}`);
  }

  const link = `/SiteContacts/${record.id}`;

  return (
    <>
      <ListItemMainBlock
        record={record}
        descriptions={descriptions}
        textStatuses={textStatuses}
        imagePath={record.photo}
        hasButtons={hasButtons}
        link={link}
      />
      <div className={classes.buttons}>
        <div className={classes.buttonsItem}>
          <SortOrderListItem sortOrder={record.sortOrder} />
        </div>
      </div>
    </>
  );
};
