import React from 'react';
import { DefaultMenu } from '../../Menus';
import { imageSeasons, imageTypes } from '../../../../constants';
import { Card, SortOrderCard } from '../..';

export const ImageCard = (props) => {
  const { data: item, statusFields: statusFieldsProp, resource, onChangeSortOrder } = props;

  const statusFields = [...statusFieldsProp];

  const fieldNameProps = {
    name: item.name,
  };

  const imagePath = item.path;

  const menuElement = <DefaultMenu record={item} resource={resource} />;

  const chipTexts = [];

  if (item.imageSeason) {
    chipTexts.push(imageSeasons[item.imageSeason]);
  }

  if (item.imageType) {
    chipTexts.push(imageTypes[item.imageType]);
  }

  if (item.isNightTime === true || item.isNightTime === false) {
    chipTexts.push(item.isNightTime === true ? 'Ночь' : 'День');
  }

  if (item.isShowInPdf !== false) {
    chipTexts.push('Используется в pdf');
  }

  return (
    <Card
      {...props}
      fieldNameProps={fieldNameProps}
      menuElement={menuElement}
      statusFields={statusFields}
      chipTexts={chipTexts}
      imagePath={imagePath}
    >
      <SortOrderCard
        onChangeSortOrder={onChangeSortOrder}
        id={item.id}
        defaultValue={item.sortOrder}
      />
    </Card>
  );
};
