import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import { Dialog } from '../Dialog';
import { Button } from '../../buttons';

const useStyles = makeStyles(() => ({
  dialogPaper: {
    maxWidth: 600,
  },
}));

export const CreateTaskOrShowDialog = (props) => {
  const classes = useStyles();
  const { changeOpenRef, changeOpenShowRef, changeOpenTaskRef } = props;

  const handleClick = useCallback(
    (type) => () => {
      if (type === 'show') {
        changeOpenShowRef?.current?.(true);
      }
      if (type === 'task') {
        changeOpenTaskRef?.current?.(true);
      }
      changeOpenRef?.current?.(false);
    },
    [changeOpenRef, changeOpenShowRef, changeOpenTaskRef],
  );

  return (
    <Dialog
      {...props}
      dialogProps={{ fullWidth: true, classes: { paper: classes.dialogPaper } }}
      title="Выберите что создать!"
    >
      <Button size="small" {...props} onClick={handleClick('show')} style={{ marginRight: '12px' }}>
        Добавить показ
      </Button>
      <Button size="small" {...props} onClick={handleClick('task')}>
        Добавить задачу
      </Button>
    </Dialog>
  );
};
