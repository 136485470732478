import React from 'react';

import { FormCMSPages } from '../../../../components/forms';
import { Edit } from '../../../../components/common';

export const EditCMSPages = (props) => {
  return (
    <Edit {...props}>
      <FormCMSPages {...props} redirect={false} />
    </Edit>
  );
};
