import React, { useCallback } from 'react';

import { makeStyles, Switch as MUSwitch } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 48,
    height: 24,
    padding: 0,
    margin: 0,
    overflow: 'visible',

    '& input': {
      width: 48,
      left: 0,
    },
  },
  switchBase: {
    padding: 0,
    width: 24,
    height: 24,
    boxShadow:
      '0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.16)',
    backgroundColor: '#fff',
    fontSize: (prop) => prop.fontSize || 16,
    color: '#000',

    '&:hover': {
      backgroundColor: '#fff',
    },

    '&.Mui-checked + span': {
      backgroundColor: (props) =>
        props.isDark ? '#4F4F4F !important' : `${theme.palette.primaryNew['0.7']} !important`,
    },
  },
  thumb: {},
  track: {
    marginTop: 4,
    height: 16,
    background: '#F2F2F2',
    borderRadius: '100px',
    transition: 'none',
  },
  checked: {
    '&.Mui-checked': {
      transform: 'translateX(24px)',
      color: () => theme.palette.primaryNew['contrastText'],
      backgroundColor: (props) => (props.isDark ? '#333333' : theme.palette.primaryNew['main']),

      '&:hover': {
        backgroundColor: (props) => (props.isDark ? '#333333' : theme.palette.primaryNew['main']),
      },

      '& input': {
        left: -24,
      },
    },
  },
  focusVisible: {
    outline: '1px solid grey',
  },
}));

export const Switch = ({ onChange, name, styleProps, checked = false, ...otherProps }) => {
  const classes = useStyles(styleProps);

  const handleChange = useCallback(
    (event) => {
      const value = event.target.checked;
      if (onChange) {
        onChange(value, event.target.name);
      }
    },
    [onChange],
  );

  return (
    <MUSwitch
      checked={checked}
      onChange={handleChange}
      name={name}
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...otherProps}
    />
  );
};
