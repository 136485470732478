import React, { useCallback } from 'react';
import { useDelete, useRefresh, useNotify, useUnselectAll } from 'react-admin';
import { MenuItem } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { dataSelector } from '../../../redux';

export const DeleteAllSelectedMenuItem = ({ selectedIds, onClose, resource }) => {
  const reduxData = useSelector(dataSelector(resource));
  const unselectAll = useUnselectAll();

  const refresh = useRefresh();
  const notify = useNotify();

  const [deleteOne] = useDelete(null, null, null, {
    onSuccess: () => {},
    onFailure: (error) => notify(`Error: ${error.message}`, 'warning'),
  });

  const handleDelete = useCallback(() => {
    onClose();

    const sendRequests = async () => {
      const promises = selectedIds.map((id) => {
        return deleteOne(resource, id, reduxData[id]);
      });

      try {
        await Promise.all(promises);
        unselectAll(resource);
        refresh();
      } catch (error) {
        console.log(error);
      }
    };

    sendRequests();
  }, [deleteOne, onClose, reduxData, refresh, resource, selectedIds, unselectAll]);

  return <MenuItem onClick={handleDelete}>Удалить</MenuItem>;
};
