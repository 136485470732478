import React, { useMemo } from 'react';

import { PeopleSelect } from '../People';
import { currentUser } from '../../../authProvider';

export const AssignedBySelect = (props) => {
  const { id } = props;

  const defaultPerson = useMemo(() => {
    if (!id) {
      return currentUser;
    }

    return null;
  }, [id]);

  return (
    <PeopleSelect
      {...props}
      source="assignedBy"
      resourceId="assignedById"
      componentProps={{
        label: 'Кто назначил',
        fullDynamic: 'AllNames',
        defaultValue: defaultPerson,
        disabled: true,
        providerParams: {
          filter: {
            IsStaff: true,
          },
        },
      }}
    />
  );
};
