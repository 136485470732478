import { useMemo, useState, useCallback, useRef, useEffect } from 'react';
import { useInput } from 'react-admin';

export const useAdditionalTableValue = (record, key, defaultValueProp, validate) => {
  const defaultValue = useMemo(() => {
    if (defaultValueProp) {
      return defaultValueProp;
    }

    return record && record[key] ? record[key] : [];
  }, [defaultValueProp, record, key]);

  const [values, setValues] = useState(defaultValue);

  const defaultInputValue = useMemo(() => {
    if (defaultValueProp) {
      return {
        old: [],
        new: defaultValueProp,
        current: defaultValueProp,
        isMultipleRelations: true,
      };
    }
  }, [defaultValueProp]);

  const newValuesRef = useRef(defaultValueProp || []);

  const defaultInputValueRef = useRef(defaultInputValue || defaultValue);

  const { input, meta } = useInput({
    name: key,
    source: key,
    defaultValue: defaultInputValueRef.current,
    validate,
  });

  useEffect(() => {
    input.onChange(defaultInputValueRef.current);
  }, [input]);

  useEffect(() => {
    if (Object.keys(record).length > 0) {
      setValues(defaultValue);
      defaultInputValueRef.current = defaultInputValue || defaultValue;
      newValuesRef.current = defaultValueProp || [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  const oldValues = useMemo(() => {
    return record[key];
  }, [record, key]);

  const onChange = useCallback(
    (changedValues, isReorder) => {
      let newValues = newValuesRef.current;
      let currentValues = [].concat(values);

      if (isReorder) {
        changedValues.newItems.forEach((newItem) => {
          const foundIndex = newValues.findIndex((item) => item.id === newItem.id);
          if (foundIndex !== -1) {
            newValues[foundIndex] = newItem;
          } else {
            newValues.push(newItem);
          }
        });
        newValuesRef.current = newValues;
        let filteredValues = [...values];
        changedValues.currentItems.forEach((currentItem) => {
          filteredValues = filteredValues.filter((value) => {
            return value.id !== currentItem.id;
          });
        });
        currentValues = filteredValues.concat(changedValues.currentItems);
      } else if (Array.isArray(changedValues)) {
        newValues = newValues.concat(changedValues);
        newValuesRef.current = newValues;
        currentValues = currentValues.concat(changedValues);
      } else {
        const foundValue = newValues.find((item) => item.id === changedValues.id);
        if (foundValue) {
          if (String(foundValue.id).includes('client')) {
            changedValues.clientStatus = 'create';
          }
          Object.assign(foundValue, changedValues);
        } else {
          newValues.push(changedValues);
        }

        const foundCurrentValue = currentValues.find((item) => item.id === changedValues.id);
        if (foundCurrentValue) {
          Object.assign(foundCurrentValue, changedValues);
        } else {
          currentValues.push(changedValues);
        }
      }

      const newInputValue = {
        old: oldValues,
        new: newValues,
        current: currentValues,
        isMultipleRelations: true,
      };
      input.onChange(newInputValue);
      defaultInputValueRef.current = newInputValue;
      setValues(currentValues);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values, oldValues, input.onChange],
  );

  const onDelete = useCallback(
    (ids) => {
      let newValues = newValuesRef.current;
      let currentValues = [].concat(values);

      const isArray = Array.isArray(ids);
      let newIds = ids;
      if (!isArray) {
        newIds = [ids];
      }

      newIds.forEach((id) => {
        const foundValue = newValues.find((item) => item.id === id);
        if (foundValue) {
          if (String(foundValue.id).includes('client')) {
            newValues = newValues.filter((newValue) => {
              return newValue.id !== id;
            });
            newValuesRef.current = newValues;
          } else {
            Object.assign(foundValue, { clientStatus: 'delete' });
          }
        } else {
          const oldValue = oldValues.find((item) => item.id === id);
          newValues.push({ ...oldValue, clientStatus: 'delete' });
        }
        currentValues = currentValues.filter((value) => {
          return value.id !== id;
        });
      });

      setValues(currentValues);
      const newInputValue = {
        old: oldValues,
        new: newValues,
        current: currentValues,
        isMultipleRelations: true,
      };
      input.onChange(newInputValue);
      defaultInputValueRef.current = newInputValue;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values, oldValues, input.onChange],
  );

  return [values, onChange, onDelete, meta];
};
