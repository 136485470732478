import React, { useRef, useState, useCallback } from 'react';

import { Button, Menu, Badge } from '@material-ui/core';

export const SelectedItemsMenu = ({
  menuOptions,
  selectedIds,
  resource,
  data,
  permissions,
  ...otherProps
}) => {
  const [open, setOpen] = useState(null);

  const anchorRef = useRef();

  const MenuOptions = menuOptions;

  const handleClick = useCallback(() => {
    setOpen((propOpen) => !propOpen);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(null);
  }, []);

  if (selectedIds.length === 0) {
    return null;
  }

  return (
    <>
      <Badge badgeContent={selectedIds.length} color="primary" max={9999}>
        <Button color="default" onClick={handleClick} ref={anchorRef}>
          Выбранные элементы
        </Button>
      </Badge>
      <Menu
        id="simple-menu"
        anchorEl={anchorRef.current}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuOptions
          resource={resource}
          selectedIds={selectedIds}
          data={data}
          onClose={handleClose}
          permissions={permissions}
          {...otherProps}
        />
      </Menu>
    </>
  );
};
