import React from 'react';

import { makeStyles } from '@material-ui/core';
import { DefaultMenu } from '../../Menus';
import { Card } from '../..';
import { FeedLinksField } from '../../../RAFields';

const useStyles = makeStyles(() => ({
  containerLinks: {
    fontSize: '12px',
    marginTop: '20px',
  },
}));

export const FeedCard = (props) => {
  const classes = useStyles();
  const { data: item, statusFields: statusFieldsProp, resource } = props;

  const statusFields = [...statusFieldsProp];

  const fieldNameProps = {
    name: item.name,
  };

  const menuElement = <DefaultMenu record={item} resource={resource} />;

  return (
    <Card
      {...props}
      fieldNameProps={fieldNameProps}
      statusFields={statusFields}
      menuElement={menuElement}
      hasImage={false}
    >
      <div className={classes.containerLinks}>
        <FeedLinksField record={item} />
      </div>
    </Card>
  );
};
