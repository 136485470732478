// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo, useContext, useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import { StaticImage } from '../../common';
import { ListItemPublicStatus, ListItemTasksStatus } from '../ListItemStatuses';
import { ImageFullDialogContext } from '../../providers';
import { FieldName } from '../../Fields';
import { isObject } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('600')]: {
      padding: (prop) =>
        // eslint-disable-next-line no-nested-ternary
        prop.hasButtons === false ? '0px' : prop.isMenuBottom ? '0 0 0 50px' : '0 53px 0 50px',
      borderBottom: '1px solid #E0E0E0',
      marginBottom: '4px',
      width: '100%',
      boxSizing: 'border-box',
    },
  },
  photoContainer: {
    flex: '0 0 auto',
    width: 72,
    lineHeight: 0,
    fontSize: 0,
    paddingRight: 12,
  },
  content: {
    flex: '1 1 auto',
    padding: '4px 0',
    maxWidth: '800px',
    marginRight: 8,
    [theme.breakpoints.down('600')]: {
      minHeight: '70px',
    },
  },
  mainTexts: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  mainTextsSeparator: {
    padding: '0 8px 0 8px',
    flex: '0 0 auto',
  },
  mainTextsItem: {
    flex: '0 1 auto',
    paddingBottom: 4,
    fontSize: '12px',
    color: 'rgba(33, 33, 33, 0.54)',
    textTransform: 'uppercase',
    lineHeight: '14px',
  },
  header: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginBottom: 4,
  },
  statuses: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textStatuses: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  contentStatuses: {
    marginTop: 12,
    display: 'flex',
    flexWrap: 'wrap',
  },
  statusesItem: {
    flex: '0 0 auto',
    paddingBottom: 4,
    paddingLeft: 8,
    lineHeight: '12px',
  },
  name: {
    color: 'rgba(33, 33, 33, 0.87)',
    fontSize: 14,
    lineHeight: '14px',
    fontWeight: '500',
  },
  descriptions: {
    marginTop: 8,
    display: 'flex',
    flexWrap: 'wrap',
  },
  descriptionsItem: {
    flex: '0 1 auto',
    paddingBottom: 4,
    lineHeight: '14px',
    fontSize: '13px',
    color: 'rgba(33, 33, 33, 0.54)',
  },
  descriptionsSeparator: {
    padding: '0 4px 0 4px',
    flex: '0 0 auto',
  },
  comment: {
    marginTop: 8,
    color: 'rgba(33, 33, 33, 0.54)',
    lineHeight: '14px',
    fontSize: '12px',
  },
}));

const useStylesTextStatus = makeStyles((theme) => ({
  root: {
    flex: '0 0 auto',
    margin: (props) => (props.isContent ? '0 8px 4px 0' : '0 0 4px 8px'),
    padding: (props) => (props.isContent ? 6 : 4),
    borderRadius: 4,
    lineHeight: '12px',
    textTransform: 'uppercase',
    fontSize: 10,
    fontWeight: 'bold',
    color: (props) => {
      const { color } = props;

      if (color === 'red') {
        return theme.palette.red['accentFont'];
      }

      if (color === 'green') {
        return theme.palette.green['accentFont'];
      }

      return theme.palette.primaryNew['main'];
    },
    backgroundColor: (props) => {
      const { color } = props;

      if (color === 'red') {
        return theme.palette.red['background'];
      }

      if (color === 'green') {
        return theme.palette.green['background'];
      }

      return theme.palette.primaryNew['background'];
    },
  },
}));

const TextStatusItem = ({ status, isContent = false }) => {
  let color = 'default';
  let text = status;
  if (isObject(status)) {
    text = status.text;
    color = status.color;
  }

  const classes = useStylesTextStatus({ color, isContent });

  return <div className={classes.root}>{text}</div>;
};

export const ListItemMainBlock = ({
  record,
  name: nameProp,
  mainTexts: mainTextsProp = [],
  statuses: statusesProp = [],
  textStatuses = [],
  contentStatuses = [],
  descriptions = [],
  comment = '',
  additionalId = 'slug',
  isMenuBottom = false,
  hasImage = true,
  imagePath,
  link,
  depthKey,
  hasTaskStatus = false,
  hasButtons = true,
  children,
}) => {
  const classes = useStyles({ isMenuBottom, hasButtons });

  const fieldNameProps = useMemo(() => {
    return {
      name: nameProp || record.name,
      link,
      target: false,
    };
  }, [link, nameProp, record.name]);

  const onOpenDialog = useContext(ImageFullDialogContext);

  const handleOpenDialog = useCallback(
    (event) => {
      event.preventDefault();
      onOpenDialog(imagePath);
    },
    [imagePath, onOpenDialog],
  );

  const mainTexts = useMemo(() => {
    const texts = [`ID-${record.id}`];

    if (record[additionalId]) {
      texts.push(String(record[additionalId]));
    }

    return texts.concat(mainTextsProp);
  }, [additionalId, mainTextsProp, record]);

  const mainTextsElements = useMemo(() => {
    return mainTexts.map((mainText, index) => {
      return (
        <div className={classes.mainTextsItem}>
          {index !== 0 && <span className={classes.mainTextsSeparator}>°</span>}
          {mainText}
        </div>
      );
    });
  }, [classes.mainTextsItem, classes.mainTextsSeparator, mainTexts]);

  const statuses = useMemo(() => {
    const items = [];

    if (hasTaskStatus && !depthKey) {
      items.push(<ListItemTasksStatus taskStats={record.taaskObjectStats} />);
    }

    items.push(<ListItemPublicStatus record={record} />);

    return items.concat(statusesProp);
  }, [depthKey, hasTaskStatus, record, statusesProp]);

  const statusesElements = useMemo(() => {
    return statuses.map((status) => {
      return <div className={classes.statusesItem}>{status}</div>;
    });
  }, [classes.statusesItem, statuses]);

  const descriptionsElements = useMemo(() => {
    return descriptions.map((description, index) => {
      return (
        <div className={classes.descriptionsItem}>
          {index !== 0 && <span className={classes.descriptionsSeparator}>¬</span>}
          {description}
        </div>
      );
    });
  }, [classes.descriptionsItem, classes.descriptionsSeparator, descriptions]);

  const textStatusElements = useMemo(() => {
    return textStatuses.map((status) => {
      return <TextStatusItem status={status} />;
    });
  }, [textStatuses]);

  const contentStatusElements = useMemo(() => {
    return contentStatuses.map((status) => {
      return <TextStatusItem status={status} isContent={true} />;
    });
  }, [contentStatuses]);

  return (
    <div className={classes.root}>
      {hasImage && imagePath && (
        <div className={classes.photoContainer}>
          <a href="#" onClick={handleOpenDialog}>
            <StaticImage
              imagePath={imagePath}
              size="72x72"
              imgAttributes={{
                alt: record.name,
              }}
            />
          </a>
        </div>
      )}
      <div className={classes.content}>
        <div className={classes.header}>
          <div className={classes.mainTexts}>{mainTextsElements}</div>
          {textStatusElements.length > 0 && (
            <div className={classes.textStatuses}>{textStatusElements}</div>
          )}
          <div className={classes.statuses}>{statusesElements}</div>
        </div>
        <div className={classes.name}>
          <FieldName {...fieldNameProps} />
        </div>
        {descriptionsElements.length > 0 && (
          <div className={classes.descriptions}>{descriptionsElements}</div>
        )}
        {contentStatusElements.length > 0 && (
          <div className={classes.contentStatuses}>{contentStatusElements}</div>
        )}
        {comment && <div className={classes.comment}>Комментарий: {comment}</div>}
        {children}
      </div>
    </div>
  );
};
