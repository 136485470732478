import React, { useMemo, useRef, useCallback, useState } from 'react';
import { FormDataConsumer, FormWithRedirect, useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { Dialog } from '../Dialog';

import { Button } from '../../buttons';
import { dataProvider } from '../../../dataProvider';
import { validates } from '../../../utils';
import { FormRow } from '../../FormRow';
import { TogglerWithFormRow, movedToSPItems, movedToSupportItems } from '../../forms';
import { BooleanInput, FormTitle, NumberInput } from '../../common';
import { AutocompleteWithFormRow, ConstantSelect } from '../../app';
import { currencyTypes } from '../../../constants';
import { DateTimePickerInput } from '../../RAInputs';
import { PeopleSelect } from '../../RASelects';

const useStyles = makeStyles(() => ({
  dialogPaper: {
    maxWidth: 600,
  },
}));

export const CloseContractKanbantDialog = (props) => {
  const classes = useStyles();
  const { changeOpenRef, data = {} } = props;
  const { foundItem } = data;
  const formRef = useRef();
  const notify = useNotify();

  const [loading, setLoading] = useState(false);

  const handleSave = useCallback(
    (values) => {
      const fetch = async () => {
        const { id, fromId, toId, dispatch } = data;
        setLoading(true);
        try {
          const updateResult = await dataProvider.update('Contracts', {
            id,
            data: {
              ...foundItem,
              ...values,
              status: Number(toId),
            },
            previousData: foundItem,
          });
          dispatch({
            type: 'updateItem',
            payload: { id, listId: fromId, updatedItem: updateResult.data },
          });
          dispatch({
            type: 'changeLists',
            payload: { id, fromId, toId },
          });

          if (changeOpenRef.current) {
            changeOpenRef.current(false);
          }
        } catch (error) {
          notify(`Error: ${error.message}`, 'error');
        } finally {
          setLoading(false);
        }
      };

      fetch();
    },
    [changeOpenRef, data, foundItem, notify],
  );

  const handleSubmit = useCallback(() => {
    const submit = formRef.current.handleSubmit;
    if (submit) {
      submit();
    }
  }, []);

  const buttons = useMemo(() => {
    return (
      <Button onClick={handleSubmit} loading={loading}>
        Сохранить
      </Button>
    );
  }, [handleSubmit, loading]);

  return (
    <Dialog
      {...props}
      buttons={buttons}
      dialogProps={{ fullWidth: true, classes: { paper: classes.dialogPaper } }}
      title="Данные по сделке"
    >
      <FormWithRedirect
        save={handleSave}
        render={(formProps) => {
          formRef.current = formProps;

          return (
            <>
              <FormDataConsumer>
                {() => {
                  const type = Number(foundItem.type);
                  const isBuyAndRent = type === 100 || type === 300;

                  return (
                    <>
                      {isBuyAndRent && (
                        <>
                          <AutocompleteWithFormRow
                            {...props}
                            source="realtyObject"
                            resourceName="RealtyObjects"
                            resourceId="realtyObjectId"
                            validate={validates.required}
                            componentProps={{
                              label: 'Объект в сделке',
                              fullDynamic: 'QueryWithFid',
                              getOptionLabel: (option) =>
                                `${option.id}: ${option.name} | ${option.friendlyId}`,
                            }}
                          />
                        </>
                      )}
                    </>
                  );
                }}
              </FormDataConsumer>
              <AutocompleteWithFormRow
                {...props}
                source="realtyObjectPayment"
                resourceName="RealtyObjectPayments"
                resourceId="realtyObjectPaymentId"
                componentProps={{
                  label: 'Тип оплаты',
                  filterSelectedOptions: true,
                }}
              />
              <PeopleSelect
                source="oppositeExpert"
                resourceId="oppositeExpertId"
                {...props}
                componentProps={{
                  label: 'Эксперт продавца',
                  providerParams: { filter: { isExpert: true } },
                }}
              />
              <FormRow>
                <DateTimePickerInput
                  pickerType="date"
                  source="closedDate"
                  label="Дата закрытия"
                  disabled
                />
              </FormRow>
              <FormTitle>Сроки сделки</FormTitle>
              <FormRow>
                <DateTimePickerInput
                  pickerType="date"
                  source="prepayDate"
                  label="Дата аванса/задатка"
                />
              </FormRow>
              <FormRow>
                <DateTimePickerInput
                  pickerType="date"
                  source="prepayTillDate"
                  label="Срок действия аванса/задатка до"
                />
              </FormRow>
              <FormRow>
                <DateTimePickerInput
                  pickerType="date"
                  source="dkpDate"
                  label="Дата подписания ДКП"
                  validate={validates.required}
                />
              </FormRow>
              <FormRow>
                <DateTimePickerInput
                  pickerType="date"
                  source="dvrDate"
                  label="Дата поступления ДВР"
                />
              </FormRow>
              <FormTitle>Сумма сделки</FormTitle>
              <FormRow>
                <ConstantSelect
                  source="dealCurrencyType"
                  objectChoices={currencyTypes}
                  label="Валюта стоимости продажи"
                />
              </FormRow>
              <FormRow>
                <NumberInput source="dealPrice" label="Стоимость продажи" />
              </FormRow>
              <FormRow>
                <NumberInput source="dealPriceInRub" label="Стоимость продажи в рублях" disabled />
              </FormRow>
              <FormTitle>Комиссия сделки</FormTitle>
              <FormRow>
                <ConstantSelect
                  source="comissionCurrencyType"
                  objectChoices={currencyTypes}
                  label="Валюта комиссии"
                />
              </FormRow>
              <FormRow>
                <NumberInput source="comission" label="Комиссия" />
              </FormRow>
              <FormRow>
                <NumberInput source="comissionInRub" label="Комиссия в рублях" disabled />
              </FormRow>
              <FormRow>
                <NumberInput
                  source="comissionPercents"
                  label="Комиссия в процентах"
                  InputProps={{
                    endAdornment: '%',
                  }}
                  disabled
                />
              </FormRow>
              <FormRow>
                <BooleanInput label="Сделка с участием агентства?" source="isWithAgency" />
              </FormRow>
              <FormDataConsumer>
                {({ formData }) => {
                  return (
                    formData.isWithAgency && (
                      <>
                        <FormRow>
                          <NumberInput
                            source="agencyFeeInRub"
                            label="Сумма комиссии агентства в рублях"
                          />
                        </FormRow>
                        <FormRow>
                          <NumberInput
                            source="agencyFeePercents"
                            label="Сумма комиссии агентства в процентах"
                            InputProps={{
                              endAdornment: '%',
                            }}
                            disabled
                          />
                        </FormRow>
                      </>
                    )
                  );
                }}
              </FormDataConsumer>
              <FormTitle>Передача клиента для сопровождения</FormTitle>
              <TogglerWithFormRow
                items={movedToSPItems}
                source="isMovedToSP"
                validate={validates.required}
              />
              <TogglerWithFormRow
                items={movedToSupportItems}
                source="isMovedToSupport"
                validate={validates.required}
              />
            </>
          );
        }}
      />
    </Dialog>
  );
};
