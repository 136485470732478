import React from 'react';

import { DefaultMenu } from '../../Menus';
import { Card } from '../..';

export const DefaultCard = (props) => {
  const { data: item, statusFields: statusFieldsProp, resource } = props;

  const statusFields = [...statusFieldsProp];

  const fieldNameProps = {
    name: item.name,
  };

  const menuElement = <DefaultMenu record={item} resource={resource} />;

  return (
    <Card
      {...props}
      fieldNameProps={fieldNameProps}
      statusFields={statusFields}
      menuElement={menuElement}
      hasImage={false}
    />
  );
};
