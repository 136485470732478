import React from 'react';

import { FormGeoSeeds } from '../../../components/forms';
import { Edit } from '../../../components/common';

export const EditGeoSeeds = (props) => {
  return (
    <Edit {...props}>
      <FormGeoSeeds {...props} redirect={false} />
    </Edit>
  );
};
