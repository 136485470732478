import React, { useMemo } from 'react';
// import { makeStyles } from '@material-ui/core';
import { getFormattedDate, checkExpired, getExpiredCount } from '../../../utils';
import { StatusFieldExpired } from '../../common/StatusFields';

// const useStyles = makeStyles(() => ({
//   soldCell: {
//     display: 'block',
//     padding: '8px 12px',
//     background: '#E9F5EA',
//     borderRadius: '3px',
//     color: '#50AE54',
//     fontSize: '13px',
//     textAlign: 'center',
//     lineHeight: '1.6',
//   },
//   isSoldInactive: {
//     color: '#F2443D',
//     background: '#FFEBEE',
//   },
// }));

const dateOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

export const TaskDatesField = ({ record, hasStatus = true }) => {
  const isExpired = useMemo(() => {
    return checkExpired(record);
  }, [record]);

  return (
    <div>
      <div>{`Создано: ${getFormattedDate(record.createdDate, dateOptions)}`}</div>
      {record.plannedDate && (
        <div>
          {`Планируемая дата выполнения: ${getFormattedDate(record.plannedDate, dateOptions)}`}
        </div>
      )}

      {record.completedDate && (
        <div>{`Дата выполнения: ${getFormattedDate(record.completedDate, dateOptions)}`}</div>
      )}

      {record.approvedDate && (
        <div>{`Дата подтверждения: ${getFormattedDate(record.approvedDate, dateOptions)}`}</div>
      )}

      {hasStatus && isExpired && (
        <div>
          <StatusFieldExpired fontSize="14px" count={getExpiredCount(record)} />
        </div>
      )}
    </div>
  );
};
