import React, { useState, useCallback } from 'react';
import { useDataProvider } from 'react-admin';

import { makeStyles } from '@material-ui/core';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { Button } from '../Button';
import { downloadFileFromBlob, mergeClasses } from '../../../utils';

const useStyles = makeStyles(() => ({
  root: {
    padding: '8px 14px',
    minWidth: 0,
    fontSize: '13px',
  },
}));

export const ExportButton = (props) => {
  const classes = useStyles();

  const dataProvider = useDataProvider();
  const { resource, filterValues, filter = {} } = props;

  const [loading, setLoading] = useState(false);

  const handleClick = useCallback(() => {
    setLoading(true);
    const fetch = async () => {
      const allFilters = { ...filter, ...filterValues };
      const result = await dataProvider.exportCSV({ resource, filter: allFilters });
      downloadFileFromBlob(result.data, 'Выгрузка.csv.gz');
      setLoading(false);
    };

    fetch();
  }, [dataProvider, filterValues, resource, filter]);

  return (
    <Button
      {...props}
      icon={<ImportExportIcon />}
      loading={loading}
      onClick={handleClick}
      classes={{
        root: mergeClasses(classes.root),
      }}
    >
      Загрузить в CSV
    </Button>
  );
};
