import React from 'react';
import { BooleanInput as RABooleanInput } from 'react-admin';

// import { makeStyles } from '@material-ui/core/styles';
// import { mergeClasses } from '../../../utils';

// const useStyles = makeStyles(() => ({
//   input: {
//     paddingTop: 18,
//     paddingBottom: 18,
//   },
//   root: {
//     margin: '0',
//   },
// }));

export const BooleanInput = (props) => {
  // const { className } = props;
  // const classes = useStyles();

  return <RABooleanInput helperText="" {...props} />;
};
