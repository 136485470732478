import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ListItemMainBlock, SortOrderListItem } from '../..';
import { getFullName } from '../../../../utils';
import { ownerStatuses, roleFlags, roles } from '../../../../constants';

const useStyles = makeStyles((theme) => ({
  buttons: {
    flex: '0 0 auto',
    padding: '0 12px',
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('1024')]: {
      flexWrap: 'wrap',
    },
  },
  link: {
    color: theme.palette.primaryNew['main'],
  },
}));

export const ListItemPeople = ({ record, hasButtons, originalRecord, isMiniCard }) => {
  const classes = useStyles();

  const descriptions = [];

  const name = getFullName(record);

  const link = `/Peoples/${record.id}`;

  if (record.eMail) {
    descriptions.push(record.eMail);
  }

  if (record.phone) {
    descriptions.push(record.phone);
  }

  const contentStatuses = [];

  if (record.ownerStatus) {
    let color = 'default';
    const status = parseInt(record.ownerStatus, 10);
    if (status === 200) {
      color = 'green';
    }
    if (status === 300 || status === 500 || status === 100) {
      color = 'red';
    }
    contentStatuses.push({ text: ownerStatuses[record.ownerStatus], color });
  }

  const textStatuses = [];

  if (record.isNew) {
    textStatuses.push({ text: `Новый`, color: 'green' });
  }

  if (record.isStaff) {
    textStatuses.push(`Villagio`);
  }

  if (record.isGodExpert) {
    textStatuses.push(`Ведущий эксперт`);
  }

  Object.keys(roleFlags).forEach((flag) => {
    if (record[flag]) {
      contentStatuses.push(roles[roleFlags[flag]]);
    }
  });

  return (
    <>
      <ListItemMainBlock
        record={record}
        descriptions={descriptions}
        hasTaskStatus
        name={name}
        link={link}
        imagePath={record.photo}
        textStatuses={textStatuses}
        hasButtons={hasButtons}
        contentStatuses={isMiniCard ? [] : contentStatuses}
      />
      {!isMiniCard && (
        <div className={classes.buttons}>
          <div className={classes.buttonsItem}>
            <SortOrderListItem sortOrder={originalRecord.sortOrder} />
          </div>
        </div>
      )}
    </>
  );
};
