import React, { useCallback } from 'react';
import { useRedirect } from 'react-admin';

import { MenuItem, makeStyles } from '@material-ui/core';
import { EditIcon } from '../../Icons';

const useStyles = makeStyles(() => ({
  root: {
    fontSize: 16,
    lineHeight: '1.4',
    height: 40,
    padding: '25px 25px',
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      marginRight: 15,
    },
  },
}));

export const EditMenuItem = ({ record, resource }) => {
  const classes = useStyles();
  const redirect = useRedirect();

  const handleEdit = useCallback(() => {
    redirect(`/${resource}/${record.id}`);
  }, [record.id, redirect, resource]);

  return (
    <MenuItem className={classes.root} onClick={handleEdit}>
      <EditIcon width={24} height={24} /> Редактировать
    </MenuItem>
  );
};
