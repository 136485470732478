import {
  villagesUrl,
  baseUrl,
  urlsObjectByType,
  privateSetsUrl,
  setsUrl,
  presentationBaseUrl,
  urlsPresentationByType,
  feedLinks,
} from '../constants';

export const getVillageLink = (record) => {
  if (record.sysStatus === 200) {
    return `${villagesUrl}/${record.id}`;
  }
};

export const getObjectLink = (record, isPrivate = false) => {
  if (record.objectTypes) {
    const typeId = record.objectTypes[0].objectTypeId;
    // const hasPrice = record.oldPrice >= 0 || record.oldRentPrice >= 0;
    const privateString = isPrivate ? 'private-' : '';

    // && hasPrice
    if (record.sysStatus === 200) {
      return `${baseUrl}${privateString}${urlsObjectByType[typeId]}/${record.id}`;
    }
  }
};

export const getSetLink = (record) => {
  if (record.sysStatus === 200 && record.realtyObjectSetTypeId) {
    if (record.realtyObjectSetTypeId === 2) {
      return `${privateSetsUrl}/${record.id}`;
    }

    return `${setsUrl}/${record.id}`;
  }
};

export const getPresentationLink = (type = 'sets', id, withoutLogo) => {
  const withoutLogoString = withoutLogo ? '-private' : '';
  const href = `${presentationBaseUrl}${urlsPresentationByType[type]}/${id}${withoutLogoString}.pdf`;

  return href;
};

const getFeedFullLink = (link, id, extension = 'xml') => {
  return `${link}${id}.${extension}`;
};

export const getFeedLinks = (record) => {
  const links = [];

  record.feedTargets.forEach((feedTargetRelation) => {
    const feedTargetId = feedTargetRelation.feedTarget.id;
    if (feedTargetId in feedLinks) {
      feedLinks[feedTargetId].forEach((feedLink) => {
        let extension;
        if (feedTargetId === 6 || feedTargetId === 12) {
          extension = 'csv';
        }
        links.push(getFeedFullLink(feedLink, record.id, extension));
      });
    } else {
      console.error(`Новый фид ${feedTargetRelation.feedTarget.name}`);
    }
  });

  return links;
};
