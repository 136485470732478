import React, { useMemo } from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';

// const useStyles = makeStyles((theme) => ({
//   default: {
//     color: 'inherit',
//     backgroundColor: 'transparent',
//   },
//   active: {
//     color: theme.palette.primaryNew['main'],
//     backgroundColor: 'transparent',
//   },
//   error: {
//     color: '#FF3D00',
//     backgroundColor: 'transparent',
//   },
// }));

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '16px',
    color: (props) => {
      const { type } = props;

      if (type === 'active') {
        return theme.palette.primaryNew['main'];
      }

      if (type === 'error') {
        return '#FF0404';
      }

      return 'rgba(33, 33, 33, 0.54)';
    },
  },
}));

export const ListItemTasksStatus = ({ taskStats }) => {
  let type = 'default';
  let tooltipText = 'Нет задач';

  if (taskStats?.isNew || taskStats?.isInProgress) {
    type = 'active';
    tooltipText = 'Есть задачи';
  }

  if (taskStats?.isOutdated) {
    type = 'error';
    tooltipText = 'Есть просроченная задача';
  }

  const classes = useStyles({ type });

  const icon = useMemo(() => {
    return <NotificationsIcon fontSize="inherit" />;
  }, []);

  return (
    <span className={classes.root}>
      <Tooltip title={tooltipText} arrow>
        {icon}
      </Tooltip>
    </span>
  );

  // if (taskStats && taskStats.isOutdated) {
  //   return <mark className={classes.error}>Есть просроченная задача</mark>;
  // }

  // if ((taskStats && taskStats.isNew) || (taskStats && taskStats.isInProgress)) {
  //   return <mark className={classes.active}>Есть задачи</mark>;
  // }

  // return <mark className={classes.default}>Нет задач</mark>;
};
