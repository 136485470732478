import React, { useMemo } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { AutocompleteDynamic } from '../../common';
import { PhotoField, NameField } from '../../RAFields';

const useStyles = makeStyles(() => ({
  avatar: {
    width: '24px',
    height: '24px',
    marginRight: '6px',
    fontSize: '10px',
  },
  photo: {
    paddingRight: '20px',
  },
}));

const WrapperPhotoField = ({ record }) => {
  const path = useMemo(() => {
    return record.cover;
  }, [record]);

  return <PhotoField path={path} />;
};

const WrapperNameField = ({ record }) => {
  const { id, name, slug } = record;

  return <NameField id={id} name={name} additionalId={slug} />;
};

const Option = ({ option }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.photo}>
        <WrapperPhotoField record={option} />
      </div>
      <WrapperNameField record={option} />
    </>
  );
};

const params = {
  filter: {
    FlatDetails: 300,
  },
};

export const CMSPagesSelect = (props) => {
  return (
    <AutocompleteDynamic
      multiple
      filterSelectedOptions
      resource="cmsAdmin/pages"
      limitTags={4}
      renderOption={(option) => <Option option={option} />}
      providerParams={params}
      getOptionLabel={(option) => `${option.id} / ${option.name}`}
      fullDynamic="NameContains"
      filterOptions={(options) => options}
      {...props}
    />
  );
};
