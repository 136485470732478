import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Preloader } from '../../../Preloader';

const useContentStyles = makeStyles(() => ({
  content: {
    marginTop: '12px',
    borderTop: '1px dashed rgba(0, 0, 0, 0.3)',
    paddingTop: '12px',
    position: 'relative',
    minHeight: '40px',
  },
}));

export const ListItemContent = ({ children, loading }) => {
  const classes = useContentStyles();

  return (
    <div className={classes.content}>
      <Preloader loading={loading} />
      {!loading && children}
    </div>
  );
};
