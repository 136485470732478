import React from 'react';

import { FormFeeds } from '../../../components/forms';
import { Edit } from '../../../components/common';

export const EditFeeds = (props) => {
  return (
    <Edit {...props}>
      <FormFeeds {...props} redirect={false} />
    </Edit>
  );
};
