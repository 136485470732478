import React from 'react';

import { Edit } from '../../../components/common';
import { FormFakeContracts } from '../../../components/forms/FakeContracts';

export const EditFakeContracts = (props) => {
  return (
    <Edit {...props}>
      <FormFakeContracts {...props} redirect={false} />
    </Edit>
  );
};
