import React, { useMemo } from 'react';
import { FormTab } from 'react-admin';

import { TextInput } from '../../TextInput/TextInput';
import { FormTitle, TabbedForm, RichText } from '../../common';

import { validates } from '../../../utils';
import { FormRow } from '../../FormRow';
import { ConstantSelect, AutocompleteWithFormRow } from '../../app';
import { publicationStatuses } from '../../../constants';
import { ExpertSelect } from '../../RASelects';
import { FeedLinksField } from '../../RAFields';

import { ListObjects, ListVillages } from '../../additionalLists';
import { FormCard } from '../../FormCard';

export const FormFeeds = (props) => {
  const { id, record, location } = props;

  const defaultObjects = useMemo(() => {
    if (location.state?.items) {
      return location.state?.items;
    }
  }, [location.state]);

  return (
    <TabbedForm {...props}>
      <FormTab label="Основное">
        <FormCard>
          {id && (
            <FormRow>
              <TextInput source="id" label="Id" disabled />
            </FormRow>
          )}
          <AutocompleteWithFormRow
            {...props}
            source="feedTargets"
            resourceName="FeedTargets"
            resourceId="feedTargetId"
            additionalSource="feedTarget"
            componentProps={{
              label: 'Площадки фида',
              multiple: true,
              filterSelectedOptions: true,
              providerParams: { filter: { Status: 200 } },
            }}
          />
          <FormRow>
            <ConstantSelect
              source="sysStatus"
              objectChoices={publicationStatuses}
              label="Статус публикации"
              defaultValue={100}
            />
          </FormRow>
          <FormRow>
            <TextInput
              source="sortOrder"
              label="Порядок сортировки"
              type="number"
              defaultValue={1000}
            />
          </FormRow>
          <ExpertSelect {...props} />
          <FormRow>
            <TextInput source="name" label="Название" validate={validates.name} />
          </FormRow>
          <FormTitle>Описание</FormTitle>
          <FormRow maxWidth={800}>
            <RichText source="description" />
          </FormRow>
          <FormRow>
            <TextInput source="phone" label="Телефон" helperText="Вводить по маске 79998887766" />
          </FormRow>
          <FormRow>
            <TextInput source="email" label="Электронная почта" />
          </FormRow>
          <FormRow>
            <TextInput source="url" label="Ссылка" />
          </FormRow>
          <FormRow>
            <TextInput source="query" label="Текст запроса" />
          </FormRow>
        </FormCard>
      </FormTab>
      <FormTab label="Объекты">
        <ListObjects defaultValue={defaultObjects} />
      </FormTab>
      <FormTab label="Комплексы">
        <ListVillages />
      </FormTab>
      {record?.feedTargets && record.feedTargets.length > 0 && record.sysStatus === 200 && (
        <FormTab label="Ссылки">
          <FormCard>
            <FormTitle>Ссылки</FormTitle>
            <FormRow>
              <FeedLinksField record={record} />
            </FormRow>
          </FormCard>
        </FormTab>
      )}
    </TabbedForm>
  );
};
