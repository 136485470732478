import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useInput } from 'react-admin';
import { HelperText, AutocompleteDynamic } from '../../common';

export const AutocompleteDynamicInput = (props) => {
  const {
    resourceName,
    resourceId,
    componentProps,
    componentProps: { multiple, defaultValue: defaultValueProp } = {},
    record,
    source,
    additionalSource,
    helperText = '',
    onChange,
  } = props;

  const oldItems = useMemo(() => {
    if (record && record[source]) {
      return record[source];
    }

    return [];
  }, [record, source]);

  const getMultipleValue = useCallback(
    (newValue) => {
      const currentItems = newValue.map((item) => {
        return { id: item.id, [resourceId]: item.id, name: '.', sysStatus: 200, original: item };
      });

      const newItems = [];

      oldItems.forEach((oldItem) => {
        if (
          currentItems.findIndex((currentItem) => {
            return currentItem.id === oldItem[resourceId];
          }) === -1
        ) {
          newItems.push({ ...oldItem, clientStatus: 'delete' });
        }
      });

      currentItems.forEach((currentItem) => {
        if (
          oldItems.findIndex((oldItem) => {
            return currentItem.id === oldItem[resourceId];
          }) === -1
        ) {
          newItems.push({ ...currentItem, clientStatus: 'create' });
        }
      });

      return {
        old: oldItems,
        current: currentItems,
        new: newItems,
        isMultipleRelations: true,
      };
    },
    [oldItems, resourceId],
  );

  const defaultValue = useMemo(() => {
    if (defaultValueProp) {
      if (multiple) {
        return getMultipleValue([defaultValueProp]);
      }

      return defaultValueProp.id;
    }

    return undefined;
  }, [defaultValueProp, getMultipleValue, multiple]);

  const { input, meta, isRequired } = useInput({
    ...props,
    source: multiple ? source : resourceId,
    defaultValue,
  });

  const [value, setValue] = useState(() => {
    if (record && record[source]) {
      if (additionalSource) {
        return record[source].map((item) => {
          return item[additionalSource];
        });
      }

      return record[source];
    }

    if (defaultValueProp) {
      return defaultValueProp;
    }

    if (multiple) {
      return [];
    }

    return null;
  });

  useEffect(() => {
    if (multiple && additionalSource && record) {
      if (!record[source]) {
        // setValue([]);
        return;
      }

      setValue(
        record[source].map((item) => {
          return item[additionalSource];
        }),
      );
    }
  }, [record, multiple, source, additionalSource]);

  const handleChange = useCallback(
    (newValue) => {
      if (multiple) {
        const newMultipleValue = getMultipleValue(newValue);

        input.onChange(newMultipleValue);
        onChange?.(newMultipleValue);

        return;
      }

      input.onChange(newValue ? newValue.id : '');
      onChange?.(newValue);
    },
    [multiple, input, onChange, getMultipleValue],
  );

  const hasError = useMemo(() => {
    return !!(meta.touched && (meta.error || meta.submitError));
  }, [meta]);

  const handleLoadDefaultValue = useCallback(
    (newValue) => {
      setValue(newValue);
      input.onChange(newValue.id);
    },
    [input],
  );

  return (
    <>
      <AutocompleteDynamic
        {...componentProps}
        resource={resourceName}
        onChange={handleChange}
        valueProp={value}
        setValueProp={setValue}
        hasError={hasError}
        isRequired={isRequired}
        onLoadDefaultValue={handleLoadDefaultValue}
      />
      <HelperText {...meta} helperText={helperText} />
    </>
  );
};
