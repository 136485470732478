import React from 'react';
import { WorkOutlineOutlined as WorkOutlineOutlinedIcon } from '@material-ui/icons';
import { StatusField } from '../StatusField';

export const StatusFieldRent = () => {
  return (
    <StatusField styleProps={{ backgroundColor: '#64DD17', fontSize: '16px' }}>
      <WorkOutlineOutlinedIcon fontSize="inherit" />
    </StatusField>
  );
};
