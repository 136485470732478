import React, { useMemo, useCallback } from 'react';
import { Datagrid, DateField } from 'react-admin';

import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { ListBase } from '../../../components/ListBase';

import { StatusesField } from '../../../components/RAFields/StatusesField';

import { checkRole } from '../../../utils';
import { setsUrl, privateSetsUrl, roleFlags } from '../../../constants';
import { currentUser } from '../../../authProvider';
import { PhotoField, NameField, AuthorField } from '../../../components/RAFields';
import { SetsMenu } from '../../../components/app/Menus';
import { RAList } from '../../../components/app';

const WrapperPhotoField = ({ record }) => {
  const count = useMemo(() => {
    if (record.realtyObjects) {
      return record.realtyObjects.length;
    }

    return 0;
  }, [record]);

  return <PhotoField path={record.photo} hasCounter count={count} />;
};

const WrapperNameField = ({ record }) => {
  const { id, name, slug } = record;

  const link = useMemo(() => {
    if (record.sysStatus === 200 && record.realtyObjectSetTypeId) {
      if (record.realtyObjectSetTypeId === 2) {
        return `${privateSetsUrl}/${id}`;
      }

      return `${setsUrl}/${id}`;
    }
  }, [id, record.realtyObjectSetTypeId, record.sysStatus]);

  const type = useMemo(() => {
    if (record.realtyObjectSetType) {
      return record.realtyObjectSetType.name;
    }
  }, [record]);

  return <NameField name={name} id={id} additionalId={slug} type={type} link={link} />;
};

const TableSets = () => {
  return (
    <Datagrid hasBulkActions>
      <WrapperPhotoField label="Фото" sortable={false} />
      <WrapperNameField source="name" label="Название" sortable={false} />
      <StatusesField source="sysStatus" label="Статус" sortable={false} />
      <AuthorField source="createdBy" label="Автор" sortable={false} />
      <DateField
        source="lastPublicationDate"
        label="Публикация"
        sortable={false}
        locales="ru"
        options={{
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        }}
      />
      <SetsMenu />
    </Datagrid>
  );
};

const CardsSets = ({ menuItem }) => {
  return <RAList type="set" menuItem={menuItem} />;
};

const ListSets = (props) => {
  const { permissions, resource } = props;

  const defaultExpertId = useMemo(() => {
    if (checkRole(permissions, roleFlags.isExpert)) {
      return currentUser?.id;
    }

    return null;
  }, [permissions]);

  const defaultFilters = useMemo(() => {
    if (defaultExpertId) {
      return { ManagerId: String(defaultExpertId) };
    }

    return {};
  }, [defaultExpertId]);

  const menuItem = useCallback(
    (record) => {
      return <SetsMenu record={record} resource={resource} icon={<MoreVertIcon />} />;
    },
    [resource],
  );

  return (
    <ListBase
      {...props}
      filterDefaultValues={defaultFilters}
      hasToggleView
      tableRender={<TableSets {...props} />}
      cardRender={<CardsSets {...props} menuItem={menuItem} />}
    />
  );
};

export default ListSets;
