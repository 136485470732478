// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-unreachable */
import React, { useMemo, useRef, useCallback, useState } from 'react';
import { FormWithRedirect, useRefresh } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { Dialog } from '../Dialog';

import { Button } from '../../buttons';
import { dataProvider } from '../../../dataProvider';
import { FormTitle } from '../../common';
import { ConstantSelect, AutocompleteWithFormRow } from '../../app';
import { FormRow } from '../../FormRow';

import { validates } from '../../../utils';
import { showResults } from '../../../constants';
import { PeopleSelect } from '../../RASelects';
import { DateTimePickerInput } from '../../RAInputs';
import { TextInput } from '../../TextInput/TextInput';

const useStyles = makeStyles(() => ({
  dialogPaper: {
    maxWidth: 465,
  },
}));

export const ShowsMultipleDialog = (props) => {
  const refresh = useRefresh();
  const classes = useStyles();
  const { changeOpenRef, record = {}, resource, data, selectedIds } = props;

  const formRef = useRef();

  const [loading, setLoading] = useState(false);

  const handleSave = useCallback(
    (values) => {
      setLoading(true);
      const items = selectedIds.map((selectedId) => {
        return data[selectedId];
      });

      const fetch = async () => {
        try {
          await dataProvider.updateMany(resource, items, { ...values });
          if (changeOpenRef.current) {
            changeOpenRef.current(false);
          }
          refresh();
        } finally {
          setLoading(false);
        }
      };

      fetch();
    },
    [changeOpenRef, data, refresh, resource, selectedIds],
  );

  const handleSubmit = useCallback(() => {
    const submit = formRef.current.handleSubmit;
    if (submit) {
      submit();
    }
  }, []);

  const buttons = useMemo(() => {
    return (
      <Button onClick={handleSubmit} loading={loading}>
        Сохранить
      </Button>
    );
  }, [handleSubmit, loading]);

  return (
    <Dialog
      {...props}
      buttons={buttons}
      dialogProps={{ fullWidth: true, classes: { paper: classes.dialogPaper } }}
      title="Редактировать поля показов"
    >
      <FormWithRedirect
        save={handleSave}
        record={record}
        render={(formProps) => {
          formRef.current = formProps;

          return (
            <>
              <FormTitle>Основные поля</FormTitle>
              <AutocompleteWithFormRow
                {...props}
                source="contract"
                resourceName="Contracts"
                resourceId="contractId"
                componentProps={{
                  label: 'Лид',
                  getOptionLabel: (option) => `${option.name} / Id - ${option.id}`,
                }}
              />
              <FormRow>
                <DateTimePickerInput source="date" label="Дата показа (план)" pickerType="date" />
              </FormRow>
              <FormRow>
                <DateTimePickerInput source="actualDate" label="Дата показа" pickerType="date" />
              </FormRow>
              <PeopleSelect
                source="expert"
                resourceId="expertId"
                {...props}
                componentProps={{
                  label: 'Эксперт на показе',
                  providerParams: { filter: { isExpert: true } },
                }}
              />
              <FormRow maxWidth={800} isLast>
                <TextInput source="description1" label="Комментарий" multiline rows={6} />
              </FormRow>
              <FormTitle>Результат показа</FormTitle>
              <FormRow>
                <ConstantSelect
                  source="result"
                  objectChoices={showResults}
                  label="Как прошел показ"
                  allowEmpty
                />
              </FormRow>
              <FormRow maxWidth={800} isLast>
                <TextInput
                  source="description2"
                  label="Комментарий результата"
                  multiline
                  rows={6}
                  validate={validates.description2Shows}
                />
              </FormRow>
            </>
          );
        }}
      />
    </Dialog>
  );
};
