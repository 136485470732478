import React, { useCallback, useMemo, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { MenuItemLink, getResources, Layout, AppBar, DashboardMenuItem } from 'react-admin';
import {
  Build as IconBuild,
  DesktopWindows as DesktopWindowsIcon,
  NotificationsActive as NotificationsActiveIcon,
  // Equalizer as EqualizerIcon,
  // Today as TodayIcon,
} from '@material-ui/icons';
import DefaultIcon from '@material-ui/icons/ViewList';
import {
  IconButton,
  Badge,
  Box,
  Link,
  MenuItem,
  Typography,
  ListItemText,
  ListItemIcon,
  createStyles,
  makeStyles,
  Breadcrumbs,
  Tooltip,
} from '@material-ui/core';
import SubMenu from './SubMenu';
import { checkRoleOdp, mergeClasses } from '../../utils';
import { ErrorPage } from '../../pages';
import { TouchNotifications, ActiveCall } from '../app';
import { OidcContext } from '../providers';
import { customTheme } from '../../theme';
import { RouterLink } from '../common';
import { browserHistory } from '../../App';
import { MainIcon } from '../Icons';

const useStyles = makeStyles((theme) =>
  createStyles({
    SideMenu: {
      paddingTop: '12px',
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100%',
      background: customTheme.palette.grey.background,
      boxSizing: 'border-box',
      borderRight: '0.5px solid #e0e0e0',
    },
    SideMenuBottomBlock: {
      backgroundColor: customTheme.palette.common.white,
      marginTop: 'auto',
      padding: 8,
    },
    SideMenuBottomLink: {
      borderRadius: 2,
      paddingTop: '10px',
      paddingBottom: '10px',
      paddingLeft: '8px',
      paddingRight: '8px',
      textDecoration: 'none',

      '&:hover': {
        background: customTheme.palette.primary[50],
        color: customTheme.palette.primary['A200'],
      },

      '&:hover *': {
        color: customTheme.palette.primary['A200'],
      },
    },
    SideMenuContainer: {
      paddingLeft: 8,
      paddingRight: 8,
      paddingBottom: 8,
      background: customTheme.palette.grey.background,
    },
    SideMenuLink: {
      borderRadius: 2,
      marginBottom: 4,
      fontSize: 14,
      paddingTop: 12,
      paddingBottom: 12,
      paddingLeft: 8,
      paddingRight: 8,
    },
    SideMenuBottomLinkTitle: {
      color: theme.palette.grey[700],
      fontSize: 14,
    },
    SideMenuLinkBordered: {
      position: 'relative',
      marginTop: 16,
      overflow: 'visible',
      '&::after': {
        content: '""',
        position: 'absolute',
        top: -8,
        left: 0,
        width: '100%',
        height: 1,
        backgroundColor: theme.palette.grey[300],
      },
    },
    appBarContent: {
      flex: '1 1 auto',
      color: '#000',
      textAlign: 'right',
    },
    callButtonDisabled: {
      opacity: '0.6',
    },
    //
    breadcrumbs: {
      display: 'flex',
      alignItems: 'baseline',
      marginRight: 'auto',
    },
    breadcrumbsLink: {
      [theme.breakpoints.down('1024')]: {
        fontSize: 12,
      },
    },
    breadcrumbsLinkActive: {
      textDecoration: 'underline',
    },
    subMenuItem: {
      paddingLeft: '12px',
    },
  }),
);

const getAllMenu = (resources) => {
  const mainMenu = [];
  const subMenu = {};
  resources.forEach((resource) => {
    if (resource.options && resource.options.subMenu) {
      const key = resource.options.subMenu;
      if (!(key in subMenu)) {
        subMenu[key] = [];
      }

      subMenu[key].push(resource);

      return;
    }

    if (resource.name === 'FakeContracts') {
      return;
    }

    mainMenu.push(resource);
  });

  return [mainMenu, subMenu];
};

const getResourcesMap = (resources = {}) => {
  const items = {};
  resources.forEach((resource) => {
    items[resource.name] = resource?.options?.label || resource.name;
  });

  return items;
};

const getResourceItems = (resourceItems = '', hasLongPath = false) => {
  if (hasLongPath) {
    const currentItems = resourceItems.split('/').slice(1, 4);
    return [`${currentItems[0]}/${currentItems[1]}`, currentItems[2]];
  }

  return resourceItems.split('/').slice(1, 3);
};

const translates = {
  create: 'Создание',
  Kanban: 'Канбан',
};

const MyBreadcrumbs = () => {
  const classes = useStyles();
  const pathName = browserHistory.location.pathname;
  const resources = useSelector(getResources);
  const resourcesMap = getResourcesMap(resources);
  let hasLongPath = false;
  if (pathName.indexOf('cmsAdmin') !== -1) {
    hasLongPath = true;
  }
  const resourceItems = getResourceItems(pathName, hasLongPath);

  const BreadcrumbElements = useMemo(() => {
    return resourceItems.map((resourceItem, index) => {
      let text = resourceItem;

      if (index === 0) {
        text = resourcesMap[resourceItem];
      }

      if (index !== 0 && resourceItem in translates) {
        text = translates[resourceItem];
      }

      if (resourceItems.length - 1 === index) {
        return (
          <Typography className={classes.breadcrumbsLink} color="textPrimary" key={resourceItem}>
            {text}
          </Typography>
        );
      }

      return (
        <RouterLink to={`/${resourceItem}`} key={resourceItem}>
          <Typography
            className={mergeClasses(classes.breadcrumbsLink, classes.breadcrumbsLinkActive)}
            color="textPrimary"
          >
            {text}
          </Typography>
        </RouterLink>
      );
    });
  }, [resourceItems, resourcesMap, classes.breadcrumbsLink, classes.breadcrumbsLinkActive]);

  return (
    <div className={classes.breadcrumbs}>
      <Breadcrumbs>{BreadcrumbElements}</Breadcrumbs>
    </div>
  );
};

const MyAppBar = (props) => {
  const classes = useStyles();

  const oidc = useContext(OidcContext);

  const [count, setCount] = useState(0);
  const [visible, setVisible] = useState(false);
  const onChangeCount = useCallback((newCount) => {
    setCount(newCount);
  }, []);

  const handleClick = useCallback(() => {
    setVisible(true);
  }, []);

  const permissions = useMemo(() => {
    if (!oidc) {
      return null;
    }

    return oidc.profile.role;
  }, [oidc]);

  const isOdp = useMemo(() => {
    return checkRoleOdp(permissions);
  }, [permissions]);

  return (
    <>
      <AppBar {...props}>
        <MyBreadcrumbs />
        <div className={classes.appBarContent}>
          {isOdp && (
            <Tooltip title="Нотификации клиентов" arrow>
              <IconButton
                color="inherit"
                onClick={handleClick}
                classes={{ root: !visible && classes.callButtonDisabled }}
              >
                <Badge badgeContent={count} color="error">
                  <NotificationsActiveIcon />
                </Badge>
              </IconButton>
            </Tooltip>
          )}
        </div>
      </AppBar>
      {isOdp && (
        <>
          <TouchNotifications
            onChangeCount={onChangeCount}
            visible={visible}
            changeVisible={setVisible}
          />
          <ActiveCall />
        </>
      )}
    </>
  );
};

// const getCustomMenuItems = (isAdmin) => {
//   const items = [
//     {
//       name: 'calendar',
//       options: { label: 'Календарь' },
//       icon: TodayIcon,
//     },
//   ];

//   if (isAdmin) {
//     items.push({
//       name: 'analytics',
//       options: { label: 'Аналитика' },
//       icon: EqualizerIcon,
//     });
//   }

//   return items;
// };

const MySideMenu = ({ onMenuClick, dense = false, hasDashboard }) => {
  const classes = useStyles();
  const sidebarOpen = useSelector((state) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);
  // const oidc = useContext(OidcContext);

  // const isAdmin = useMemo(() => {
  //   if (!oidc) {
  //     return false;
  //   }

  //   return checkRoleAdminOrSupervisor(oidc.profile.role);
  // }, [oidc]);

  const [mainMenu, subMenu] = getAllMenu(resources);

  const menuItemProps = useCallback(
    (resource, isSubMenu) => {
      const isBordered =
        resource.name === 'RealtyObjects' ||
        resource.name === 'Clients' ||
        resource.name === 'RealtyObjectSets' ||
        resource.name === 'ClientTouches' ||
        resource.name === 'Infrastructures' ||
        resource.name === 'Taasks' ||
        resource.name === 'Peoples' ||
        resource.name === 'ChangeHistory' ||
        resource.name === 'Demos' ||
        resource.name === 'RealtyFeeds' ||
        resource.name === 'RealtyObjectDiscounts' ||
        resource.name === 'Articles' ||
        resource.name === 'Instructions' ||
        resource.name === 'OurNews' ||
        resource.name === 'RealtySectors';

      return {
        className: mergeClasses(
          classes.SideMenuLink,
          isBordered && classes.SideMenuLinkBordered,
          isSubMenu && classes.subMenuItem,
        ),
        key: resource.name,
        to: `/${resource.name}`,
        primaryText: resource.options.label,
        leftIcon: resource.icon ? <resource.icon width="24" height="24" /> : <DefaultIcon />,
        onClick: onMenuClick,
        sidebarIsOpen: sidebarOpen,
        dense,
      };
    },
    [classes, onMenuClick, sidebarOpen, dense],
  );

  const mainMenuItems = useMemo(() => {
    const allMenuItems = [...mainMenu];
    // allMenuItems.push(...getCustomMenuItems(isAdmin));

    return allMenuItems.map((resource) => {
      return <MenuItemLink {...menuItemProps(resource)} />;
    });
  }, [mainMenu, menuItemProps]);

  const subMenuItems = useMemo(() => {
    return Object.keys(subMenu).map((key) => {
      let SubMenuIcon = IconBuild;
      const firstItem = subMenu[key][0];
      if (firstItem.options && firstItem.options.subMenuIcon) {
        SubMenuIcon = firstItem.options.subMenuIcon;
      }
      return (
        <SubMenu
          sidebarIsOpen={sidebarOpen}
          name={key}
          icon={<SubMenuIcon />}
          key={key}
          dense={dense}
        >
          {subMenu[key].map((resource) => {
            return <MenuItemLink {...menuItemProps(resource, true)} />;
          })}
        </SubMenu>
      );
    });
  }, [subMenu, sidebarOpen, dense, menuItemProps]);

  return (
    <div className={classes.SideMenu}>
      <div className={classes.SideMenuContainer}>
        {hasDashboard && (
          <DashboardMenuItem
            dense={dense}
            className={mergeClasses(classes.SideMenuLink)}
            leftIcon={<MainIcon width="24" height="24" />}
          />
        )}
        {mainMenuItems}
        {subMenuItems}
      </div>
      <Box boxShadow={3} className={classes.SideMenuBottomBlock}>
        <MenuItem
          button
          component={Link}
          href="https://villagio-realty.ru"
          target="_blank"
          className={classes.SideMenuBottomLink}
        >
          <ListItemIcon style={{ minWidth: 40 }}>
            <DesktopWindowsIcon />
          </ListItemIcon>
          <ListItemText>
            <Typography className={classes.SideMenuBottomLinkTitle} variant="body2">
              Сайт
            </Typography>
          </ListItemText>
        </MenuItem>
      </Box>
    </div>
  );
};

export const SideMenu = (props) => (
  <Layout {...props} appBar={MyAppBar} menu={MySideMenu} error={ErrorPage} />
);
