import React, { useMemo, useEffect, useReducer, useRef, useCallback } from 'react';
import { Collapse, useTheme, makeStyles } from '@material-ui/core';
import { useRefresh } from 'react-admin';
import { showCalcStatuses, showResults, visitShowStatuses } from '../../../../constants';
import {
  getFormattedDate,
  getFormattedDateAndTime,
  getFullNameWithId,
  isObject,
  parseBoolean,
} from '../../../../utils';
import { useDebounce } from '../../../../hooks/useDebounce';
import { dataProvider } from '../../../../dataProvider';
import { ListItemHeader } from '../ItemHeader';
import { DateTimePickerInput } from '../../../RAInputs';
import { ConstantSelectField } from '../../ConstantSelectField';
import { AutocompleteDynamic, Avatar, TextField } from '../../../common';
import { PeopleOption } from '../../../RASelects';
import { ListItemContent } from '../ItemContent';
import { ListItem } from '../Item';

const reducer = (state, action) => {
  if (action.type === 'date' && !action.payload) {
    return { ...state, date: new Date().toISOString() };
  }

  return { ...state, [action.type]: action.payload };
};

const useNewStyles = makeStyles((theme) => ({
  buttons: {
    marginTop: '12px',
    borderTop: '1px dashed rgba(0, 0, 0, 0.3)',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  buttonsRow: {
    width: '32%',
    boxSizing: 'border-box',
    paddingTop: '16px',
    flex: '0 0 auto',

    [theme.breakpoints.down('1024')]: {
      width: '49%',
    },

    [theme.breakpoints.down('600')]: {
      width: '100%',
    },
  },
  inputAvatar: {
    width: '24px',
    height: '24px',
    marginRight: '2px',
    fontSize: '10px',
  },
}));

export const NewListItemShow = ({ record, hasButtons, onChangeBgColor, headerProps }) => {
  const classes = useNewStyles();
  const { id } = record;
  const { expanded } = headerProps;

  let name = id;

  if (record.date) {
    name += ` | ${getFormattedDate(record.date)}`;
  }

  if (record.createdDate) {
    name += ` | ${getFormattedDateAndTime(record.createdDate)}`;
  }

  const link = `/Demos/${record.id}`;

  const tags = [];

  const status = parseInt(record.calcStatus, 10);
  const text = showCalcStatuses[status];
  let statusColor = 'default';

  if (status === 200) {
    statusColor = 'green';
  }

  if (status === 300) {
    statusColor = 'red';
  }

  tags.push({
    text,
    color: statusColor,
  });

  if (record.result) {
    tags.push({
      text: showResults[record.result],
      color: 'green',
    });
  }

  const theme = useTheme();

  const color = useMemo(() => {
    if (status === 200) {
      return theme.palette.green['background'];
    }

    if (status === 300) {
      return theme.palette.red['background'];
    }
  }, [status, theme.palette.green, theme.palette.red]);

  useEffect(() => {
    onChangeBgColor(color);
  }, [color, onChangeBgColor]);

  const defaultValueDate = useMemo(() => {
    return new Date().toISOString();
  }, []);

  const [inputValues, dispatch] = useReducer(reducer, {
    date: record?.date || defaultValueDate,
    isNotFirst: record?.isNotFirst ?? '',
    result: record?.result || '',
    expertId: record?.expertId,
    expert: record?.expert,
    description1: record?.description1 || '',
    description2: record?.description2 || '',
  });

  const refresh = useRefresh();

  const { clearTimeoutDebounce, timeoutDebounce } = useDebounce();

  const hasChangesRef = useRef(false);

  const onChangeInputValues = useCallback((newValue, event) => {
    let resultValue = newValue;
    const fieldName = isObject(event) ? event.target.name : event;
    if (fieldName === 'isNotFirst') {
      resultValue = parseBoolean(resultValue);
    }
    if (isObject(resultValue)) {
      dispatch({ type: `${fieldName}Id`, payload: resultValue.id });
    }
    dispatch({ type: fieldName, payload: resultValue });
    hasChangesRef.current = true;
  }, []);

  const onBlurInputValues = useCallback(() => {
    if (hasChangesRef.current) {
      const fetch = async () => {
        await dataProvider.update('Demos', {
          id: record.id,
          data: {
            ...record,
            ...inputValues,
          },
          previousData: record,
        });

        refresh();
      };

      timeoutDebounce(() => {
        fetch();
        hasChangesRef.current = false;
      });
    }
  }, [inputValues, record, refresh, timeoutDebounce]);

  const onFocusInputValues = useCallback(() => {
    clearTimeoutDebounce();
  }, [clearTimeoutDebounce]);

  return (
    <>
      <ListItemHeader
        record={record}
        link={link}
        name={name}
        hasButtons={hasButtons}
        tags={tags}
        tagName="Показ"
        {...headerProps}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {record?.contract?.mainClient && (
          <ListItemContent>
            <ListItem
              type="client"
              record={record.contract.mainClient}
              isNewItem
              hasButtons={false}
            />
          </ListItemContent>
        )}
        <div className={classes.buttons}>
          <div className={classes.buttonsRow}>
            <DateTimePickerInput
              source="date"
              label="Дата показа"
              pickerType="date"
              isRA={false}
              size="small"
              onBlur={onBlurInputValues}
              onFocus={onFocusInputValues}
              name="date"
              onChange={onChangeInputValues}
              value={inputValues.date}
              isControlled
            />
          </div>
          <div className={classes.buttonsRow}>
            <ConstantSelectField
              items={visitShowStatuses}
              size="small"
              value={inputValues.isNotFirst}
              onChange={onChangeInputValues}
              name="isNotFirst"
              label="Статус визита"
              isControlled
              onBlur={onBlurInputValues}
              onFocus={onFocusInputValues}
              allowEmpty
            />
          </div>
          <div className={classes.buttonsRow}>
            <ConstantSelectField
              items={showResults}
              size="small"
              value={inputValues.result}
              onChange={onChangeInputValues}
              name="result"
              label="Как прошел показ"
              isControlled
              onBlur={onBlurInputValues}
              onFocus={onFocusInputValues}
              allowEmpty
            />
          </div>
          <div className={classes.buttonsRow}>
            <AutocompleteDynamic
              filterSelectedOptions
              resource="Peoples"
              fullDynamic="AllNames"
              label="Эксперт"
              name="expert"
              onChange={onChangeInputValues}
              size="small"
              valueProp={inputValues.expert}
              onBlur={onBlurInputValues}
              onFocus={onFocusInputValues}
              getOptionLabel={(option) => (!isObject(option) ? '' : `${getFullNameWithId(option)}`)}
              renderOption={(option) => <PeopleOption option={option} />}
              startAdornment={(value) => {
                return (
                  value && <Avatar option={value} source="photo" className={classes.inputAvatar} />
                );
              }}
              providerParams={{ filter: { isExpert: true } }}
            />
          </div>
        </div>
        <ListItemContent>
          <TextField
            multiline
            rows={4}
            label="Комментарий"
            size="small"
            onBlur={onBlurInputValues}
            onFocus={onFocusInputValues}
            name="description1"
            onChange={onChangeInputValues}
            value={inputValues.description1}
            isControlled
          />
        </ListItemContent>
        <ListItemContent>
          <TextField
            multiline
            rows={4}
            label="Комментарий результата"
            size="small"
            onBlur={onBlurInputValues}
            onFocus={onFocusInputValues}
            name="description2"
            onChange={onChangeInputValues}
            value={inputValues.description2}
            isControlled
          />
        </ListItemContent>
      </Collapse>
    </>
  );
};
