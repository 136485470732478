import React from 'react';

import { FormFeatures } from '../../../components/forms';
import { Create } from '../../../components/common';

export const CreateFeatures = (props) => {
  return (
    <Create {...props}>
      <FormFeatures {...props} redirect="list" />
    </Create>
  );
};
