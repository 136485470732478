import React, { useState, useCallback, useMemo } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Add as AddIcon, Remove as RemoveIcon } from '@material-ui/icons';
import { FilterNumber } from '../FilterNumber';
import { customTheme } from '../../../../theme';

const useStyles = makeStyles(() => ({
  content: {
    width: '100%',
  },
  expanded: {
    backgroundColor: '#FAFAFA',
  },
  count: {
    fontSize: '14px',
    padding: '4px 8px',
    display: 'block',
    flex: '0 0 auto',
    background: customTheme.palette.primary['A200'],
    borderRadius: '2px',
    color: 'white',
  },
  summary: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export const FilterItem = ({
  children,
  label,
  currentFilters,
  filters = [],
  multipleFilters,
  getNumberFilters,
}) => {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);

  const handleChange = useCallback((_, newExpanded) => {
    setExpanded(newExpanded);
  }, []);

  const numberActiveFilters = useMemo(() => {
    return getNumberFilters(currentFilters, multipleFilters, filters);
  }, [currentFilters, filters, multipleFilters, getNumberFilters]);

  return (
    <>
      <Accordion expanded={expanded} onChange={handleChange}>
        <AccordionSummary
          expandIcon={expanded ? <RemoveIcon /> : <AddIcon />}
          classes={{ content: classes.summary, expanded: classes.expanded }}
        >
          <Typography>{label}</Typography>
          <FilterNumber number={numberActiveFilters} />
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.content}>{children}</div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
