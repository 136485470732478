import React, { useMemo, useRef, useCallback, useState } from 'react';
import { FormWithRedirect, useRefresh } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { Dialog } from '../Dialog';

import { Button } from '../../buttons';
import { PeopleSelect } from '../../RASelects';
import { dataProvider } from '../../../dataProvider';

const useStyles = makeStyles(() => ({
  dialogPaper: {
    maxWidth: 600,
  },
}));

export const MergeClientsDialog = (props) => {
  const classes = useStyles();
  const { changeOpenRef, data, handleCloseRef } = props;

  const refresh = useRefresh();
  const formRef = useRef();

  const [loading, setLoading] = useState(false);

  const closeMenu = useCallback(() => {
    handleCloseRef?.current?.();
  }, [handleCloseRef]);

  const handleSave = useCallback(
    (values) => {
      const fetch = async () => {
        setLoading(true);
        const fromUserId = data.id;
        const toUserId = values.clientId;

        try {
          await dataProvider.mergePeoples({ fromUserId, toUserId, data });

          if (changeOpenRef.current) {
            changeOpenRef.current(false);
          }

          refresh();
          closeMenu();
        } finally {
          setLoading(false);
        }
      };

      fetch();
    },
    [changeOpenRef, closeMenu, data, refresh],
  );

  const handleSubmit = useCallback(() => {
    const submit = formRef.current.handleSubmit;
    if (submit) {
      submit();
    }
  }, []);

  const handleClose = useCallback(() => {
    closeMenu();
  }, [closeMenu]);

  const buttons = useMemo(() => {
    return (
      <Button onClick={handleSubmit} loading={loading}>
        Сохранить
      </Button>
    );
  }, [handleSubmit, loading]);

  return (
    <Dialog
      {...props}
      buttons={buttons}
      dialogProps={{ fullWidth: true, classes: { paper: classes.dialogPaper } }}
      title="Объединение пользователей"
      onClose={handleClose}
    >
      <FormWithRedirect
        save={handleSave}
        record={{}}
        render={(formProps) => {
          formRef.current = formProps;

          return (
            <>
              <PeopleSelect
                source="client"
                resourceId="clientId"
                componentProps={{
                  label: 'Пользователь с которым объединить',
                  providerParams: { filter: { isClient: true } },
                  getOptionDisabled: (option) => option.id === data.id,
                }}
              />
            </>
          );
        }}
      />
    </Dialog>
  );
};
