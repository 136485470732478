import React from 'react';

import { PeopleSelect } from '../People';

export const EmployeeSelect = (props) => {
  const { componentProps } = props;

  return (
    <PeopleSelect
      source="employee"
      resourceId="employeeId"
      {...props}
      componentProps={{
        label: 'Сотрудник',
        disabled: false,
        providerParams: { filter: { isClient: true } },
        ...componentProps,
      }}
    />
  );
};
