import React, { useRef, useCallback } from 'react';

import AddIcon from '@material-ui/icons/Add';
import { Button } from '../Button';
import { AddCommentDialog } from '../../dialogs/AddComment';

export const AddCommentDialogButton = ({ id, type, comment, onReload, ...props }) => {
  const changeOpenRef = useRef();

  const handleOpenDialog = useCallback(() => {
    if (changeOpenRef.current) {
      changeOpenRef.current(true);
    }
  }, []);

  return (
    <>
      <Button size="small" {...props} icon={<AddIcon />} onClick={handleOpenDialog}>
        Добавить комментарий
      </Button>
      <AddCommentDialog changeOpenRef={changeOpenRef} id={id} onReload={onReload} type={type} />
    </>
  );
};
