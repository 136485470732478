import React, { useMemo } from 'react';

import { makeStyles, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { RouterLink } from '../../components/common';
import { DynamicList } from '../../components/additionalLists';
import { List } from '../../components/app';

const CreateButton = ({ basePath, title }) => {
  return (
    <Button
      size="small"
      variant="contained"
      color="primary"
      component={Link}
      to={{
        pathname: `/${basePath}/create`,
      }}
    >
      {title}
    </Button>
  );
};

const useStylesKanbanList = makeStyles(() => ({
  header: {
    lineHeight: '1',
    padding: '16px',
    borderBottom: '0.5px solid #e0e0e0',
    flex: '0 0 auto',
  },
  header_title: {
    fontWeight: 'bold',
  },
  content: {
    flex: '1 1 auto',
  },
  list: {
    position: 'relative',
    height: '100%',
    overflow: 'auto',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
}));

const KanbanList = ({ title, basePath = '', filter = {}, defaultFilter = {}, type }) => {
  const classes = useStylesKanbanList();

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <span className={classes.header_title}>{title}</span>
      </div>
      <div className={classes.content}>
        <div className={classes.list}>
          <DynamicList
            fetchOptions={{
              resource: basePath,
              filter: { ...filter, ...defaultFilter },
            }}
            contentOptions={{
              type,
              hasButtons: false,
              isNewItem:
                type === 'client' || type === 'show' || type === 'task' || type === 'contract',
            }}
            contentElement={List}
          />
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  rows: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    flex: '1 1 auto',
    minHeight: '450px',
  },

  row: {
    width: '32%',
    boxSizing: 'border-box',
    flex: '0 1 auto',
    margin: '10px 0',

    [theme.breakpoints.down('1280')]: {
      width: '100%',
      minHeight: '450px',
    },
  },

  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingBottom: '12px',
    boxSizing: 'border-box',
  },

  footer: {
    flex: '0 0 auto',
    display: 'flex',
    alignItems: 'center',
    paddingTop: '16px',
  },

  footerLink: {
    marginRight: '16px',

    '& a': {
      display: 'flex',
      alignItems: 'center',
    },
    '& svg': {
      marginLeft: '8px',
    },
  },
}));

export const KanbanPage = (props) => {
  const { items, buttonTitle, basePath } = props;

  const classes = useStyles();

  const kanbanItems = useMemo(() => {
    return items.map((item) => {
      return (
        <div className={classes.row}>
          <KanbanList {...props} {...item} />
        </div>
      );
    });
  }, [classes.row, items, props]);

  return (
    <div className={classes.root}>
      <div className={classes.rows}>{kanbanItems}</div>
      <div className={classes.footer}>
        <div className={classes.footerLink}>
          <RouterLink to={`/${basePath}`}>
            Смотреть все <ArrowForwardIcon />
          </RouterLink>
        </div>
        <CreateButton basePath={basePath} title={buttonTitle} />
      </div>
    </div>
  );
};
