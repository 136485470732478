// eslint-disable-next-line no-use-before-define
import React from 'react';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import List from '@material-ui/core/List';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import { customTheme } from '../../theme';
import { mergeClasses } from '../../utils';

const useStyles = makeStyles((theme) => ({
  icon: { minWidth: theme.spacing(5) },
  iconRight: { minWidth: theme.spacing(3), marginLeft: 'auto' },
  sidebarIsOpen: {
    '& a': {
      transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
  },
  sidebarIsClosed: {
    '& a': {
      transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
  },
  menuItem: {
    fontSize: 14,
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 2,

    '&:hover': {
      background: customTheme.palette.primary[50],
      color: customTheme.palette.primary['A200'],
    },

    '&:hover *': {
      color: customTheme.palette.primary['A200'],
    },
  },
  menuItemBordered: {
    position: 'relative',
    marginTop: 16,
    overflow: 'visible',

    '&::after': {
      content: '""',
      position: 'absolute',
      top: -8,
      left: 0,
      width: '100%',
      height: 1,
      backgroundColor: theme.palette.grey[300],
    },
  },
}));

// eslint-disable-next-line no-unused-vars
const SubMenu = ({ sidebarIsOpen, name, icon, children, dense }) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleToggle = React.useCallback(() => {
    setOpen(!open);
  }, [open]);

  const header = (
    <MenuItem
      dense={dense}
      button
      onClick={handleToggle}
      className={mergeClasses(classes.menuItem, classes.menuItemBordered)}
    >
      <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
      <Typography variant="inherit" color="textSecondary">
        {name}
      </Typography>
      <ListItemIcon className={classes.iconRight}>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemIcon>
    </MenuItem>
  );

  return (
    <>
      {sidebarIsOpen || open ? (
        header
      ) : (
        <Tooltip title={name} placement="right">
          {header}
        </Tooltip>
      )}
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          dense={dense}
          component="div"
          disablePadding
          className={sidebarIsOpen ? classes.sidebarIsOpen : classes.sidebarIsClosed}
        >
          {children}
        </List>
      </Collapse>
    </>
  );
};

export default SubMenu;
