import React, { useMemo } from 'react';

import { makeStyles, Tooltip } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '16px',
    color: theme.palette.primaryNew['main'],
  },
}));

export const ListItemDeferredCallStatus = () => {
  const classes = useStyles();

  const icon = useMemo(() => {
    return <PhoneIcon fontSize="inherit" />;
  }, []);

  return (
    <div className={classes.root}>
      <Tooltip title="Запланирован звонок" arrow>
        {icon}
      </Tooltip>
    </div>
  );
};
