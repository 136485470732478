import React, { useMemo, useCallback } from 'react';
import { FormTab } from 'react-admin';
import { useTheme } from '@material-ui/core';

import { TextInput } from '../../TextInput/TextInput';
import { FormTitle, TabbedForm } from '../../common';

import { checkRoleAdminOrSupervisorOrOdp, validates } from '../../../utils';
import { FormRow } from '../../FormRow';
import { FormCard } from '../../FormCard';
import { AutocompleteWithFormRow, ConstantSelect, List } from '../../app';
import { showResults, visitShowStatuses, showCalcStatuses } from '../../../constants';
import { PeopleSelect } from '../../RASelects';
import { DateTimePickerInput } from '../../RAInputs';
import { DynamicList, ListObjects, ListVillages } from '../../additionalLists';
import { currentProfile, currentUser } from '../../../authProvider';
import { RescheduleShowButton } from '../../buttons';
import { ListItem } from '../../app/ListItems';
import { StatusField } from '../../common/StatusFields';

export const FormShows = (props) => {
  const { id, record, location } = props;
  // const notify = useNotify();
  // const redirect = useRedirect();
  // const [mutate] = useMutation();
  // const [loading, setLoading] = useState(false);

  const defaultContract = useMemo(() => {
    return location.state?.item;
  }, [location.state]);

  const getToolbarButtons = useCallback((toolbarProps) => {
    return <RescheduleShowButton toolbarProps={toolbarProps} />;
  }, []);

  const defaultValueDate = useMemo(() => {
    return new Date().toISOString();
  }, []);

  const theme = useTheme();

  const isAdmin = checkRoleAdminOrSupervisorOrOdp(currentProfile?.role);

  // const handleSave = useCallback(
  //   (values) => {
  //     const fetch = async () => {
  //       if (values.result) {
  //         if (!values.realtyObjects && !values.realtyGroups) {
  //           notify(`Произошла ошибка загрузки файла`, 'error');
  //           return;
  //         }

  //         let realtyObjectsLength = 0;
  //         if (values.realtyObjects?.new) {
  //           const filteredObjects = values.realtyObjects?.new.filter((object) => {
  //             return object.clientStatus !== 'delete';
  //           });
  //           realtyObjectsLength = filteredObjects.length;
  //         }

  //         let realtyGroupsLength = 0;
  //         if (values.realtyGroups?.new) {
  //           const filteredGroups = values.realtyGroups?.new.filter((group) => {
  //             return group.clientStatus !== 'delete';
  //           });
  //           realtyGroupsLength = filteredGroups.length;
  //         }

  //         if (realtyObjectsLength === 0 && realtyGroupsLength === 0) {
  //           notify(`Произошла ошибка загрузки файла`, 'error');
  //           return;
  //         }
  //       }

  //       setLoading(true);
  //       if (record.id) {
  //         await mutate(
  //           {
  //             type: 'update',
  //             resource,
  //             payload: { id: record.id, data: values, previousData: record },
  //           },
  //           { returnPromise: true },
  //         );
  //         setLoading(false);
  //       } else {
  //         const result = await dataProvider.create(resource, { data: { ...values } });
  //         redirect(`/${resource}/${result.data.id}`);
  //       }
  //     };

  //     fetch();
  //   },
  //   [mutate, notify, record, redirect, resource],
  // );

  return (
    // save={handleSave} saving={loading}
    <TabbedForm {...props} getToolbarButtons={getToolbarButtons}>
      <FormTab label="Основное">
        <FormCard>
          {id && (
            <FormRow>
              <StatusField
                styleProps={{
                  backgroundColor:
                    // eslint-disable-next-line no-nested-ternary
                    record.calcStatus === 100
                      ? theme.palette.primaryNew['accentFont']
                      : record.calcStatus === 200
                      ? theme.palette.green['accentFont']
                      : theme.palette.red['accentFont'],
                  fontSize: 16,
                }}
              >
                {showCalcStatuses[record.calcStatus]}
              </StatusField>
            </FormRow>
          )}
          {/* {id && (
            <>
              <FormRow>
                <TextInput source="id" label="Id" disabled />
              </FormRow>
              <FormRow>
                <ConstantSelect
                  source="calcStatus"
                  objectChoices={showCalcStatuses}
                  label="Статус показа"
                  filledColors={showCalcStatusFilledColors}
                  disabled
                />
              </FormRow>
            </>
          )} */}
          <AutocompleteWithFormRow
            {...props}
            source="contract"
            resourceName="Contracts"
            resourceId="contractId"
            validate={validates.contract}
            componentProps={{
              label: 'Лид',
              getOptionLabel: (option) => `${option.name} / Id - ${option.id}`,
              defaultValue: defaultContract,
              providerParams: {
                filter: { ...(!isAdmin && { CurrentExpertId: String(currentUser?.id) }) },
              },
            }}
          />
          <FormRow>
            <ConstantSelect
              source="isNotFirst"
              objectChoices={visitShowStatuses}
              label="Статус визита"
              allowEmpty
              validate={validates.required}
            />
          </FormRow>
          <FormRow>
            <DateTimePickerInput
              source="date"
              label="Дата показа (план)"
              pickerType="date"
              validate={validates.required}
              defaultValue={defaultValueDate}
            />
          </FormRow>
          <FormRow>
            <DateTimePickerInput
              source="actualDate"
              label="Дата показа"
              pickerType="date"
              validate={validates.showActualDate}
            />
          </FormRow>
          <FormTitle>Результат показа</FormTitle>
          <FormRow>
            <ConstantSelect
              source="result"
              objectChoices={showResults}
              label="Как прошел показ"
              allowEmpty
            />
          </FormRow>
          <FormRow maxWidth={800} isLast>
            <TextInput
              source="description2"
              label="Комментарий результата"
              multiline
              rows={6}
              validate={validates.description2Shows}
            />
          </FormRow>
          {id && record?.contract?.mainClient && (
            <>
              <FormTitle>Клиент</FormTitle>
              <FormRow>
                <ListItem
                  type="client"
                  record={record.contract.mainClient}
                  hasButtons={false}
                  isNewItem
                />
              </FormRow>
            </>
          )}
          <PeopleSelect
            source="expert"
            resourceId="expertId"
            {...props}
            validate={validates.expertShows}
            componentProps={{
              label: 'Эксперт на показе',
              providerParams: { filter: { isExpert: true } },
              defaultValue: defaultContract?.currentExpert,
            }}
          />
          <FormRow maxWidth={800} isLast>
            <TextInput source="description1" label="Комментарий" multiline rows={6} />
          </FormRow>
          <PeopleSelect
            source="createdUser"
            resourceId="createdUserId"
            {...props}
            componentProps={{
              label: 'Создатель показа',
              providerParams: { filter: { isClient: true } },
              disabled: true,
              defaultValue: currentUser,
            }}
          />
        </FormCard>
      </FormTab>
      <FormTab label="Объекты">
        <ListObjects page="shows" isCreate={!id} validate={validates.showObjectsOrVillages} />
      </FormTab>
      <FormTab label="Обзорная экскурсия">
        <ListVillages page="shows" validate={validates.showObjectsOrVillages} />
      </FormTab>
      <FormTab label="Важные объекты">
        <DynamicList
          fetchOptions={{
            resource: 'RealtyObjects',
            filter: { IsImportant: true },
          }}
          contentElement={List}
          contentOptions={{
            type: 'object',
            hasButtons: false,
          }}
        />
      </FormTab>
    </TabbedForm>
  );
};
