import React, { useMemo, useCallback, useState, useRef } from 'react';
import { makeStyles, Chip, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { FilterItem } from './FilterItem';
import { FiltersIcon } from '../../Icons';
import { getNumberFilters } from '../../../utils';
import { customTheme } from '../../../theme';

const useStyles = makeStyles(() => ({
  root: {
    width: '360px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: 500,
    padding: '24px',
  },
  headerIcon: {
    lineHeight: '0',
    paddingRight: '12px',
    flex: '0 0 auto',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: '1 1 auto',
  },
  title: {
    position: 'relative',
    flex: '0 0 auto',
  },
  chip: {
    borderRadius: 0,
  },
  numberFIlters: {
    position: 'absolute',
    top: -10,
    right: -25,
    background: customTheme.palette.primary['A200'],
    borderRadius: '50%',
    color: 'white',
    fontSize: '12px',
    lineHeight: '12px',
    padding: '5px 8px',
  },
  clearButton: {
    flex: '0 0 auto',
    marginRight: 10,
  },
}));

export const Filters = ({
  items,
  filters,
  onSetFilters,
  multipleFilters,
  onHideDrawer,
  searchClearRef,
}) => {
  const classes = useStyles();

  const [activeFilters, setActiveFilters] = useState(filters);
  const activeFiltersRef = useRef(filters);

  const onChange = useCallback(
    (filterName, valueModifier) => {
      return (newValue) => {
        if (Array.isArray(newValue)) {
          newValue = newValue
            .map((value) => {
              return valueModifier(value);
            })
            .join(',');

          if (newValue.length === 0) {
            newValue = null;
          }
        } else if (valueModifier) {
          newValue = valueModifier(newValue);
        }

        const newFilters = { ...activeFiltersRef.current };
        if (newValue) {
          newFilters[filterName] = newValue;
        } else {
          delete newFilters[filterName];
        }
        activeFiltersRef.current = newFilters;
        setActiveFilters(newFilters);
        onSetFilters(newFilters);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onSetFilters, activeFilters],
  );

  const filterElements = useMemo(() => {
    return items.map((item) => {
      const id = item.id;
      const Component = item.component;
      const label = item.label;
      const componentProps = item.componentProps || {};
      let componentOnChange = onChange;
      let filterName;
      let children;
      let valueModifier;
      const currentFilters = activeFiltersRef.current;

      if (!item.isGroup) {
        filterName = item.filter;
        children = componentProps.children;
        valueModifier = item.valueModifier;
        componentOnChange = onChange(filterName, valueModifier);
      }

      let defaultValue;
      // eslint-disable-next-line no-shadow
      let filters = [];

      if (item.isGroup) {
        if (componentProps && 'items' in componentProps) {
          componentProps.items.forEach((nestedItem) => {
            if (nestedItem.isGroup) {
              filters = filters.concat(nestedItem.componentProps.filter);
            } else {
              filters.push(nestedItem.filter);
            }
          });
        }
      } else {
        filters.push(filterName);
      }

      if (filterName in currentFilters) {
        const filterValue = currentFilters[filterName];
        if (componentProps.multiple) {
          defaultValue = filterValue.split(',');
        } else {
          defaultValue = filterValue;
        }
      }

      return (
        <FilterItem
          label={label}
          key={id}
          currentFilters={currentFilters}
          filters={filters}
          multipleFilters={multipleFilters}
          getNumberFilters={getNumberFilters}
        >
          <Component
            {...componentProps}
            defaultValue={defaultValue}
            onChange={componentOnChange}
            currentFilters={currentFilters}
          >
            {children}
          </Component>
        </FilterItem>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChange, items, activeFilters, multipleFilters]);

  const numberActiveFilters = useMemo(() => {
    return getNumberFilters(activeFiltersRef.current, multipleFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multipleFilters, activeFilters]);

  const handleClear = useCallback(() => {
    const newFilters = {};
    activeFiltersRef.current = newFilters;
    setActiveFilters(newFilters);
    onSetFilters(newFilters);
    onHideDrawer();
    if (searchClearRef.current) {
      searchClearRef.current();
    }
  }, [onSetFilters, onHideDrawer, searchClearRef]);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <span className={classes.headerIcon}>
          <FiltersIcon width={24} height={24} />
        </span>
        <div className={classes.titleContainer}>
          <span className={classes.title}>
            Фильтры
            {numberActiveFilters !== 0 && (
              <span className={classes.numberFIlters}>{numberActiveFilters}</span>
            )}
          </span>
          {numberActiveFilters !== 0 && (
            <div className={classes.clearButton}>
              <Chip
                classes={{ root: classes.chip }}
                label="Очистить"
                onClick={handleClear}
                variant="outlined"
              />
            </div>
          )}
        </div>
        <div>
          <IconButton onClick={onHideDrawer}>
            <CloseIcon width={16} height={16} />
          </IconButton>
        </div>
      </div>
      {filterElements}
    </div>
  );
};
