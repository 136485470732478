import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  soldCell: {
    display: 'block',
    padding: '8px 12px',
    background: '#E9F5EA',
    borderRadius: '3px',
    color: '#50AE54',
    fontSize: '13px',
    textAlign: 'center',
    lineHeight: '1.6',
  },
  isSoldInactive: {
    color: '#F2443D',
    background: '#FFEBEE',
  },
}));

export const SoldField = ({ record, source }) => {
  const classes = useStyles();

  const isSold = record[source];

  return (
    <div>
      <span className={`${classes.soldCell} ${isSold && classes.isSoldInactive}`}>
        {isSold ? 'Продан' : 'Активен'}
      </span>
    </div>
  );
};
