import React, { useEffect, useState, useCallback } from 'react';
import { useDataProvider } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { getRandomRGB, mergeClasses } from '../../../../utils';
import { Chart } from '../../../common';
import { DateTimePickerInput } from '../../../RAInputs';

const defaultFilter = {};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '12px',

    [theme.breakpoints.down('1024')]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
  },
  row: {
    flex: '1 1 auto',
    boxSizing: 'border-box',
    paddingRight: '10px',

    '&:last-child': {
      paddingRight: 0,
      paddingLeft: 8,
    },

    [theme.breakpoints.down('1024')]: {
      padding: '0 0 8px 0',
      width: '100%',

      '&:last-child': {
        padding: '8px 0 0 0',
      },
    },
  },
  startAdornment: {
    paddingRight: 8,
  },
}));

export const InputsFromTo = ({ onChange }) => {
  const classes = useStyles();

  return (
    <div className={mergeClasses(classes.root)}>
      <div className={mergeClasses(classes.row)}>
        <DateTimePickerInput isRA={false} name="min" onChange={onChange} label="от" />
      </div>
      <div className={mergeClasses(classes.row)}>
        <DateTimePickerInput isRA={false} name="max" onChange={onChange} label="до" />
      </div>
    </div>
  );
};

export const LineDateChart = ({ datasetLabels = {}, resource = '', filter = defaultFilter }) => {
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);
  const [config, setConfig] = useState();

  const [minDate, setMinDate] = useState();
  const [maxDate, setMaxDate] = useState();

  const onChangeInputs = useCallback((value, name) => {
    if (name === 'max') {
      setMaxDate(value);
      return;
    }

    setMinDate(value);
  }, []);

  useEffect(() => {
    const fetch = async () => {
      try {
        const newFilter = { ...filter, DateFrom: minDate, DateTo: maxDate };
        const result = await dataProvider.getList(resource, {
          filter: newFilter,
        });

        const data = {
          datasets: [],
        };

        // if (result.data.length === 1) {
        //   data.labels = [...Object.values(datasetLabels)];
        //   const datasetItem = { fill: false, borderColor: getRandomRGB(), tension: 0.1, data: [] };
        //   Object.keys(datasetLabels).forEach((key) => {
        //     datasetItem.data.push(result.data[0][key]);
        //   });
        //   data.datasets = [{ ...datasetItem }];
        //   console.log(data);
        // } else {
        data.datasets = Object.keys(datasetLabels).map((key) => {
          return {
            label: datasetLabels[key],
            data: result.data,
            fill: false,
            borderColor: getRandomRGB(),
            tension: 0.1,
            parsing: {
              xAxisKey: 'date',
              yAxisKey: key,
            },
          };
        });
        // }

        const newConfig = {
          type: 'line', // result.data.length === 1 ? 'bar' : 'line'
          data,
          options: {
            scales: {
              xAxis: {
                type: 'time',
                time: {
                  unit: 'day',
                  displayFormats: {
                    millisecond: 'DD MM YYYY HH:mm:ss.SSS',
                    second: 'DD MM YYYY HH:mm:ss',
                    minute: 'DD MM YYYY HH:mm',
                    hour: 'DD MM YYYY HH',
                    day: 'DD MM YYYY',
                  },
                },
              },
            },
          },
        };

        setConfig(newConfig);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    setLoading(true);
    fetch();
  }, [dataProvider, resource, datasetLabels, filter, minDate, maxDate]);

  return (
    <div>
      <InputsFromTo onChange={onChangeInputs} />
      <Chart loading={loading} config={config} />
    </div>
  );
};
