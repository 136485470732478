import React from 'react';
import { DateTimeInput as RADateTimeInput } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import { mergeClasses } from '../../../utils';

const useStyles = makeStyles(() => ({
  root: {
    margin: '0',
  },
}));

const parseDateTime = (value: string) => {
  if (!value) {
    return '';
  }

  return new Date(value);
};

export const DateTimeInput = (props) => {
  const { className, InputProps } = props;
  const classes = useStyles();

  return (
    <RADateTimeInput
      helperText=""
      fullWidth
      variant="outlined"
      {...props}
      className={mergeClasses(classes.root, className)}
      InputProps={{ ...InputProps }}
      margin="none"
      parse={(value) => {
        return parseDateTime(value);
      }}
    />
  );
};
