import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import { DragIndicator as DragIndicatorIcon } from '@material-ui/icons';
import { Draggable } from 'react-beautiful-dnd';
import { ListItem } from '..';

const useStyles = makeStyles((theme) => ({
  dragIcon: {
    color: '#BDBDBD',
    fontSize: 28,
    lineHeight: 0,
    flex: '0 1 auto',

    [theme.breakpoints.down('600')]: {
      transform: 'rotate(90deg)',
    },
  },
  container: {
    marginBottom: 12,

    '&:last-child': {
      marginBottom: 0,
    },
  },
}));

const getItemStyle = (isDragging, draggableStyle) => ({
  // background: isDragging || '#fff',
  ...draggableStyle,
});

export const DnDListItem = ({ index, ...otherProps }) => {
  const { record } = otherProps;
  const classes = useStyles();

  const getDnDElement = useCallback(
    (dragHandleProps) => {
      return (
        <div className={classes.dragIcon} {...dragHandleProps}>
          <DragIndicatorIcon fontSize="inherit" />
        </div>
      );
    },
    [classes.dragIcon],
  );

  return (
    <Draggable key={record.id} draggableId={String(record.id)} index={index}>
      {(itemProvided, itemSnapshot) => (
        <div
          ref={itemProvided.innerRef}
          {...itemProvided.draggableProps}
          style={getItemStyle(itemSnapshot.isDragging, itemProvided.draggableProps.style)}
          className={classes.container}
        >
          <ListItem
            {...otherProps}
            dndElement={getDnDElement(itemProvided.dragHandleProps)}
            dragHandleProps={itemProvided.dragHandleProps}
          />
        </div>
      )}
    </Draggable>
  );
};
