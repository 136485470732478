import React, { useMemo } from 'react';

import { makeStyles } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '20px',
    display: 'flex',
    flexWrap: 'wrap',
    listStyleType: 'none',
    padding: '0',
    marginBottom: '0',
  },
  item: {
    fontSize: '12px',
    lineHeight: '14px',
    flex: '0 1 auto',
    padding: '0 8px 8px 0',
  },
  chipRoot: {
    fontSize: 'inherit',
  },
}));

export const CardChips = ({ texts = [] }) => {
  const styles = useStyles();

  const elements = useMemo(() => {
    return texts.map((text) => {
      return (
        <li className={styles.item}>
          <Chip classes={{ root: styles.chipRoot }} label={text} variant="outlined" />
        </li>
      );
    });
  }, [styles.chipRoot, styles.item, texts]);

  if (elements.length === 0) {
    return null;
  }

  return <ul className={styles.root}>{elements}</ul>;
};
