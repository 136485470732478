import React from 'react';
import { WbSunny as WbSunnyIcon, Brightness3 as Brightness3Icon } from '@material-ui/icons';
import { Switch } from '../../../common';

export const DayTimeSwitch = (props) => {
  return (
    <Switch
      {...props}
      icon={<WbSunnyIcon fontSize="inherit" />}
      checkedIcon={<Brightness3Icon fontSize="inherit" />}
      styleProps={{ isDark: true }}
      name="isNightTime"
    />
  );
};
