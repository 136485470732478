import React, { useEffect, useMemo } from 'react';
import { FormDataConsumer, FormTab } from 'react-admin';

import { TextInput } from '../../TextInput/TextInput';
import { BooleanInput, TabbedForm } from '../../common';

import { checkRoleAdminOrSupervisor, validates } from '../../../utils';
import { FormRow } from '../../FormRow';
import { AutocompleteWithFormRow, ConstantSelect, List } from '../../app';
import { publicationStatuses } from '../../../constants';
import { FormCard } from '../../FormCard';
import { PeopleSelect } from '../../RASelects';
import { StaticList } from '../../additionalLists';

export const FormBaseObjects = (props) => {
  const { id, location, record, permissions } = props;

  useEffect(() => {
    // fix for map
    window.dispatchEvent(new Event('resize'));
  }, [location]);

  const hasRoleAdminOrSupervisor = useMemo(() => {
    return checkRoleAdminOrSupervisor(permissions);
  }, [permissions]);

  const disableEdit = useMemo(() => {
    if (hasRoleAdminOrSupervisor && !record.id1c) {
      return false;
    }

    return true;
  }, [hasRoleAdminOrSupervisor, record]);

  return (
    <TabbedForm hideSave={disableEdit} {...props}>
      <FormTab label="Основное">
        <FormCard>
          {id && (
            <FormRow>
              <TextInput source="id" label="Id" disabled />
            </FormRow>
          )}
          <FormRow>
            <TextInput source="id1c" label="ID в 1С" />
          </FormRow>
          <FormRow>
            <ConstantSelect
              source="sysStatus"
              objectChoices={publicationStatuses}
              label="Статус публикации"
              defaultValue={100}
            />
          </FormRow>
          <FormRow>
            <TextInput
              source="sortOrder"
              label="Порядок сортировки"
              type="number"
              defaultValue={1000}
            />
          </FormRow>
          <FormRow>
            <TextInput source="friendlyId" label="Название" validate={validates.friendlyId} />
          </FormRow>
          <FormRow>
            <TextInput
              source="landCadastralNo"
              label="Кадастровый номер участка"
              validate={validates.landCadastralNo}
            />
          </FormRow>
          <FormRow>
            <TextInput
              source="buildingCadastralNo"
              label="Кадастровый номер постройки"
              validate={validates.landCadastralNo}
            />
          </FormRow>
          <FormRow>
            <BooleanInput label="Помечен к удалению" source="markedToDelete" />
          </FormRow>
          <AutocompleteWithFormRow
            {...props}
            source="realtyObjectType"
            resourceName="RealtyObjectTypes"
            resourceId="realtyObjectTypeId"
            componentProps={{
              label: 'Тип объекта',
            }}
          />
          <AutocompleteWithFormRow
            {...props}
            source="realtyGroup"
            resourceName="RealtyGroups"
            resourceId="realtyGroupId"
            validate={validates.objectVillage}
            componentProps={{
              label: 'Комплекс',
              fullDynamic: 'NameContains',
              getOptionLabel: (option) => `${option.name} / Id - ${option.id}`,
            }}
          />
          <FormDataConsumer>
            {({ formData }) => {
              const villageId = formData.realtyGroupId;
              if (!villageId) {
                delete formData.realtySector;
                delete formData.realtySectorId;
              }

              return (
                villageId && (
                  <AutocompleteWithFormRow
                    {...props}
                    source="realtySector"
                    resourceName="RealtySectors"
                    resourceId="realtySectorId"
                    componentProps={{
                      label: 'Сектор',
                      providerParams: { filter: { RealtyGroup: villageId } },
                    }}
                  />
                )
              );
            }}
          </FormDataConsumer>
          {/* <ExpertSelect
            source="realtyManager"
            resourceId="realtyManagerId"
            label="Эксперт риэлти"
            {...props}
          /> */}
          <PeopleSelect
            source="serviceManager"
            resourceId="serviceManagerId"
            {...props}
            componentProps={{
              label: 'Менеджер сопровождения',
              providerParams: { filter: { isExpert: true } },
            }}
          />
        </FormCard>
      </FormTab>
      {record.realtyObjects?.length > 0 && (
        <FormTab label="Объекты">
          <StaticList
            contentOptions={{
              type: 'object',
              hasButtons: false,
            }}
            items={record.realtyObjects}
            contentElement={List}
            hasPagination={true}
          />
        </FormTab>
      )}
    </TabbedForm>
  );
};
