import React from 'react';

import { FormGeoSeeds } from '../../../components/forms';
import { Create } from '../../../components/common';

export const CreateGeoSeeds = (props) => {
  return (
    <Create {...props}>
      <FormGeoSeeds {...props} redirect="list" />
    </Create>
  );
};
