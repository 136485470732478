import React, { useRef, useCallback } from 'react';

import { MenuCell } from '../../../tables';
import { DeleteMenuItem, EditMenuItem } from '../../../menuItems';

import { PresentationItems } from '../PresentationItems';

export const VillagesMenu = ({ record, resource }) => {
  const handleCloseRef = useRef();

  const handleClose = useCallback(() => {
    if (handleCloseRef.current) {
      handleCloseRef.current();
    }
  }, []);

  return (
    <MenuCell handleCloseRef={handleCloseRef}>
      <EditMenuItem resource={resource} record={record} />
      {record.sysStatus === 200 && (
        <PresentationItems type="villages" keyId="slug" onClose={handleClose} record={record} />
      )}
      <DeleteMenuItem resource={resource} record={record} handleCloseRef={handleCloseRef} />
    </MenuCell>
  );
};
