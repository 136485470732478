import React, { useRef } from 'react';

import { MenuCell } from '../../../tables';
import { EditMenuItem, MergeClientsMenuItem } from '../../../menuItems';

export const ClientsMenu = ({ record, resource }) => {
  const handleCloseRef = useRef();

  return (
    <MenuCell handleCloseRef={handleCloseRef}>
      <EditMenuItem resource={resource} record={record} />
      <MergeClientsMenuItem resource={resource} record={record} handleCloseRef={handleCloseRef} />
    </MenuCell>
  );
};
