import React from 'react';
import { FormTab } from 'react-admin';

import { TextInput } from '../../TextInput/TextInput';
import { TabbedForm, RichText } from '../../common';
import { validates } from '../../../utils';
import { FormRow } from '../../FormRow';
import { FormCard } from '../../FormCard';
import { ConstantSelect } from '../../app';
import { publicationStatuses } from '../../../constants';

const initValues = {
  listType: 'Instruction',
};

export const FormInstructions = (props) => {
  const { id } = props;

  return (
    <TabbedForm {...props} initialValues={initValues}>
      <FormTab label="Основное">
        <FormCard>
          {id && (
            <FormRow>
              <TextInput source="id" label="Id" disabled />
            </FormRow>
          )}
          <FormRow>
            <TextInput source="name" label="Название" validate={validates.name} />
          </FormRow>
          <FormRow>
            <ConstantSelect
              source="sysStatus"
              objectChoices={publicationStatuses}
              label="Статус публикации"
              defaultValue={100}
            />
          </FormRow>
          <FormRow maxWidth={800} isLast>
            <RichText source="anyText" hasImage validate={validates.instructionHtml} />
          </FormRow>
        </FormCard>
      </FormTab>
    </TabbedForm>
  );
};
