import React, { useMemo } from 'react';

import { makeStyles, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { RouterLink } from '../../components/common';
import { checkRoleAdminOrSupervisorOrOdp } from '../../utils';
import { currentUser } from '../../authProvider';
import { DynamicList } from '../../components/additionalLists';
import { List } from '../../components/app';
import { ListItem } from '../../components/app/ListItems';

export const CreateButton = ({ basePath, title }) => {
  return (
    <Button
      size="small"
      variant="contained"
      color="primary"
      component={Link}
      to={{
        pathname: `/${basePath}/create`,
      }}
    >
      {title}
    </Button>
  );
};

const useStylesDashboardProfile = makeStyles(() => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    lineHeight: '1',
    padding: '16px',
    borderBottom: '0.5px solid #e0e0e0',
  },
  header_title: {
    fontWeight: 'bold',
  },
  content: {
    padding: '16px',
  },
}));

export const DashboardProfile = () => {
  const classes = useStylesDashboardProfile();

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <span className={classes.header_title}>Мои данные</span>
      </div>
      <div className={classes.content}>
        <ListItem type="people" record={currentUser} hasButtons={false} />
      </div>
    </div>
  );
};

const useStylesDashboardList = makeStyles(() => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    lineHeight: '1',
    padding: '16px',
    borderBottom: '0.5px solid #e0e0e0',
  },
  header_title: {
    fontWeight: 'bold',
  },
  header_link: {
    '& a': {
      display: 'flex',
      alignItems: 'center',
    },
    '& svg': {
      marginLeft: '8px',
    },
  },
  footer: {
    padding: '16px',
    borderTop: '0.5px solid #e0e0e0',
  },
  content: {
    padding: '0 0 16px 0',
  },
  list: {
    position: 'relative',
    height: '450px',
    overflow: 'auto',
  },
}));

export const DashboardList = ({
  title,
  basePath = '',
  buttonTitle,
  filter = {},
  type,
  hasCreate = true,
}) => {
  const classes = useStylesDashboardList();

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <span className={classes.header_title}>{title}</span>
        <span className={classes.header_link}>
          <RouterLink to={`/${basePath}`}>
            Смотреть все <ArrowForwardIcon />
          </RouterLink>
        </span>
      </div>
      <div className={classes.content}>
        <div className={classes.list}>
          <DynamicList
            fetchOptions={{
              resource: basePath,
              filter,
            }}
            contentOptions={{
              type,
              hasButtons: false,
              isNewItem:
                type === 'client' || type === 'show' || type === 'task' || type === 'contract',
            }}
            contentElement={List}
          />
        </div>
      </div>
      <div className={classes.footer}>
        {hasCreate && <CreateButton basePath={basePath} title={buttonTitle} />}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  rows: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },

  row: {
    width: '49%',
    boxSizing: 'border-box',
    flex: '0 1 auto',
    margin: '10px 0',
    background: '#FAFAFA',
    boxShadow:
      '0px 0px 2px rgba(124, 77, 255, 0.14), 0px 2px 2px rgba(124, 77, 255, 0.12), 0px 1px 3px rgba(124, 77, 255, 0.2)',
    borderRadius: '2px',

    [theme.breakpoints.down('1280')]: {
      width: '100%',
    },
  },
}));

const filterKeysByType = {
  task: 'AssignedBoth',
  contract: 'CurrentExpertId',
  client: 'ExpertOrOdpIds',
  show: 'ExpertId',
  object: 'ManagerId',
};

const getExpertFilter = (isAdminOrOdp, type) => {
  const filter = {};

  if (type === 'object') {
    filter.IsSold = false;
    filter.Status = 200;
  }

  if (type === 'task') {
    filter.TaaskObjectTypes = 700;
    filter.IsCompleted = false;
  }

  if (type === 'contract') {
    filter.ContractStatus = '100,200,300,400,500,600,700,800,900,1000';
  }

  if (!isAdminOrOdp && filterKeysByType[type]) {
    filter[filterKeysByType[type]] = String(currentUser?.id);
  }

  return filter;
};

const dashboards = [
  {
    title: 'Задачи',
    buttonTitle: 'Создать задачу',
    basePath: 'Taasks',
    type: 'task',
    id: 1,
  },
  {
    title: 'Лиды',
    buttonTitle: 'Создать лид',
    basePath: 'Contracts',
    type: 'contract',
    id: 2,
  },
  {
    title: 'Показы',
    buttonTitle: 'Создать показ',
    basePath: 'Demos',
    type: 'show',
    id: 3,
  },
  {
    title: 'Клиенты',
    buttonTitle: 'Создать клиента',
    basePath: 'Clients',
    type: 'client',
    id: 4,
    hasCreate: false,
  },
  {
    title: 'Объекты',
    buttonTitle: 'Создать объект',
    basePath: 'RealtyObjects',
    type: 'object',
    id: 5,
  },
];

export const Dashboard = (props) => {
  const { permissions } = props;

  const classes = useStyles();

  const isAdminOrOdp = useMemo(() => {
    return checkRoleAdminOrSupervisorOrOdp(permissions);
  }, [permissions]);

  const dashboardItems = useMemo(() => {
    return dashboards.map((dashboard) => {
      return (
        <div className={classes.row}>
          <DashboardList {...dashboard} filter={getExpertFilter(isAdminOrOdp, dashboard.type)} />
        </div>
      );
    });
  }, [classes.row, isAdminOrOdp]);

  return (
    <div className={classes.root}>
      {permissions && (
        <div className={classes.rows}>
          <div className={classes.row}>
            <DashboardProfile />
          </div>
          {dashboardItems}
        </div>
      )}
    </div>
  );
};
