import React, { useCallback, useMemo, useState, useEffect } from 'react';

import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { useListContext } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { ListBase } from '../../../components/ListBase';

import { ConstantSelectField, RAList } from '../../../components/app';
import { DefaultMenu } from '../../../components/app/Menus';
import { checkRoleAdminOrSupervisorOrOdp } from '../../../utils';
import { currentUser } from '../../../authProvider';
import { contractStatuses, contractTypes } from '../../../constants';
import { ContractsSelectedMenu } from '../../../components/app/SelectedMenus';

const Cards = ({ menuItem }) => {
  return <RAList type="contract" menuItem={menuItem} isNewItem />;
};

const useHeaderStyles = makeStyles(() => ({
  filterRows: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  filterRow: {
    flex: '0 0 auto',
    width: '49%',

    '&:first-child': {
      paddingLeft: 0,
    },
  },
}));

const CustomHeaderContent = () => {
  const classes = useHeaderStyles();
  const [status, setStatus] = useState([]);
  const [type, setType] = useState('');
  const { filterValues, setFilters } = useListContext();

  useEffect(() => {
    setType(filterValues.Type || '');
    setStatus(filterValues.ContractStatus ? filterValues.ContractStatus.split(',') : []);
  }, [filterValues]);

  const handleChange = useCallback(
    (newValue, event) => {
      const name = event.target.name;
      const newFilterValues = { ...filterValues };

      if (name === 'Type') {
        setType(String(newValue));
      }
      if (name === 'ContractStatus') {
        setStatus(newValue.split(','));
      }

      newFilterValues[name] = newValue;
      if (!newValue) {
        delete newFilterValues[name];
      }
      setFilters(newFilterValues);
    },
    [filterValues, setFilters],
  );

  return (
    <>
      <div className={classes.filterRows}>
        <div className={classes.filterRow}>
          <ConstantSelectField
            allowEmpty
            items={contractTypes}
            onChange={handleChange}
            name="Type"
            value={type}
            isControlled
            label="Сценарий лида"
          />
        </div>
        <div className={classes.filterRow}>
          <ConstantSelectField
            items={contractStatuses}
            onChange={handleChange}
            name="ContractStatus"
            value={status}
            isControlled
            label="Этап воронки"
            multiple
          />
        </div>
      </div>
    </>
  );
};

export const ListContracts = (props) => {
  const { resource, permissions } = props;

  const menuItem = useCallback(
    (record) => {
      return (
        <DefaultMenu
          icon={<MoreVertIcon />}
          record={record}
          resource={resource}
          hasDelete={false}
        />
      );
    },
    [resource],
  );

  const isAdminOrOdp = useMemo(() => {
    return checkRoleAdminOrSupervisorOrOdp(permissions);
  }, [permissions]);

  const filter = useMemo(() => {
    if (!isAdminOrOdp) {
      return { CurrentExpertId: String(currentUser?.id) };
    }

    return {};
  }, [isAdminOrOdp]);

  return (
    <ListBase
      {...props}
      hasDelete={false}
      filter={filter}
      customHeaderContent={<CustomHeaderContent />}
      filterDefaultValues={{ ContractStatus: '100,200,300,400,500,600,700,800,900,1000' }}
      selectedMenuOptions={ContractsSelectedMenu}
      hasKanban
      hasExport
    >
      <Cards {...props} menuItem={menuItem} />
    </ListBase>
  );
};
