import React from 'react';

import { FormCMSPages } from '../../../../components/forms';
import { Edit } from '../../../../components/common';

export const EditMagazineMainPages = (props) => {
  return (
    <Edit {...props}>
      <FormCMSPages {...props} redirect={false} hasWidgets={false} />
    </Edit>
  );
};
