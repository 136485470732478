import React from 'react';

import { FormCMSProjects } from '../../../../components/forms';
import { Edit } from '../../../../components/common';

export const EditCMSProjects = (props) => {
  return (
    <Edit {...props}>
      <FormCMSProjects {...props} redirect={false} />
    </Edit>
  );
};
