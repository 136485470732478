import React from 'react';

import { Create } from '../../../../components/common';
import { FormCMSContacts } from '../../../../components/forms';

export const CreateCMSContacts = (props) => {
  return (
    <Create {...props}>
      <FormCMSContacts {...props} redirect="list" />
    </Create>
  );
};
