import React, { useMemo, useEffect, useReducer, useRef, useCallback } from 'react';
import { Collapse, useTheme, makeStyles } from '@material-ui/core';
import { useRefresh } from 'react-admin';
import { taskObjectTypes } from '../../../../constants';
import {
  checkExpired,
  checkRoleAdminOrSupervisor,
  getExpiredCount,
  getFormattedDateAndTime,
  getFullNameWithId,
  isObject,
  parseBoolean,
} from '../../../../utils';
import { useDebounce } from '../../../../hooks/useDebounce';
import { dataProvider } from '../../../../dataProvider';
import { ListItemHeader } from '../ItemHeader';
import { DateTimePickerInput } from '../../../RAInputs';
import { AutocompleteDynamic, Avatar, HTMLContent, TextField } from '../../../common';
import { PeopleOption } from '../../../RASelects';
import { ListItemContent } from '../ItemContent';
import { ListItemContentDatesAndPeoples } from '../ItemContentDatesAndPeoples';
import { currentProfile } from '../../../../authProvider';
// import { SwitchField } from '../../../common/SwitchField';
// import { ConfirmTaskCompletionDialogButton } from '../../../buttons/ConfirmTaskCompletion';

const reducer = (state, action) => {
  if (action.type === 'plannedDate' && !action.payload) {
    return { ...state, plannedDate: new Date().toISOString() };
  }

  return { ...state, [action.type]: action.payload };
};

const useNewStyles = makeStyles((theme) => ({
  buttons: {
    marginTop: '12px',
    borderTop: '1px dashed rgba(0, 0, 0, 0.3)',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  buttonsRow: {
    width: '49%',
    boxSizing: 'border-box',
    paddingTop: '16px',
    flex: '0 0 auto',

    [theme.breakpoints.down('1024')]: {
      width: '49%',
    },

    [theme.breakpoints.down('600')]: {
      width: '100%',
    },
  },
  inputAvatar: {
    width: '24px',
    height: '24px',
    marginRight: '2px',
    fontSize: '10px',
  },
}));

export const NewListItemTask = ({ record, hasButtons, onChangeBgColor, headerProps, onReload }) => {
  const classes = useNewStyles();
  const { id } = record;
  const { expanded } = headerProps;

  let name = id;

  if (record.plannedDate) {
    name += ` | ${getFormattedDateAndTime(record.plannedDate)}`;
  }

  if (record?.taaskType) {
    name += ` | ${record?.taaskType.name}`;
  }

  if (record.createdDate) {
    name += ` | ${getFormattedDateAndTime(record.createdDate)}`;
  }

  const link = `/Taasks/${record.id}`;

  const tags = [];

  let status = 100;
  let text = 'В работе';
  let statusColor = 'default';

  const isExpired = checkExpired(record);

  if (isExpired) {
    status = 300;
  }

  if (record.completedDate) {
    text = 'Выполнена';
    statusColor = 'green';
    status = 200;
  }

  tags.push({
    text,
    color: statusColor,
  });

  if (record.taaskObjects?.[0].taaskObjectType) {
    tags.push(taskObjectTypes[record.taaskObjects[0].taaskObjectType]);
  }

  if (isExpired) {
    let tagText = 'Просрочена';
    const count = getExpiredCount(record);
    if (count) {
      tagText = `Просрочена [${count}]`;
    }
    tags.push({ text: tagText, color: 'red' });
  }

  if (record?.taaskTags?.length > 0) {
    record?.taaskTags.forEach((taaskTag) => {
      if (taaskTag?.taaskTag?.name) {
        tags.push(taaskTag.taaskTag.name);
      }
    });
  }

  const theme = useTheme();

  const color = useMemo(() => {
    if (status === 200) {
      return theme.palette.green['background'];
    }

    if (status === 300) {
      return theme.palette.red['background'];
    }
  }, [status, theme.palette.green, theme.palette.red]);

  useEffect(() => {
    onChangeBgColor(color);
  }, [color, onChangeBgColor]);

  const defaultValueDate = useMemo(() => {
    return new Date().toISOString();
  }, []);

  const [inputValues, dispatch] = useReducer(reducer, {
    plannedDate: record?.plannedDate || defaultValueDate,
    assignedToId: record?.assignedToId,
    assignedTo: record?.assignedTo,
    description: record?.description || '',
    result: record?.result || '',
    isCompleted: record?.isCompleted || false,
  });

  const refresh = useRefresh();

  const { clearTimeoutDebounce, timeoutDebounce } = useDebounce();

  const hasChangesRef = useRef(false);

  const onChangeInputValues = useCallback((newValue, event) => {
    let resultValue = newValue;
    const fieldName = isObject(event) ? event.target.name : event;
    if (fieldName === 'isNotFirst') {
      resultValue = parseBoolean(resultValue);
    }
    if (isObject(resultValue)) {
      dispatch({ type: `${fieldName}Id`, payload: resultValue.id });
    }
    dispatch({ type: fieldName, payload: resultValue });
    hasChangesRef.current = true;
  }, []);

  const onBlurInputValues = useCallback(() => {
    if (hasChangesRef.current) {
      const fetch = async () => {
        await dataProvider.update('Taasks', {
          id: record.id,
          data: {
            ...record,
            ...inputValues,
          },
          previousData: record,
        });

        if (onReload) {
          onReload();
          return;
        }

        refresh();
      };

      timeoutDebounce(() => {
        fetch();
        hasChangesRef.current = false;
      });
    }
  }, [inputValues, onReload, record, refresh, timeoutDebounce]);

  const onFocusInputValues = useCallback(() => {
    clearTimeoutDebounce();
  }, [clearTimeoutDebounce]);

  const datesAndPeoplesItems = useMemo(() => {
    return [
      {
        type: 'people',
        key: 'assignedBy',
        title: 'Кто назначил',
        id: 1,
      },
      ...(record.completedDate
        ? [
            {
              type: 'date',
              key: 'plannedDate',
              title: 'Планируемая дата выполнения',
              id: 2,
            },
          ]
        : []),
      ...(checkRoleAdminOrSupervisor(currentProfile.role)
        ? [
            {
              type: 'date',
              key: 'completedDate',
              title: 'Дата выполнения',
              id: 3,
            },
          ]
        : []),
    ];
  }, [record.completedDate]);

  return (
    <>
      <ListItemHeader
        record={record}
        link={link}
        name={name}
        hasButtons={hasButtons}
        tags={tags}
        tagName="Задача"
        {...headerProps}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <ListItemContentDatesAndPeoples record={record} items={datesAndPeoplesItems} />
        <div className={classes.buttons}>
          {!record.completedDate && (
            <div className={classes.buttonsRow}>
              <DateTimePickerInput
                source="plannedDate"
                label="Планируемая дата выполнения"
                isRA={false}
                size="small"
                onBlur={onBlurInputValues}
                onFocus={onFocusInputValues}
                name="plannedDate"
                onChange={onChangeInputValues}
                value={inputValues.plannedDate}
                isControlled
              />
            </div>
          )}
          <div className={classes.buttonsRow}>
            <AutocompleteDynamic
              filterSelectedOptions
              resource="Peoples"
              fullDynamic="AllNames"
              label="Кому назначена"
              name="assignedTo"
              onChange={onChangeInputValues}
              size="small"
              valueProp={inputValues.assignedTo}
              onBlur={onBlurInputValues}
              onFocus={onFocusInputValues}
              getOptionLabel={(option) => (!isObject(option) ? '' : `${getFullNameWithId(option)}`)}
              renderOption={(option) => <PeopleOption option={option} />}
              startAdornment={(value) => {
                return (
                  value && <Avatar option={value} source="photo" className={classes.inputAvatar} />
                );
              }}
              providerParams={{
                filter: {
                  IsStaff: true,
                },
              }}
            />
          </div>
          {!record.completedDate && (
            <div className={classes.buttonsRow}>
              {/* <FormControlLabel
                control={
                  <SwitchField
                    name="isCompleted"
                    checked={inputValues.isCompleted}
                    onChange={onChangeInputValues}
                    onBlur={onBlurInputValues}
                    onFocus={onFocusInputValues}
                  />
                }
                label="Выполнена"
              /> */}
              {/* <ConfirmTaskCompletionDialogButton
                record={record}
                refresh={refresh}
                onReload={onReload}
              /> */}
            </div>
          )}
        </div>
        {record.description && (
          <ListItemContent>
            <HTMLContent html={record.description} />
          </ListItemContent>
        )}
        {record.completedDate && (
          <ListItemContent>
            <TextField
              multiline
              rows={4}
              label="Результат выполнения"
              size="small"
              onBlur={onBlurInputValues}
              onFocus={onFocusInputValues}
              name="result"
              onChange={onChangeInputValues}
              value={inputValues.result}
              isControlled
            />
          </ListItemContent>
        )}
      </Collapse>
    </>
  );
};
