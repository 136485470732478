import React, { useRef, useCallback, useMemo } from 'react';

import { MenuCell } from '../../../tables';
import { DeleteMenuItem, EditMenuItem } from '../../../menuItems';

import { checkRoleAdminOrSupervisor } from '../../../../utils';
import { PresentationItems } from '../PresentationItems';

export const ObjectsMenu = ({ record, resource, permissions }) => {
  const handleCloseRef = useRef();

  const handleClose = useCallback(() => {
    if (handleCloseRef.current) {
      handleCloseRef.current();
    }
  }, []);

  const hasRoleAdminOrSupervisor = useMemo(() => {
    return checkRoleAdminOrSupervisor(permissions);
  }, [permissions]);

  const showDelete = hasRoleAdminOrSupervisor;

  return (
    <MenuCell handleCloseRef={handleCloseRef}>
      <EditMenuItem resource={resource} record={record} />
      {record.sysStatus === 200 && (
        <PresentationItems type="objects" keyId="id" onClose={handleClose} record={record} />
      )}
      {showDelete && (
        <DeleteMenuItem resource={resource} record={record} handleCloseRef={handleCloseRef} />
      )}
    </MenuCell>
  );
};
