import React from 'react';

import { FormVillages } from '../../../components/forms';
import { Create } from '../../../components/common';

export const CreateVillages = (props) => {
  return (
    <Create {...props}>
      <FormVillages {...props} redirect="list" />
    </Create>
  );
};
