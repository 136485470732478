import React, { useMemo } from 'react';
import { StatusField } from '../StatusField';

export const StatusFieldIds = ({ record, additionalId }) => {
  const content = useMemo(() => {
    let defaultString = `id: ${record.id}`;
    if (additionalId && record[additionalId]) {
      defaultString = `${defaultString} | ${additionalId}: ${record[additionalId]}`;
    }

    if (record['sortOrder']) {
      defaultString = `${defaultString} | sort: ${record['sortOrder']}`;
    }

    return defaultString;
  }, [record, additionalId]);

  return <StatusField>{content}</StatusField>;
};
