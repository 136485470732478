import React, { useState, useMemo } from 'react';

export const ClientsWebsocketContext = React.createContext();

export const ClientsWebsocketProvider = ({ children, onSetClientRef }) => {
  const [clientInfo, setClientInfo] = useState();

  useMemo(() => {
    if (onSetClientRef) {
      onSetClientRef.current = setClientInfo;
    }
  }, [onSetClientRef]);

  return (
    <ClientsWebsocketContext.Provider value={clientInfo}>
      {children}
    </ClientsWebsocketContext.Provider>
  );
};
