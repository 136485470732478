import React, { useCallback, useMemo, useState, useEffect } from 'react';

import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';
import { useListContext } from 'react-admin';
import { ListBase } from '../../../components/ListBase';

import { ConstantSelectField, RAList } from '../../../components/app';
import { DefaultMenu } from '../../../components/app/Menus';
import { checkRoleAdminOrSupervisorOrOdp } from '../../../utils';
import { currentUser } from '../../../authProvider';
import { showCalcStatuses } from '../../../constants';
import { ShowsSelectedMenu } from '../../../components/app/SelectedMenus';

const Cards = ({ menuItem }) => {
  return <RAList type="show" menuItem={menuItem} isNewItem />;
};

const useHeaderStyles = makeStyles(() => ({
  filterRows: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  filterRow: {
    flex: '1 1 auto',

    '&:first-child': {
      paddingLeft: 0,
    },
  },
}));

const CustomHeaderContent = () => {
  const classes = useHeaderStyles();
  const [status, setStatus] = useState([]);
  const { filterValues, setFilters } = useListContext();

  useEffect(() => {
    setStatus(filterValues.DemoCalcStatus ? filterValues.DemoCalcStatus.split(',') : []);
  }, [filterValues]);

  const handleChange = useCallback(
    (newValue, event) => {
      const name = event.target.name;
      const newFilterValues = { ...filterValues };

      if (name === 'DemoCalcStatus') {
        setStatus(newValue.split(','));
      }

      newFilterValues[name] = newValue;
      if (!newValue) {
        delete newFilterValues[name];
      }
      setFilters(newFilterValues);
    },
    [filterValues, setFilters],
  );

  return (
    <>
      <div className={classes.filterRows}>
        <div className={classes.filterRow}>
          <ConstantSelectField
            multiple
            items={showCalcStatuses}
            onChange={handleChange}
            name="DemoCalcStatus"
            value={status}
            isControlled
            label="Статус показа"
          />
        </div>
      </div>
    </>
  );
};

export const ListShows = (props) => {
  const { resource, permissions } = props;

  const menuItem = useCallback(
    (record) => {
      return (
        <DefaultMenu
          icon={<MoreVertIcon />}
          record={record}
          resource={resource}
          hasDelete={false}
        />
      );
    },
    [resource],
  );

  const isAdminOrOdp = useMemo(() => {
    return checkRoleAdminOrSupervisorOrOdp(permissions);
  }, [permissions]);

  const filter = useMemo(() => {
    if (!isAdminOrOdp) {
      return { ExpertId: String(currentUser.id) };
    }

    return {};
  }, [isAdminOrOdp]);

  return (
    <ListBase
      {...props}
      hasDelete={false}
      filter={filter}
      customHeaderContent={<CustomHeaderContent />}
      selectedMenuOptions={ShowsSelectedMenu}
      hasExport
      hasKanban
    >
      <Cards {...props} menuItem={menuItem} />
    </ListBase>
  );
};
