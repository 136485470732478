import React, { useMemo, useRef, useCallback } from 'react';
import { FormWithRedirect } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { TextInput } from '../../TextInput/TextInput';
import { FormRow } from '../../FormRow';
import { ImageInputWithFormRow, ConstantSelect } from '../../app';
import { BooleanInput } from '../../common';
import { imageSeasons, imageTypes, publicationStatuses } from '../../../constants';
import { Dialog } from '../Dialog';

import { Button } from '../../buttons';
import { parseBoolean, validates } from '../../../utils';

const useStyles = makeStyles(() => ({
  dialogPaper: {
    maxWidth: 465,
  },
}));

export const ImageOrVideo = (props) => {
  const classes = useStyles();
  const { changeOpenRef, record = {}, onChange, isVideo, imageType, hasTemplateName } = props;

  const formRef = useRef();

  const handleSave = useCallback(
    (values) => {
      const newValues = { ...values };
      if (!newValues.id) {
        newValues.id = `client:${new Date().getTime()}`;
        newValues.clientStatus = 'create';
      } else {
        newValues.clientStatus = 'edit';
      }
      if (onChange) {
        onChange(newValues);
      }
      if (changeOpenRef.current) {
        changeOpenRef.current(false);
      }
    },
    [changeOpenRef, onChange],
  );

  const handleSubmit = useCallback(() => {
    const submit = formRef.current.handleSubmit;
    if (submit) {
      submit();
    }
  }, []);

  const buttons = useMemo(() => {
    return <Button onClick={handleSubmit}>Сохранить</Button>;
  }, [handleSubmit]);

  return (
    <Dialog
      {...props}
      buttons={buttons}
      dialogProps={{ fullWidth: true, classes: { paper: classes.dialogPaper } }}
    >
      <FormWithRedirect
        save={handleSave}
        record={record}
        render={(formProps) => {
          formRef.current = formProps;

          return (
            <>
              {!isVideo ? (
                <ImageInputWithFormRow source="path" validate={validates.objectImage} />
              ) : (
                <FormRow>
                  <TextInput source="path" label="Ссылка" validate={validates.objectVideo} />
                </FormRow>
              )}
              <FormRow>
                <TextInput source="name" label="Имя" validate={validates.name} />
              </FormRow>
              {hasTemplateName && (
                <FormRow>
                  <BooleanInput
                    label="Использовать шаблон для имени (SEO)"
                    source="anyJsonDictionary.hasTemplateName"
                    format={parseBoolean}
                  />
                </FormRow>
              )}
              <FormRow>
                <ConstantSelect
                  source="imageSeason"
                  objectChoices={imageSeasons}
                  label="Сезон"
                  allowEmpty
                />
              </FormRow>
              <FormRow>
                <ConstantSelect
                  source="imageType"
                  objectChoices={imageTypes}
                  label="Тип"
                  allowEmpty
                  defaultValue={Number(imageType) || undefined}
                />
              </FormRow>
              <FormRow>
                <ConstantSelect
                  source="sysStatus"
                  objectChoices={publicationStatuses}
                  label="Статус публикации"
                  defaultValue={200}
                />
              </FormRow>
              {/* <FormRow>
                <TextInput
                  source="sortOrder"
                  label="Порядок сортировки"
                  type="number"
                  defaultValue={1000}
                />
              </FormRow> */}
              {!isVideo && (
                <>
                  <FormRow>
                    <BooleanInput label="Ночное время" source="isNightTime" />
                  </FormRow>
                  <FormRow>
                    <BooleanInput
                      label="Показывать в презентации"
                      source="isShowInPdf"
                      defaultValue={true}
                      helperText=""
                    />
                  </FormRow>
                </>
              )}
              <FormRow>
                <TextInput source="source" label="Источник" />
              </FormRow>
              <FormRow>
                <TextInput source="author" label="Автор" />
              </FormRow>
              <FormRow>
                <TextInput
                  source="rightsNote"
                  label="Инфа по авторским правам"
                  multiline
                  rows={6}
                />
              </FormRow>
              <FormRow isLast>
                <TextInput source="description" label="Описание" multiline rows={6} />
              </FormRow>
            </>
          );
        }}
      />
    </Dialog>
  );
};
