import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    padding: '4px 8px',
    backgroundColor: (props) => props.backgroundColor || 'rgba(0, 0, 0, 0.4)',
    fontSize: (props) => props.fontSize || '10px',
    color: (props) => props.color || '#fff',
    lineHeight: '1.4',
    minHeight: '18px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: '500',
    borderRadius: '2px',
    marginRight: '8px',
    marginBottom: '8px',
  },
}));

export const StatusField = ({ children, styleProps }) => {
  const classes = useStyles(styleProps);

  return <div className={classes.root}>{children}</div>;
};
