import React from 'react';
import { MenuItem } from '@material-ui/core';

import { TextField } from '../TextField';

export const SelectField = (props) => {
  const { items = [{ id: 'test', text: 'test' }], allowEmpty } = props;

  return (
    <TextField select {...props}>
      {allowEmpty && (
        <MenuItem key="0" value="">
          &nbsp;
        </MenuItem>
      )}
      {items.map((item) => (
        <MenuItem key={item.id} value={item.id}>
          {item.text}
        </MenuItem>
      ))}
    </TextField>
  );
};
