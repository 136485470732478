import React from 'react';

import { makeStyles } from '@material-ui/core';

import { UsersVilagioIcon } from '../../Icons';
import { Avatar } from '../../common';
import { getFullName } from '../../../utils';

const useStyles = makeStyles(() => ({
  cellName: {
    fontSize: '14px',
    lineHeight: '16px',
    color: '#1E1E1E',
    display: 'flex',
    alignItems: 'center',
    fontWeight: '500',

    '& svg': {
      float: 'left',
      marginRight: '6px',
      color: '#A71930',
    },
  },
  avatar: {
    width: '40px',
    height: '40px',
    marginRight: '10px',
    fontSize: '16px',
    flex: '0 0 auto',
  },
  cellNameInfo: {
    flex: '1 1 auto',
  },
}));

export const PeopleNameField = ({ record, hasId = false }) => {
  const classes = useStyles();

  if (!record) {
    return null;
  }

  return (
    <div className={classes.cellName}>
      <Avatar option={record} source="photo" className={classes.avatar} />
      <div className={classes.cellNameInfo}>
        {record.isStaff && <UsersVilagioIcon />}
        {hasId && `${record.id}: `}
        {getFullName(record)}
      </div>
    </div>
  );
};
