import React, { useMemo, useEffect, useCallback, useState } from 'react';
import { Datagrid, useListContext } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { ListBase } from '../../../components/ListBase';

import {
  NameField,
  PeopleNameField,
  TaskDatesField,
  TextByConstantField,
} from '../../../components/RAFields';
import { DefaultMenu } from '../../../components/app/Menus';
import { ConstantSelectField, RAList } from '../../../components/app';
import { taskImportance, taskObjectTypes, taskStatusesAdmin } from '../../../constants';
import { currentUser } from '../../../authProvider';
import { checkRoleAdminOrSupervisorOrOdp } from '../../../utils';

const WrapperNameField = ({ record }) => {
  const { id, name } = record;

  return <NameField name={name} id={id} />;
};

const WrapperPeopleNameField = ({ record, source }) => {
  return <PeopleNameField record={record[source]} />;
};

const TypeObjectField = ({ record }) => {
  if (!record.taaskObjects) {
    return null;
  }

  if (!(record.taaskObjects[0].taaskObjectType in taskObjectTypes)) {
    alert('Не существует такого типа задачи!!!');
    return null;
  }

  return taskObjectTypes[record.taaskObjects[0].taaskObjectType];
};

export const TableTasks = () => {
  return (
    <Datagrid hasBulkActions>
      <WrapperNameField source="name" label="Название" sortable={false} />
      <WrapperPeopleNameField source="assignedBy" label="Кем поставлена" sortable={false} />
      <WrapperPeopleNameField source="assignedTo" label="Кому поставлена" sortable={false} />
      {/* <TextField source="description" label="Описание" sortable={false} /> */}
      <TextByConstantField
        source="taaskStatus"
        label="Статус"
        sortable={false}
        constant={taskStatusesAdmin}
      />
      <TextByConstantField
        source="importance"
        label="Важность"
        sortable={false}
        constant={taskImportance}
      />
      <TypeObjectField label="Тип объектов" sortable={false} />
      <TaskDatesField label="Даты" sortable={false} />
      <DefaultMenu />
    </Datagrid>
  );
};

const CardsTasks = () => {
  return <RAList type="task" isNewItem />;
};

const useHeaderStyles = makeStyles(() => ({
  filterRows: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  filterRow: {
    flex: '0 0 auto',
    width: '49%',

    '&:first-child': {
      paddingLeft: 0,
    },
  },
}));

const CustomHeaderContent = () => {
  const classes = useHeaderStyles();
  // const [status, setStatus] = useState([]);
  const [type, setType] = useState([]);
  const { filterValues, setFilters } = useListContext();

  useEffect(() => {
    setType(filterValues.TaaskObjectTypes ? filterValues.TaaskObjectTypes.split(',') : []);
    // setStatus(filterValues.TaaskStatuses ? filterValues.TaaskStatuses.split(',') : []);
  }, [filterValues]);

  const handleChange = useCallback(
    (newValue, event) => {
      const name = event.target.name;
      const newFilterValues = { ...filterValues };

      if (name === 'TaaskObjectTypes') {
        setType(newValue.split(','));
      }
      // if (name === 'TaaskStatuses') {
      //   setStatus(newValue.split(','));
      // }

      newFilterValues[name] = newValue;
      if (!newValue) {
        delete newFilterValues[name];
      }
      setFilters(newFilterValues);
    },
    [filterValues, setFilters],
  );

  return (
    <>
      <div className={classes.filterRows}>
        {/* <div className={classes.filterRow}>
          <ConstantSelectField
            multiple
            items={taskStatusesAdmin}
            onChange={handleChange}
            name="TaaskStatuses"
            value={status}
            isControlled
            label="Статус"
          />
        </div> */}
        {/* <div className={classes.filterRow}> */}
        <ConstantSelectField
          multiple
          items={taskObjectTypes}
          onChange={handleChange}
          name="TaaskObjectTypes"
          value={type}
          isControlled
          label="Тип объекта"
        />
        {/* </div> */}
      </div>
    </>
  );
};

export const ListTasks = (props) => {
  const { permissions } = props;

  const isAdminOrOdp = useMemo(() => {
    return checkRoleAdminOrSupervisorOrOdp(permissions);
  }, [permissions]);

  const filter = useMemo(() => {
    if (!isAdminOrOdp) {
      return { AssignedBoth: String(currentUser?.id) };
    }

    return {};
  }, [isAdminOrOdp]);

  const defaultFilter = useMemo(() => {
    return {
      IsCompleted: false,
      // TaaskObjectTypes: '700',
      ...(isAdminOrOdp && { AssignedBoth: String(currentUser?.id) }),
    };
  }, [isAdminOrOdp]);

  return (
    <ListBase
      {...props}
      hasToggleView
      tableRender={<TableTasks {...props} />}
      cardRender={<CardsTasks {...props} />}
      customHeaderContent={<CustomHeaderContent />}
      filterDefaultValues={defaultFilter}
      filter={filter}
      hasKanban
      hasExport
    />
  );
};
