import React, { useMemo } from 'react';
import { SelectInput } from '../../common';
import { convertObjectChoicesToArray } from '../../../utils';
import { sysStatusFilledColors } from '../../../constants';

const colors = {
  sysStatus: sysStatusFilledColors,
};

export const ConstantSelect = (props) => {
  const { objectChoices, source } = props;

  const choices = useMemo(() => {
    return convertObjectChoicesToArray(objectChoices);
  }, [objectChoices]);

  const filledColors = useMemo(() => {
    if (source in colors) {
      return colors[source];
    }
  }, [source]);

  return (
    <SelectInput
      filledColors={filledColors}
      {...props}
      choices={choices}
      optionText="text"
      optionValue="id"
    />
  );
};
