import React, { useMemo } from 'react';
import { useListContext } from 'react-admin';
import { List } from '../List';

export const RAList = (props) => {
  const { ids, data, resource, onToggleItem, selectedIds } = useListContext();

  const items = useMemo(() => {
    return ids.map((id) => {
      return data[id];
    });
  }, [data, ids]);

  return (
    <List
      resource={resource}
      onToggleItem={onToggleItem}
      selectedIds={selectedIds}
      items={items}
      {...props}
    />
  );
};
