import React from 'react';

import { Create } from '../../../../components/common';
import { FormCMSWidgets } from '../../../../components/forms';

export const CreateCMSWidgets = (props) => {
  return (
    <Create {...props}>
      <FormCMSWidgets {...props} redirect="list" />
    </Create>
  );
};
