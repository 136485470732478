import React, { useEffect, useCallback } from 'react';
import {
  MenuList,
  Popper,
  Paper,
  Grow,
  ClickAwayListener,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { customTheme } from '../../../../theme';

const useStyles = makeStyles(() => ({
  menuListWrapper: {
    zIndex: 10,
  },
}));

export const MenuCell = ({ children, handleCloseRef, icon }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = (event) => {
    anchorRef.current = event.currentTarget;
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = useCallback((event) => {
    if (anchorRef.current && event && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  }, []);

  const handleListKeyDown = useCallback((event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }, []);

  useEffect(() => {
    if (handleCloseRef) {
      handleCloseRef.current = handleClose;
    }
  }, [handleClose, handleCloseRef]);

  return (
    <div>
      <IconButton
        size="medium"
        color={icon ? customTheme.palette.grey.fadedFont : 'primary'}
        aria-label="add an alarm"
        onClick={handleToggle}
        aria-describedby="test"
      >
        {icon || <MoreHorizIcon />}
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom"
        className={classes.menuListWrapper}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="MenuListSets" onKeyDown={handleListKeyDown}>
                  {children}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};
