import React, { useMemo } from 'react';
import { NumberInput as RANumberInput, useInput } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import { mergeClasses } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0',
  },
  filled: {
    '& > div:not(.Mui-disabled)': {
      backgroundColor: theme.palette.primaryNew.light,
    },
    '& > div:hover:not(.Mui-disabled):not(.Mui-error)': {
      backgroundColor: theme.palette.primaryNew.light100,
    },
  },
}));

export const NumberInput = (props) => {
  const { className, InputProps, source } = props;
  const classes = useStyles();

  const {
    input: { value },
  } = useInput({
    source,
  });

  const isFilled = useMemo(() => {
    return Boolean(value);
  }, [value]);

  return (
    <RANumberInput
      helperText=""
      fullWidth
      variant="filled"
      {...props}
      className={mergeClasses(classes.root, isFilled && classes.filled, className)}
      InputProps={{ ...InputProps, classes: { input: mergeClasses(classes.input) } }}
      margin="none"
    />
  );
};
