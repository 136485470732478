import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
}));

export const StatusFields = ({ fields }) => {
  const classes = useStyles();

  if (fields.length === 0) {
    return null;
  }

  return <div className={classes.root}>{fields}</div>;
};
