import React from 'react';
import { Datagrid, TextField, DateField, FunctionField } from 'react-admin';

import { ListBase } from '../../../components/ListBase';
import { changeHistoryActions, changeHistoryTypes } from '../../../constants';

export const TableChangeHistory = () => {
  return (
    <Datagrid>
      <TextField label="ID" source="id" sortable={false} />
      <TextField label="ID объекта" source="objectId" sortable={false} />
      <FunctionField
        label="Тип объекта"
        render={(record) =>
          `${
            changeHistoryTypes[record.objectType]
              ? changeHistoryTypes[record.objectType]
              : 'Ошибка, Необходимо добавить новый тип!'
          }`
        }
      />
      <FunctionField
        label="Действие"
        render={(record) =>
          `${
            changeHistoryActions[record.action]
              ? changeHistoryActions[record.action]
              : 'Ошибка, Необходимо добавить новое действие!'
          }`
        }
      />
      <DateField
        source="changeDate"
        label="Когда"
        sortable={false}
        locales="ru"
        options={{
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
        }}
      />
      <TextField label="Кто" source="changedBy" sortable={false} />
      <TextField label="Детали" source="details" sortable={false} />
    </Datagrid>
  );
};

export const ListChangeHistory = (props) => {
  return (
    <ListBase {...props} hasCheckbox={false}>
      <TableChangeHistory />
    </ListBase>
  );
};
