import React from 'react';

import { DefaultMenu } from '../../Menus';
import { Card } from '../..';

export const InfrastructureCard = (props) => {
  const { data: item, statusFields: statusFieldsProp, resource } = props;

  const statusFields = [...statusFieldsProp];

  const fieldNameProps = {
    name: item.name,
  };

  const imagePath = item.images?.[0]?.path;

  const menuElement = <DefaultMenu record={item} resource={resource} />;

  const chipTexts = [];

  if (item.infrastructureType) {
    chipTexts.push(item.infrastructureType.name);
  }

  return (
    <Card
      {...props}
      fieldNameProps={fieldNameProps}
      menuElement={menuElement}
      statusFields={statusFields}
      chipTexts={chipTexts}
      imagePath={imagePath}
    />
  );
};
