import React from 'react';

import { LineDateChart } from '../LineDate';

const datasetLabels = {
  priceInRubs: 'Цена продажи',
  rentPriceInRubs: 'Цена аренды',
};

export const ObjectPriceChart = ({ record }) => {
  return (
    <LineDateChart
      datasetLabels={datasetLabels}
      resource={`realtyObjects/${record.id}/priceHistory`}
    />
  );
};
