// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/jsx-no-target-blank */
import React, { useMemo } from 'react';
import { getFeedLinks } from '../../../utils';

export const FeedLinksField = ({ record }) => {
  const platforms = useMemo(() => {
    if (!record.feedTargets || record.feedTargets.length === 0 || record.sysStatus !== 200) {
      return;
    }

    return getFeedLinks(record);
  }, [record]);

  const linkElements = useMemo(() => {
    if (!platforms) {
      return null;
    }

    return platforms.map((platform) => {
      return (
        <div>
          <a href={platform} target="_blank">
            {platform}
          </a>
        </div>
      );
    });
  }, [platforms]);

  return linkElements;
};
