import React from 'react';
import { MenuItem, Select, FormControl, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import MUIPagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles(() =>
  createStyles({
    pagination: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
    paginationButtons: {
      padding: '4px 0',
    },
    paginationStatus: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '30px',
      padding: '4px 0',
    },
    paginationStatusTitle: {
      marginRight: '12px',
    },
    '@media (max-width: 960px)': {
      pagination: {
        flexDirection: 'column',
        alignItems: 'center',
        paddingRight: '12px',
        paddingLeft: '12px',
      },
      paginationStatus: {
        marginLeft: 0,
      },
    },
  }),
);

export const Pagination = ({ page, total, perPage, setPage, setPerPage }) => {
  const classes = useStyles();

  return (
    <div className={classes.pagination}>
      <div className={classes.paginationButtons}>
        <MUIPagination
          page={page}
          count={Math.ceil(total / perPage)}
          onChange={(_, newPage) => {
            setPage(newPage);
          }}
          variant="outlined"
          shape="rounded"
          color="primary"
        />
      </div>
      <div className={classes.paginationStatus}>
        <Typography variant="body2" component="h2" className={classes.paginationStatusTitle}>
          На странице:
        </Typography>
        <FormControl className={classes.paginationPerPage}>
          <Select
            value={perPage}
            onChange={(e) => {
              setPerPage(e.target.value);
            }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
            <MenuItem value={200}>200</MenuItem>
            <MenuItem value={500}>500</MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  );
};
