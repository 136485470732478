import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';

import { makeStyles, useTheme } from '@material-ui/core';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useNotify } from 'react-admin';
import { DnDListItem } from '../../components/app/ListItems';
import { dataProvider } from '../../dataProvider';
import { contractStatusesForBuy, contractStatusesForSell, contractTypes } from '../../constants';
import { ConstantSelectField } from '../../components/app';
import { Preloader } from '../../components';
import { CloseContractKanbantDialog } from '../../components/dialogs/CloseContractKanban';
import { currentProfile, currentUser } from '../../authProvider';
import { checkRoleAdminOrSupervisorOrOdp, dayMilliseconds } from '../../utils';
import { convertToMillions } from '../../components/app/ListItems/Contract';

// const CreateButton = ({ basePath, title }) => {
//   return (
//     <Button
//       size="small"
//       variant="contained"
//       color="primary"
//       component={Link}
//       to={{
//         pathname: `/${basePath}/create`,
//       }}
//     >
//       {title}
//     </Button>
//   );
// };

// const useStylesKanbanList = makeStyles(() => ({
//   header: {
//     lineHeight: '1',
//     padding: '16px',
//     borderBottom: '0.5px solid #e0e0e0',
//     flex: '0 0 auto',
//   },
//   header_title: {
//     fontWeight: 'bold',
//   },
//   content: {
//     flex: '1 1 auto',
//   },
//   list: {
//     position: 'relative',
//     height: '100%',
//     overflow: 'auto',
//   },
//   root: {
//     display: 'flex',
//     flexDirection: 'column',
//     height: '100%',
//   },
// }));

// const KanbanList = ({ title, basePath = '', filter = {}, defaultFilter = {}, type }) => {
//   const classes = useStylesKanbanList();

//   return (
//     <div className={classes.root}>
//       <div className={classes.header}>
//         <span className={classes.header_title}>{title}</span>
//       </div>
//       <div className={classes.content}>
//         <div className={classes.list}>
//           <DynamicList
//             fetchOptions={{
//               resource: basePath,
//               filter: { ...filter, ...defaultFilter },
//             }}
//             contentOptions={{
//               type,
//               hasButtons: false,
//               isNewItem:
//                 type === 'client' || type === 'show' || type === 'task' || type === 'contract',
//             }}
//             contentElement={List}
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

const useStyles = makeStyles((theme) => ({
  content: {
    flex: '1 1 0',
    minHeight: '450px',
    marginTop: '20px',
    minWidth: '100%',
  },

  columns: {
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between',
    overflow: 'auto',
  },

  column: {
    width: 'calc(20% - 20px)',
    boxSizing: 'border-box',
    flex: '0 0 auto',
    marginLeft: '20px',

    '&:first-child': {
      marginLeft: 0,
    },

    [theme.breakpoints.down('1900')]: {
      width: 'calc(25% - 10px)',
    },

    [theme.breakpoints.down('1280')]: {
      width: 'calc(33.333% - 10px)',
    },

    [theme.breakpoints.down('900')]: {
      width: 'calc(50% - 10px)',
    },

    [theme.breakpoints.down('500')]: {
      width: '80%',
    },
  },

  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingBottom: '12px',
    boxSizing: 'border-box',
    position: 'relative',
  },

  listContainer: {
    overflow: 'auto',
    height: '100%',
    'scrollbar-width': 'none',
    '-ms-overflow-style': 'none',

    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },

  list: {
    width: '100%',
    height: 'calc(100% - 54px)',
    minHeight: '18px',
    minWidth: '100%',
  },

  title: {
    minHeight: '44px',
    marginBottom: '10px',
    textAlign: 'center',
  },
}));

const getListStyle = (isDraggingOver, background) => ({
  background: isDraggingOver ? background : '#F5F5F5',
});

const listItemsInitialState = {};

const getItems = (items) => {
  const newItems = {};

  items.forEach((item) => {
    const key = item.status;
    if (key in newItems) {
      newItems[key].push(item);
    } else {
      newItems[key] = [item];
    }
  });

  return newItems;
};

function listItemsReducer(state, action) {
  switch (action.type) {
    case 'addItems': {
      return action.payload;
    }
    case 'reset': {
      return {};
    }
    case 'changeLists': {
      const { id, fromId, toId } = action.payload;

      const fromList = state[fromId];
      let newItem;
      const fromNewItems = fromList.filter((listItem) => {
        if (listItem.id === Number(id)) {
          newItem = { ...listItem, status: toId };
        }
        return listItem.id !== Number(id);
      });
      const toList = state[toId] || [];
      const toNewItems = [newItem, ...toList];
      return { ...state, [fromId]: fromNewItems, [toId]: toNewItems };
    }
    case 'updateItem': {
      const { id, listId, updatedItem } = action.payload;

      const newList = state[listId].map((item) => {
        let newItem = { ...item };
        if (Number(id) === item.id) {
          newItem = { ...item, ...updatedItem };
        }
        return newItem;
      });

      return { ...state, [listId]: newList };
    }
    default:
      throw new Error();
  }
}

const RESOURCE = 'Contracts';

export const ContractsKanbanPage = () => {
  const [contractType, setContractType] = useState('100');
  const [state, dispatch] = useReducer(listItemsReducer, listItemsInitialState);
  const [loading, setLoading] = useState(true);
  const notify = useNotify();
  const changeOpenRef = useRef();
  const [modalData, setModalData] = useState({});

  const isAdminOrOdp = useMemo(() => {
    return checkRoleAdminOrSupervisorOrOdp(currentProfile?.role);
  }, []);

  const filter = useMemo(() => {
    if (!isAdminOrOdp) {
      return { CurrentExpertId: String(currentUser?.id), forKaban: true };
    }

    return { forKaban: true };
  }, [isAdminOrOdp]);

  const handleOpenDialog = useCallback((props) => {
    setModalData({ dispatch, ...props });
    if (changeOpenRef.current) {
      changeOpenRef.current(true);
    }
  }, []);

  const classes = useStyles();
  const theme = useTheme();

  const handleDragEnd = useCallback(
    (result) => {
      if (!result.destination) {
        return;
      }

      const fromId = result.source.droppableId;
      const toId = result.destination.droppableId;
      const id = result.draggableId;

      if (fromId === toId) {
        return;
      }

      const foundItem = state[fromId].find((item) => {
        return item.id === Number(id);
      });

      if (toId === '10000') {
        handleOpenDialog({ id, fromId, toId, foundItem });
        return;
      }

      dispatch({
        type: 'changeLists',
        payload: { id, fromId, toId },
      });

      const fetch = async () => {
        setLoading(true);
        try {
          const updateResult = await dataProvider.update(RESOURCE, {
            id,
            data: {
              ...foundItem,
              status: Number(toId),
            },
            previousData: foundItem,
          });
          dispatch({
            type: 'updateItem',
            payload: { id, listId: toId, updatedItem: updateResult.data },
          });
        } catch (error) {
          notify(`Error: ${error.message}`, 'error');
          dispatch({
            type: 'changeLists',
            payload: { id, fromId: toId, toId: fromId },
          });
        } finally {
          setLoading(false);
        }
      };

      fetch();
    },
    [handleOpenDialog, notify, state],
  );

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const result = await dataProvider.getList(RESOURCE, {
          pagination: { page: 1, perPage: 100 },
          filter: { type: contractType, ...filter },
        });
        dispatch({ type: 'reset' });
        const newItems = getItems(result.data);
        dispatch({ type: 'addItems', payload: newItems });
      } finally {
        setLoading(false);
      }
    };

    fetch();
  }, [contractType, filter]);

  const handleChangeContractType = useCallback((newValue) => {
    setContractType(newValue);
  }, []);

  const listsItems = useMemo(() => {
    const isBuyAndRent = Number(contractType) === 100 || Number(contractType) === 300;
    let types = contractStatusesForSell;
    if (isBuyAndRent) {
      types = contractStatusesForBuy;
    }

    return Object.keys(types).map((key) => {
      return {
        id: key,
        name: types[key],
      };
    });
  }, [contractType]);

  const listElements = useMemo(() => {
    return listsItems.map((column) => {
      let length = 0;
      let sumPrice = 0;
      let sumTimestamp = 0;

      if (Number(column.id) in state) {
        length = state[Number(column.id)].length;

        const sums = state[Number(column.id)].reduce(
          (sum, currentItem) => {
            let newPrice = sum.price;
            let newTimestamp = sum.timestamp;
            if (currentItem.budget) {
              newPrice = sum.price + currentItem.budget;
            }

            const createdTimestamp = new Date(currentItem.createdDate).getTime();
            if (currentItem.closedDate) {
              newTimestamp += new Date(currentItem.closedDate).getTime() - createdTimestamp;
            }

            newTimestamp += new Date().getTime() - createdTimestamp;

            return { price: newPrice, timestamp: newTimestamp };
          },
          { price: 0, timestamp: 0 },
        );

        sumPrice = sums.price;
        sumTimestamp = sums.timestamp;
      }

      return (
        <div className={classes.column} key={column.id}>
          <div className={classes.title}>
            {`${column.name}`}
            <br />
            {`${length} лидов · ${length ? convertToMillions(sumPrice / length) : 0} млн. · ${
              length ? (sumTimestamp / dayMilliseconds / length).toFixed() : 0
            } дн.`}
          </div>
          <Droppable droppableId={`${column.id}`}>
            {(provided, snapshot) => {
              let elements = [];

              if (Number(column.id) in state) {
                elements = state[Number(column.id)].map((item, index) => {
                  return (
                    <DnDListItem
                      record={item}
                      index={index}
                      key={item.id}
                      type="contract"
                      isNewItem
                      hasButtons
                      hasDelete={false}
                      hasCheckbox={false}
                      resource={RESOURCE}
                      hasCollapse={false}
                      hasDragItem={false}
                    />
                  );
                });
              }

              return (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={classes.list}
                  style={getListStyle(snapshot.isDraggingOver, theme.palette.primaryNew['0.24'])}
                >
                  <div className={classes.listContainer}>
                    {elements}
                    {provided.placeholder}
                  </div>
                </div>
              );
            }}
          </Droppable>
        </div>
      );
    });
  }, [
    classes.column,
    classes.list,
    classes.listContainer,
    classes.title,
    listsItems,
    state,
    theme.palette.primaryNew,
  ]);

  return (
    <div className={classes.root}>
      <Preloader loading={loading} />
      <ConstantSelectField
        items={contractTypes}
        defaultValue={contractType}
        onChange={handleChangeContractType}
      />
      <div className={classes.content}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <div className={classes.columns}>{listElements}</div>
        </DragDropContext>
      </div>
      <CloseContractKanbantDialog changeOpenRef={changeOpenRef} data={modalData} />
    </div>
  );
};
