import React, { useCallback, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { Checkbox } from '../../../common/Checkbox';
import { ListItemImage, ListItemObject } from '..';
import { NewListItemTouch } from '../Touch';
import { mergeClasses } from '../../../../utils';
import { DefaultMenu } from '../../Menus';
import { NewListItemContract } from '../Contract';
import { NewListItemShow } from '../Show';
import { NewListItemClient } from '../Client';
import { ListItemCMSContacts } from '../CMSContacts';
import { ListItemDefault } from '../Default';
import { ListItemPromo } from '../Promo';
import { ListItemFeed } from '../Feed';
import { ListItemPeople } from '../People';
import { ListItemSet } from '../Set';
import { ListItemInfrastructure } from '../Infrastructure';
import { ListItemSector } from '../Sector';
import { ListItemVillage } from '../Village';
import { ListItemDiscount } from '../Discount';
import { NewListItemTask } from '../Task';
import { ListItemCMSWidget } from '../CMSWidget';
import { ListItemCMSPage } from '../CMSPage';
import { Comments } from '../../Comments';
import { ListItemHistory } from '../History';

const useStyles = makeStyles((theme) => ({
  container: {
    boxShadow:
      '0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.16)',
    borderRadius: '2px',
    background: '#fff',
    padding: '4px 0',
  },
  container__nodnd: {
    marginBottom: 12,

    '&:last-child': {
      marginBottom: 0,
    },
  },
  root: {
    position: 'relative',
    padding: '0 53px 0 78px',

    [theme.breakpoints.down('600')]: {
      padding: 0,
    },
  },
  root__nodnd: {
    padding: '0 53px 0 50px',

    [theme.breakpoints.down('600')]: {
      padding: 0,
    },
  },
  root__nobuttons: {
    padding: '0 16px',
  },
  dragIcon: {
    color: '#BDBDBD',
    fontSize: 28,
    lineHeight: 0,
    flex: '0 1 auto',

    [theme.breakpoints.down('600')]: {
      transform: 'rotate(90deg)',
    },
  },
  checkboxContainer: {
    flex: '0 1 auto',
  },
  menuContainer: {
    color: '#828282',
    fontSize: 24,
    lineHeight: 0,
    height: '48px',
    marginTop: '-24px',
    position: 'absolute',
    right: 0,
    top: '50%',

    [theme.breakpoints.down('600')]: {
      top: 'calc(50% - 24px)',
    },
  },
  menuContainer__bottom: {
    [theme.breakpoints.down('600')]: {
      top: 'auto',
      bottom: 0,
    },
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: '1 1 auto',
    boxSizing: 'border-box',
    minHeight: '72px',

    [theme.breakpoints.down('1024')]: {
      flexWrap: 'wrap',
    },

    // [theme.breakpoints.down('600')]: {
    //   justifyContent: 'center',
    // },
  },
  leftSidebar: {
    height: '42px',
    marginTop: '-21px',
    position: 'absolute',
    left: 0,
    top: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down('600')]: {
      top: 'calc(50% - 24px)',
      height: 'auto',
      marginTop: '-35px',
      flexDirection: 'column',
      left: 5,
    },
  },
  leftSidebar__notdnd: {
    [theme.breakpoints.down('600')]: {
      marginTop: '-21px',
    },
  },
}));

const useStylesNew = makeStyles((theme) => ({
  container: {
    borderRadius: '12px',
    background: (props) => props.bgColor || theme.palette.grey.light,
    padding: '16px',
    display: 'flex',
    marginBottom: 12,
    border: `1px solid rgba(0, 0, 0, 0.3)`,

    '&:last-child': {
      marginBottom: 0,
    },
  },
  dndContainer: {
    flex: '0 0 auto',
    padding: '0 12px 0 0',
  },
  content: {
    flex: '1 1 auto',
  },
}));

const listElements = {
  video: ListItemImage,
  image: ListItemImage,
  touch: NewListItemTouch,
  client: NewListItemClient,
  object: ListItemObject,
  cmsContacts: ListItemCMSContacts,
  default: ListItemDefault,
  promo: ListItemPromo,
  feed: ListItemFeed,
  people: ListItemPeople,
  set: ListItemSet,
  infrastructure: ListItemInfrastructure,
  sector: ListItemSector,
  village: ListItemVillage,
  discount: ListItemDiscount,
  task: NewListItemTask,
  contract: NewListItemContract,
  show: NewListItemShow,
  cmsWidget: ListItemCMSWidget,
  cmsPage: ListItemCMSPage,
  comment: Comments,
  contractHistory: ListItemHistory,
};

export const ListItem = (props) => {
  const {
    record,
    menuItem,
    onToggleItem,
    selected,
    type = 'default',
    dndElement = null,
    resource,
    hasButtons,
    depthKey,
    hasDelete,
    isNewItem = false,
    dragHandleProps,
    hasDragItem = true,
    expanded: expandedProp = false,
  } = props;
  const classes = useStyles();

  const [bgColor, setBgColor] = useState();

  const classesNew = useStylesNew({ bgColor });

  const [expanded, setExpanded] = useState(expandedProp);

  const handleExpandClick = useCallback(() => {
    setExpanded((oldProp) => !oldProp);
  }, []);

  const handleToggleCheckbox = useCallback(() => {
    if (onToggleItem) {
      onToggleItem(record.id);
    }
  }, [onToggleItem, record.id]);

  const Element = useMemo(() => {
    let currentType = type;
    if (!(type in listElements)) {
      alert('Не существующий тип элемента списка!!!');
      currentType = 'default';
    }

    return listElements[currentType];
  }, [type]);

  const currentRecord = useMemo(() => {
    if (depthKey) {
      return record[depthKey];
    }

    return record;
  }, [depthKey, record]);

  const menuElement = useMemo(() => {
    if (typeof menuItem === 'function') {
      return menuItem(record, resource, currentRecord);
    }

    return (
      <DefaultMenu
        icon={<MoreVertIcon />}
        record={record}
        resource={resource}
        hasDelete={hasDelete}
      />
    );
  }, [currentRecord, hasDelete, menuItem, record, resource]);

  const checkbox = useMemo(() => {
    return <Checkbox checked={selected} onChange={handleToggleCheckbox} />;
  }, [handleToggleCheckbox, selected]);

  const headerProps = useMemo(() => {
    return {
      expanded,
      onExpandClick: handleExpandClick,
      checkbox,
      menuElement,
    };
  }, [checkbox, expanded, handleExpandClick, menuElement]);

  if (type === 'comment') {
    const comments = [currentRecord];
    return <Comments items={comments} isCard />;
  }

  if (isNewItem) {
    return (
      <div className={classesNew.container} {...(hasDragItem ? {} : dragHandleProps)}>
        {dndElement && hasDragItem && hasButtons && (
          <div className={classesNew.dndContainer}>{dndElement}</div>
        )}
        <div className={classesNew.content}>
          <Element
            {...props}
            record={currentRecord}
            originalRecord={record}
            onChangeBgColor={setBgColor}
            headerProps={headerProps}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={mergeClasses(classes.container, !dndElement && classes.container__nodnd)}>
      <div
        className={mergeClasses(
          classes.root,
          !dndElement && classes.root__nodnd,
          !hasButtons && classes.root__nobuttons,
        )}
      >
        {hasButtons && (
          <div
            className={mergeClasses(
              classes.leftSidebar,
              !dndElement && classes.leftSidebar__notdnd,
            )}
          >
            {dndElement}
            <div className={classes.checkboxContainer}>{checkbox}</div>
          </div>
        )}
        <div className={classes.content}>
          <Element
            {...props}
            record={currentRecord}
            originalRecord={record}
            onChangeBgColor={setBgColor}
          />
        </div>
        {hasButtons && (
          <div
            className={mergeClasses(
              classes.menuContainer,
              type === 'object' && classes.menuContainer__bottom,
            )}
          >
            {menuElement}
          </div>
        )}
      </div>
    </div>
  );
};
