import React, { useMemo, useRef, useCallback, useState } from 'react';
import { FormWithRedirect, useRefresh } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { Dialog } from '../Dialog';

import { Button } from '../../buttons';
import { dataProvider } from '../../../dataProvider';
import { validates } from '../../../utils';
import { RichText } from '../../common';

const useStyles = makeStyles(() => ({
  dialogPaper: {
    maxWidth: 600,
  },
}));

export const EditCommentDialog = (props) => {
  const classes = useStyles();
  const { changeOpenRef, data = {}, onReload } = props;

  const refresh = useRefresh();
  const formRef = useRef();

  const [loading, setLoading] = useState(false);

  const handleSave = useCallback(
    (values) => {
      const fetch = async () => {
        setLoading(true);
        const newData = { ...data, ...values };

        try {
          await dataProvider.editComment(newData);

          if (changeOpenRef.current) {
            changeOpenRef.current(false);
          }

          if (onReload) {
            onReload();
            return;
          }

          refresh();
        } finally {
          setLoading(false);
        }
      };

      fetch();
    },
    [changeOpenRef, data, onReload, refresh],
  );

  const handleSubmit = useCallback(() => {
    const submit = formRef.current.handleSubmit;
    if (submit) {
      submit();
    }
  }, []);

  const buttons = useMemo(() => {
    return (
      <Button onClick={handleSubmit} loading={loading}>
        Сохранить
      </Button>
    );
  }, [handleSubmit, loading]);

  return (
    <Dialog
      {...props}
      buttons={buttons}
      dialogProps={{ fullWidth: true, classes: { paper: classes.dialogPaper } }}
      title="Редактирование комментария"
    >
      <FormWithRedirect
        save={handleSave}
        record={data}
        render={(formProps) => {
          formRef.current = formProps;

          return (
            <>
              <RichText label="Комментарий" source="text" validate={validates.required} hasImage />
            </>
          );
        }}
      />
    </Dialog>
  );
};
