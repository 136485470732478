import React from 'react';
import { FormTab } from 'react-admin';

import { TextInput } from '../../TextInput/TextInput';
import { FormTitle, TabbedForm, RichText, BooleanInput } from '../../common';

import { parseBoolean, validates } from '../../../utils';
import { FormRow } from '../../FormRow';
import { ConstantSelect, ImageInputWithFormRow } from '../../app';
import { publicationStatuses } from '../../../constants';
import { SeoTab } from '../../formTabs';
import { DateTimePickerInput } from '../../RAInputs';
import { ListObjects, ListVillages } from '../../additionalLists';
import { FormCard } from '../../FormCard';

export const FormPromos = (props) => {
  const { id } = props;

  return (
    <TabbedForm {...props}>
      <FormTab label="Основное">
        <FormCard>
          {id && (
            <FormRow>
              <TextInput source="id" label="Id" disabled />
            </FormRow>
          )}
          <FormRow>
            <TextInput source="slug" label="Слаг" validate={validates.slug} />
          </FormRow>
          <FormRow>
            <TextInput source="name" label="Название" validate={validates.name} />
          </FormRow>
          <FormRow>
            <ConstantSelect
              source="sysStatus"
              objectChoices={publicationStatuses}
              label="Статус публикации"
              defaultValue={100}
            />
          </FormRow>
          <FormRow>
            <TextInput
              source="sortOrder"
              label="Порядок сортировки"
              type="number"
              defaultValue={1000}
            />
          </FormRow>
          <FormRow>
            <TextInput source="shortName" label="Краткое название" validate={validates.shortName} />
          </FormRow>
          <FormRow>
            <TextInput source="subTitle" label="Подзаголовок" />
          </FormRow>
          <ImageInputWithFormRow {...props} source="photo" />
          <ImageInputWithFormRow
            {...props}
            source="videoPoster"
            buttonText="Добавить постер к видео"
          />
          <ImageInputWithFormRow {...props} source="video" isVideo />
          <FormRow maxWidth={800}>
            <RichText source="description" />
          </FormRow>
        </FormCard>
      </FormTab>
      <FormTab label="Спецпредложение">
        <FormCard>
          <FormRow>
            <DateTimePickerInput
              source="start"
              label="Дата начала действия"
              helperText="Если не указано, то начнет действовать сразу"
            />
          </FormRow>
          <FormRow>
            <DateTimePickerInput
              source="end"
              label="Дата окончания действия"
              helperText="Если не указано, то будет действовать всегда"
            />
          </FormRow>
          <FormRow>
            <BooleanInput
              label="Скрыть даты?"
              source="anyJsonDictionary.hiddenDates"
              format={parseBoolean}
            />
          </FormRow>
          <FormRow>
            <BooleanInput label="Снять затемнение с обложки?" source="isPhotoEnhance1" />
          </FormRow>
          <FormRow>
            <BooleanInput label="Отображать на главной странице?" source="isShowMain" />
          </FormRow>
          <FormRow>
            <BooleanInput label="Отображать в слайдере?" source="isShowSlider" />
          </FormRow>
          <FormRow>
            <BooleanInput
              label="Не отображать в списке спецпредложений?"
              source="anyJsonDictionary.hiddenInList"
              format={parseBoolean}
            />
          </FormRow>
          <FormRow>
            <TextInput source="linkObject" label="Ссылка внутри объектов" />
          </FormRow>
          <FormRow>
            <TextInput source="linkGroup" label="Ссылка внутри комплексов" />
          </FormRow>
          <FormRow>
            <TextInput source="linkSlider" label="Ссылка внутри слайдера" />
          </FormRow>
          <FormRow>
            <TextInput source="linkSpec" label="Ссылка внутри главной страницы" />
          </FormRow>
          <FormRow>
            <TextInput source="anyJsonDictionary.buttonText" label="Текст кнопки" />
          </FormRow>
        </FormCard>
      </FormTab>
      <FormTab label="Продвижение">
        <FormCard>
          <FormTitle>Seo</FormTitle>
          <SeoTab isLast={false} />
        </FormCard>
      </FormTab>
      <FormTab label="Объекты">
        <ListObjects />
      </FormTab>
      <FormTab label="Комплексы">
        <ListVillages />
      </FormTab>
    </TabbedForm>
  );
};
