import React, { useCallback, useMemo } from 'react';
import { useListContext } from 'react-admin';
import { IconButton, makeStyles } from '@material-ui/core';
import { FiltersIcon } from '../../Icons';
import { getNumberFilters } from '../../../utils';
import { multipleFilters } from '../../../constants/filters';
import { customTheme } from '../../../theme';

const useStyles = makeStyles(() => ({
  number: {
    position: 'absolute',
    top: 0,
    right: -10,
    background: customTheme.palette.primary['A200'],
    borderRadius: '50%',
    color: 'white',
    fontSize: '12px',
    lineHeight: '12px',
    padding: '5px 8px',
  },
  button: {
    border: '1px solid #BDBDBD',
    color: customTheme.palette.common.black,
    borderRadius: 6,
    height: 56,
    width: 60,
  },
}));

export const FiltersButton = ({ onClick }) => {
  const classes = useStyles();
  const { filterValues } = useListContext();

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  const numberActiveFilters = useMemo(() => {
    return getNumberFilters(filterValues, multipleFilters);
  }, [filterValues]);

  return (
    <IconButton onClick={handleClick} disableRipple className={classes.button}>
      <FiltersIcon width={24} height={24} />
      {numberActiveFilters !== 0 && <span className={classes.number}>{numberActiveFilters}</span>}
    </IconButton>
  );
};
