import React, { useMemo, useCallback } from 'react';
import { Datagrid, TextField } from 'react-admin';

import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { ListBase } from '../../../components/ListBase';

import { StatusesField } from '../../../components/RAFields/StatusesField';

import { PhotoField, NameField, AuthorField } from '../../../components/RAFields';

import { villagesUrl } from '../../../constants';
import { VillagesMenu } from '../../../components/app/Menus';
import { RAList } from '../../../components/app';

const WrapperPhotoField = ({ record }) => {
  const path = useMemo(() => {
    const images = record.images;
    if (images && images[0]) {
      return images[0].path;
    }
  }, [record]);

  return <PhotoField path={path} />;
};

const WrapperNameField = ({ record }) => {
  const { id, name, slug } = record;

  const link = useMemo(() => {
    if (record.sysStatus === 200) {
      return `${villagesUrl}/${id}`;
    }
  }, [id, record.sysStatus]);

  const type = useMemo(() => {
    if (record.groupTypes && record.groupTypes[0] && record.groupTypes[0].groupType) {
      return record.groupTypes[0].groupType.name;
    }
  }, [record]);

  return <NameField name={name} id={id} additionalId={slug} link={link} type={type} />;
};

const TableVillages = () => {
  return (
    <Datagrid hasBulkActions>
      <WrapperPhotoField label="Фото" sortable={false} />
      <WrapperNameField source="name" label="Название" sortable={false} />
      <TextField source="area" label="Площадь (сот.)" sortable={false} />
      <StatusesField source="sysStatus" label="Статус публикации" sortable={false} />
      <AuthorField source="createdBy" label="Автор" sortable={false} />
      <VillagesMenu />
    </Datagrid>
  );
};

const CardsVillages = ({ menuItem }) => {
  return <RAList type="village" menuItem={menuItem} />;
};

export const ListVillages = (props) => {
  const { resource, permissions } = props;

  const menuItem = useCallback(
    (record) => {
      return (
        <VillagesMenu
          record={record}
          resource={resource}
          permissions={permissions}
          icon={<MoreVertIcon />}
        />
      );
    },
    [permissions, resource],
  );

  return (
    <ListBase
      {...props}
      hasToggleView
      tableRender={<TableVillages {...props} />}
      cardRender={<CardsVillages {...props} menuItem={menuItem} />}
      searchBy="NameAndSearchWords"
    />
  );
};
