import React, { useState, useEffect, useMemo } from 'react';
import { useDataProvider } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Cards } from '../../app';
import { Preloader } from '../../Preloader';
import { Pagination } from '../../common';

const useStyles = makeStyles(() => ({
  innerRoot: {
    width: '100%',
  },
  innerContent: {
    flex: '1 1 0',
    minHeight: 0,
    overflow: 'visible',
    width: '100%',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    padding: '16px 16px 0 16px',
    boxSizing: 'border-box',
  },
  content: {
    flex: '1 1 0',
    minHeight: 0,
    overflow: 'auto',
    // padding: '0 10px',
  },
  footer: {
    paddingTop: '15px',
    flex: '0 0 auto',
  },
}));

export const DynamicList = ({
  fetchOptions: { resource, filter },
  contentOptions = {},
  contentElement: contentElementProp = Cards,
  innerTableLayout = false,
  perPage: perPageProp = 10,
}) => {
  const dataProvider = useDataProvider();
  const classes = useStyles();

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(perPageProp);

  useEffect(() => {
    setPage(1);
  }, [perPage]);

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const result = await dataProvider.getAdditionalList(resource, {
          filter,
          pagination: { page, perPage },
        });

        setItems(result.data);
        setTotal(result.total || 0);
      } catch (error) {
        console.log(error);
      }

      setLoading(false);
    };

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, resource]);

  const hasItems = useMemo(() => {
    return items.length > 0;
  }, [items]);

  const contentElement = useMemo(() => {
    const Element = contentElementProp;

    return <Element {...contentOptions} items={items} />;
  }, [contentElementProp, contentOptions, items]);

  return (
    <div className={!innerTableLayout ? classes.root : classes.innerRoot}>
      <div className={!innerTableLayout ? classes.content : classes.innerContent}>
        <Preloader loading={loading} />
        {hasItems ? contentElement : 'Список пуст!'}
      </div>
      {total !== 0 && (
        <div className={classes.footer}>
          <Pagination
            page={page}
            perPage={perPage}
            total={total}
            setPage={setPage}
            setPerPage={setPerPage}
          />
        </div>
      )}
    </div>
  );
};
