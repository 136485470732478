import React, { useCallback, useState, useEffect, useContext } from 'react';

import { makeStyles } from '@material-ui/core';
import { useListContext } from 'react-admin';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { ListBase } from '../../../components/ListBase';
import { ConstantSelectField, List, RAList } from '../../../components/app';
// import { DemandsSelectedMenu } from '../../../components/app/SelectedMenus';
import { TouchesWebsocketContext } from '../../../components/providers';
import { DefaultMenu } from '../../../components/app/Menus';
import { clientTouchTypes, touchDirections } from '../../../constants';
import { TouchesSelectedMenu } from '../../../components/app/SelectedMenus';

const CardsTouches = ({ menuItem }) => {
  return <RAList type="touch" menuItem={menuItem} isNewItem />;
};

const useHeaderStyles = makeStyles(() => ({
  filterRows: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  filterRow: {
    flex: '0 1 auto',
    width: '49%',

    '&:first-child': {
      paddingLeft: 0,
    },
  },
}));

const CustomHeaderContent = () => {
  const classes = useHeaderStyles();
  const [status, setStatus] = useState([]);
  const [direction, setDirection] = useState('');
  const { filterValues, setFilters } = useListContext();

  useEffect(() => {
    setStatus(filterValues.Targets ? filterValues.Targets.split(',') : []);
    setDirection(filterValues.IsInDirection || '');
  }, [filterValues]);

  const handleChange = useCallback(
    (newValue, event) => {
      const name = event.target.name;
      const newFilterValues = { ...filterValues };

      if (name === 'Targets') {
        setStatus(newValue.split(','));
      }

      if (name === 'IsInDirection') {
        setDirection(newValue);
      }

      newFilterValues[name] = newValue;
      if (!newValue) {
        delete newFilterValues[name];
      }
      setFilters(newFilterValues);
    },
    [filterValues, setFilters],
  );

  return (
    <>
      <div className={classes.filterRows}>
        <div className={classes.filterRow}>
          <ConstantSelectField
            multiple
            items={clientTouchTypes}
            onChange={handleChange}
            name="Targets"
            value={status}
            isControlled
            label="Статус обработки"
          />
        </div>
        <div className={classes.filterRow}>
          <ConstantSelectField
            items={touchDirections}
            onChange={handleChange}
            name="IsInDirection"
            value={direction}
            isControlled
            label="Входящие/Исходящие"
            allowEmpty
          />
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  newItems: {
    flex: '0 0 auto',
    maxHeight: '50%',
    position: 'relative',
    overflow: 'auto',
    borderBottom: '1px solid #E0E0E0',
    paddingBottom: 16,
    marginBottom: 16,
  },
  items: {
    flex: '1 1 auto',
    position: 'relative',
    overflow: 'auto',
  },
}));

const NewTouches = ({ menuItem }) => {
  const styles = useStyles();
  const [items, setItems] = useState([]);
  const { selectedIds, onToggleItem, resource, ids } = useListContext();
  const newTouch = useContext(TouchesWebsocketContext);

  useEffect(() => {
    if (newTouch) {
      setItems((currentItems) => {
        const newItems = [...currentItems];
        const foundIndex = currentItems.findIndex((currentItem) => {
          return newTouch.clientTouch.id === currentItem.id;
        });

        if (foundIndex !== -1) {
          newItems.splice(foundIndex, 1, { ...newTouch.clientTouch });

          return newItems;
        }

        return [{ ...newTouch.clientTouch }, ...currentItems];
      });
    }
  }, [newTouch]);

  useEffect(() => {
    setItems([]);
  }, [ids]);

  if (items.length === 0) {
    return null;
  }

  return (
    <div className={styles.newItems}>
      <List
        type="touch"
        menuItem={menuItem}
        selectedIds={selectedIds}
        onToggleItem={onToggleItem}
        resource={resource}
        items={items}
        isNewItem
      />
    </div>
  );
};

export const ListTouches = (props) => {
  const { resource } = props;
  const styles = useStyles();

  const menuItem = useCallback((record, currentResource) => {
    return (
      <DefaultMenu
        icon={<MoreVertIcon />}
        record={record}
        resource={currentResource}
        hasDelete={false}
      />
    );
  }, []);

  return (
    <ListBase
      customHeaderContent={<CustomHeaderContent />}
      {...props}
      hasDelete={false}
      searchBy="IdPhoneContains"
      filterDefaultValues={{ SortBy: 401 }}
      selectedMenuOptions={TouchesSelectedMenu}
      hasExport
    >
      <div className={styles.root}>
        <NewTouches resource={resource} menuItem={menuItem} />
        <div className={styles.items}>
          <CardsTouches {...props} menuItem={menuItem} />
        </div>
      </div>
    </ListBase>
  );
};
