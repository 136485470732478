import React, { useCallback, useMemo } from 'react';
import { useNotify } from 'react-admin';

import { MenuItem, makeStyles } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import { UploadIcon } from '../../../Icons';
import {
  downloadPDF,
  copyToClipboard,
  getSetLink,
  getPresentationLink,
  getObjectLink,
  getVillageLink,
} from '../../../../utils';

const useStyles = makeStyles(() => ({
  menuItem: {
    fontSize: 16,
    lineHeight: '1.4',
    height: 40,
    padding: '25px 25px',
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      marginRight: 15,
    },
  },
}));

const getLinkByType = {
  sets: getSetLink,
  objects: getObjectLink,
  villages: getVillageLink,
  presentation: getPresentationLink,
};

export const PresentationItems = ({ record, type, keyId, onClose }) => {
  const classes = useStyles();
  const notify = useNotify();

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const handleDownload = useCallback(
    (withoutLogo) => async () => {
      const downloaded = await downloadPDF(type, record[keyId], withoutLogo);
      if (!downloaded) {
        notify(`Извините, данная презентация еще не загружена, подождите еще немного...`, 'info');
      }
      handleClose();
    },
    [type, record, keyId, handleClose, notify],
  );

  const link = useMemo(() => {
    return getLinkByType[type](record);
  }, [type, record]);

  const handleCopyLink = useCallback(
    (isPresentation, withoutLogo = false, isPrivate = false) =>
      () => {
        const getLink = () => {
          if (isPresentation) {
            return getLinkByType['presentation'](type, record[keyId], withoutLogo);
          }

          return getLinkByType[type](record, isPrivate);
        };

        const currentLink = getLink();

        copyToClipboard(currentLink);
        handleClose();
      },
    [handleClose, keyId, record, type],
  );

  return (
    <>
      {link && (
        <>
          <MenuItem classes={{ root: classes.menuItem }} onClick={handleCopyLink()}>
            <LinkIcon width={24} height={23} /> Копировать ссылку
          </MenuItem>
          {type === 'objects' && (
            <MenuItem
              classes={{ root: classes.menuItem }}
              onClick={handleCopyLink(false, false, true)}
            >
              <LinkIcon width={24} height={23} /> Копировать ссылку без эксперта
            </MenuItem>
          )}
        </>
      )}
      <MenuItem classes={{ root: classes.menuItem }} onClick={handleDownload()}>
        <UploadIcon width={24} height={23} /> Скачать презентацию
      </MenuItem>
      <MenuItem classes={{ root: classes.menuItem }} onClick={handleDownload(true)}>
        <UploadIcon width={24} height={23} /> Скачать презентацию без лого
      </MenuItem>
      <MenuItem classes={{ root: classes.menuItem }} onClick={handleCopyLink(true)}>
        <LinkIcon width={24} height={23} /> Копировать ссылку презентации
      </MenuItem>
      <MenuItem classes={{ root: classes.menuItem }} onClick={handleCopyLink(true, true)}>
        <LinkIcon width={24} height={23} /> Копировать ссылку презентации без лого
      </MenuItem>
    </>
  );
};
