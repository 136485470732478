import React from 'react';

import { Create } from '../../../../components/common';
import { FormCMSPages } from '../../../../components/forms';

const initialValues = {
  cmsPageTypeId: 12,
  cmsPageParentId: 11,
};

export const CreateMagazineArticlePages = (props) => {
  return (
    <Create {...props}>
      <FormCMSPages {...props} redirect="list" initialValues={initialValues} />
    </Create>
  );
};
