import React, { useCallback, useState } from 'react';

import { makeStyles } from '@material-ui/core';
import { Button } from '../Button';
import { mergeClasses, downloadFileFromBlob } from '../../../utils';
import { staticProvider } from '../../../staticApi';

const useStyles = makeStyles(() => ({
  root: {
    padding: '8px 14px',
    minWidth: 0,
    fontSize: '13px',
  },
}));

export const DownloadImages = (props) => {
  const classes = useStyles();

  const { images = [] } = props;
  const disabled = images.length === 0;

  const [loading, setLoading] = useState(false);

  const handleClick = useCallback(() => {
    setLoading(true);
    const fetch = async (path) => {
      let type;
      const typeRegExp = new RegExp('[^.]*$');
      const originalType = path.match(typeRegExp);
      if (originalType[0] === 'webp') {
        type = '.jpg';
      }
      const result = await staticProvider.downloadImage(path, type);
      let name = path.match(/[^/]*$/)[0];
      if (type) {
        name = name.replace(typeRegExp, 'jpg');
      }
      downloadFileFromBlob(result.data, name);
    };

    const sendRequests = async () => {
      const requests = images.map((image) => {
        return fetch(image.path);
      });

      try {
        await Promise.all(requests);
      } catch (error) {
        alert(error);
      }

      setLoading(false);
    };

    sendRequests();
  }, [images]);

  return (
    <Button
      {...props}
      loading={loading}
      disabled={disabled}
      onClick={handleClick}
      classes={{
        root: mergeClasses(classes.root),
      }}
    >
      Скачать изображения
    </Button>
  );
};
