import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { customTheme } from '../../../theme';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  name: {
    lineHeight: '1.4',
    fontSize: '14px',
    display: 'block',

    '& a': {
      color: customTheme.palette.primary['A200'],
    },
  },
  id: {
    color: '#757575',
    lineHeight: '1.4',
    fontSize: '11px',
    flex: '0 1 auto',
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '6px',
  },
  type: {
    flex: '0 1 auto',
    marginLeft: '12px',
    padding: '4px 8px',
    fontWeight: '500',
    fontSize: '9px',
    lineHeight: '12px',
    textTransform: 'uppercase',
    color: '#50AE54',
    background: '#E9F5EA',
    borderRadius: '3px',
  },
}));

export const NameField = ({ name, id, additionalId, link, type }) => {
  const classes = useStyles();

  const idsElement = useMemo(() => {
    if (additionalId) {
      return `${id} / ${additionalId}`;
    }

    return id;
  }, [id, additionalId]);

  return (
    <div className={classes.root}>
      <span className={classes.name}>
        {link ? (
          <a href={link} rel="noreferrer" target="_blank">
            {name}
          </a>
        ) : (
          name
        )}
      </span>
      <div className={classes.info}>
        <span className={classes.id}>{idsElement}</span>
        {type && <span className={classes.type}>{type}</span>}
      </div>
    </div>
  );
};
