import React from 'react';
import { ThumbUp as ThumbUpIcon, ThumbDown as ThumbDownIcon } from '@material-ui/icons';
import { Switch } from '../../../common';

export const GoodSwitch = (props) => {
  return (
    <Switch
      {...props}
      icon={<ThumbDownIcon fontSize="inherit" />}
      checkedIcon={<ThumbUpIcon fontSize="inherit" />}
      name="isGood"
    />
  );
};
