import React from 'react';

import { FormContracts } from '../../../components/forms';
import { Edit } from '../../../components/common';

export const EditContracts = (props) => {
  return (
    <Edit {...props}>
      <FormContracts {...props} redirect={false} />
    </Edit>
  );
};
