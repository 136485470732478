import React, { useMemo, useCallback, useState } from 'react';
import { makeStyles, FormControl, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import { useForm } from 'react-final-form';
import { Dialog } from '../Dialog';

import { Button } from '../../buttons';
import { customTheme } from '../../../theme';

const useStyles = makeStyles(() => ({
  dialogPaper: {
    maxWidth: 465,
  },
}));

export const UnpublishObjectDialog = (props) => {
  const classes = useStyles();
  const { changeOpenRef } = props;
  const { change } = useForm();

  const [type, setType] = useState('type1');

  const handleChangeType = (event) => {
    setType(event.target.value);
  };

  const handleSubmit = useCallback(() => {
    if (type === 'type1') {
      change('isPreSold', true);
    }

    if (type === 'type2') {
      change('isForSale', false);
      change('isForRent', false);
    }

    changeOpenRef.current(false);
  }, [change, changeOpenRef, type]);

  const buttons = useMemo(() => {
    return <Button onClick={handleSubmit}>Да</Button>;
  }, [handleSubmit]);

  return (
    <Dialog
      {...props}
      buttons={buttons}
      dialogProps={{ fullWidth: true, classes: { paper: classes.dialogPaper } }}
      title="Причина снятия с публикации"
      closeButtonText="Нет"
    >
      <FormControl component="fieldset">
        <RadioGroup value={type} onChange={handleChangeType} color={customTheme.palette.primary}>
          <FormControlLabel
            value="type1"
            control={<Radio color="primary" />}
            label="Объект продан/сдан"
          />
          <FormControlLabel
            value="type2"
            control={<Radio color="primary" />}
            label="Объект снят собственником с продажи/аренды"
          />
          <FormControlLabel
            value="type3"
            control={<Radio color="primary" />}
            label="Объект в продаже/аренде, снятие публикации с сайта"
          />
        </RadioGroup>
      </FormControl>
    </Dialog>
  );
};
