import React, { useRef } from 'react';
import { MoreVert } from '@material-ui/icons';

import { MenuCell } from '../../../tables';
import { EditMenuItem } from '../../../menuItems';

export const DemandCallsMenu = ({ record, resource }) => {
  const handleCloseRef = useRef();
  const menuIcon = <MoreVert />;

  return (
    <MenuCell handleCloseRef={handleCloseRef} icon={menuIcon}>
      <EditMenuItem resource={resource} record={record} />
    </MenuCell>
  );
};
