import React from 'react';
import { ShoppingCart as ShoppingCartIcon } from '@material-ui/icons';
import { StatusField } from '../StatusField';

export const StatusFieldSell = () => {
  return (
    <StatusField styleProps={{ backgroundColor: '#64DD17', fontSize: '16px' }}>
      <ShoppingCartIcon fontSize="inherit" />
    </StatusField>
  );
};
