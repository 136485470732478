import React from 'react';
import { Switch } from '../../../common';

export const PdfSwitch = (props) => {
  return (
    <Switch
      {...props}
      icon={<>PDF</>}
      checkedIcon={<>PDF</>}
      styleProps={{ fontSize: 9 }}
      name="isShowInPdf"
    />
  );
};
