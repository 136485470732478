import React from 'react';

import { Checkbox } from '../../Checkbox';
import { FormRow } from '../../FormRow';

export const CheckboxWithFormRow = ({ formRowProps, ...props }) => {
  return (
    <FormRow {...formRowProps}>
      <Checkbox {...props} />
    </FormRow>
  );
};
