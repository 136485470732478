import React, { useCallback, useRef } from 'react';
import { MenuItem } from '@material-ui/core';
import { ImagesMultipleDialog } from '../../../dialogs';

export const ImagesSelectedMenu = ({ onClose, onReset, onDeleteItems, ...otherProps }) => {
  const { selectedIds } = otherProps;
  const changeOpenRef = useRef();

  const handleOpenDialog = useCallback(() => {
    changeOpenRef.current(true);

    onClose();
  }, [onClose]);

  const handleClear = useCallback(() => {
    if (onReset) {
      onReset();
    }
  }, [onReset]);

  const handleDelete = useCallback(() => {
    if (onDeleteItems) {
      onDeleteItems(selectedIds);
    }
  }, [onDeleteItems, selectedIds]);

  return (
    <>
      <ImagesMultipleDialog changeOpenRef={changeOpenRef} {...otherProps} />
      <MenuItem onClick={handleClear}>Очистить все</MenuItem>
      <MenuItem onClick={handleOpenDialog}>Редактировать поля</MenuItem>
      <MenuItem onClick={handleDelete}>Удалить</MenuItem>
    </>
  );
};
