import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { getFullNameWithId, isObject } from '../../../utils';
import { Avatar } from '../../common';
import { AutocompleteWithFormRow } from '../../app';

const useStyles = makeStyles(() => ({
  avatar: {
    width: '24px',
    height: '24px',
    marginRight: '6px',
    fontSize: '10px',
  },
  inputAvatar: {
    width: '24px',
    height: '24px',
    marginRight: '2px',
    fontSize: '10px',
  },
}));

export const PeopleOption = ({ option }) => {
  const classes = useStyles();

  return (
    <>
      <Avatar option={option} source="photo" className={classes.avatar} />
      {`${getFullNameWithId(option)} ${option.phone ? `| ${option.phone}` : ''}`}
    </>
  );
};

export const PeopleSelect = (props) => {
  const { componentProps = {}, disabled } = props;
  const classes = useStyles();

  return (
    <AutocompleteWithFormRow
      {...props}
      resourceName="Peoples"
      componentProps={{
        disabled,
        ...componentProps,
        fullDynamic: 'AllNames',
        getOptionLabel: (option) => (!isObject(option) ? '' : `${getFullNameWithId(option)}`),
        renderOption: (option) => <PeopleOption option={option} />,
        startAdornment: (value) => {
          return (
            value &&
            !componentProps.multiple && (
              <Avatar option={value} source="photo" className={classes.inputAvatar} />
            )
          );
        },
      }}
    />
  );
};
