import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ListItemMainBlock, SortOrderListItem } from '../..';

const useStyles = makeStyles((theme) => ({
  buttons: {
    flex: '0 0 auto',
    padding: '0 12px',
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('1024')]: {
      flexWrap: 'wrap',
    },
  },
}));

export const ListItemCMSPage = ({ record, depthKey, originalRecord, hasButtons, resource }) => {
  const classes = useStyles();

  const imagePath = record.cover;

  let basePath = '/cmsAdmin/pages';

  if (
    resource === 'cmsAdmin/magazineMainPages' ||
    resource === 'cmsAdmin/magazineArticlePages' ||
    resource === 'cmsAdmin/magazineCategoryPages'
  ) {
    basePath = `/${resource}`;
  }

  const link = `${basePath}/${record.id}`;

  return (
    <>
      <ListItemMainBlock
        record={record}
        imagePath={imagePath}
        link={link}
        depthKey={depthKey}
        hasButtons={hasButtons}
      />
      <div className={classes.buttons}>
        <div className={classes.buttonsItem}>
          <SortOrderListItem sortOrder={originalRecord.sortOrder} />
        </div>
      </div>
    </>
  );
};
