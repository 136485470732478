import React from 'react';

import { FormArticles } from '../../../components/forms';
import { Edit } from '../../../components/common';

export const EditArticles = (props) => {
  return (
    <Edit {...props}>
      <FormArticles {...props} redirect={false} />
    </Edit>
  );
};
