import React from 'react';
import { Create as RACreate } from 'react-admin';

export const Create = (props) => {
  const { children } = props;

  return (
    <RACreate {...props} title="Создание">
      {children}
    </RACreate>
  );
};
