import React from 'react';

import { FormDiscounts } from '../../../components/forms';
import { Create } from '../../../components/common';

export const CreateDiscounts = (props) => {
  return (
    <Create {...props}>
      <FormDiscounts {...props} redirect="list" />
    </Create>
  );
};
