import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ListItemMainBlock, SortOrderListItem } from '../..';
import { getValueByDepthKey } from '../../../../utils';
import { ListItemPredefined } from '../../ListItemStatuses/Predefined';

const useStyles = makeStyles((theme) => ({
  buttons: {
    flex: '0 0 auto',
    padding: '0 12px',
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('1024')]: {
      flexWrap: 'wrap',
    },
  },
}));

export const ListItemDefault = ({ record, imgKey = 'photo', hasButtons, resource }) => {
  const classes = useStyles();

  const link = `/${resource}/${record.id}`;

  const statuses = [];

  if (record.isPredefined) {
    statuses.push(<ListItemPredefined />);
  }

  return (
    <>
      <ListItemMainBlock
        record={record}
        imagePath={getValueByDepthKey(record, imgKey)}
        hasButtons={hasButtons}
        link={link}
        statuses={statuses}
      />
      {hasButtons && (
        <div className={classes.buttons}>
          <div className={classes.buttonsItem}>
            <SortOrderListItem sortOrder={record.sortOrder} />
          </div>
        </div>
      )}
    </>
  );
};
