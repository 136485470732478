import React from 'react';
import { Checkbox as MaterialCheckbox } from '@material-ui/core';

export const Checkbox = ({ checked = false, name = false, color = 'primary', onChange }) => {
  return (
    <MaterialCheckbox
      checked={checked}
      onChange={(event) => {
        const value = event.target.checked;
        if (onChange) {
          onChange(value);
        }
      }}
      name={name}
      color={color}
    />
  );
};
