import React, { useState, useEffect } from 'react';
import { dataProvider } from '../../../dataProvider';

export const InstructionsContext = React.createContext();

let isFetched = false;

export const InstructionsProvider = ({ oidc, children }) => {
  const [instructions, setInstructions] = useState();

  useEffect(() => {
    const fetch = async () => {
      try {
        const result = await dataProvider.getInstructions();
        if (result.data.length > 0 && result.data[0].sysStatus === 200) {
          setInstructions(result.data);
        }
      } catch (error) {
        console.log(error);
      }

      isFetched = true;
    };

    if (oidc && !isFetched) {
      fetch();
    }
  }, [oidc]);

  return (
    <InstructionsContext.Provider value={instructions}>{children}</InstructionsContext.Provider>
  );
};
