import React, { useMemo } from 'react';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '24px',
    display: 'flex',
    flexWrap: 'wrap',
    listStyleType: 'none',
    padding: '0',
    marginBottom: '0',
  },
  item: {
    fontSize: '12px',
    lineHeight: '18px',
    flex: '0 1 auto',
    marginBottom: '8px',
    padding: '0 8px 0 8px',
    borderLeft: '1px solid rgba(0, 0, 0, 0.3)',

    '&:first-child': {
      borderLeft: '0',
      paddingLeft: '0',
    },

    '&:last-child': {
      paddingRight: '0',
    },
  },
}));

export const CardDescriptions = ({ descriptions = [] }) => {
  const styles = useStyles();

  const elements = useMemo(() => {
    return descriptions.map((description) => {
      return <li className={styles.item}>{description}</li>;
    });
  }, [descriptions, styles.item]);

  if (elements.length === 0) {
    return null;
  }

  return <ul className={styles.root}>{elements}</ul>;
};
