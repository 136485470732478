import React from 'react';
import { Datagrid } from 'react-admin';

import { ListBase } from '../../../components/ListBase';

import { StatusesField } from '../../../components/RAFields/StatusesField';

import { NameField, AuthorField } from '../../../components/RAFields';
import { DefaultMenu } from '../../../components/app/Menus';
import { RAList } from '../../../components/app';

const WrapperNameField = ({ record }) => {
  const { id, name, slug } = record;

  return <NameField name={name} id={id} additionalId={slug} />;
};

const Table = () => {
  return (
    <Datagrid hasBulkActions>
      <WrapperNameField source="name" label="Название" sortable={false} />
      <StatusesField source="sysStatus" label="Статус публикации" sortable={false} />
      <AuthorField source="createdBy" label="Автор" sortable={false} />
      <DefaultMenu />
    </Datagrid>
  );
};

const Cards = () => {
  return <RAList />;
};

export const ListInstructions = (props) => {
  return (
    <ListBase
      {...props}
      hasToggleView
      tableRender={<Table {...props} />}
      cardRender={<Cards {...props} />}
    />
  );
};
