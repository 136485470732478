import React, { useCallback } from 'react';
import { makeStyles, Chip, Typography } from '@material-ui/core';
import { customTheme } from '../../../../theme';
import { getFormattedDate } from '../../../../utils';
import {
  demandStatuses,
  demandTypes,
  demandSourceStatuses,
  luxuryClasses,
  demandMarkTypes,
} from '../../../../constants';
import { Checkbox } from '../../../common/Checkbox';
import { DemandsMenu } from '../../Menus/DemandsMenu';

const useStyles = makeStyles(() => ({
  containerLinks: {
    fontSize: '12px',
    marginTop: '20px',
  },
  card: {
    margin: '10px',
    boxShadow:
      '0px 0px 24px rgba(0, 0, 0, 0.05), 0px 0px 4px rgba(0, 0, 0, 0.05), 0px 0px 8px rgba(0, 0, 0, 0.05)',
    borderRadius: '6px',
    boxSizing: 'border-box',
    background: '#fff',
    display: 'flex',
    flexWrap: 'wrap',
    flex: '1 1 300px',
    position: 'relative',
    padding: '16px',
  },
  header: {
    width: '100%',
    borderBottom: `1px solid ${customTheme.palette.grey['300']}`,
    paddingBottom: 16,
    display: 'flex',
    alignItems: 'center',
  },
  properties: {
    width: '100%',
    paddingTop: 16,
    paddingBottom: 16,
    borderBottom: `1px solid ${customTheme.palette.grey['300']}`,
  },
  footer: {
    paddingTop: 16,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  date: {
    marginLeft: 'auto',
  },
  propertyRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:not(:last-child)': {
      marginBottom: 12,
    },
  },
  propertyRowTitle: {
    paddingRight: 12,
  },
  propertyRowValue: {
    textAlign: 'right',
  },
  checkbox: {
    marginLeft: 'auto',
  },
}));

export const DemandCard = (props) => {
  const classes = useStyles();
  const { data: item, resource, onToggleItem, selected, hasCheckbox } = props;
  const {
    name,
    id,
    expert,
    demandStatus,
    demandType,
    nextEventDate,
    createdDate,
    source,
    luxuryClass,
    mark,
  } = item;

  const status = demandStatuses[demandStatus];
  const type = demandTypes[demandType];
  const sourceType = demandSourceStatuses[source];
  const luxuryType = luxuryClasses[luxuryClass];
  const markType = demandMarkTypes[mark];

  const menuElement = <DemandsMenu record={item} resource={resource} />;

  const handleToggleCheckbox = useCallback(() => {
    if (onToggleItem) {
      onToggleItem(id);
    }
  }, [id, onToggleItem]);

  return (
    <div className={classes.card}>
      <div className={classes.header}>
        <Chip label={type} variant="outlined" />
        <Typography variant="body2" className={classes.date}>
          {getFormattedDate(createdDate, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          })}
        </Typography>
      </div>
      <div className={classes.properties}>
        <div className={classes.propertyRow}>
          <div className={classes.propertyRowTitle}>
            <Typography variant="body2">Имя клиента</Typography>
          </div>
          <div className={classes.propertyRowValue}>
            <Typography variant="body2">{name}</Typography>
          </div>
        </div>
        <div className={classes.propertyRow}>
          <div className={classes.propertyRowTitle}>
            <Typography variant="body2">ID</Typography>
          </div>
          <div className={classes.propertyRowValue}>
            <Typography variant="body2">{id}</Typography>
          </div>
        </div>
        <div className={classes.propertyRow}>
          <div className={classes.propertyRowTitle}>
            <Typography variant="body2">Источник</Typography>
          </div>
          <div className={classes.propertyRowValue}>
            <Typography variant="body2">{sourceType || ''}</Typography>
          </div>
        </div>
        <div className={classes.propertyRow}>
          <div className={classes.propertyRowTitle}>
            <Typography variant="body2">Класс объекта</Typography>
          </div>
          <div className={classes.propertyRowValue}>
            <Typography variant="body2">{luxuryType || ''}</Typography>
          </div>
        </div>
        <div className={classes.propertyRow}>
          <div className={classes.propertyRowTitle}>
            <Typography variant="body2">Метка</Typography>
          </div>
          <div className={classes.propertyRowValue}>
            <Typography variant="body2">{markType || ''}</Typography>
          </div>
        </div>
        <div className={classes.propertyRow}>
          <div className={classes.propertyRowTitle}>
            <Typography variant="body2">Назначен эксперт</Typography>
          </div>
          <div className={classes.propertyRowValue}>
            <Typography variant="body2">
              {expert ? `${expert.name} ${expert.lastName}` : ''}
            </Typography>
          </div>
        </div>
        <div className={classes.propertyRow}>
          <div className={classes.propertyRowTitle}>
            <Typography variant="body2">Обработать до</Typography>
          </div>
          <div className={classes.propertyRowValue}>
            <Typography variant="body2">
              {nextEventDate
                ? getFormattedDate(nextEventDate, {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                  })
                : ''}
            </Typography>
          </div>
        </div>
        <div className={classes.propertyRow}>
          <div className={classes.propertyRowTitle}>
            <Typography variant="body2">Статус заявки</Typography>
          </div>
          <div className={classes.propertyRowValue}>
            <Typography variant="body2">{status}</Typography>
          </div>
        </div>
      </div>
      <div className={classes.footer}>
        {menuElement}
        <div className={classes.checkbox}>
          {hasCheckbox && <Checkbox checked={selected} onChange={handleToggleCheckbox} />}
        </div>
      </div>
    </div>
  );
};
