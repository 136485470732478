import React from 'react';
import { useListContext } from 'react-admin';

import { Pagination } from '../../common';

const ListBasePagination = () => {
  const { page, perPage, total, setPage, setPerPage } = useListContext();

  return (
    <Pagination
      page={page}
      perPage={perPage}
      total={total}
      setPage={setPage}
      setPerPage={setPerPage}
    />
  );
};

export default ListBasePagination;
