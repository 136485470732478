import React, { useMemo } from 'react';
import { useListContext } from 'react-admin';
import { Filters as CommonFilters } from '../../common';
import { filtersByResources, multipleFilters, commonFilters } from '../../../constants/filters';

export const Filters = ({ onHideDrawer, searchClearRef }) => {
  const { filterValues, setFilters, resource } = useListContext();

  const items = useMemo(() => {
    let filters = [];

    if (
      resource !== 'ChangeHistory' &&
      resource !== 'TaaskHistory' &&
      resource !== 'ContractHistory' &&
      resource !== 'ClientsHistory' &&
      resource !== 'Clients'
    ) {
      filters = filters.concat(commonFilters);
    }

    if (resource in filtersByResources) {
      filters = filters.concat(filtersByResources[resource]);
    }

    if (
      resource === 'ClientTouches' ||
      resource === 'Contracts' ||
      resource === 'Demos' ||
      resource === 'Taasks'
    ) {
      filters = filters.filter((filter) => {
        return filter.id !== 100;
      });
    }

    if (resource === 'RealtyObjects') {
      filters = filters.filter((filter) => {
        return filter.id !== 101;
      });
    }

    return filters;
  }, [resource]);

  return (
    <CommonFilters
      items={items}
      filters={filterValues}
      onSetFilters={setFilters}
      multipleFilters={multipleFilters}
      onHideDrawer={onHideDrawer}
      searchClearRef={searchClearRef}
    />
  );
};
