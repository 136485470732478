import React from 'react';

import { FormFeeds } from '../../../components/forms';
import { Create } from '../../../components/common';

export const CreateFeeds = (props) => {
  return (
    <Create {...props}>
      <FormFeeds {...props} redirect="list" />
    </Create>
  );
};
