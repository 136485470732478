import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useNotify } from 'react-admin';
import {
  IconButton,
  makeStyles,
  Typography,
  Slider,
  Menu,
  MenuItem,
  Badge,
} from '@material-ui/core';
import {
  PlayCircleOutline,
  PauseCircleOutline,
  HourglassEmptyRounded,
  AccessTime,
  Stop,
  GetApp as GetAppIcon,
} from '@material-ui/icons';
import { customTheme } from '../../theme';
import { dataProvider } from '../../dataProvider';
import { seconds2time } from '../../utils/audio';
import { downloadFileFromBlob } from '../../utils';

const useStyles = makeStyles(() => ({
  startIcon: {
    marginRight: 0,
    marginLeft: 0,
  },
  spinner: {
    marginRight: '10px',
    color: 'grey',
  },
  audioSpeedBadge: {
    backgroundColor: customTheme.palette.grey.light,
  },
  audioButtonWrapper: {
    borderRadius: 8,
    padding: '12px 12px',
    background: customTheme.palette.grey.light,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    width: '100%',
    boxSizing: 'border-box',
    // height: 56,
  },
  duration: {
    marginRight: 6,
    fontSize: 11,
    lineHeight: 1,
    paddingTop: 2,
    fontWeight: 700,
  },
  durationSliderWrapper: {
    flexGrow: 1,
    backgroundColor: '#fff',
    width: 50,
    height: 32,
    marginRight: 12,
    borderRadius: 2,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
  },
  controlButtons: {
    display: 'flex',
    alignItems: 'center',
  },
  '@media (max-width: 575px)': {
    durationSliderWrapper: {
      width: '100%',
      marginRight: 0,
    },
    controlButtons: {
      paddingTop: 12,
      width: '100%',
    },
    audioSpeedBadge: {
      marginLeft: 'auto',
    },
  },
}));

export const AudioPlayer = ({ recordId }) => {
  const classes = useStyles();
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioCurrentTime, setAudioCurrentTime] = useState('00:00:00');
  const [audioDurationTime, setAudioDurationTime] = useState('00:00:00');
  const [sliderDurationValue, setSliderDurationValue] = useState(0);
  const [isAudioSpeedMenuOpened, setIsAudioSpeedMenuOpened] = useState(null);
  const [sliderIsActive, setSliderIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAudioSpeed, setSelectedAudioSpeed] = useState(1);
  const audioRef = useRef();
  const audioBlobRef = useRef();
  const anchorSpeedButtonRef = useRef();

  const notify = useNotify();

  const loadAudioContent = async () => {
    if (audioRef.current.src) return;
    try {
      const audioResult = await dataProvider.getCallRecord(recordId);
      audioRef.current.src = URL.createObjectURL(audioResult.data);
      audioBlobRef.current = audioResult.data;
      downloadFileFromBlob(audioBlobRef.current, `audio-${recordId}`);
      setIsLoading(false);
    } catch (error) {
      notify(`Произошла ошибка загрузки файла`, 'error');
      setIsLoading(false);
    }
  };

  const playAudio = async () => {
    await loadAudioContent();

    const allAudioElements = document.querySelectorAll('[data-is-playing="true"]');
    if (allAudioElements.length > 0) {
      allAudioElements.forEach((item) => {
        item.pause();
        item.currentTime = 0;
      });
    }

    setIsLoading(false);
    setIsPlaying(true);
    audioRef.current.play();
    // setAudioCurrentTime('00:00:00');
    setSliderIsActive(true);
  };
  const pauseAudio = () => {
    setIsPlaying(false);
    audioRef.current.pause();
    setSliderIsActive(false);
  };
  const stopAudio = () => {
    setIsPlaying(false);
    audioRef.current.pause();
    audioRef.current.currentTime = 0;
    setSliderIsActive(false);
  };
  const setAudioTime = () => {
    if (!audioRef.current) return;
    const trackCurrentTime = Math.round(audioRef.current.currentTime);

    setAudioCurrentTime(seconds2time(trackCurrentTime));
    setSliderDurationValue(trackCurrentTime);
  };
  const onEnded = () => setIsPlaying(false);

  const handleSliderChange = (evt, value) => {
    setSliderDurationValue(value);
    audioRef.current.currentTime = value;
  };

  useEffect(() => {
    audioRef.current.addEventListener('timeupdate', setAudioTime);
    return () => stopAudio();
  }, []);

  const downloadFile = useCallback(() => {
    const fetch = async () => {
      setIsLoading(true);
      try {
        const audioResult = await dataProvider.getCallRecord(recordId);
        audioRef.current.src = URL.createObjectURL(audioResult.data);
        audioBlobRef.current = audioResult.data;
        downloadFileFromBlob(audioBlobRef.current, `audio-${recordId}`);
        setIsLoading(false);
      } catch (error) {
        notify(`Произошла ошибка загрузки файла`, 'error');
      }
    };

    if (!audioBlobRef.current) {
      fetch();
    } else {
      downloadFileFromBlob(audioBlobRef.current, `audio-${recordId}`);
    }
  }, [notify, recordId]);

  const handleAudioSpeedMenuClose = useCallback(() => {
    setIsAudioSpeedMenuOpened(null);
  }, []);

  const handleAudioSpeedMenuClick = useCallback(() => {
    setIsAudioSpeedMenuOpened((propOpen) => !propOpen);
  }, []);

  const handleCloseSpeedMenu = useCallback(
    (selectedMenuOption) => () => {
      setIsAudioSpeedMenuOpened(null);
      setSelectedAudioSpeed(selectedMenuOption);
      if (audioRef.current) {
        audioRef.current.playbackRate = selectedMenuOption;
      }
    },
    [],
  );

  return (
    <div className={classes.audioButtonWrapper}>
      <div className={classes.durationSliderWrapper}>
        <Slider
          disabled={!sliderIsActive}
          min={0}
          max={audioDurationTime || 100}
          aria-label="duration slider"
          value={sliderDurationValue}
          onChange={(evt, value) => handleSliderChange(evt, value)}
        />
      </div>
      <div className={classes.controlButtons}>
        <Typography variant="overline" className={classes.duration}>
          {/* {isPlaying ? audioCurrentTime : seconds2time(audioDurationTime)} */}
          {audioCurrentTime} / {seconds2time(audioDurationTime)}
        </Typography>
        {/* eslint-disable-next-line no-nested-ternary */}
        {isLoading ? (
          <IconButton size="small" color={customTheme.palette.grey.fadedFont} disabled={true}>
            <HourglassEmptyRounded />
          </IconButton>
        ) : !isPlaying ? (
          <IconButton
            size="small"
            color={customTheme.palette.grey.fadedFont}
            aria-label="add an alarm"
            onClick={playAudio}
            aria-describedby="test"
          >
            <PlayCircleOutline />
          </IconButton>
        ) : (
          <IconButton
            size="small"
            color={customTheme.palette.grey.fadedFont}
            aria-label="add an alarm"
            onClick={pauseAudio}
            aria-describedby="test"
          >
            <PauseCircleOutline />
          </IconButton>
        )}
        <IconButton
          size="small"
          color={customTheme.palette.grey.fadedFont}
          aria-label="add an alarm"
          onClick={stopAudio}
          aria-describedby="test"
        >
          <Stop />
        </IconButton>
        <Badge
          badgeContent={`x${selectedAudioSpeed}`}
          className={classes.audioSpeedBadge}
          color="primary"
          overlap="circular"
        >
          <IconButton
            size="small"
            color={customTheme.palette.grey.fadedFont}
            aria-label="add an alarm"
            onClick={handleAudioSpeedMenuClick}
            aria-describedby="test"
            ref={anchorSpeedButtonRef}
          >
            <AccessTime />
          </IconButton>
        </Badge>
        <Menu
          anchorEl={anchorSpeedButtonRef.current}
          keepMounted
          open={isAudioSpeedMenuOpened}
          onClose={handleAudioSpeedMenuClose}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={handleCloseSpeedMenu(1)}>x1</MenuItem>
          <MenuItem onClick={handleCloseSpeedMenu(1.5)}>x1.5</MenuItem>
          <MenuItem onClick={handleCloseSpeedMenu(2)}>x2</MenuItem>
          <MenuItem onClick={handleCloseSpeedMenu(3)}>x3</MenuItem>
        </Menu>
        {isLoading ? (
          <IconButton size="small" color={customTheme.palette.grey.fadedFont} disabled={true}>
            <HourglassEmptyRounded />
          </IconButton>
        ) : (
          <IconButton
            size="small"
            color={customTheme.palette.grey.fadedFont}
            onClick={downloadFile}
          >
            <GetAppIcon />
          </IconButton>
        )}
      </div>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio
        data-is-playing={isPlaying}
        ref={audioRef}
        onLoadedMetadata={() => {
          setAudioDurationTime(audioRef.current.duration);
        }}
        onEnded={onEnded}
        onPause={onEnded}
      />
    </div>
  );
};
