import React, { useRef } from 'react';

import { MenuCell } from '../../../tables';
import { ClonePromoMenuItem, DeleteMenuItem, EditMenuItem } from '../../../menuItems';

export const PromosMenu = ({ record, resource }) => {
  const handleCloseRef = useRef();

  return (
    <MenuCell handleCloseRef={handleCloseRef}>
      <EditMenuItem resource={resource} record={record} />
      <ClonePromoMenuItem resource={resource} record={record} handleCloseRef={handleCloseRef} />
      <DeleteMenuItem resource={resource} record={record} handleCloseRef={handleCloseRef} />
    </MenuCell>
  );
};
