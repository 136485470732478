import React, { useMemo } from 'react';
import { SelectField } from '../../common';
import { convertObjectChoicesToArray } from '../../../utils';

export const ConstantSelectField = (props) => {
  const { items } = props;

  const convertedItems = useMemo(() => {
    return convertObjectChoicesToArray(items);
  }, [items]);

  return <SelectField {...props} items={convertedItems} />;
};
