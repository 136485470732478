import React from 'react';

import { FormInstructions } from '../../../components/forms';
import { Create } from '../../../components/common';

export const CreateInstructions = (props) => {
  return (
    <Create {...props}>
      <FormInstructions {...props} redirect="list" />
    </Create>
  );
};
