import React, { useEffect, useState } from 'react';
import { useInput } from 'react-admin';
import { FormControlLabel, Checkbox as MaterialCheckbox } from '@material-ui/core';

export const Checkbox = ({ label = 'checkbox', color = 'primary', ...props }) => {
  const { source } = props;
  const { input } = useInput({ ...props });

  const [checked, setChecked] = useState(input.value);

  useEffect(() => {
    input.onChange(checked);
  }, [checked, input]);

  return (
    <FormControlLabel
      control={
        <MaterialCheckbox
          checked={checked}
          onChange={(event) => {
            setChecked(event.target.checked);
          }}
          name={source}
          color={color}
        />
      }
      label={label}
    />
  );
};
