import React, { useCallback, useMemo } from 'react';
import { RemoveCircle as RemoveCircleIcon, DoneAll as DoneAllIcon } from '@material-ui/icons';
import { Switch } from '../../../common';

const formatChecked = (checked) => {
  if (Number(checked) === 100) {
    return false;
  }

  if (Number(checked) === 200) {
    return true;
  }

  return null;
};

export const PublicSwitch = ({ onChange, checked, ...props }) => {
  const handleChange = useCallback(
    (value, name) => {
      let newValue = 100;
      if (value === true) {
        newValue = 200;
      }
      onChange(newValue, name);
    },
    [onChange],
  );

  const newChecked = useMemo(() => {
    return formatChecked(checked);
  }, [checked]);

  if (newChecked === undefined || newChecked === null) {
    return null;
  }

  return (
    <Switch
      {...props}
      icon={<RemoveCircleIcon fontSize="inherit" />}
      checkedIcon={<DoneAllIcon fontSize="inherit" />}
      name="sysStatus"
      onChange={handleChange}
      checked={newChecked}
    />
  );
};
