import React, { useMemo, useRef, useCallback, useState } from 'react';
import { FormWithRedirect } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { Dialog } from '../Dialog';

import { Button } from '../../buttons';
import { FormRow } from '../../FormRow';
import { DateTimePickerInput } from '../../RAInputs';
import { dataProvider } from '../../../dataProvider';
import { downloadFileFromBlob } from '../../../utils';

const useStyles = makeStyles(() => ({
  dialogPaper: {
    maxWidth: 600,
  },
}));

export const CommentsByOwnersDialog = (props) => {
  const classes = useStyles();
  const { changeOpenRef, selectedIds } = props;
  const formRef = useRef();

  const [loading, setLoading] = useState(false);

  const handleSave = useCallback(
    (values) => {
      const fetch = async () => {
        setLoading(true);

        try {
          const result = await dataProvider.exportOwnersCSV({
            filter: { RealtySectorIds: selectedIds.join(','), ...values },
          });
          downloadFileFromBlob(result.data, 'Выгрузка.xlsx');

          if (changeOpenRef.current) {
            changeOpenRef.current(false);
          }
        } finally {
          setLoading(false);
        }
      };

      fetch();
    },
    [changeOpenRef, selectedIds],
  );

  const handleSubmit = useCallback(() => {
    const submit = formRef.current.handleSubmit;
    if (submit) {
      submit();
    }
  }, []);

  const buttons = useMemo(() => {
    return (
      <Button onClick={handleSubmit} loading={loading}>
        Сохранить
      </Button>
    );
  }, [handleSubmit, loading]);

  return (
    <Dialog
      {...props}
      buttons={buttons}
      dialogProps={{ fullWidth: true, classes: { paper: classes.dialogPaper } }}
      title="Выгрузить комментарии по собственникам"
    >
      <FormWithRedirect
        save={handleSave}
        record={{}}
        render={(formProps) => {
          formRef.current = formProps;

          return (
            <>
              <FormRow>
                <DateTimePickerInput source="CreatedDateMin" label="Дата создания комментария: С" />
              </FormRow>
              <FormRow>
                <DateTimePickerInput
                  source="CreatedDateMax"
                  label="Дата создания комментария: По"
                />
              </FormRow>
            </>
          );
        }}
      />
    </Dialog>
  );
};
