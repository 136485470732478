import React from 'react';

import { Edit } from '../../../components/common';

import { FormSets } from '../../../components/forms';

export const EditSets = (props) => {
  return (
    <Edit {...props}>
      <FormSets {...props} redirect={false} />
    </Edit>
  );
};
