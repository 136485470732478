import React, { useMemo, useRef, useCallback } from 'react';
import { FormWithRedirect, useRefresh } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { Dialog } from '../Dialog';

import { Button } from '../../buttons';
import { ExpertSelect } from '../../RASelects';

import { dataProvider } from '../../../dataProvider';

const useStyles = makeStyles(() => ({
  dialogPaper: {
    maxWidth: 465,
  },
}));

export const DemandChangeExpertDialog = (props) => {
  const refresh = useRefresh();
  const classes = useStyles();
  const { changeOpenRef, record = {}, resource, data, selectedIds } = props;

  const formRef = useRef();

  const handleSave = useCallback(
    (values) => {
      const items = selectedIds.map((selectedId) => {
        return data[selectedId];
      });

      const fetch = async () => {
        await dataProvider.updateMany(resource, items, { ...values });
        if (changeOpenRef.current) {
          changeOpenRef.current(false);
        }
        refresh();
      };

      fetch();
    },
    [changeOpenRef, data, refresh, resource, selectedIds],
  );

  const handleSubmit = useCallback(() => {
    const submit = formRef.current.handleSubmit;
    if (submit) {
      submit();
    }
  }, []);

  const buttons = useMemo(() => {
    return <Button onClick={handleSubmit}>Сохранить</Button>;
  }, [handleSubmit]);

  return (
    <Dialog
      {...props}
      buttons={buttons}
      dialogProps={{ fullWidth: true, classes: { paper: classes.dialogPaper } }}
      title="Назначить эксперта"
      changeOpenRef={changeOpenRef}
    >
      <FormWithRedirect
        save={handleSave}
        record={record}
        render={(formProps) => {
          formRef.current = formProps;

          return <ExpertSelect source="expert" resourceId="expertId" />;
        }}
      />
    </Dialog>
  );
};
