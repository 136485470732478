import React from 'react';
import { ListItemMainBlock } from '../..';
import { getFormattedDateAndTime } from '../../../../utils';

export const ListItemHistory = ({ record }) => {
  const mainTexts = [];

  if (record.changedBy) {
    mainTexts.push(record.changedBy);
  }

  if (record.changeDate) {
    mainTexts.push(getFormattedDateAndTime(record.changeDate));
  }

  const descriptions = [record.details];

  return (
    <>
      <ListItemMainBlock
        record={record}
        hasButtons={false}
        mainTexts={mainTexts}
        descriptions={descriptions}
      />
    </>
  );
};
