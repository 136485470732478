// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-nested-ternary */
import * as React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { StatusPublishIcon, StatusUnpublishIcon, DeleteIcon } from '../../Icons';

const statuses = {
  100: 'Не опубликован',
  200: 'Опубликован',
  '-100': 'Удалён',
};

const useStyles = makeStyles(() => ({
  root: {
    padding: '8px 10px',
    fontSize: '13px',
    lineHeight: '1.4',
    maxHeight: '36px',
    textAlign: 'center',
    boxSizing: 'border-box',
    border: '1px solid #000',
    borderRadius: '3px',
    display: 'inline-block',
    background: '#fff',
  },
  icon: {
    width: '18px',
    height: '18px',
    fontSize: '0',
    lineHeight: '0',
    display: 'block',
  },
  root__publish: {
    color: '#50ae54',
    background: '#e9f5ea',
    borderColor: '#e9f5ea',
  },
  root__unpublish: {
    color: '#9e9e9e',
    borderColor: '#e0e0e0',
  },
  root__deleted: {
    color: '#F2443D',
    background: '#FFEBEE',
    borderColor: '#FFEBEE',
  },
}));

export const StatusesField = ({ record, source }) => {
  const classes = useStyles();
  const status = statuses[record[source]];

  return (
    <div
      className={`${classes.root} ${
        status === 'Опубликован'
          ? classes.root__publish
          : status === 'Не опубликован'
          ? classes.root__unpublish
          : classes.root__deleted
      }`}
    >
      <span className={classes.icon}>
        {status === 'Опубликован' ? (
          <StatusPublishIcon />
        ) : status === 'Не опубликован' ? (
          <StatusUnpublishIcon />
        ) : (
          <DeleteIcon />
        )}
      </span>
    </div>
  );
};
