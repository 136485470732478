import React, { useState, useRef, useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import ruLocale from '@fullcalendar/core/locales/ru';
import { dataProvider } from '../../dataProvider';
import { checkExpired, hourMilliseconds } from '../../utils';
import {
  CreateShowDialog,
  CreateTaskDialog,
  CreateTaskOrShowDialog,
} from '../../components/dialogs';
import { currentUser } from '../../authProvider';

const useStyles = makeStyles(() => ({
  wrapper: {
    padding: '12px',
    width: '100%',
    maxWidth: '840px',
    boxSizing: 'border-box',
    margin: '0 auto',
    fontSize: '14px',
    minWidth: '700px',
  },
}));

const headerToolbarOptions = {
  left: 'prev,next today',
  center: 'title',
  right: 'dayGridMonth,dayGridWeek,dayGridDay,listWeek',
};

// const renderEventContent = (eventInfo) => {
//   console.log(eventInfo);
//   return (
//     <>
//       <b>{eventInfo.event.extendedProps.description}</b>
//       <i>{eventInfo.event.title}</i>
//     </>
//   );
// };

const getDemos = async (info, successCallback, failureCallback) => {
  if (!currentUser) {
    return [];
  }

  try {
    const result = await dataProvider.getList('Demos', {
      pagination: { page: 1, perPage: 300 },
      filter: {
        DemoDateMin: info.startStr,
        DemoDateMax: info.endStr,
        ExpertId: String(currentUser?.id),
      },
    });

    successCallback(
      result.data.map((demo) => {
        return {
          id: `demo${demo.id}`,
          title: `Показ: ${demo.id} | ${demo.contract.name}`,
          start: demo.date,
          allDay: true,
          extendedProps: {
            type: 'show',
            item: demo,
          },
          // eslint-disable-next-line no-nested-ternary
          color: demo.calcStatus === 200 ? 'green' : demo.calcStatus === 300 ? 'red' : 'blue',
        };
      }),
    );
  } catch (error) {
    failureCallback(error);
  }
};

const getStartTime = (endTime) => {
  const end = new Date(endTime).getTime();
  const start = end - hourMilliseconds;
  return new Date(start).toISOString();
};

const getTasks = async (info, successCallback, failureCallback) => {
  if (!currentUser) {
    return [];
  }

  try {
    const result = await dataProvider.getList('Taasks', {
      pagination: { page: 1, perPage: 300 },
      filter: {
        PlannedDateMin: info.startStr,
        PlannedDateMax: info.endStr,
        AssignedBoth: String(currentUser?.id),
      },
    });

    successCallback(
      result.data.map((task) => {
        return {
          id: `task${task.id}`,
          title: `Задача: ${task.id} ${task?.taaskType?.name || ''}`,
          start: getStartTime(task.plannedDate),
          end: task.plannedDate,
          extendedProps: {
            type: 'task',
            item: task,
          },
          // eslint-disable-next-line no-nested-ternary
          color: task.completedDate ? 'green' : checkExpired(task) ? 'red' : 'grey',
          // textColor: 'black',
        };
      }),
    );
  } catch (error) {
    failureCallback(error);
  }
};

export const CalendarPage = () => {
  const classes = useStyles();

  const changeOpenCreateRef = useRef();
  const changeOpenShowRef = useRef();
  const changeOpenTaskRef = useRef();
  const calendarRef = useRef();
  const [showRecord, setShowRecord] = useState();
  const [taskRecord, setTaskRecord] = useState();
  const [selectedDate, setSelectedDate] = useState();

  const handleReset = useCallback(() => {
    setShowRecord();
    setTaskRecord();
    setSelectedDate();
  }, []);

  const onCloseDialog = useCallback(() => {
    handleReset();
  }, [handleReset]);

  const onSaveDialog = useCallback(() => {
    handleReset();
    calendarRef?.current.getApi().refetchEvents();
  }, [handleReset]);

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <MyCalendar
          setShowRecord={setShowRecord}
          setTaskRecord={setTaskRecord}
          setSelectedDate={setSelectedDate}
          changeOpenCreateRef={changeOpenCreateRef}
          changeOpenShowRef={changeOpenShowRef}
          changeOpenTaskRef={changeOpenTaskRef}
          calendarRef={calendarRef}
        />
        <CreateShowDialog
          record={showRecord}
          changeOpenRef={changeOpenShowRef}
          onClose={onCloseDialog}
          defaultDate={selectedDate}
          onSave={onSaveDialog}
        />
        <CreateTaskDialog
          record={taskRecord}
          changeOpenRef={changeOpenTaskRef}
          onClose={onCloseDialog}
          defaultDate={selectedDate}
          onSave={onSaveDialog}
        />
        <CreateTaskOrShowDialog
          changeOpenShowRef={changeOpenShowRef}
          changeOpenTaskRef={changeOpenTaskRef}
          changeOpenRef={changeOpenCreateRef}
        />
      </div>
    </div>
  );
};

const MyCalendar = React.memo((props) => {
  const {
    setShowRecord,
    setTaskRecord,
    setSelectedDate,
    changeOpenShowRef,
    changeOpenTaskRef,
    changeOpenCreateRef,
    calendarRef,
  } = props;

  const eventClick = useCallback(
    (info) => {
      if (info.event.extendedProps.type === 'show') {
        setShowRecord(info.event.extendedProps.item);
        changeOpenShowRef?.current?.(true);
      }

      if (info.event.extendedProps.type === 'task') {
        setTaskRecord(info.event.extendedProps.item);
        changeOpenTaskRef?.current?.(true);
      }
    },
    [changeOpenShowRef, changeOpenTaskRef, setShowRecord, setTaskRecord],
  );

  const dateClick = useCallback(
    (info) => {
      setSelectedDate(info.date.toISOString());
      changeOpenCreateRef?.current?.(true);
    },
    [changeOpenCreateRef, setSelectedDate],
  );

  return (
    <FullCalendar
      plugins={[interactionPlugin, dayGridPlugin, dayGridPlugin, listPlugin]}
      initialView="dayGridMonth"
      ref={calendarRef}
      headerToolbar={headerToolbarOptions}
      eventSources={[
        {
          events: getDemos,
        },
        {
          events: getTasks,
        },
      ]}
      locale={ruLocale}
      dayMaxEvents={true}
      navLinks={true}
      // eventContent={renderEventContent}
      showNonCurrentDates={false}
      eventClick={eventClick}
      dateClick={dateClick}
    />
  );
});
