import React, { useCallback, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { ConstantSelectField, SectorTasksChart } from '../../components/app';
import { AutocompleteDynamic, FormTitle } from '../../components/common';
import { SectorOwnersChart } from '../../components/app/Charts/SectorOwners/SectorTasks';
import { FormRow } from '../../components/FormRow';

const useStyles = makeStyles(() => ({
  wrapper: {
    padding: '12px',
    width: '100%',
    maxWidth: '840px',
    boxSizing: 'border-box',
    margin: '0 auto',
  },
}));

const analyticItems = {
  1: 'Сектора',
};

const SectorsAnalytic = () => {
  const [record, setRecord] = useState();

  const handleChange = useCallback((newRecord) => {
    setRecord(newRecord);
  }, []);

  return (
    <div>
      <FormTitle>Выберите сектор:</FormTitle>
      <FormRow>
        <AutocompleteDynamic
          label="Выберите сектор"
          resource="RealtySectors"
          onChange={handleChange}
        />
      </FormRow>
      {record && (
        <div>
          <FormTitle>Задачи</FormTitle>
          <SectorTasksChart record={record} />
          <FormTitle>Собственники</FormTitle>
          <SectorOwnersChart record={record} />
        </div>
      )}
    </div>
  );
};

const componentByType = {
  1: SectorsAnalytic,
};

export const AnalyticsPage = () => {
  const classes = useStyles();

  const [type, setType] = useState();
  const Component = useMemo(() => {
    if (type) {
      return componentByType[type];
    }

    return () => {
      return null;
    };
  }, [type]);

  const handleChange = useCallback((value) => {
    setType(value);
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <FormRow>
          <ConstantSelectField
            allowEmpty
            items={analyticItems}
            onChange={handleChange}
            label="Выберите тип аналитики"
          />
        </FormRow>
        <Component />
      </div>
    </div>
  );
};
