import React from 'react';
import { makeStyles, Chip } from '@material-ui/core';

import { roles as staticRoles, roleFlags } from '../../../constants';

const useStyles = makeStyles(() => ({
  chip: {
    marginRight: '4px',
    marginBottom: '4px',
  },
}));

export const RolesField = ({ record }) => {
  const classes = useStyles();

  const roles = [];

  Object.keys(roleFlags).forEach((flag) => {
    if (record[flag]) {
      roles.push(roleFlags[flag]);
    }
  });

  const rolesItems = roles.map((role) => {
    return <Chip className={classes.chip} variant="outlined" label={staticRoles[role]} />;
  });

  return rolesItems;
};
