import React, { useMemo, useRef, useCallback } from 'react';
import { FormWithRedirect } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { TextInput } from '../../TextInput/TextInput';
import { FormRow } from '../../FormRow';
import { Dialog } from '../Dialog';

import { Button } from '../../buttons';

const useStyles = makeStyles(() => ({
  dialogPaper: {
    maxWidth: 465,
  },
}));

export const ObjectRelation = (props) => {
  const classes = useStyles();
  const { changeOpenRef, record = {}, onChange } = props;

  const formRef = useRef();

  const handleSave = useCallback(
    (values) => {
      const newValues = { ...values };
      if (!newValues.id) {
        newValues.id = `client:${new Date().getTime()}`;
        newValues.clientStatus = 'create';
      } else {
        newValues.clientStatus = 'edit';
      }
      if (onChange) {
        onChange(newValues);
      }
      if (changeOpenRef.current) {
        changeOpenRef.current(false);
      }
    },
    [changeOpenRef, onChange],
  );

  const handleSubmit = useCallback(() => {
    const submit = formRef.current.handleSubmit;
    if (submit) {
      submit();
    }
  }, []);

  const buttons = useMemo(() => {
    return <Button onClick={handleSubmit}>Сохранить</Button>;
  }, [handleSubmit]);

  return (
    <Dialog
      {...props}
      buttons={buttons}
      dialogProps={{ fullWidth: true, classes: { paper: classes.dialogPaper } }}
    >
      <FormWithRedirect
        save={handleSave}
        record={record}
        render={(formProps) => {
          formRef.current = formProps;

          return (
            <FormRow isLast>
              <TextInput
                source="sortOrder"
                label="Порядок сортировки"
                type="number"
                defaultValue={1000}
              />
            </FormRow>
          );
        }}
      />
    </Dialog>
  );
};
