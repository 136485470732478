import React, { useMemo, useCallback, useState, useRef, useEffect } from 'react';
import {
  FormWithRedirect,
  useRefresh,
  TabbedFormView,
  FormTab,
  FormDataConsumer,
} from 'react-admin';
import { makeStyles, useTheme } from '@material-ui/core';
import { Dialog } from '../Dialog';

import { Button } from '../../buttons';
// import { dataProvider } from '../../../dataProvider';

import { TextInput } from '../../TextInput/TextInput';
import { BooleanInput } from '../../common';

import { FormRow } from '../../FormRow';
import { AutocompleteWithFormRow, ConstantSelect, List, TabWithFooter } from '../../app';
import { FormCard } from '../../FormCard';

import { DateTimePickerInput } from '../../RAInputs';

import {
  validates,
  checkExpired,
  checkRoleAdminOrSupervisor,
  getExpiredCount,
} from '../../../utils';
import { taskObjectTypes } from '../../../constants';
import { AssignedBySelect, AssignedToSelect } from '../../RASelects';
import { TaskTypedSelect } from '../../Selects';
import { StatusField, StatusFieldExpired } from '../../common/StatusFields';
import { dataProvider } from '../../../dataProvider';
import { currentProfile } from '../../../authProvider';
import { DynamicList } from '../../additionalLists';

const useStyles = makeStyles(() => ({
  dialogPaper: {
    // maxWidth: 600,
  },
}));

const defaultRecord = {};

export const CreateTaskDialog = (props) => {
  const classes = useStyles();
  const {
    changeOpenRef,
    record = defaultRecord,
    handleCloseRef,
    defaultType,
    defaultItem,
    onReload,
    onClose,
    onSave,
    defaultDate,
  } = props;

  const isEdit = useMemo(() => {
    if (Object.keys(record).length > 0) {
      return true;
    }

    return false;
  }, [record]);

  const refresh = useRefresh();
  const formRef = useRef();

  const [loading, setLoading] = useState(false);
  const [firstContractItem, setFirstContractItem] = useState();

  const closeMenu = useCallback(() => {
    handleCloseRef?.current?.();
  }, [handleCloseRef]);

  const handleSave = useCallback(
    (values) => {
      const fetch = async () => {
        setLoading(true);

        const resource = 'Taasks';

        try {
          if (isEdit) {
            await dataProvider.update(resource, {
              id: record.id,
              data: values,
              previousData: record,
            });
          } else {
            await dataProvider.create(resource, { data: { ...values } });
          }

          if (changeOpenRef.current) {
            changeOpenRef.current(false);
          }

          if (onReload) {
            onReload();
            return;
          }

          refresh();
          onSave?.();
          closeMenu();
        } finally {
          setLoading(false);
        }
      };

      fetch();
    },
    [changeOpenRef, closeMenu, isEdit, onReload, onSave, record, refresh],
  );

  const handleSubmit = useCallback(() => {
    const submit = formRef.current.handleSubmit;
    if (submit) {
      submit();
    }
  }, []);

  const handleClose = useCallback(() => {
    closeMenu();
    onClose?.();
  }, [closeMenu, onClose]);

  const buttons = useMemo(() => {
    return (
      <Button onClick={handleSubmit} loading={loading}>
        Сохранить
      </Button>
    );
  }, [handleSubmit, loading]);

  const [taskType, setTaskType] = useState(() => {
    if (defaultType) {
      return defaultType;
    }

    if (record.taaskObjects) {
      return record.taaskObjects[0].taaskObjectType;
    }
  });

  const [isChangedTaskType, setIsChangedTaskType] = useState(false);

  const handleChangeTaskItem = useCallback(
    (items) => {
      let newValue;
      if (Number(taskType) === 700 && items.length > 0) {
        newValue = items[0];
      }

      setFirstContractItem(newValue);
    },
    [taskType],
  );

  useEffect(() => {
    let newType = defaultType;
    let newFirstContractItem;
    if (Object.keys(record).length > 0 && record.taaskObjects) {
      newType = record.taaskObjects[0].taaskObjectType;

      if (Number(newType) === 700) {
        newFirstContractItem = record.taaskObjects[0].taaskObject;
      }
    }
    setTaskType(newType);
    setIsChangedTaskType(false);
    setFirstContractItem(newFirstContractItem);
  }, [defaultType, record]);

  const defaultTaskItem = useMemo(() => {
    if (isChangedTaskType) {
      return [];
    }

    if (defaultItem) {
      return [defaultItem];
    }
  }, [defaultItem, isChangedTaskType]);

  const handleChangeTaskType = useCallback((event) => {
    const newValue = event.target.value;
    setTaskType(newValue);
    setIsChangedTaskType(true);
    if (newValue !== 700) {
      setFirstContractItem();
    }
  }, []);

  const isExpired = useMemo(() => {
    return checkExpired(record);
  }, [record]);

  const hasRoleAdminOrSupervisor = useMemo(() => {
    return checkRoleAdminOrSupervisor(currentProfile.role);
  }, []);

  const defaultValueDate = useMemo(() => {
    if (defaultDate) {
      return defaultDate;
    }

    return new Date().toISOString();
  }, [defaultDate]);

  const theme = useTheme();

  return (
    <Dialog
      {...props}
      buttons={buttons}
      dialogProps={{ fullScreen: true, classes: { paper: classes.dialogPaper } }}
      title={isEdit ? 'Редактирование задачи' : 'Создание задачи'}
      onClose={handleClose}
    >
      <FormWithRedirect
        save={handleSave}
        record={record}
        render={(formProps) => {
          formRef.current = formProps;

          return (
            <TabbedFormView {...formProps} syncWithLocation={false} toolbar={null}>
              <FormTab label="Основное">
                <FormCard>
                  {isEdit && (
                    <FormRow>
                      <StatusField
                        styleProps={{
                          backgroundColor: record.completedDate
                            ? theme.palette.green['accentFont']
                            : theme.palette.primaryNew['accentFont'],
                          fontSize: 16,
                        }}
                      >
                        {record.completedDate ? 'Выполнена' : 'В работе'}
                      </StatusField>
                      {isExpired && (
                        <StatusFieldExpired fontSize={15} count={getExpiredCount(record)} />
                      )}
                    </FormRow>
                  )}
                  {record.id && (
                    <FormRow>
                      <TextInput source="id" label="Id" disabled />
                    </FormRow>
                  )}
                  <FormRow>
                    <DateTimePickerInput
                      source="plannedDate"
                      label="Планируемая дата выполнения"
                      validate={validates.taskPlannedDate}
                      defaultValue={defaultValueDate}
                      disabled={record.completedDate}
                    />
                  </FormRow>
                  <AssignedBySelect {...props} />
                  <AssignedToSelect {...props} />
                  <FormRow>
                    <ConstantSelect
                      source="taaskObjectType"
                      objectChoices={taskObjectTypes}
                      label="Тип объекта"
                      allowEmpty
                      onChange={handleChangeTaskType}
                      defaultValue={taskType}
                    />
                  </FormRow>
                  <FormDataConsumer>
                    {({ formData }) => {
                      if (!taskType) {
                        delete formData.taaskObjects;
                      }

                      // Костыль для бэка...
                      if (formData?.taaskObjects) {
                        formData.taaskObjects = formData.taaskObjects.map((taaskObject) => {
                          return {
                            name: taaskObject.name,
                            taaskObjectId: taaskObject.taaskObjectId,
                            taaskObjectType: taaskObject.taaskObjectType,
                          };
                        });
                      }

                      const taskObjectType = formData.taaskObjectType;

                      return (
                        taskObjectType && (
                          <FormRow>
                            <TaskTypedSelect
                              selectType={taskObjectType}
                              record={record}
                              defaultValue={defaultTaskItem}
                              onChange={handleChangeTaskItem}
                            />
                          </FormRow>
                        )
                      );
                    }}
                  </FormDataConsumer>
                  <AutocompleteWithFormRow
                    {...props}
                    source="taaskType"
                    resourceName="TaaskTypes"
                    resourceId="taaskTypeId"
                    componentProps={{
                      label: 'Тип задачи',
                      providerParams: {
                        filter: { ...(taskType && { TaaskObjectTypes: taskType }) },
                      },
                    }}
                    // validate={validates.required}
                  />
                  <FormRow>
                    <TextInput source="description" label="Описание" multiline rows={6} />
                  </FormRow>
                  <AutocompleteWithFormRow
                    {...props}
                    source="taaskTags"
                    resourceName="TaaskTags"
                    resourceId="taaskTagId"
                    additionalSource="taaskTag"
                    componentProps={{
                      label: 'Тэги задач',
                      multiple: true,
                      filterSelectedOptions: true,
                    }}
                  />
                  {!record.completedDate && (
                    <FormRow>
                      <BooleanInput
                        label="Выполнена?"
                        source="isCompleted"
                        disabled={record.completedDate}
                      />
                    </FormRow>
                  )}
                  <FormDataConsumer>
                    {({ formData }) => {
                      return (
                        (formData.isCompleted || formData.completedDate) && (
                          <>
                            <FormRow>
                              <TextInput
                                source="result"
                                label="Результат выполнения"
                                multiline
                                rows={6}
                                validate={validates.required}
                              />
                            </FormRow>
                          </>
                        )
                      );
                    }}
                  </FormDataConsumer>
                  {hasRoleAdminOrSupervisor && record.completedDate && (
                    <FormRow>
                      <DateTimePickerInput
                        source="completedDate"
                        label="Дата выполнения"
                        disabled
                      />
                    </FormRow>
                  )}
                </FormCard>
              </FormTab>
              {firstContractItem && (
                <FormTab label="Лог">
                  <TabWithFooter>
                    <DynamicList
                      fetchOptions={{
                        resource: `Contracts/${firstContractItem.id}/mergedLog`,
                      }}
                      contentElement={List}
                      contentOptions={{
                        hasButtons: false,
                        dynamicType: true,
                      }}
                      perPage={25}
                    />
                  </TabWithFooter>
                </FormTab>
              )}
            </TabbedFormView>
          );
        }}
      />
    </Dialog>
  );
};
