import React, { useState, useCallback, useEffect, useRef } from 'react';

import { Dialog as MaterialDialog, makeStyles, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { StaticImage } from '../../common';
import { Preloader } from '../..';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',

    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },
  content: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 12,

    '& button': {
      backgroundColor: 'lightgrey',

      '&:hover': {
        backgroundColor: 'lightgrey',
      },
    },
  },
}));

export const ImageFullDialog = ({ changeOpenRef, imagePath }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const isImageLoadedRef = useRef(false);

  useEffect(() => {
    if (open === true && isImageLoadedRef.current === false) {
      setLoading(true);
    }
  }, [open]);

  if (changeOpenRef) {
    changeOpenRef.current = setOpen;
  }

  const handleClose = () => {
    setOpen(false);
    isImageLoadedRef.current = false;
  };

  const handleLoading = useCallback(() => {
    setLoading(false);
    isImageLoadedRef.current = true;
  }, []);

  const handleError = useCallback(() => {
    setLoading(false);
    isImageLoadedRef.current = true;
  }, []);

  return (
    <MaterialDialog fullScreen open={open} onClose={handleClose}>
      <div className={classes.closeButton}>
        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </div>
      <div className={classes.root}>
        <div className={classes.content}>
          {loading && <Preloader loading={loading} size={60} />}
          <StaticImage
            imagePath={imagePath}
            size="1500x1500"
            onLoading={handleLoading}
            onError={handleError}
          />
        </div>
      </div>
    </MaterialDialog>
  );
};
