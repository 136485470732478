import React, { useCallback, useRef } from 'react';
import { MenuItem } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { DefaultSelectedMenu } from '../Default';
import { TouchesMultipleDialog } from '../../../dialogs';
import { dataSelector } from '../../../../redux';

export const TouchesSelectedMenu = ({ onClose, selectedIds, resource, permissions }) => {
  const reduxData = useSelector(dataSelector(resource));
  const changeOpenRef = useRef();

  const handleOpenDialog = useCallback(() => {
    changeOpenRef.current(true);

    onClose();
  }, [onClose]);

  return (
    <>
      <TouchesMultipleDialog
        changeOpenRef={changeOpenRef}
        selectedIds={selectedIds}
        data={reduxData}
        resource={resource}
        permissions={permissions}
      />
      <DefaultSelectedMenu resource={resource} selectedIds={selectedIds} onClose={onClose} />
      <MenuItem onClick={handleOpenDialog}>Редактировать поля</MenuItem>
    </>
  );
};
