import React from 'react';
import { FormTab } from 'react-admin';

import { TextInput } from '../../TextInput/TextInput';
import { TabbedForm, RichText } from '../../common';

import { validates } from '../../../utils';
import { SeoTab } from '../../formTabs';
import { FormRow } from '../../FormRow';
import { ConstantSelect } from '../../app';
import { publicationStatuses } from '../../../constants';
import { FormCard } from '../../FormCard';
import { ListImagesOrVideos } from '../../additionalLists';

export const FormFeatures = (props) => {
  const { id } = props;

  return (
    <TabbedForm {...props}>
      <FormTab label="Основное">
        <FormCard>
          {id && (
            <FormRow>
              <TextInput source="id" label="Id" disabled />
            </FormRow>
          )}
          <FormRow>
            <TextInput source="slug" label="Слаг" validate={validates.slug} />
          </FormRow>
          <FormRow>
            <ConstantSelect
              source="sysStatus"
              objectChoices={publicationStatuses}
              label="Статус публикации"
              defaultValue={100}
            />
          </FormRow>
          <FormRow>
            <TextInput
              source="sortOrder"
              label="Порядок сортировки"
              type="number"
              defaultValue={1000}
            />
          </FormRow>
          <FormRow>
            <TextInput source="name" label="Название" validate={validates.name} />
          </FormRow>
          <FormRow>
            <TextInput source="shortName" label="Краткое название" validate={validates.shortName} />
          </FormRow>
          <FormRow maxWidth={800} isLast>
            <RichText source="description" />
          </FormRow>
        </FormCard>
      </FormTab>
      <FormTab label="SEO">
        <FormCard>
          <SeoTab />
        </FormCard>
      </FormTab>
      <FormTab label="Изображения">
        <ListImagesOrVideos id={id} hasMultiple />
      </FormTab>
      <FormTab label="Видео">
        <ListImagesOrVideos id={id} isVideo />
      </FormTab>
    </TabbedForm>
  );
};
