import React from 'react';
import { NumberField } from 'react-admin';
import { currencyTypesById } from '../../../constants';

export const PriceField = (props) => {
  const { record } = props;
  const currencyId = record.currencyType;

  return (
    <NumberField
      locales="ru"
      sortable={false}
      label="Цена"
      {...props}
      options={{
        style: 'currency',
        currency: currencyTypesById[currencyId],
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }}
      style={{ fontSize: 'inherit', color: 'inherit' }}
    />
  );
};
