import { useRef, useCallback } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useDebounce = (time = 500) => {
  const timerIdRef = useRef<undefined | number>();

  const clearTimeoutDebounce = useCallback(() => {
    window.clearTimeout(timerIdRef.current);
  }, []);

  const timeout = useCallback(
    (callback: () => void) => {
      clearTimeoutDebounce();
      timerIdRef.current = window.setTimeout(() => {
        callback();
      }, time);
    },
    [clearTimeoutDebounce, time],
  );

  return { clearTimeoutDebounce, timeoutDebounce: timeout };
};
