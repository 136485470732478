import React, { useEffect, useCallback, useState } from 'react';
import { FormTab } from 'react-admin';

import { TextInput } from '../../TextInput/TextInput';
import { TabbedForm, RichText, Map, BooleanInput, FormTitle } from '../../common';

import { validates } from '../../../utils';
import { FormRow } from '../../FormRow';
import { ConstantSelect, ImageInputWithFormRow } from '../../app';
import { publicationStatuses, siteContactTypes } from '../../../constants';
import { FormCard } from '../../FormCard';

export const FormCMSContacts = (props) => {
  const { id, location, record } = props;

  const [type, setType] = useState(() => {
    if (record && record.contactType) {
      return String(record.contactType);
    }

    return '100';
  });

  useEffect(() => {
    // fix for map!
    window.dispatchEvent(new Event('resize'));
  }, [location]);

  const handleChangeType = useCallback((event) => {
    const value = event.target.value;
    setType(value);
  }, []);

  return (
    <TabbedForm {...props}>
      <FormTab label="Основное">
        <FormCard>
          {id && (
            <FormRow>
              <TextInput source="id" label="Id" disabled />
            </FormRow>
          )}
          <FormRow>
            <TextInput source="name" label="Название" validate={validates.name} />
          </FormRow>
          <FormRow>
            <ConstantSelect
              source="sysStatus"
              objectChoices={publicationStatuses}
              label="Статус публикации"
              defaultValue={100}
            />
          </FormRow>
          <FormRow>
            <TextInput
              source="sortOrder"
              label="Порядок сортировки"
              type="number"
              defaultValue={1000}
            />
          </FormRow>
          <FormTitle>Выберите тип:</FormTitle>
          <FormRow>
            <ConstantSelect
              source="contactType"
              objectChoices={siteContactTypes}
              label="Тип контактов"
              defaultValue={100}
              onChange={handleChangeType}
            />
          </FormRow>
          <FormRow>
            <TextInput source="phone" label="Телефон" helperText="Вводить по маске 79998887766" />
          </FormRow>
          {(type === '100' || type === '200') && (
            <>
              <ImageInputWithFormRow {...props} source="photo" />
              <ImageInputWithFormRow
                {...props}
                source="videoPoster"
                buttonText="Добавить постер к видео"
              />
              <ImageInputWithFormRow {...props} source="video" isVideo />
              <FormRow maxWidth={800}>
                <RichText source="description" />
              </FormRow>
              <FormRow>
                <TextInput source="email" label="Email" />
              </FormRow>
              <FormRow maxWidth={800}>
                <TextInput source="address" label="Адрес" />
              </FormRow>
              <FormRow>
                <TextInput source="whatsAppLink" label="Ссылка на WhatsApp" />
              </FormRow>
            </>
          )}
          {type === '300' && (
            <>
              <FormRow>
                <TextInput
                  source="workHoursFrom"
                  label="Время работы с"
                  type="time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormRow>
              <FormRow>
                <TextInput
                  source="workHoursTo"
                  label="Время работы по"
                  type="time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormRow>
              <FormRow>
                <BooleanInput label="Без выходных" source="isNoWeekend" />
              </FormRow>
            </>
          )}
        </FormCard>
      </FormTab>
      {type === '200' && (
        <FormTab label="Локация">
          <FormCard>
            <Map {...props} />
          </FormCard>
        </FormTab>
      )}
    </TabbedForm>
  );
};
