import React, { useCallback, useEffect } from 'react';
import { useListContext } from 'react-admin';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import { Search as SearchIcon, Clear as ClearIcon } from '@material-ui/icons';

export const Search = ({ searchBy = 'NameContains', searchClearRef }) => {
  const { filterValues, setFilters } = useListContext();
  const [value, setValue] = React.useState(filterValues[searchBy] || '');

  const handleChange = useCallback(
    (event) => {
      const newFilters = { ...filterValues };
      const newValue = event.target.value;
      if (newValue) {
        newFilters[searchBy] = newValue;
      } else {
        delete newFilters[searchBy];
      }
      setFilters(newFilters);
      setValue(newValue);
    },
    [filterValues, searchBy, setFilters],
  );

  const handleReset = useCallback(() => {
    handleChange({ target: { value: '' } });
  }, [handleChange]);

  const handleClear = useCallback(() => {
    setValue('');
    setFilters({});
  }, [setFilters]);

  useEffect(() => {
    searchClearRef.current = handleClear;
  }, [handleClear, searchClearRef]);

  return (
    <TextField
      helperText=""
      fullWidth
      variant="outlined"
      margin="none"
      label="Поиск"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <IconButton onClick={handleReset} disabled={!value}>
            <ClearIcon fontSize="small" />
          </IconButton>
        ),
      }}
      value={value}
      onChange={handleChange}
    />
  );
};
