import React, { useMemo, useRef, useCallback, useState, useEffect } from 'react';
import { FormWithRedirect, useRefresh, TabbedFormView, FormTab } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { Dialog } from '../Dialog';

import { Button } from '../../buttons';
import { dataProvider } from '../../../dataProvider';

import { TextInput } from '../../TextInput/TextInput';
import { BooleanInput, RichText } from '../../common';

import { validates } from '../../../utils';
import { FormRow } from '../../FormRow';
import { AutocompleteWithFormRow, ConstantSelect } from '../../app';
import { publicationStatuses } from '../../../constants';
import { FormCard } from '../../FormCard';
import {
  ListImagesOrVideos,
  ListInfrastructures,
  ListObjects,
  ListPeoples,
  ListVillages,
} from '../../additionalLists';
import { defaultVisibleLists, widgetTemplates, widgetTemplatesById } from '../../forms';

const useStyles = makeStyles(() => ({
  dialogPaper: {
    // maxWidth: 600,
  },
}));

const project = 'magazine';

const defaultRecord = {};

export const CreateWidgetDialog = (props) => {
  const classes = useStyles();
  const { changeOpenRef, record = defaultRecord, handleCloseRef, isEdit, page } = props;

  const refresh = useRefresh();
  const formRef = useRef();

  const [loading, setLoading] = useState(false);
  const [widgetTemplateId, setWidgetTemplateId] = useState(record?.cmsWidgetTemplateId);

  useEffect(() => {
    setWidgetTemplateId(record?.cmsWidgetTemplateId);
  }, [record]);

  const closeMenu = useCallback(() => {
    handleCloseRef?.current?.();
  }, [handleCloseRef]);

  const handleSave = useCallback(
    (values) => {
      const fetch = async () => {
        setLoading(true);

        const resource = 'cmsAdmin/widgets';

        try {
          if (isEdit) {
            await dataProvider.update(resource, {
              id: record.id,
              data: values,
              previousData: record,
            });
          } else {
            let length = 1;
            if (page.widgets) {
              length = page.widgets.length + 1;
            }
            const pages = {
              current: [
                {
                  clientStatus: 'create',
                  cmsPageId: page.id,
                  name: '.',
                  sortOrder: length,
                  // cmsPage: page,
                  id: `client:${new Date().getTime()}`,
                },
              ],
              isMultipleRelations: true,
              new: [
                {
                  clientStatus: 'create',
                  cmsPageId: page.id,
                  name: '.',
                  sortOrder: length,
                  // cmsPage: page,
                  id: `client:${new Date().getTime()}`,
                },
              ],
              old: null,
            };

            await dataProvider.create(resource, { data: { ...values, pages } });
          }

          if (changeOpenRef.current) {
            changeOpenRef.current(false);
          }

          refresh();
          closeMenu();
        } finally {
          setLoading(false);
        }
      };

      fetch();
    },
    [changeOpenRef, closeMenu, isEdit, page, record, refresh],
  );

  const handleSubmit = useCallback(() => {
    const submit = formRef.current.handleSubmit;
    if (submit) {
      submit();
    }
  }, []);

  const handleClose = useCallback(() => {
    closeMenu();
    setWidgetTemplateId(record?.cmsWidgetTemplateId);
  }, [closeMenu, record.cmsWidgetTemplateId]);

  const buttons = useMemo(() => {
    return (
      <Button onClick={handleSubmit} loading={loading}>
        Сохранить
      </Button>
    );
  }, [handleSubmit, loading]);

  const widgetTemplate = useMemo(() => {
    if (widgetTemplateId) {
      return widgetTemplatesById[widgetTemplateId];
    }
  }, [widgetTemplateId]);

  const visibleLists = useMemo(() => {
    const currentVisibleLists = { ...defaultVisibleLists };

    if (
      widgetTemplate === 'objectsSet1' ||
      widgetTemplate === 'objectsSet2' ||
      widgetTemplate === 'object'
    ) {
      currentVisibleLists.objects = true;
    }

    if (widgetTemplate === 'villages') {
      currentVisibleLists.villages = true;
    }

    if (widgetTemplate === 'experts') {
      currentVisibleLists.experts = true;
    }

    if (widgetTemplate === 'quote2') {
      currentVisibleLists.peoples = true;
    }

    return currentVisibleLists;
  }, [widgetTemplate]);

  const content = useMemo(() => {
    if (widgetTemplate) {
      return widgetTemplates[project][widgetTemplate](props);
    }
  }, [props, widgetTemplate]);

  return (
    <Dialog
      {...props}
      buttons={buttons}
      dialogProps={{ fullWidth: true, classes: { paper: classes.dialogPaper } }}
      title={isEdit ? 'Редактирование виджета' : 'Создание виджета'}
      onClose={handleClose}
    >
      <FormWithRedirect
        save={handleSave}
        record={record}
        render={(formProps) => {
          formRef.current = formProps;

          return (
            <TabbedFormView {...formProps} syncWithLocation={false} toolbar={null}>
              <FormTab label="Основное">
                <FormCard>
                  <AutocompleteWithFormRow
                    {...props}
                    source="cmsWidgetTemplate"
                    resourceName="cmsAdmin/widgetTemplates"
                    resourceId="cmsWidgetTemplateId"
                    componentProps={{
                      label: 'Шаблон виджета',
                    }}
                    onChange={(value) => {
                      setWidgetTemplateId(value?.id);
                    }}
                  />
                  <FormRow>
                    <TextInput source="name" label="Название" validate={validates.name} />
                  </FormRow>
                  <FormRow>
                    <ConstantSelect
                      source="sysStatus"
                      objectChoices={publicationStatuses}
                      label="Статус публикации"
                      defaultValue={100}
                    />
                  </FormRow>
                  {/* <FormRow>
                    <TextInput
                      source="sortOrder"
                      label="Порядок сортировки"
                      type="number"
                      defaultValue={1000}
                    />
                  </FormRow> */}
                  <FormRow maxWidth={800}>
                    <RichText source="description" />
                  </FormRow>
                  <FormRow>
                    <BooleanInput label="Общий компонент" source="isShared" />
                  </FormRow>
                  <AutocompleteWithFormRow
                    {...props}
                    source="widgetTags"
                    resourceName="cmsAdmin/widgetTags"
                    resourceId="cmsWidgetTagId"
                    additionalSource="cmsWidgetTag"
                    componentProps={{
                      label: 'Тэги виджетов',
                      multiple: true,
                      filterSelectedOptions: true,
                    }}
                  />
                </FormCard>
              </FormTab>
              {widgetTemplate && (
                <FormTab label="Контент">
                  <FormCard>{content}</FormCard>
                </FormTab>
              )}
              {visibleLists.images && (
                <FormTab label="Изображения">
                  <ListImagesOrVideos hasMultiple hasTemplateName record={record} />
                </FormTab>
              )}
              {visibleLists.videos && (
                <FormTab label="Видео">
                  <ListImagesOrVideos isVideo record={record} />
                </FormTab>
              )}
              {visibleLists.infrastructures && (
                <FormTab label="Инфраструктура">
                  <ListInfrastructures record={record} page="createWidget" />
                </FormTab>
              )}
              {visibleLists.objects && (
                <FormTab label="Объекты">
                  <ListObjects record={record} page="createWidget" />
                </FormTab>
              )}
              {visibleLists.villages && (
                <FormTab label="Комплексы">
                  <ListVillages record={record} page="createWidget" />
                </FormTab>
              )}
              {visibleLists.peoples && (
                <FormTab label="Люди">
                  <ListPeoples record={record} />
                </FormTab>
              )}
              {visibleLists.experts && (
                <FormTab label="Эксперты">
                  <ListPeoples onlyExperts />
                </FormTab>
              )}
            </TabbedFormView>
          );
        }}
      />
    </Dialog>
  );
};
