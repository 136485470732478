import React, { useCallback, useState, useRef, useEffect } from 'react';

import { MenuItem } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { AddButton } from '../../buttons';

import { MenuCell } from '../../tables';
import { useAdditionalTableValue } from '../../../hooks';
import { StaticList } from '..';
import { DnDList } from '../../app';
import { RelationObjectSelectedMenu } from '../../app/SelectedMenus';
import { CreateWidgetDialog } from '../../dialogs';
// import { CMSWidgetsSelect } from '../../Selects';
// import { InfrastructureRelation as InfrastructureRelationDialog } from '../../dialogs';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    padding: '16px 16px 0 16px',
    boxSizing: 'border-box',
  },
  content: {
    flex: '1 1 0',
    minHeight: 0,
    overflow: 'auto',
    width: '100%',
  },
  headerContainer: {
    flex: '0 0 auto',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '24px',
  },
  title: {
    fontSize: '18px',
    lineHeight: '1.4',
    fontWeight: '500',
    paddingBottom: '16px',
    display: 'flex',
    alignItems: 'center',
  },
  title_left: {
    flex: '1 1 auto',
    boxSizing: 'border-box',
    paddingRight: '20px',
  },
  title_right: {
    flex: '0 1 auto',
    boxSizing: 'border-box',
  },
  header_left: {
    flex: '1 1 auto',
    boxSizing: 'border-box',
    paddingRight: '20px',
  },
  header_right: {
    flex: '0 1 auto',
    boxSizing: 'border-box',
  },
}));

const Cards = ({ menuItem, items, onChangeItems, record, onDeleteItems }) => {
  const [selectedIds, setSelectedIds] = useState([]);
  const selectedIdsRef = useRef([]);

  const handleChangeSelectedIds = useCallback(
    (id) => {
      let newIds = [...selectedIds];
      if (newIds.includes(id)) {
        newIds = newIds.filter((selectedId) => {
          return selectedId !== id;
        });
      } else {
        newIds.push(id);
      }
      setSelectedIds(newIds);
      selectedIdsRef.current = newIds;
    },
    [selectedIds],
  );

  useEffect(() => {
    const newIds = selectedIdsRef.current.filter((selectedId) => {
      return items.find((item) => {
        return item.id === selectedId;
      });
    });

    setSelectedIds(newIds);
  }, [items]);

  useEffect(() => {
    setSelectedIds([]);
    selectedIdsRef.current = [];
  }, [record]);

  const onReset = useCallback(() => {
    setSelectedIds([]);
  }, []);

  return (
    <StaticList
      contentOptions={{
        type: 'cmsWidget',
        menuItem,
        onToggleItem: handleChangeSelectedIds,
        selectedIds,
        depthKey: 'cmsWidget',
      }}
      selectedOptions={{
        selectedMenuOptions: RelationObjectSelectedMenu,
        onChangeItems,
        onReset,
        onDeleteItems,
      }}
      items={items}
      contentElement={DnDList}
      hasPagination={false}
    />
  );
};

const Table = ({ items, onChange, onDelete, record: propRecord }) => {
  const changeOpenRef = useRef();
  const [record, setRecord] = useState();

  const handleOpenDialog = useCallback((currentRecord) => {
    return () => {
      setRecord(currentRecord);
      if (changeOpenRef.current) {
        changeOpenRef.current(true);
      }
    };
  }, []);

  const handleDelete = useCallback(
    (currentRecord) => {
      return () => {
        if (onDelete) {
          onDelete(currentRecord.id);
        }
      };
    },
    [onDelete],
  );

  const menuItem = (item, _, originalItem) => {
    return (
      <MenuCell icon={<MoreVertIcon />}>
        <MenuItem onClick={handleOpenDialog(originalItem)}>Редактировать</MenuItem>
        <MenuItem onClick={handleDelete(item)}>Удалить</MenuItem>
      </MenuCell>
    );
  };

  return (
    <div style={{ position: 'relative', flex: '1 1 auto' }}>
      <Cards
        items={items}
        menuItem={menuItem}
        onChangeItems={onChange}
        onDeleteItems={onDelete}
        record={propRecord}
      />
      <CreateWidgetDialog
        title="Редактирование виджета"
        changeOpenRef={changeOpenRef}
        record={record}
        isEdit={true}
      />
    </div>
  );
};

export const ListCMSWidgets = ({ record, defaultValue }) => {
  const [values, onChangeValues, onDeleteValues] = useAdditionalTableValue(
    record,
    'widgets',
    defaultValue,
  );
  const classes = useStyles();

  const changeOpenRef = useRef();

  const handleCreate = useCallback(() => {
    if (changeOpenRef.current) {
      changeOpenRef.current(true);
    }
  }, []);

  // const [selectValue, setSelectValue] = useState([]);

  // const disabledAdd = useMemo(() => {
  //   return selectValue.length === 0;
  // }, [selectValue]);

  // const handleAdd = useCallback(() => {
  //   const currentValues = selectValue;
  //   let newValues = currentValues.filter((currentValue) => {
  //     // eslint-disable-next-line no-extra-boolean-cast
  //     return !Boolean(
  //       values.find((value) => {
  //         return value.cmsWidgetId === currentValue.id;
  //       }),
  //     );
  //   });

  //   newValues = newValues.map((value, index) => {
  //     return {
  //       id: `client:${new Date().getTime()}${index}`,
  //       cmsWidgetId: value.id,
  //       cmsWidget: { ...value },
  //       name: '.',
  //       clientStatus: 'create',
  //       sortOrder: 1000,
  //     };
  //   });

  //   onChangeValues(newValues);
  //   setSelectValue([]);
  // }, [selectValue, values, onChangeValues]);

  return (
    <div className={classes.root}>
      <div className={classes.headerContainer}>
        <span className={classes.title}>
          <div className={classes.title_left}>Виджеты</div>
          <div className={classes.title_right}>
            <AddButton onClick={handleCreate} text="Создать виджет" />
          </div>
        </span>
        {/* <div className={classes.header}>
          <div className={classes.header_left}>
            <CMSWidgetsSelect valueProp={selectValue} setValueProp={setSelectValue} />
          </div>
          <div className={classes.header_right}>
            <AddButton disabled={disabledAdd} onClick={handleAdd} styleSelectAdd />
          </div>
        </div> */}
      </div>
      <Table items={values} onChange={onChangeValues} onDelete={onDeleteValues} record={record} />
      <CreateWidgetDialog title="Создание виджета" changeOpenRef={changeOpenRef} page={record} />
    </div>
  );
};
