import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { customTheme } from '../../theme';

const useStyles = makeStyles(() => ({
  formCard: {
    width: '100%',
    marginBottom: 16,
    boxSizing: 'border-box',
    padding: '25px 16px 1px 16px',
    borderRadius: 4,
    background: customTheme.palette.common.white,
  },
}));

export const FormCard = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.formCard}>{children}</div>;
};
