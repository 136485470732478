import React, { useMemo, useState, useCallback, useRef, useEffect } from 'react';
import { useInput, useRedirect } from 'react-admin';
import { Chip } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { AutocompleteDynamic } from '../../common';
import { PhotoField, NameField } from '../../RAFields';
import { getFullName } from '../../../utils';

const useStyles = makeStyles(() => ({
  avatar: {
    width: '24px',
    height: '24px',
    marginRight: '6px',
    fontSize: '10px',
  },
  photo: {
    paddingRight: '20px',
  },
}));

const defaultProps = {
  fullDynamic: 'NameContains',
  imagePathType: 'images',
  additionalIdKey: 'slug',
};

const defaultParams = {
  filter: {
    FlatDetails: 300,
  },
};

const propsByType = {
  100: {
    ...defaultProps,
    resource: 'RealtyGroups',
  },
  200: {
    ...defaultProps,
    fullDynamic: 'QueryWithFid',
    resource: 'RealtyObjects',
    additionalIdKey: 'friendlyId',
  },
  300: {
    ...defaultProps,
    resource: 'RealtySectors',
    additionalIdKey: null,
  },
  400: {
    ...defaultProps,
    resource: 'RealtyObjectSets',
    imagePathType: 'photo',
  },
  500: {
    ...defaultProps,
    resource: 'Infrastructures',
  },
  600: {
    ...defaultProps,
    resource: 'Peoples',
    fullDynamic: 'AllNames',
    imagePathType: 'photo',
    additionalIdKey: null,
    isPeople: true,
    providerParams: {
      ...defaultParams,
      filter: {
        ...defaultParams.filter,
        // IsStaff: true,
      },
    },
  },
  700: {
    ...defaultProps,
    resource: 'Contracts',
    imagePathType: null,
    additionalIdKey: null,
  },
};

const WrapperPhotoField = ({ record, imagePathType }) => {
  const path = useMemo(() => {
    if (imagePathType === 'images' && record.images && record.images[0]) {
      return record.images[0].path;
    }

    if (imagePathType === 'photo' && record.photo) {
      return record.photo;
    }
  }, [imagePathType, record.images, record.photo]);

  return <PhotoField path={path} />;
};

const WrapperNameField = ({ record, additionalIdKey, isPeople }) => {
  const { id, name: propName } = record;

  let name = propName;

  let additionalId;

  if (additionalIdKey && record[additionalIdKey]) {
    additionalId = record[additionalIdKey];
  }

  if (isPeople) {
    name = getFullName(record);
  }

  return <NameField id={id} name={name} additionalId={additionalId} />;
};

const Option = ({ option, additionalIdKey, imagePathType, isPeople }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.photo}>
        <WrapperPhotoField record={option} imagePathType={imagePathType} />
      </div>
      <WrapperNameField record={option} additionalIdKey={additionalIdKey} isPeople={isPeople} />
    </>
  );
};

const OptionLabel = ({ record, additionalIdKey, isPeople, resource }) => {
  const redirect = useRedirect();

  const handleClick = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      let resultResource = resource;
      if (resource === 'Peoples' && record.isClient) {
        resultResource = 'Clients';
      }
      if (event.ctrlKey || event.metaKey) {
        window.open(`/${resultResource}/${record.id}`, '_blank');
      } else {
        redirect('edit', `/${resultResource}`, record.id);
      }
    },
    [record.id, record.isClient, redirect, resource],
  );

  let additionalIdString = '';
  let name = record.name;

  if (record[additionalIdKey]) {
    additionalIdString = ` / ${record[additionalIdKey]}`;
  }

  if (isPeople) {
    name = getFullName(record);
  }

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a href="#" onClick={handleClick} style={{ color: 'inherit' }}>
      {record.id} / {name}
      {additionalIdString}
    </a>
  );
};

const getDefaultValues = (record) => {
  if (record.taaskObjects) {
    return record.taaskObjects.map((taaskObject) => {
      return taaskObject.taaskObject;
    });
  }

  return [];
};

const convertToInputValue = (items, selectType) => {
  return items.map((item) => {
    return {
      name: String(item.id),
      taaskObjectId: Number(item.id),
      taaskObjectType: Number(selectType),
      // taaskObject: item,
    };
  });
};

export const TaskTypedSelect = (props) => {
  const { selectType = 200, record, defaultValue, onChange } = props;
  const { additionalIdKey, imagePathType, isPeople = false, resource } = propsByType[selectType];

  const [selectValue, setSelectValue] = useState(() => {
    if (defaultValue) {
      return defaultValue;
    }

    // if (isChangedTaskType) {
    //   return;
    // }

    return getDefaultValues(record);
  });

  const defaultInputValue = useMemo(() => {
    if (defaultValue) {
      return convertToInputValue(defaultValue, selectType);
    }

    return [];
  }, [defaultValue, selectType]);

  const { input } = useInput({
    name: 'taaskObjects',
    source: 'taaskObjects',
    defaultValue: defaultInputValue,
  });

  const oldSeletTypeRef = useRef();

  useEffect(() => {
    if (oldSeletTypeRef.current !== undefined) {
      setSelectValue([]);
      input.onChange([]);
    }

    oldSeletTypeRef.current = selectType;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectType]);

  const handleChangeTaskObject = useCallback(
    (newValue) => {
      const value = convertToInputValue(newValue, selectType);
      input.onChange(value);
      onChange?.(newValue);
    },
    [input, onChange, selectType],
  );

  return (
    <AutocompleteDynamic
      multiple
      filterSelectedOptions
      limitTags={4}
      renderOption={(option) => (
        <Option
          option={option}
          additionalIdKey={additionalIdKey}
          imagePathType={imagePathType}
          isPeople={isPeople}
        />
      )}
      providerParams={defaultParams}
      getOptionLabel={(option) => option.name || ''}
      renderTags={(value, getChipProps) => {
        return value.map((option, index) => (
          <Chip
            label={
              <OptionLabel
                record={option}
                additionalIdKey={additionalIdKey}
                isPeople={isPeople}
                resource={resource}
              />
            }
            {...getChipProps({ index })}
            color="primary"
          />
        ));
      }}
      filterOptions={(options) => options}
      {...propsByType[selectType]}
      {...props}
      valueProp={selectValue}
      setValueProp={setSelectValue}
      onChange={handleChangeTaskObject}
      label="Объекты"
    />
  );
};
