import React from 'react';

import { ListBase } from '../../../../components/ListBase';

import { RAList } from '../../../../components/app';

const MainList = () => {
  return <RAList type="cmsWidget" />;
};

export const ListCMSWidgets = (props) => {
  return (
    <ListBase {...props}>
      <MainList {...props} />
    </ListBase>
  );
};
