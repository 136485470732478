import { getPresentationLink } from './links';
import { dataProvider } from '../dataProvider';
import { roleFlags, urlsPresentationByType } from '../constants';

export * from './validates';
export * from './filters';
export * from './roles';
export * from './links';
export * from './tasks';
export * from './discounts';

export const getRowsFromListContext = (ids, data, resource) => {
  const rows = [];
  ids.forEach((id) => {
    if (id in data) {
      rows.push({ ...data[id], _resource: resource });
    }
  });

  return rows;
};

export const getMillions = (number) => {
  return number / 1e6;
};

export const getFormattedNumber = (number) => {
  return new Intl.NumberFormat('ru', {
    maximumFractionDigits: 0,
  }).format(number);
};

export const getFormattedCurrency = (number) => {
  return new Intl.NumberFormat('ru', {
    style: 'currency',
    currency: 'RUB',
    maximumFractionDigits: 0,
  }).format(number);
};

export const getFormattedDate = (
  value,
  options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  },
) => {
  if (value) {
    return new Intl.DateTimeFormat('ru', options).format(Date.parse(String(value)));
  }

  return '';
};

export const getFormattedDateAndTime = (
  value,
  options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
) => {
  return getFormattedDate(value, options);
};

const checkSafariBrowser = () => {
  return navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') <= -1;
};

const isSafariBrowser = checkSafariBrowser();

export const downloadPDF = async (type = 'sets', id, withoutLogo) => {
  const withoutLogoString = withoutLogo ? '-private' : '';
  const href = getPresentationLink(type, id, withoutLogo);
  const name = `${urlsPresentationByType[type]}-${id}${withoutLogoString}`;

  const isExist = await dataProvider.existPresentation(href);

  if (!isExist) {
    return;
  }

  const link = document.createElement('a');
  link.setAttribute('href', href);
  link.setAttribute('download', name);
  if (!isSafariBrowser) {
    link.setAttribute('target', '_blank');
  }
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  return true;
};

export const downloadFileFromBlob = (blob, name) => {
  const link = document.createElement('a');
  let newName = name;
  if (blob.type === 'audio/vnd.wave') {
    newName += '.wav';
  }
  link.download = newName;

  link.href = URL.createObjectURL(blob);
  link.click();

  URL.revokeObjectURL(link.href);
};

export const copyToClipboard = (text) => {
  const textArea = document.createElement('textarea');

  textArea.style.position = 'fixed';
  textArea.style.top = -10;
  textArea.style.left = -10;

  // Ensure it has a small width and height. Setting to 1px / 1em
  // doesn't work as this gives a negative w/h on some browsers.
  textArea.style.width = 0;
  textArea.style.height = 0;

  textArea.value = text;

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    console.log(`Copying text command was ${msg}`);
  } catch (err) {
    console.log('Oops, unable to copy');
  }

  document.body.removeChild(textArea);
};

export const convertObjectChoicesToArray = (object) => {
  return Object.keys(object).map((key) => {
    return { id: key, text: object[key] };
  });
};

export const mergeClasses = (...classes) => {
  return classes.filter(Boolean).join(' ') || '';
};

export const getStaticImage = (size, url) => {
  return `${process.env.REACT_APP_STATIC_API_URL}cache-new/${size}/${url}`;
};

export const getValueByDepthKey = (object, path) => {
  return path.split('.').reduce((prev, item) => {
    return prev?.[item];
  }, object);
};

export const parseBoolean = (value) => {
  if (value === '' || value === undefined || value === null) {
    return '';
  }

  if (value === 'false' || value === false) {
    return false;
  }

  return true;
};

export const getFullName = (user) => {
  let name = '';

  if (user.name) {
    name += user.name;
  }
  if (user.middleName) {
    name += ` ${user.middleName}`;
  }
  if (user.lastName) {
    name += ` ${user.lastName}`;
  }

  return name;
};

export const getFullNameWithId = (user) => {
  let newString = `${user.id}: `;
  newString += getFullName(user);

  return newString;
};

export const checkType = (value) => {
  return Object.prototype.toString.call(value).match(/\w+/g, '')[1];
};

export const isObject = (value) => {
  if (checkType(value) === 'Object') {
    return true;
  }

  return false;
};

export const cropObjectDepth = (object, maxDepth = 2, depth = 1) => {
  const newObject = { ...object };

  Object.keys(newObject).forEach((key) => {
    const value = newObject[key];

    if (checkType(value) === 'Object') {
      if (depth < maxDepth) {
        newObject[key] = cropObjectDepth(value, maxDepth, depth + 1);
      } else {
        delete newObject[key];
      }
    } else if (checkType(value) === 'Array' && checkType(value[0]) === 'Object') {
      if (depth < maxDepth) {
        value.forEach((item, index) => {
          const newArray = [...value];
          newArray[index] = cropObjectDepth(item, maxDepth, depth + 1);
          newObject[key] = newArray;
        });
      } else {
        delete newObject[key];
      }
    }
  });

  return newObject;
};

const randomNum = () => {
  return Math.floor(Math.random() * (235 - 52 + 1) + 52);
};

export const getRandomRGB = () => {
  return `rgb(${randomNum()}, ${randomNum()}, ${randomNum()})`;
};

export const getISOTime = (stringDate) => {
  return stringDate.substr(11, 5);
};

export const checkExistingISOTime = (time) => {
  if (time === '00:00') {
    return false;
  }

  return true;
};

export const convertLoginToPeople = (profile) => {
  const flags = {};

  Object.keys(roleFlags).forEach((flag) => {
    if (profile.roles.includes(roleFlags[flag])) {
      flags[flag] = true;
    }
  });

  const newPerson = {
    name: profile.firstName,
    lastName: profile.lastName,
    eMail: profile.email,
    middleName: profile.otherName,
    phone: profile.phoneNumber,
    isStaff: profile.isStaff,
    authId: profile.id,
    ...flags,
  };

  return newPerson;
};

export const dayMilliseconds = 24 * 3600 * 1000;
export const hourMilliseconds = 1 * 3600 * 1000;

export const getToday = () => {
  const start = new Date();
  start.setUTCHours(0, 0, 0, 0);
  const end = new Date();
  end.setUTCHours(23, 59, 59, 999);

  return {
    start,
    end,
  };
};

export const getTomorrow = () => {
  const now = new Date().getTime();
  const start = new Date(now + dayMilliseconds);
  start.setUTCHours(0, 0, 0, 0);
  const end = new Date(now + dayMilliseconds);
  end.setUTCHours(23, 59, 59, 999);

  return {
    start,
    end,
  };
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
