import React, { useCallback, useState, useEffect, useMemo } from 'react';

import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { useListContext } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { ListBase } from '../../../components/ListBase';

import { ConstantSelectField, RAList } from '../../../components/app';
import { ClientsMenu } from '../../../components/app/Menus';
import { clientProcessStatuses, clientTouchTypes } from '../../../constants';
// import { currentUser } from '../../../authProvider';
// import { checkRoleAdminOrSupervisorOrOdp } from '../../../utils';
import { ClientsSelectedMenu } from '../../../components/app/SelectedMenus';

// const useStyles = makeStyles(() => ({
//   root: {
//     display: 'flex',
//     flexDirection: 'column',
//     height: '100%',
//   },
//   newItems: {
//     flex: '0 0 auto',
//     maxHeight: '50%',
//     position: 'relative',
//     overflow: 'auto',
//     borderBottom: '1px solid #E0E0E0',
//     paddingBottom: 16,
//     marginBottom: 16,
//   },
//   items: {
//     flex: '1 1 auto',
//     position: 'relative',
//     overflow: 'auto',
//   },
// }));

// const NewClients = ({ menuItem }) => {
//   const styles = useStyles();
//   const [items, setItems] = useState([]);
//   const { selectedIds, onToggleItem, resource, ids } = useListContext();
//   const newTouch = useContext(TouchesWebsocketContext);
//   const newClient = useContext(ClientsWebsocketContext);

//   useEffect(() => {
//     if (newTouch) {
//       setItems((currentItems) => {
//         const newItems = [...currentItems];
//         const foundIndex = currentItems.findIndex((currentItem) => {
//           return newTouch.clientTouch.client.id === currentItem.id;
//         });

//         if (foundIndex !== -1) {
//           newItems.splice(foundIndex, 1, { ...newTouch.clientTouch.client });

//           return newItems;
//         }

//         return [{ ...newTouch.clientTouch.client }, ...currentItems];
//       });
//     }
//   }, [newTouch]);

//   useEffect(() => {
//     if (newClient?.userId) {
//       setItems((currentItems) => {
//         const newItems = [...currentItems];
//         const foundItem = newItems.find((newItem) => {
//           return newClient.userId === newItem.id;
//         });

//         if (foundItem) {
//           delete foundItem['isNotProcessed'];
//           return newItems;
//         }

//         return currentItems;
//       });
//     }
//   }, [newClient]);

//   useEffect(() => {
//     setItems([]);
//   }, [ids]);

//   if (items.length === 0) {
//     return null;
//   }

//   return (
//     <div className={styles.newItems}>
//       <List
//         type="client"
//         menuItem={menuItem}
//         selectedIds={selectedIds}
//         onToggleItem={onToggleItem}
//         resource={resource}
//         items={items}
//       />
//     </div>
//   );
// };

const CardsClients = ({ menuItem }) => {
  return <RAList type="client" menuItem={menuItem} isNewItem />;
};

const useHeaderStyles = makeStyles(() => ({
  filterRows: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  filterRow: {
    flex: '0 0 auto',
    width: '49%',

    '&:first-child': {
      paddingLeft: 0,
    },
  },
}));

const CustomHeaderContent = () => {
  const classes = useHeaderStyles();
  const [status, setStatus] = useState([]);
  const [processStatus, setProcessStatus] = useState([]);
  const { filterValues, setFilters } = useListContext();

  useEffect(() => {
    setProcessStatus(filterValues.ProcessStatuses ? filterValues.ProcessStatuses.split(',') : []);
    setStatus(filterValues.Targets ? filterValues.Targets.split(',') : []);
  }, [filterValues]);

  const handleChange = useCallback(
    (newValue, event) => {
      const name = event.target.name;
      const newFilterValues = { ...filterValues };

      if (name === 'ProcessStatuses') {
        setProcessStatus(newValue.split(','));
      }
      if (name === 'Targets') {
        setStatus(newValue.split(','));
      }

      newFilterValues[name] = newValue;
      if (!newValue) {
        delete newFilterValues[name];
      }
      setFilters(newFilterValues);
    },
    [filterValues, setFilters],
  );

  return (
    <>
      <div className={classes.filterRows}>
        <div className={classes.filterRow}>
          <ConstantSelectField
            items={clientTouchTypes}
            onChange={handleChange}
            name="Targets"
            value={status}
            isControlled
            label="Статус клиента"
            multiple
          />
        </div>
        <div className={classes.filterRow}>
          <ConstantSelectField
            items={clientProcessStatuses}
            onChange={handleChange}
            name="ProcessStatuses"
            value={processStatus}
            isControlled
            label="Статус обработки"
            multiple
          />
        </div>
      </div>
    </>
  );
};

export const ListClients = (props) => {
  const { resource } = props;
  // const styles = useStyles();

  const menuItem = useCallback(
    (record) => {
      return <ClientsMenu icon={<MoreVertIcon />} record={record} resource={resource} />;
    },
    [resource],
  );

  // const isAdminOrOdp = useMemo(() => {
  //   return checkRoleAdminOrSupervisorOrOdp(permissions);
  // }, [permissions]);

  const filter = useMemo(() => {
    // if (!isAdminOrOdp) {
    //   return { ExpertOrOdpIds: String(currentUser?.id) };
    // }

    return {};
  }, []);

  return (
    <ListBase
      {...props}
      hasSearchVariants={false}
      searchBy="AllNames"
      resource="Clients"
      filter={{ IsClient: true, ...filter }}
      filterDefaultValues={{ SortBy: 1500 }}
      customHeaderContent={<CustomHeaderContent />}
      selectedMenuOptions={ClientsSelectedMenu}
      hasExport
    >
      {/* <div className={styles.root}> */}
      {/* <NewClients resource={resource} menuItem={menuItem} /> */}
      {/* <div className={styles.items}> */}
      <CardsClients {...props} menuItem={menuItem} />
      {/* </div> */}
      {/* </div> */}
    </ListBase>
  );
};
