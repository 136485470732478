import React from 'react';
import { RemoveShoppingCart as RemoveShoppingCartIcon } from '@material-ui/icons';
import { StatusField } from '../StatusField';

export const StatusFieldSold = () => {
  return (
    <StatusField styleProps={{ backgroundColor: '#FF3D00', fontSize: '16px' }}>
      <RemoveShoppingCartIcon fontSize="inherit" />
    </StatusField>
  );
};
