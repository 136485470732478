// eslint-disable-next-line no-use-before-define
import React from 'react';
import { useListContext } from 'react-admin';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => {
  return createStyles({
    EditButton: {
      marginLeft: 'auto',
    },
  });
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const EditButton = ({ id }: { id: number }) => {
  const { resource } = useListContext();
  const styles = useStyles();

  return (
    <Button
      size="small"
      variant="contained"
      color="primary"
      className={styles.EditButton}
      component={Link}
      to={{
        pathname: `/${resource}/${id}`,
      }}
    >
      Подробнее
    </Button>
  );
};
