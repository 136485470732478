import React, { useCallback } from 'react';
import { useRedirect } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { customTheme } from '../../../theme';

const useStyles = makeStyles(() => ({
  name: {
    lineHeight: '1.4',
    fontSize: '14px',
    display: 'block',

    '& a': {
      color: customTheme.palette.primary['A200'],

      '&:hover': {
        textDecoration: 'none',
      },
    },
  },
}));

export const FieldName = ({ name, link, target = '_blank' }) => {
  const classes = useStyles();
  const redirect = useRedirect();

  const handleClick = useCallback(
    (event) => {
      if (!target) {
        event.preventDefault();
        redirect(link);
      }
    },
    [link, redirect, target],
  );

  return (
    <div className={classes.root}>
      <span className={classes.name}>
        {link ? (
          <a href={link} rel="noreferrer" target={target} onClick={handleClick}>
            {name}
          </a>
        ) : (
          name
        )}
      </span>
    </div>
  );
};
