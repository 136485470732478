import React, { useMemo, useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import { mergeClasses } from '../../../utils';
import { Avatar, StaticImage } from '../../common';
import defaultPhoto from '../../../assets/images/deafultPhoto.png';
import { StatusFields } from '../../common/StatusFields';
import { FieldName } from '../../Fields';
import { CardDescriptions } from '../CardDescriptions';
import { CardChips } from '../CardChips';
import { Checkbox } from '../../common/Checkbox';

const useStyles = makeStyles(() => ({
  root: {
    margin: '10px',
    width: '300px',
    boxShadow:
      '0px 0px 24px rgba(0, 0, 0, 0.05), 0px 0px 4px rgba(0, 0, 0, 0.05), 0px 0px 8px rgba(0, 0, 0, 0.05)',
    borderRadius: '6px',
    boxSizing: 'border-box',
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
    flex: '0 1 auto',
  },
  header: {
    flex: '0 1 auto',
    position: 'relative',
    height: '197px',
    overflow: 'hidden',
    borderBottom: '1px solid #bdbdbd',
  },
  headerImage: {
    minWidth: '100%',
    minHeight: '100%',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  container_content: {
    padding: '24px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
  },
  content: {
    flex: '1 1 auto',
  },
  managerContainer: {
    position: 'absolute',
    borderRadius: '50%',
    border: '4px solid white',
    top: '-14px',
    right: '12px',
    zIndex: '2',
  },
  manager: {
    width: 32,
    height: 32,
  },
  authorContainer: {
    borderTop: '1px solid #bdbdbd',
    paddingTop: '8px',
    marginTop: '16px',
  },
  name: {
    marginTop: '16px',
  },
  buttons: {
    marginTop: '20px',
    paddingTop: '12px',
    borderTop: '1px solid rgba(0, 0, 0, 0.3)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: '0 1 auto',
  },
}));

export const Card = (props) => {
  const {
    data,
    fieldNameProps,
    imagePath,
    statusFields = [],
    descriptions,
    chipTexts,
    menuElement: menuElementProp = 'menu',
    menuItem,
    onToggleItem,
    selected,
    hasCheckbox = true,
    classes: propClasses = {},
    hasImage = true,
    children,
    hasButtons = true,
  } = props;

  const classes = useStyles();

  const manager = useMemo(() => {
    return data?.manager;
  }, [data]);

  const handleToggleCheckbox = useCallback(() => {
    if (onToggleItem) {
      onToggleItem(data.id);
    }
  }, [onToggleItem, data]);

  const menuElement = useMemo(() => {
    if (menuItem) {
      return menuItem;
    }

    return menuElementProp;
  }, [menuElementProp, menuItem]);

  return (
    <div className={classes.root}>
      {hasImage && (
        <div className={classes.header}>
          <StaticImage
            imagePath={imagePath}
            size="320x197"
            defaultImagePath={defaultPhoto}
            imgAttributes={{
              className: mergeClasses(classes.headerImage, propClasses?.image),
              alt: data.name,
            }}
          />
        </div>
      )}
      <div className={classes.container_content}>
        <div className={classes.content}>
          {manager && (
            <div className={classes.managerContainer}>
              <Avatar option={manager} className={classes.manager} />
            </div>
          )}
          <StatusFields fields={statusFields} />
          <div className={classes.name}>
            <FieldName {...fieldNameProps} />
          </div>
          <CardDescriptions descriptions={descriptions} />
          <CardChips texts={chipTexts} />
          {children}
        </div>
        {hasButtons && (
          <div className={classes.buttons}>
            <div className={classes.container_menu}>{menuElement}</div>
            <div className={classes.container_checkbox}>
              {hasCheckbox && <Checkbox checked={selected} onChange={handleToggleCheckbox} />}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
