import React from 'react';

import { FormTasks } from '../../../components/forms';
import { Edit } from '../../../components/common';

export const EditTasks = (props) => {
  return (
    <Edit {...props}>
      <FormTasks {...props} redirect={false} />
    </Edit>
  );
};
