import React, { useState, useCallback, useMemo } from 'react';

import { TextField as MaterialTextField } from '@material-ui/core';

export const TextField = (props) => {
  const {
    value: propValue,
    isControlled = false,
    defaultValue = '',
    onChange,
    children,
    ...otherProps
  } = props;

  const { select, multiple } = otherProps;

  const [value, setValue] = useState(() => {
    if (!defaultValue && select && multiple) {
      return [];
    }

    return defaultValue;
  });

  const handleChange = useCallback(
    (event) => {
      let newValue = event.target.value;
      setValue(newValue);

      if (select && multiple) {
        newValue = newValue.join(',');
      }

      if (onChange) {
        onChange(newValue, event);
      }
    },
    [multiple, onChange, select],
  );

  const selectProps = useMemo(() => {
    if (select && multiple) {
      return {
        SelectProps: {
          multiple: true,
          value: isControlled ? propValue : value,
          onChange: handleChange,
        },
      };
    }

    return {};
  }, [handleChange, isControlled, multiple, propValue, select, value]);

  return (
    <MaterialTextField
      helperText=""
      fullWidth
      variant="outlined"
      {...otherProps}
      value={isControlled ? propValue : value}
      onChange={handleChange}
      {...selectProps}
    >
      {children}
    </MaterialTextField>
  );
};
