import React, { useRef, useCallback, useMemo } from 'react';

import { Button } from '../Button';
import { CommentsByOwnersDialog } from '../../dialogs/CommentsByOwners';

export const CommentsByOwnersDialogButton = ({ record, ...props }) => {
  const changeOpenRef = useRef();

  const handleOpenDialog = useCallback(() => {
    if (changeOpenRef.current) {
      changeOpenRef.current(true);
    }
  }, []);

  const selectedIds = useMemo(() => {
    return [record.id];
  }, [record.id]);

  return (
    <>
      <Button size="small" {...props} onClick={handleOpenDialog}>
        Выгрузить комментарии
      </Button>
      <CommentsByOwnersDialog changeOpenRef={changeOpenRef} selectedIds={selectedIds} />
    </>
  );
};
