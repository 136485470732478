import { getFormattedDate } from '.';
import { currencyTypes } from '../constants';

const dateOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

export const getDiscountDates = (start, end) => {
  let text = '';
  if (start) {
    text += `c ${getFormattedDate(start, dateOptions)} `;
  }
  if (end) {
    text += `по ${getFormattedDate(end, dateOptions)}`;
  }

  return text;
};

export const getDiscountValue = (amount, isPercents, currencyType) => {
  return `${amount} ${isPercents ? '%' : currencyTypes[currencyType]}`;
};
