import React, { useRef } from 'react';

import { MenuCell } from '../../../tables';
import { DeleteMenuItem, EditMenuItem } from '../../../menuItems';

export const DefaultMenu = ({ record, icon, resource, hasDelete = true }) => {
  const handleCloseRef = useRef();

  return (
    <MenuCell icon={icon} handleCloseRef={handleCloseRef}>
      {!record?.isPredefined && <EditMenuItem resource={resource} record={record} />}
      {hasDelete && (
        <DeleteMenuItem resource={resource} record={record} handleCloseRef={handleCloseRef} />
      )}
    </MenuCell>
  );
};
