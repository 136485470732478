import React from 'react';

import { makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Button } from '../Button';
import { mergeClasses } from '../../../utils';

const useStyles = makeStyles(() => ({
  root: {
    padding: '8px 14px',
    minWidth: 0,
    fontSize: '13px',
  },
  root__selectAdd: {
    padding: '17px',
  },
}));

export const AddButton = (props) => {
  const classes = useStyles();

  const { styleSelectAdd, multiple, text } = props;

  return (
    <Button
      {...props}
      icon={<AddIcon />}
      classes={{
        root: mergeClasses(classes.root, styleSelectAdd && classes.root__selectAdd),
      }}
    >
      {text || (multiple && 'Несколько')}
    </Button>
  );
};
