import React, { useMemo, useRef, useCallback, useState } from 'react';
import { FormDataConsumer, FormWithRedirect } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { Dialog } from '../Dialog';

import { Button } from '../../buttons';
import { FormRow } from '../../FormRow';

import { PeopleSelect } from '../../RASelects';
import { DateTimePickerInput } from '../../RAInputs';
import { FormTitle, NumberInput } from '../../common';
import { getFullName, validates } from '../../../utils';
import { dataProvider } from '../../../dataProvider';

const useStyles = makeStyles(() => ({
  dialogPaper: {
    maxWidth: 465,
  },
}));

export const ContractsCallingDialog = (props) => {
  const classes = useStyles();
  const { changeOpenRef, record = {}, selectedIds } = props;

  const formRef = useRef();

  const [loading, setLoading] = useState(false);

  const handleSave = useCallback(
    (values) => {
      setLoading(true);

      const expertPercents = {};

      Object.keys(values).forEach((key) => {
        if (key.includes('percent')) {
          const paths = key.split('_');
          expertPercents[paths[1]] = values[key];
        }
      });

      const fetch = async () => {
        try {
          const body = {
            plannedDate: values.plannedDate,
            leads: selectedIds,
            expertsAndShares: expertPercents,
          };
          await dataProvider.addContactCalling(body);
          if (changeOpenRef.current) {
            changeOpenRef.current(false);
          }
        } finally {
          setLoading(false);
        }
      };

      fetch();
    },
    [changeOpenRef, selectedIds],
  );

  const handleSubmit = useCallback(() => {
    const submit = formRef.current.handleSubmit;
    if (submit) {
      submit();
    }
  }, []);

  const buttons = useMemo(() => {
    return (
      <Button onClick={handleSubmit} loading={loading}>
        Сохранить
      </Button>
    );
  }, [handleSubmit, loading]);

  return (
    <Dialog
      {...props}
      buttons={buttons}
      dialogProps={{ fullWidth: true, classes: { paper: classes.dialogPaper } }}
      title="Сформировать базу для обзвона"
    >
      <FormWithRedirect
        save={handleSave}
        record={record}
        render={(formProps) => {
          formRef.current = formProps;

          return (
            <>
              <FormRow>
                <DateTimePickerInput
                  pickerType="date"
                  source="plannedDate"
                  label="Выполнить к дате"
                  validate={validates.required}
                />
              </FormRow>
              <PeopleSelect
                source="experts"
                resourceId="expertsIds"
                // additionalSource="taaskTag"
                {...props}
                componentProps={{
                  label: 'Эксперты',
                  providerParams: { filter: { isExpert: true } },
                  multiple: true,
                  filterSelectedOptions: true,
                }}
              />
              <FormDataConsumer>
                {({ formData }) => {
                  return (
                    <>
                      {formData.experts?.current?.length > 0 &&
                        formData.experts?.current.map((expert) => {
                          return (
                            <>
                              <FormTitle>{getFullName(expert.original)}</FormTitle>
                              <FormRow>
                                <NumberInput
                                  source={`percent_${expert.id}`}
                                  label="Доля распределения лидов"
                                />
                              </FormRow>
                            </>
                          );
                        })}
                    </>
                  );
                }}
              </FormDataConsumer>
            </>
          );
        }}
      />
    </Dialog>
  );
};
