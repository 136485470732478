import React from 'react';
import { FormTab } from 'react-admin';

import { TextInput } from '../../TextInput/TextInput';
import { TabbedForm } from '../../common';

import { validates } from '../../../utils';
import { FormRow } from '../../FormRow';
import { AutocompleteWithFormRow, ConstantSelect } from '../../app';
import { publicationStatuses } from '../../../constants';
import { FormCard } from '../../FormCard';

export const FormCMSProjects = (props) => {
  const { id } = props;

  return (
    <TabbedForm {...props}>
      <FormTab label="Основное">
        <FormCard>
          {id && (
            <FormRow>
              <TextInput source="id" label="Id" disabled />
            </FormRow>
          )}
          <FormRow>
            <TextInput source="name" label="Название" validate={validates.name} />
          </FormRow>
          <FormRow>
            <ConstantSelect
              source="sysStatus"
              objectChoices={publicationStatuses}
              label="Статус публикации"
              defaultValue={100}
            />
          </FormRow>
          <FormRow>
            <TextInput
              source="sortOrder"
              label="Порядок сортировки"
              type="number"
              defaultValue={1000}
            />
          </FormRow>
          <FormRow>
            <TextInput source="rootUrl" label="Корневой URL проекта" />
          </FormRow>
          <AutocompleteWithFormRow
            {...props}
            source="cmsPageRoot"
            resourceName="cmsAdmin/pages"
            resourceId="cmsPageRootId"
            componentProps={{
              label: 'Главная страница',
            }}
          />
        </FormCard>
      </FormTab>
    </TabbedForm>
  );
};
