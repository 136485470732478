import React, { useState, useCallback } from 'react';
import { useDataProvider, useRefresh } from 'react-admin';

import AddIcon from '@material-ui/icons/Add';
import { Button } from '../Button';
import { currentUser } from '../../../authProvider';

export const AddCommentButton = ({ id, type, comment, ...props }) => {
  const [loading, setLoading] = useState(false);
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const handleClick = useCallback(() => {
    if (!currentUser) {
      alert('Необходимо создать пользователя!!!');
    }

    const fetch = async () => {
      setLoading(true);
      try {
        await dataProvider.addComment({
          text: comment,
          commentObjectId: id,
          commentObjectType: type,
          authorId: currentUser.id,
        });
      } catch (error) {
        console.log(error);
      }

      refresh();
      setLoading(false);
    };

    fetch();
  }, [comment, dataProvider, id, refresh, type]);

  return (
    <Button {...props} icon={<AddIcon />} loading={loading} onClick={handleClick}>
      Добавить комментарий
    </Button>
  );
};
