import React from 'react';
import { FormTab } from 'react-admin';

import { TextInput } from '../../TextInput/TextInput';
import { FormTitle, TabbedForm, RichText, BooleanInput, NumberInput } from '../../common';

import { validates } from '../../../utils';
import { FormRow } from '../../FormRow';
import { ConstantSelect, ImageInputWithFormRow } from '../../app';
import { currencyTypes, publicationStatuses } from '../../../constants';
import { SeoTab } from '../../formTabs';
import { DateTimePickerInput } from '../../RAInputs';
import { ListObjects } from '../../additionalLists';
import { FormCard } from '../../FormCard';

export const FormDiscounts = (props) => {
  const { id } = props;

  return (
    <TabbedForm {...props}>
      <FormTab label="Основное">
        <FormCard>
          {id && (
            <FormRow>
              <TextInput source="id" label="Id" disabled />
            </FormRow>
          )}
          <FormRow>
            <TextInput source="slug" label="Слаг" validate={validates.slug} />
          </FormRow>
          <FormRow>
            <TextInput source="name" label="Название" validate={validates.name} />
          </FormRow>
          <FormRow>
            <ConstantSelect
              source="sysStatus"
              objectChoices={publicationStatuses}
              label="Статус публикации"
              defaultValue={100}
            />
          </FormRow>
          <FormRow>
            <TextInput
              source="sortOrder"
              label="Порядок сортировки"
              type="number"
              defaultValue={1000}
            />
          </FormRow>
          <FormRow>
            <TextInput source="shortName" label="Краткое название" validate={validates.shortName} />
          </FormRow>
          <FormRow>
            <TextInput source="subTitle" label="Подзаголовок" />
          </FormRow>
          <ImageInputWithFormRow {...props} source="photo" />
          <ImageInputWithFormRow
            {...props}
            source="videoPoster"
            buttonText="Добавить постер к видео"
          />
          <ImageInputWithFormRow {...props} source="video" isVideo />
          <FormRow maxWidth={800} isLast>
            <RichText source="description" />
          </FormRow>
        </FormCard>
      </FormTab>
      <FormTab label="Скидка">
        <FormCard>
          <FormRow>
            <BooleanInput label="Акция" source="isAction" />
          </FormRow>
          <FormRow>
            <BooleanInput label="В процентах?" source="isPercents" />
          </FormRow>
          <FormRow>
            <ConstantSelect source="currencyType" objectChoices={currencyTypes} label="Валюта" />
          </FormRow>
          <FormRow>
            <NumberInput
              min={0}
              source="amount"
              label="Скидка"
              helperText="Если включен флаг в процентах, то указывается в процентах от 0.001 до 0.999, иначе в валюте"
              validate={validates.discountValue}
            />
          </FormRow>
          <FormRow>
            <DateTimePickerInput
              source="start"
              label="Дата начала действия скидки"
              helperText="Если не указано, то начнет действовать сразу"
            />
          </FormRow>
          <FormRow>
            <DateTimePickerInput
              source="end"
              label="Дата окончания действия скидки"
              helperText="Если не указано, то будет действовать всегда"
            />
          </FormRow>
          <FormRow>
            <BooleanInput label="Действует на продажу?" source="forSale" />
          </FormRow>
          <FormRow isLast>
            <BooleanInput label="Действует на аренду?" source="forRent" />
          </FormRow>
        </FormCard>
      </FormTab>
      <FormTab label="Продвижение">
        <FormCard>
          <FormTitle>Seo</FormTitle>
          <SeoTab isLast={false} />
        </FormCard>
      </FormTab>
      <FormTab label="Объекты">
        <ListObjects />
      </FormTab>
    </TabbedForm>
  );
};
