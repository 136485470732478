import React, { useMemo, useRef } from 'react';
import { FormDataConsumer, FormTab } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { FormTitle, HTMLContent, TabbedForm } from '../../common';
import { AudioPlayer } from '../../AudioPlayer/AudioPlayer';
import { TextInput } from '../../TextInput';
import { ClientSelect, EmployeeSelect } from '../../RASelects';
import { DateTimePickerInput } from '../../RAInputs';
import { seconds2time } from '../../../utils/audio';
import { FormRow } from '../../FormRow';
import {
  clientTouchNotTargetTypes,
  clientTouchStatuses,
  clientTouchStatusFilledColors,
  clientTouchTargetTypes,
  clientTouchTypeFilledColors,
  touchesTypes,
} from '../../../constants';
import { AutocompleteWithFormRow, ConstantSelect, List, TabWithFooter } from '../../app';
import { FormCard } from '../../FormCard';
import { CreateContractButton } from '../../buttons';
import { DynamicList } from '../../additionalLists';
import { parseBoolean, validates } from '../../../utils';
import { customTheme } from '../../../theme';
// import { ListItem } from '../../app/ListItems';

const useStyles = makeStyles(() => ({
  chatLog: {
    '& p': {
      border: '1px solid grey',
      padding: '16px',
      borderRadius: '10px',
      marginTop: '16px',
      position: 'relative',
      display: 'flex',
      flexWrap: 'wrap',
    },

    '& p.out': {
      backgroundColor: `${customTheme.palette.grey.light}`,
    },

    '& p:first-child': {
      marginTop: 0,
    },

    '& p span': {
      flex: '0 1 auto',
      paddingRight: '8px',
      fontSize: '14px',
      lineHeight: '16px',
      color: '#1E1E1E',
      fontWeight: '500',
    },

    '& p span.content': {
      width: '100%',
      borderTop: '1px dashed grey',
      paddingTop: '8px',
      marginTop: '8px',
      fontWeight: 'normal',
    },
  },
}));

export const FormTouches = (props) => {
  const { id, record } = props;
  const { techSource } = record;
  const classes = useStyles();

  const touchType = touchesTypes[techSource];
  let isCall;
  if (touchType) {
    isCall = touchType.includes('Звонок');
  }

  const contactsTabFooter = useMemo(() => {
    return <CreateContractButton record={record} type="touch" />;
  }, [record]);

  const oldStatusRef = useRef(
    record?.isTargeted || record.target === 100 || record.target === 200 || record.target === 300,
  );

  return (
    <TabbedForm {...props}>
      <FormTab label="Основное">
        <FormCard>
          {id && (
            <>
              <FormRow>
                <TextInput source="id" label="Id" disabled />
              </FormRow>
              <FormRow>
                <DateTimePickerInput source="createdDate" label="Дата создания" disabled />
              </FormRow>
            </>
          )}
          <ClientSelect
            {...props}
            componentProps={{ label: 'Клиент/Собственник', disabled: id }}
            validate={validates.touchClient}
          />
          {/* {record.client && (
            <>
              <FormRow>
                <ListItem type="client" record={record.client} hasButtons={false} isNewItem />
              </FormRow>
            </>
          )} */}
          <AutocompleteWithFormRow
            {...props}
            source="initialRealtyObject"
            resourceName="RealtyObjects"
            resourceId="initialRealtyObjectId"
            componentProps={{
              label: 'Объект обращения',
              fullDynamic: 'QueryWithFid',
              getOptionLabel: (option) => {
                if (!option) {
                  return '';
                }

                return `${option.id}: ${option.name || ''}`;
              },
            }}
          />
          {/* <AutocompleteWithFormRow
            source="clientTags"
            resourceName="clientTags"
            resourceId="clientTagId"
            additionalSource="clientTag"
            {...props}
            componentProps={{
              label: 'Клиентские тэги',
              multiple: true,
              filterSelectedOptions: true,
            }}
          /> */}
          {/* <FormRow>
            <ConstantSelect
              source="luxuryClass"
              objectChoices={luxuryClasses}
              label="Класс недвижимости"
              allowEmpty
            />
          </FormRow> */}
          <FormRow>
            <ConstantSelect
              source="isTargeted"
              objectChoices={clientTouchStatuses}
              label="Статус клиента"
              filledColors={clientTouchStatusFilledColors}
              defaultValue={record.target === 100 || record.target === 200 || record.target === 300}
              parse={parseBoolean}
            />
          </FormRow>
          <FormDataConsumer>
            {({ formData }) => {
              const status = formData?.isTargeted;
              const types = status === true ? clientTouchTargetTypes : clientTouchNotTargetTypes;

              if (status !== oldStatusRef.current) {
                oldStatusRef.current = status;
                formData.target = '';
                setTimeout(() => {
                  delete formData.target;
                }, 1);
              }

              return (
                <FormRow>
                  <ConstantSelect
                    source="target"
                    objectChoices={types}
                    label="Причина обработки"
                    filledColors={clientTouchTypeFilledColors}
                    validate={validates.clientTouchType}
                    defaultValue={10000}
                  />
                </FormRow>
              );
            }}
          </FormDataConsumer>
          <FormRow>
            <TextInput source="comment" label="Комментарий" multiline rows={6} />
          </FormRow>
        </FormCard>
      </FormTab>
      {isCall && (
        <FormTab label="Звонок">
          <FormCard>
            {record.path && (
              <>
                <FormTitle>Запись звонка</FormTitle>
                <FormRow>
                  <AudioPlayer recordId={id} talkSeconds={record.talkSeconds} />
                </FormRow>
              </>
            )}
            <FormTitle>Данные о звонке</FormTitle>
            <FormRow>
              <TextInput source="externalPhoneNumber" label="Номер телефона клиента" disabled />
            </FormRow>
            <FormRow>
              <DateTimePickerInput source="initiatedDate" label="Дата звонка" disabled />
            </FormRow>
            <FormRow>
              <TextInput
                source="talkSeconds"
                label="Продолжительность звонка"
                disabled
                format={seconds2time}
              />
            </FormRow>
            <EmployeeSelect componentProps={{ disabled: true, label: 'Оператор' }} {...props} />
            <FormRow>
              <TextInput source="InternalPhoneNumber" label="Номер телефона сотрудника" disabled />
            </FormRow>
            <FormRow>
              <TextInput source="calledPhoneNumber" label="Изначальный номер телефона" disabled />
            </FormRow>
          </FormCard>
        </FormTab>
      )}
      {record.chatLog && (
        <FormTab label="Лог чата">
          <FormCard>
            <div className={classes.chatLog}>
              <HTMLContent html={record.chatLog} />
            </div>
          </FormCard>
        </FormTab>
      )}
      <FormTab label="Реклама">
        <FormCard>
          <FormTitle>Рекламные данные</FormTitle>
          <FormRow>
            <TextInput source="utmMedium" label="UTM Medium" />
          </FormRow>
          <FormRow>
            <TextInput source="utmTerm" label="UTM Term" />
          </FormRow>
          <FormRow>
            <TextInput source="utmSource" label="UTM Source" />
          </FormRow>
          <FormRow>
            <TextInput source="utmContent" label="UTM Content" />
          </FormRow>
          <FormRow>
            <TextInput source="utmCampaign" label="UTM Campaign" />
          </FormRow>
          <FormRow>
            <TextInput source="sitePage" label="Site Page" />
          </FormRow>
          <FormRow>
            <TextInput source="techSourceOrigin" label="Сайт источник" />
          </FormRow>
          <FormRow>
            <TextInput source="appId" label="Идентификатор клиента Comagic" />
          </FormRow>
          <FormRow>
            <TextInput source="communicationId" label="Идентификатор звонка Comagic" />
          </FormRow>
          <FormRow>
            <TextInput source="visitorId" label="Идентификатор посетителя Comagic" />
          </FormRow>
          <FormRow>
            <TextInput source="personId" label="Идентификатор персоны Comagic" />
          </FormRow>
          <FormRow>
            <TextInput source="ymClientId" label="ID Яндекс.Метрика" />
          </FormRow>
          <FormRow>
            <TextInput source="gClId" label="Google Click Identifier" />
          </FormRow>
          <FormRow>
            <TextInput source="cmId" label="Метка cm_id" />
          </FormRow>
          <FormRow>
            <TextInput source="siteId" label="Идентификатор сайта" />
          </FormRow>
          <FormRow>
            <TextInput source="uaClientId" label="Идентификатор посетителя UA" />
          </FormRow>
          <FormRow>
            <TextInput source="uaResourceId" label="ID рессурса Google Analytics" />
          </FormRow>
          <FormRow>
            <TextInput source="yClId" label="Yandex Click Identifier" />
          </FormRow>
          <FormRow>
            <TextInput source="ymCounterId" label="ID счетчика Яндекс.Метрика" />
          </FormRow>
          <FormRow>
            <TextInput source="ymClId" label="Метка ymclid" />
          </FormRow>
          <FormRow>
            <TextInput source="communicationPageUrl" label="Страница входа" />
          </FormRow>
        </FormCard>
      </FormTab>
      {id && (
        <FormTab label="Лиды">
          <TabWithFooter footerContent={contactsTabFooter}>
            <DynamicList
              fetchOptions={{
                resource: 'Contracts',
                filter: { ClientTouchId: record.id },
              }}
              contentElement={List}
              contentOptions={{
                type: 'contract',
                hasButtons: false,
                isNewItem: true,
              }}
            />
          </TabWithFooter>
        </FormTab>
      )}
    </TabbedForm>
  );
};
