import React from 'react';
import ReactDOM from 'react-dom';
import 'fontsource-roboto';
import { StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import { customTheme } from './theme';

import App from './App';
import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={customTheme}>
      <StylesProvider injectFirst>
        <App />
      </StylesProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// https://github.com/facebook/react/issues/5632#issuecomment-466959089
// to prevent make console.error warnings use console.warn
const errorsToWarn = ['Warning:'];
const oldConsError = console.error;
// eslint-disable-next-line func-names
console.error = function (...args) {
  let toWarn = false;

  if (typeof args[0] === 'string') {
    errorsToWarn.forEach((_s) => {
      if (args[0].startsWith(_s)) {
        toWarn = true;
      }
    });
  }

  if (toWarn) {
    console.warn(...args);
  } else {
    oldConsError(...args);
  }
};
