import React, { useEffect } from 'react';
import { makeStyles, createStyles, useMediaQuery, Tooltip } from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';

// import ListBaseFilters from '../Filters';

import { CardViewIcon, TableViewIcon } from '../../Icons';
import { SearchAndFilters } from '../../app';
import { customTheme } from '../../../theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    buttons: {
      border: '1px solid #BDBDBD',
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'stretch',
      width: 129,
      height: 56,
      borderRadius: 6,
      boxSizing: 'border-box',
      flex: '0 0 auto',
    },
    button: {
      flex: '1 0 auto',
      width: '50%',
      color: '#000',
      padding: 0,
      borderRadius: 0,
      '&:first-child': {
        borderRight: '1px solid #BDBDBD',
      },
    },
    buttonActive: {
      color: customTheme.palette.primary['A200'],
    },
    rows: {
      display: 'flex',
    },
    row: {
      flex: '1 1 auto',
    },
    lastRow: {
      flex: '0 0 auto',
      paddingLeft: '16px',
    },
    customContent: {
      paddingTop: '16px',
    },
  }),
);

const ListBaseHeader = (props) => {
  const classes = useStyles(props);
  const {
    isCardView,
    hasToggleView,
    onSetCardView,
    searchBy,
    hasFilters,
    customHeaderContent,
    basePath,
    hasKanban,
  } = props;

  const isDesktop = useMediaQuery((theme) => {
    return theme.breakpoints.up('sm');
  });

  useEffect(() => {
    if (!isDesktop && onSetCardView) {
      onSetCardView(true);
    }
  }, [isDesktop, onSetCardView]);

  return (
    <div className={classes.root}>
      <div className={classes.rows}>
        <div className={classes.row}>
          <SearchAndFilters
            searchBy={searchBy}
            hasFilters={hasFilters}
            basePath={basePath}
            hasKanban={hasKanban}
          />
        </div>
        {hasToggleView && isDesktop && (
          <div className={classes.lastRow}>
            <div className={classes.buttons}>
              <Tooltip title="Карточный вид" arrow>
                <IconButton
                  className={`${isCardView && classes.buttonActive} ${classes.button}`}
                  onClick={() => onSetCardView(true)}
                  disableRipple
                >
                  <CardViewIcon width={18} height={18} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Табличный вид" arrow>
                <IconButton
                  className={`${!isCardView && classes.buttonActive} ${classes.button}`}
                  onClick={() => onSetCardView(false)}
                  disableRipple
                >
                  <TableViewIcon width={18} height={16} />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        )}
      </div>
      {customHeaderContent && <div className={classes.customContent}>{customHeaderContent}</div>}
    </div>
  );
};

export default ListBaseHeader;
