// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/jsx-no-duplicate-props */
import React, { useRef, useEffect, useMemo } from 'react';
import { useInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import {
  mapboxgl,
  MapboxDraw,
  MapboxGeocoder,
  serachByCoords,
  MapboxStyleSwitcherControl,
} from './initMap';
import { TextInput } from '../../TextInput/TextInput';
import { NumberInput } from '../NumberInput';
import { FormRow } from '../../FormRow';
import { validates } from '../../../utils';

const useStyles = makeStyles(() => ({
  map: {
    width: '100%',
    height: '500px',
  },
}));

const mapStyles = [
  {
    title: 'Villagio',
    uri: process.env.REACT_APP_MAPBOX_MAIN_STYLE,
  },
  {
    title: 'Satellite',
    uri: 'mapbox://styles/mapbox/satellite-v9',
  },
];

const convert = {
  point: {
    fromString: (string) => {
      const coords = string.match(/[-]?\d+([.]\d+)?\s{1}[-]?\d+([.]\d+)?/g);
      return coords[0].split(' ');
    },
    toString: (coords) => {
      const stringCoords = coords.join(' ');
      return `POINT (${stringCoords})`;
    },
  },
  polygon: {
    fromString: (string) => {
      const coords = string.match(/[-]?\d+([.]\d+)?\s{1}[-]?\d+([.]\d+)?/g);
      return coords.map((coord) => {
        return coord.split(' ').map((simpleCoord) => Number(simpleCoord));
      });
    },
    toString: (coords) => {
      const stringCoords = coords[0]
        .map((coord) => {
          return coord.join(' ');
        })
        .join(', ');

      return `POLYGON ((${stringCoords}))`;
    },
  },
};

export const Map = ({ record, disabled }) => {
  const coordsPointInput = useInput({ name: 'coordsPoint', source: 'coordsPoint' }).input;
  const coordsPolygonInput = useInput({ name: 'coordsBorder', source: 'coordsBorder' }).input;

  const initLngLat = useMemo(() => {
    if (!record['coordsPoint']) {
      return undefined;
    }

    const lngLat = convert['point']['fromString'](record['coordsPoint']);

    return lngLat;
  }, [record]);

  const lngInput = useInput({
    name: 'lng',
    source: 'lng',
    defaultValue: initLngLat ? initLngLat[0] : '',
  }).input;
  const latInput = useInput({
    name: 'lat',
    source: 'lat',
    defaultValue: initLngLat ? initLngLat[1] : '',
  }).input;

  const mapRefs = useRef({ map: null, marker: null, draw: null, hasMarker: false });

  const classes = useStyles();
  const mapContainer = useRef();

  useEffect(() => {
    const lng = lngInput.value;
    const lat = latInput.value;

    if (!lng || !lat) {
      const { marker } = mapRefs.current;
      if (marker) {
        coordsPointInput.onChange('');

        marker.remove();
        mapRefs.current.hasMarker = false;
      }

      return;
    }

    if (lng && lat && lat >= -90 && lat <= 90) {
      const lngLat = [Number(lng), Number(lat)];
      coordsPointInput.onChange(convert['point']['toString'](lngLat));
      const { marker, map, hasMarker } = mapRefs.current;

      if (marker && !hasMarker) {
        marker.addTo(map);
        mapRefs.current.hasMarker = true;
      }

      if (marker && map) {
        map.setCenter(lngLat);
        marker.setLngLat(lngLat);

        const fetch = async () => {
          await serachByCoords(lngLat);
        };
        fetch();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lngInput.value, latInput.value]);

  useEffect(() => {
    let hasMarker = false;
    let lngLat = [37.014575, 55.788304];
    if (initLngLat) {
      lngLat = initLngLat;
    }
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: process.env.REACT_APP_MAPBOX_MAIN_STYLE,
      center: lngLat,
      zoom: 13,
    });

    const marker = new mapboxgl.Marker().setLngLat(lngLat);

    if (initLngLat) {
      marker.addTo(map);
      hasMarker = true;
    }

    const draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true,
        point: true,
      },
    });

    const geoCoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl,
      proximity: {
        latitude: 55.7558,
        longtitude: 37.6173,
      },
      countries: 'ru',
      types: 'address, poi, locality, neighborhood, place',
      marker: false,
    });

    map.addControl(geoCoder);
    map.addControl(draw);
    map.addControl(new MapboxStyleSwitcherControl(mapStyles), 'top-left');

    mapRefs.current = {
      marker,
      map,
      draw,
      hasMarker,
    };

    let lastIdPolygon;

    if (coordsPolygonInput.value) {
      const feature = {
        type: 'Polygon',
        coordinates: [convert['polygon']['fromString'](coordsPolygonInput.value)],
      };
      const featureIds = draw.add(feature);
      lastIdPolygon = featureIds[0];
    }

    map.on('draw.create', (e) => {
      const element = e.features[0];
      const geometry = element.geometry;
      const type = geometry.type;

      if (type === 'Point') {
        draw.delete(element.id);
        lngInput.onChange(geometry.coordinates[0]);
        latInput.onChange(geometry.coordinates[1]);
        coordsPointInput.onChange(convert['point']['toString'](geometry.coordinates));
      }

      if (type === 'Polygon') {
        draw.delete(lastIdPolygon);
        lastIdPolygon = element.id;
        coordsPolygonInput.onChange(convert['polygon']['toString'](geometry.coordinates));
      }
    });

    map.on('draw.update', (e) => {
      const element = e.features[0];
      const geometry = element.geometry;
      const type = geometry.type;

      if (type === 'Point') {
        marker.setLngLat(geometry.coordinates);
      }

      if (type === 'Polygon') {
        coordsPolygonInput.onChange(convert['polygon']['toString'](geometry.coordinates));
      }
    });

    map.on('draw.delete', (e) => {
      const element = e.features[0];
      const geometry = element.geometry;
      const type = geometry.type;

      if (type === 'Polygon') {
        coordsPolygonInput.onChange('');
      }
    });

    map.on('load', () => {
      map.resize();
    });

    return () => map.remove();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <FormRow maxWidth="100%">
        <div ref={mapContainer} className={classes.map} />
      </FormRow>
      <div style={{ display: 'none' }}>
        <TextInput source="coordsBorder" label="Полигон" disabled />
        <TextInput source="coordsPoint" label="Координаты" disabled />
      </div>
      <FormRow maxWidth="100%">
        <NumberInput
          source="lat"
          label="Широта"
          min={-90}
          max={90}
          validate={validates.lat}
          disabled={disabled}
        />
      </FormRow>
      <FormRow maxWidth="100%">
        <NumberInput source="lng" label="Долгота" disabled={disabled} />
      </FormRow>
    </div>
  );
};
