import React, { useEffect } from 'react';
import { FormTab } from 'react-admin';

import { TextInput } from '../../TextInput/TextInput';
import { FormTitle, Map, TabbedForm, RichText, BooleanInput } from '../../common';

import { validates } from '../../../utils';
import { SeoTab } from '../../formTabs';
import { FormRow } from '../../FormRow';
import { ConstantSelect, AutocompleteWithFormRow, TasksTab } from '../../app';
import { publicationStatuses } from '../../../constants';
import { ListImagesOrVideos } from '../../additionalLists';
import { FormCard } from '../../FormCard';

export const FormInfrastructures = (props) => {
  const { id, location, record } = props;

  useEffect(() => {
    // fix for map
    window.dispatchEvent(new Event('resize'));
  }, [location]);

  return (
    <TabbedForm {...props}>
      <FormTab label="Основное">
        <FormCard>
          {id && (
            <FormRow>
              <TextInput source="id" label="Id" disabled />
            </FormRow>
          )}
          <FormRow>
            <TextInput source="slug" label="Слаг" validate={validates.slug} />
          </FormRow>
          <AutocompleteWithFormRow
            {...props}
            source="infrastructureType"
            resourceName="InfrastructureTypes"
            resourceId="infrastructureTypeId"
            componentProps={{
              label: 'Тип инфраструктуры',
            }}
            validate={validates.infrastructures}
          />
          <FormRow>
            <ConstantSelect
              source="sysStatus"
              objectChoices={publicationStatuses}
              label="Статус публикации"
              defaultValue={100}
            />
          </FormRow>
          <FormRow>
            <TextInput
              source="sortOrder"
              label="Порядок сортировки"
              type="number"
              defaultValue={1000}
            />
          </FormRow>
          <FormRow>
            <TextInput source="name" label="Название" validate={validates.name} />
          </FormRow>
          <FormRow>
            <TextInput source="shortName" label="Краткое название" validate={validates.shortName} />
          </FormRow>
          <FormTitle>Описание</FormTitle>
          <FormRow maxWidth={800} isLast>
            <RichText source="description" />
          </FormRow>
        </FormCard>
      </FormTab>
      <FormTab label="О инфраструктуре">
        <FormCard>
          <FormRow>
            <TextInput source="phone" label="Телефон" helperText="Вводить по маске 79998887766" />
          </FormRow>
          <FormRow>
            <TextInput source="email" label="Email" />
          </FormRow>
          <FormRow>
            <TextInput source="address" label="Адрес" />
          </FormRow>
          <FormRow>
            <TextInput source="url" label="Ссылка на cайт" />
          </FormRow>
          <FormRow>
            <TextInput
              source="workHoursFrom"
              label="Время работы с"
              type="time"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormRow>
          <FormRow>
            <TextInput
              source="workHoursTo"
              label="Время работы по"
              type="time"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormRow>
          <FormRow>
            <BooleanInput label="Без выходных" source="isNoWeekend" />
          </FormRow>
          <FormRow>
            <BooleanInput label="Входит в клубную систему Вилладжио?" source="isVillagioClub" />
          </FormRow>
        </FormCard>
      </FormTab>
      <FormTab label="Локация">
        <FormCard>
          <Map {...props} />
        </FormCard>
      </FormTab>
      <FormTab label="SEO">
        <FormCard>
          <SeoTab />
        </FormCard>
      </FormTab>
      <FormTab label="Изображения">
        <ListImagesOrVideos id={id} hasMultiple />
      </FormTab>
      <FormTab label="Видео">
        <ListImagesOrVideos id={id} isVideo />
      </FormTab>
      {/* <FormTab label="Объекты">
        <ListObjects defaultValue={defaultObjects} />
      </FormTab> */}
      {id && (
        <FormTab label="Задачи">
          <TasksTab record={record} type={500} location={location} />
        </FormTab>
      )}
    </TabbedForm>
  );
};
