import React, { useCallback, useState, useMemo, useRef } from 'react';
import { FormTab, useInput, FormDataConsumer } from 'react-admin';

import { TextInput } from '../../TextInput/TextInput';
import { AutocompleteDynamic, BooleanInput, FormTitle, RichText, TabbedForm } from '../../common';

import { validates } from '../../../utils';
import { FormRow } from '../../FormRow';
import { AutocompleteWithFormRow, ConstantSelect, ImageInputWithFormRow } from '../../app';
import { publicationStatuses } from '../../../constants';
import { FormCard } from '../../FormCard';
import { SeoTab } from '../../formTabs';
import { ListCMSWidgets } from '../../additionalLists';
import { DateTimePickerInput } from '../../RAInputs';
import { getOriginalRelativeData } from '../../../dataProvider';
import { Button } from '../../buttons';
import { ArticlePreviewDialog } from '../../dialogs';

const providerParamsArticle = {
  filter: { CmsPageTypeIds: '12' },
};

const WrapperArticles = (props) => {
  const { record } = props;
  const { input } = useInput({
    ...props,
    source: 'content.article',
  });

  const handleChange = useCallback(
    (values) => {
      input.onChange(values);
    },
    [input],
  );

  return (
    <AutocompleteDynamic
      {...props}
      resource="cmsAdmin/pages"
      onChange={handleChange}
      label="Статья"
      defaultValue={record?.content?.article}
      providerParams={providerParamsArticle}
    />
  );
};

const WrapperPageTags = (props) => {
  const { record } = props;
  const { input } = useInput({
    ...props,
    source: 'content.cmsPageTag',
  });

  const handleChange = useCallback(
    (values) => {
      input.onChange(values);
    },
    [input],
  );

  return (
    <AutocompleteDynamic
      {...props}
      resource="cmsAdmin/pageTags"
      onChange={handleChange}
      label="Тэг рубрики"
      defaultValue={record?.content?.cmsPageTag}
    />
  );
};

export const FormCMSPages = (props) => {
  const { record, id, resource, hasWidgets = true, hasPreview = false } = props;

  const [cmsPageTypeId, setCmsPageTypeId] = useState(record?.cmsPageTypeId);

  const handleChangeCMSPageTypeId = useCallback((value) => {
    if (typeof value === 'number' || !value) {
      setCmsPageTypeId(value);
    }
  }, []);

  const hasDetails = useMemo(() => {
    if (
      resource === 'cmsAdmin/magazineMainPages' ||
      resource === 'cmsAdmin/magazineArticlePages' ||
      resource === 'cmsAdmin/magazineCategoryPages'
    ) {
      return false;
    }

    return true;
  }, [resource]);

  const changeOpenRef = useRef();

  const handleOpenPreview = useCallback(() => {
    changeOpenRef.current(true);
  }, []);

  const getToolbarButtons = useCallback(() => {
    return <Button onClick={handleOpenPreview}>Показать превью</Button>;
  }, [handleOpenPreview]);

  return (
    <TabbedForm {...props} getToolbarButtons={hasPreview && getToolbarButtons}>
      <FormTab label="Основное">
        <FormCard>
          {id && (
            <FormRow>
              <TextInput source="id" label="Id" disabled />
            </FormRow>
          )}
          <FormDataConsumer>
            {({ formData }) => {
              return (
                <ArticlePreviewDialog
                  changeOpenRef={changeOpenRef}
                  formData={getOriginalRelativeData(formData)}
                />
              );
            }}
          </FormDataConsumer>
          <FormRow>
            <TextInput source="slug" label="Слаг" validate={validates.slug} />
          </FormRow>
          <FormRow>
            <TextInput source="name" label="Название" validate={validates.name} />
          </FormRow>
          <FormRow>
            <TextInput source="shortName" label="Краткое название" validate={validates.shortName} />
          </FormRow>
          <FormRow>
            <ConstantSelect
              source="sysStatus"
              objectChoices={publicationStatuses}
              label="Статус публикации"
              defaultValue={100}
            />
          </FormRow>
          <FormRow>
            <TextInput
              source="sortOrder"
              label="Порядок сортировки"
              type="number"
              defaultValue={1000}
            />
          </FormRow>
          <FormRow maxWidth={800}>
            <RichText source="description" />
          </FormRow>
          <ImageInputWithFormRow {...props} source="cover" />
          <FormRow>
            <TextInput source="promoText" label="Промо текст" />
          </FormRow>
          <FormRow>
            <TextInput source="author" label="Автор" />
          </FormRow>
          {!(
            resource === 'cmsAdmin/magazineArticlePages' ||
            resource === 'cmsAdmin/magazineCategoryPages'
          ) && (
            <FormRow>
              <DateTimePickerInput source="date" label="Дата" pickerType="date" />
            </FormRow>
          )}
          <FormRow>
            <BooleanInput label="Показывать в меню?" source="isShowInMenu" />
          </FormRow>
          {hasDetails && (
            <>
              <AutocompleteWithFormRow
                {...props}
                source="cmsPageType"
                resourceName="cmsAdmin/pageTypes"
                resourceId="cmsPageTypeId"
                onChange={handleChangeCMSPageTypeId}
                componentProps={{
                  label: 'Тип страницы',
                }}
              />
              <FormRow>
                <TextInput
                  source="cmsPageParentId"
                  label="Id родительской страницы"
                  type="number"
                />
              </FormRow>
            </>
          )}
          {resource !== 'cmsAdmin/magazineCategoryPages' && (
            <AutocompleteWithFormRow
              {...props}
              source="pageTags"
              resourceName="cmsAdmin/pageTags"
              resourceId="cmsPageTagId"
              additionalSource="cmsPageTag"
              componentProps={{
                label: 'Тэги страницы',
                multiple: true,
                filterSelectedOptions: true,
              }}
            />
          )}
          <FormRow>
            <DateTimePickerInput
              source="publishAt"
              label="Дата, когда перевести в 'Опубликовано'"
            />
          </FormRow>
          <FormRow>
            <DateTimePickerInput
              source="unPublishAt"
              label="Дата, когда перевести в 'Неопубликовано'"
            />
          </FormRow>
          <FormRow>
            <TextInput source="comment" label="Комментарий" multiline rows={6} />
          </FormRow>
        </FormCard>
      </FormTab>
      {cmsPageTypeId === 11 && (
        <FormTab label="Контент">
          <FormCard>
            <WrapperPageTags {...props} />
          </FormCard>
        </FormTab>
      )}
      {cmsPageTypeId === 10 && (
        <FormTab label="Контент">
          <FormCard>
            <WrapperArticles {...props} />
          </FormCard>
        </FormTab>
      )}
      <FormTab label="Продвижение">
        <FormCard>
          <FormTitle>Seo</FormTitle>
          <SeoTab isLast={false} />
        </FormCard>
      </FormTab>
      {id && hasWidgets && (
        <FormTab label="Виджеты">
          <ListCMSWidgets />
        </FormTab>
      )}
    </TabbedForm>
  );
};
