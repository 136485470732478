import React from 'react';

import { PeopleSelect } from '../People';

export const RepresentativeSelect = (props) => {
  return (
    <PeopleSelect
      {...props}
      source="representative"
      resourceId="representativeId"
      componentProps={{
        label: 'Представитель',
        providerParams: { filter: { isRepresentative: true } },
      }}
    />
  );
};
