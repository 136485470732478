import React, { useMemo } from 'react';

import { makeStyles } from '@material-ui/core';
import { ListItem } from '../ListItems';

const useStyles = makeStyles(() => ({
  list: {
    padding: 8,
  },
}));

const listItemDynamicTypes = {
  taask: 'task',
  demo: 'show',
  clientTouch: 'touch',
  contract: 'contract',
  comment: 'comment',
  contractHistory: 'contractHistory',
};

// eslint-disable-next-line no-unused-vars
export const List = ({
  items,
  menuItem,
  selectedIds = [],
  onToggleItem,
  type,
  resource,
  hasButtons = true,
  depthKey,
  imgKey,
  page,
  hasDelete,
  isNewItem,
  dynamicType = false,
}) => {
  const classes = useStyles();

  const elements = useMemo(() => {
    return items.map((item) => {
      const selected = selectedIds.find((selectedId) => {
        return selectedId === item.id;
      });

      let newRecord = item;
      let key = item.id;
      let newType = type;
      let newIsNewItem = isNewItem;
      let expanded = false;

      if (dynamicType) {
        newType = listItemDynamicTypes[item.objectType];
        newRecord = item.object;
        key = `${item.objectType}${newRecord.id}`;

        if (
          newType === 'show' ||
          newType === 'touch' ||
          newType === 'task' ||
          newType === 'contract'
        ) {
          newIsNewItem = true;
        }

        if (newType === 'task') {
          expanded = true;
        }
      }

      return (
        <ListItem
          record={newRecord}
          menuItem={menuItem}
          selected={selected}
          onToggleItem={onToggleItem}
          type={newType}
          key={key}
          resource={resource}
          hasButtons={hasButtons}
          depthKey={depthKey}
          imgKey={imgKey}
          page={page}
          hasDelete={hasDelete}
          isNewItem={newIsNewItem}
          expanded={expanded}
        />
      );
    });
  }, [
    depthKey,
    dynamicType,
    hasButtons,
    hasDelete,
    imgKey,
    isNewItem,
    items,
    menuItem,
    onToggleItem,
    page,
    resource,
    selectedIds,
    type,
  ]);

  return <div className={classes.list}>{elements}</div>;
};
