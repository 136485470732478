import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { getFormattedDateAndTime, getFullName } from '../../../../utils';
import { Avatar } from '../../../common';

const useDatesAndPeoplesStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: '12px',
    borderTop: '1px dashed rgba(0, 0, 0, 0.3)',
  },
}));

export const ListItemContentDatesAndPeoples = ({ record, items = [] }) => {
  const classes = useDatesAndPeoplesStyles();

  const elements = useMemo(() => {
    return items
      .map((item) => {
        const value = record[item.key];
        if (!value) {
          return null;
        }

        return <ListItemContentDatesAndPeoplesItem item={item} value={value} key={item.id} />;
      })
      .filter(Boolean);
  }, [items, record]);

  if (elements.length === 0) {
    return null;
  }

  return <div className={classes.root}>{elements}</div>;
};

const useDatesAndPeoplesItemStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 20px',
    borderLeft: '1px solid rgba(0, 0, 0, 0.3)',
    marginTop: '12px',

    '&:first-child': {
      paddingLeft: 0,
      borderLeft: 0,
    },
  },
  avatarContainer: {
    flex: '0 0 auto',
    paddingRight: '8px',
    boxSizing: 'border-box',
  },
  content: {
    flex: '1 1 auto',
  },
  title: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '12px',
    lineHeight: '14px',
    paddingBottom: '4px',
    display: 'block',
    textTransform: 'uppercase',
  },
  text: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '11px',
    lineHeight: '14px',
    display: 'block',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  avatar: {
    width: 32,
    height: 32,
    fontSize: '13px',
  },
}));

export const ListItemContentDatesAndPeoplesItem = ({ item, value }) => {
  const classes = useDatesAndPeoplesItemStyles();
  const { title, type } = item;

  return (
    <div className={classes.root}>
      {type === 'people' && (
        <div className={classes.avatarContainer}>
          <Avatar option={value} source="photo" className={classes.avatar} />
        </div>
      )}
      <div className={classes.content}>
        <span className={classes.title}>{title}</span>
        <span className={classes.text}>
          {type === 'people' ? getFullName(value) : getFormattedDateAndTime(value)}
        </span>
      </div>
    </div>
  );
};
