import React, { useCallback, useMemo, useEffect } from 'react';

import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { makeStyles, useTheme } from '@material-ui/core';
import { DnDListItem } from '../ListItems';

const reorderItems = (items) => {
  const currentItems = items.map((item, index) => {
    const newSortOrder = index + 1;
    return {
      ...item,
      sortOrder: newSortOrder,
      ...(item.sortOrder !== newSortOrder && !item.clientStatus && { clientStatus: 'edit' }),
    };
  });

  const newItems = currentItems.filter((item) => {
    return item.clientStatus;
  });

  return { currentItems, newItems };
};

const changeItems = (items, startIndex, endIndex) => {
  const currentItems = [...items];
  const [removed] = currentItems.splice(startIndex, 1);
  currentItems.splice(endIndex, 0, removed);

  let newStartIndex = startIndex;
  let newEndIndex = endIndex;

  if (startIndex > endIndex) {
    newStartIndex = endIndex;
    newEndIndex = startIndex;
  }

  currentItems.forEach((currentItem, index) => {
    if (index >= newStartIndex && index <= newEndIndex) {
      currentItem.sortOrder = index + 1;
      if (!currentItem.clientStatus) {
        currentItem.clientStatus = 'edit';
      }
    }
  });

  const newItems = currentItems.filter((currentItem, index) => {
    return index >= newStartIndex && index <= newEndIndex;
  });

  return { currentItems, newItems };
};

const getListStyle = (isDraggingOver, background) => ({
  background: isDraggingOver ? background : '#F5F5F5',
});

const useStyles = makeStyles(() => ({
  list: {
    padding: 8,
  },
}));

// eslint-disable-next-line no-unused-vars
export const DnDList = ({
  items,
  onChangeItems,
  menuItem,
  selectedIds,
  onToggleItem,
  type,
  hasButtons = true,
  depthKey,
  imgKey,
  page,
  isNewItem,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const lengthItems = useMemo(() => {
    return items.length;
  }, [items.length]);

  useEffect(() => {
    const newValues = reorderItems(items);
    const isReorder = true;
    onChangeItems(newValues, isReorder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lengthItems]);

  const handleDragEnd = useCallback(
    (result) => {
      if (!result.destination) {
        return;
      }

      const newValues = changeItems(items, result.source.index, result.destination.index);
      const isReorder = true;

      if (onChangeItems) {
        onChangeItems(newValues, isReorder);
      }
    },
    [items, onChangeItems],
  );

  const elements = useMemo(() => {
    return items.map((item, index) => {
      const selected = selectedIds.find((selectedId) => {
        return selectedId === item.id;
      });

      return (
        <DnDListItem
          record={item}
          onChangeItems={onChangeItems}
          menuItem={menuItem}
          selected={selected}
          onToggleItem={onToggleItem}
          type={type}
          index={index}
          key={item.id}
          hasButtons={hasButtons}
          depthKey={depthKey}
          imgKey={imgKey}
          page={page}
          isNewItem={isNewItem}
        />
      );
    });
  }, [
    depthKey,
    hasButtons,
    imgKey,
    isNewItem,
    items,
    menuItem,
    onChangeItems,
    onToggleItem,
    page,
    selectedIds,
    type,
  ]);

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => {
          return (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={classes.list}
              style={getListStyle(snapshot.isDraggingOver, theme.palette.primaryNew['0.24'])}
            >
              {elements}
              {provided.placeholder}
            </div>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
};
