import React, { useMemo, useEffect, useCallback, useReducer, useRef, useState } from 'react';
import { Collapse, useTheme, makeStyles } from '@material-ui/core';
import { useRefresh } from 'react-admin';
import {
  getFormattedDate,
  getFormattedDateAndTime,
  getFullName,
  getFullNameWithId,
  isObject,
  parseBoolean,
} from '../../../../utils';
import { ListItemDeferredCallStatus } from '../../ListItemStatuses';
import {
  clientProcessStatuses,
  clientTouchTypes,
  luxuryClasses,
  clientTouchNotTargetTypes,
  clientTouchStatuses,
  clientTouchTargetTypes,
} from '../../../../constants';
import { ListItemHeader } from '../ItemHeader';
import { ConstantSelectField } from '../../ConstantSelectField';
import { dataProvider } from '../../../../dataProvider';
import { useDebounce } from '../../../../hooks/useDebounce';
import {
  AddCommentDialogButton,
  CreateContractButton,
  CreateTaskDialogButton,
} from '../../../buttons';
import { DateTimePickerInput } from '../../../RAInputs';
import { ListItem } from '../Item';
import { AutocompleteDynamic, Avatar } from '../../../common';
import { PeopleOption } from '../../../RASelects';
import { Comments } from '../../Comments';
import { ListItemContent } from '../ItemContent';

const reducer = (state, action) => {
  if (action.type === 'isTargeted' && state[action.type] !== action.payload) {
    return { ...state, [action.type]: action.payload, target: null };
  }

  return { ...state, [action.type]: action.payload };
};

// eslint-disable-next-line no-unused-vars
const datesAndPeoplesItems = [
  {
    type: 'date',
    key: 'createdDate',
    title: 'Создан',
    id: 1,
  },
  {
    type: 'people',
    key: 'expert',
    title: 'Эксперт',
    id: 2,
  },
  {
    type: 'people',
    key: 'odp',
    title: 'Сотрудник одп',
    id: 3,
  },
];

const useNewStyles = makeStyles((theme) => ({
  buttons: {
    marginTop: '12px',
    borderTop: '1px dashed rgba(0, 0, 0, 0.3)',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  content: {
    marginTop: '12px',
    borderTop: '1px dashed rgba(0, 0, 0, 0.3)',
    paddingTop: '12px',
  },
  buttonsRow: {
    width: '32%',
    boxSizing: 'border-box',
    paddingTop: '16px',
    flex: '0 0 auto',

    [theme.breakpoints.down('1024')]: {
      width: '49%',
    },

    [theme.breakpoints.down('600')]: {
      width: '100%',
    },
  },
  inputAvatar: {
    width: '24px',
    height: '24px',
    marginRight: '2px',
    fontSize: '10px',
  },
}));

export const NewListItemClient = ({ record, hasButtons, onChangeBgColor, headerProps }) => {
  const classes = useNewStyles();
  const { id } = record;
  const { expanded } = headerProps;

  let name = id;

  if (record.phone) {
    name += ` | ${record.phone}`;
  }

  name += ` | ${getFullName(record)}`;

  if (record.createdDate) {
    name += ` | ${getFormattedDateAndTime(record.createdDate)}`;
  }

  const link = `/Clients/${record.id}`;

  const tags = [];

  if (record.processStatus) {
    tags.push({
      text: clientProcessStatuses[record.processStatus],
      // eslint-disable-next-line no-nested-ternary
      color: record.processStatus === 300 ? 'green' : record.processStatus === 100 ? 'red' : '',
    });
  }

  if (record.target) {
    tags.push({
      text: clientTouchTypes[record.target],
      color:
        record.target === 100 || record.target === 200 || record.target === 300 ? 'green' : 'red',
    });
  }

  if (record.clientTags?.length > 0) {
    record.clientTags.forEach((clientTag) => {
      if (clientTag.clientTag?.name) {
        tags.push(clientTag.clientTag.name);
      }
    });
  }

  if (record.deferredEventDate) {
    tags.push(`Запланирован звонок: ${getFormattedDate(record.deferredEventDate)}`);
  }

  if (record.luxuryClass) {
    tags.push(luxuryClasses[record.luxuryClass]);
  }

  if (record.isOwner) {
    tags.push(`Собственник`);
  }

  if (record.isRepresentative) {
    tags.push(`Представитель`);
  }

  const statuses = [];

  if (record.deferredEventDate) {
    statuses.push(<ListItemDeferredCallStatus />);
  }

  const theme = useTheme();

  const color = useMemo(() => {
    if (record.processStatus === 300) {
      return theme.palette.green['background'];
    }

    if (record.processStatus === 100) {
      return theme.palette.red['background'];
    }
  }, [record.processStatus, theme.palette.green, theme.palette.red]);

  useEffect(() => {
    onChangeBgColor(color);
  }, [color, onChangeBgColor]);

  const [inputValues, dispatch] = useReducer(reducer, {
    isTargeted:
      record?.isTargeted || record.target === 100 || record.target === 200 || record.target === 300,
    target: record?.target || 10000,
    processStatus: record?.processStatus || 100,
    deferredEventDate: record?.deferredEventDate || '',
    expertId: record?.expertId,
    expert: record?.expert,
    odpId: record?.odpId,
    odp: record?.odp,
  });

  const refresh = useRefresh();

  const { clearTimeoutDebounce, timeoutDebounce } = useDebounce();

  const hasChangesRef = useRef(false);

  const onChangeInputValues = useCallback((newValue, event) => {
    let resultValue = newValue;
    const fieldName = isObject(event) ? event.target.name : event;
    if (fieldName === 'isTargeted') {
      resultValue = parseBoolean(resultValue);
    }
    if (isObject(resultValue)) {
      dispatch({ type: `${fieldName}Id`, payload: resultValue.id });
    }
    dispatch({ type: fieldName, payload: resultValue });
    hasChangesRef.current = true;
  }, []);

  const onBlurInputValues = useCallback(() => {
    if (hasChangesRef.current) {
      const fetch = async () => {
        await dataProvider.update('Peoples', {
          id: record.id,
          data: {
            ...record,
            ...inputValues,
          },
          previousData: record,
        });

        refresh();
      };

      timeoutDebounce(() => {
        fetch();
        hasChangesRef.current = false;
      });
    }
  }, [inputValues, record, refresh, timeoutDebounce]);

  const onFocusInputValues = useCallback(() => {
    clearTimeoutDebounce();
  }, [clearTimeoutDebounce]);

  const [touches, setTouches] = useState([]);
  const [touchesLoading, setTouchesLoading] = useState(true);

  const loadTouches = useCallback(() => {
    const fetch = async () => {
      setTouchesLoading(true);
      try {
        const result = await dataProvider.getList('ClientTouches', {
          pagination: { perPage: 3, page: 1 },
          filter: { ClientId: record.id },
        });

        setTouches(result.data);
      } catch (error) {
        console.error(error);
      } finally {
        setTouchesLoading(false);
      }
    };

    fetch();
  }, [record.id]);

  useEffect(() => {
    if (!expanded) {
      setTouches([]);
      return;
    }

    loadTouches();
  }, [expanded, loadTouches]);

  const touchesElements = useMemo(() => {
    return touches.map((touch) => {
      return <ListItem type="touch" record={touch} isNewItem hasButtons={false} />;
    });
  }, [touches]);

  const [comments, setComments] = useState([]);
  const [commentsLoading, setCommentsLoading] = useState(true);

  const loadComment = useCallback(() => {
    const fetch = async () => {
      setCommentsLoading(true);
      try {
        const result = await dataProvider.getList('Comments', {
          pagination: { perPage: 1, page: 1 },
          filter: { CommentObjectId: record.id, CommentObjectType: 300 },
        });
        setComments(result.data);
      } catch (error) {
        console.error(error);
      } finally {
        setCommentsLoading(false);
      }
    };

    fetch();
  }, [record.id]);

  useEffect(() => {
    if (!expanded) {
      setComments([]);
      return;
    }

    loadComment();
  }, [expanded, loadComment]);

  const handleReloadComment = useCallback(() => {
    loadComment();
  }, [loadComment]);

  const commentElements = useMemo(() => {
    return (
      <>
        {comments.length > 0 && <Comments items={comments} onReload={handleReloadComment} isCard />}
        <AddCommentDialogButton
          size="small"
          type={300}
          id={record.id}
          onReload={handleReloadComment}
        />
      </>
    );
  }, [comments, handleReloadComment, record.id]);

  const [task, setTask] = useState([]);
  const [taskLoading, setTaskLoading] = useState(true);

  const loadTask = useCallback(() => {
    const fetch = async () => {
      setTaskLoading(true);
      try {
        const result = await dataProvider.getList('Taasks/ForObject', {
          pagination: { perPage: 1, page: 1 },
          filter: { taaskObjectId: record.id, taaskObjectType: 600, SortBy: 401 },
        });
        setTask(result.data?.[0]);
      } catch (error) {
        console.error(error);
      } finally {
        setTaskLoading(false);
      }
    };

    fetch();
  }, [record.id]);

  useEffect(() => {
    if (!expanded) {
      setTask();
      return;
    }

    loadTask();
  }, [expanded, loadTask]);

  const handleReloadTask = useCallback(() => {
    loadTask();
  }, [loadTask]);

  const taskElements = useMemo(() => {
    return (
      <>
        {task && (
          <ListItem
            type="task"
            record={task}
            isNewItem
            hasButtons={false}
            depthKey="taask"
            onReload={handleReloadTask}
          />
        )}
        <CreateTaskDialogButton type={600} defaultItem={record} onReload={handleReloadTask} />
      </>
    );
  }, [handleReloadTask, record, task]);

  return (
    <>
      <ListItemHeader
        record={record}
        link={link}
        name={name}
        hasButtons={hasButtons}
        tags={tags}
        statuses={statuses}
        hasTaskStatus
        tagName="Клиент"
        {...headerProps}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {(touchesLoading || touches.length > 0) && (
          <ListItemContent loading={touchesLoading}>{touchesElements}</ListItemContent>
        )}
        <ListItemContent loading={taskLoading}>{taskElements}</ListItemContent>
        <ListItemContent loading={commentsLoading}>{commentElements}</ListItemContent>
        {/* <ListItemContentDatesAndPeoples record={record} items={datesAndPeoplesItems} /> */}
        <div className={classes.buttons}>
          <div className={classes.buttonsRow}>
            <ConstantSelectField
              items={clientTouchStatuses}
              size="small"
              value={inputValues.isTargeted}
              onChange={onChangeInputValues}
              name="isTargeted"
              label="Статус клиента"
              isControlled
              onBlur={onBlurInputValues}
              onFocus={onFocusInputValues}
              defaultValue={false}
            />
          </div>
          <div className={classes.buttonsRow}>
            <ConstantSelectField
              items={
                inputValues.isTargeted === true ? clientTouchTargetTypes : clientTouchNotTargetTypes
              }
              size="small"
              value={inputValues.target}
              onChange={onChangeInputValues}
              name="target"
              label="Причина обработки"
              isControlled
              onBlur={onBlurInputValues}
              onFocus={onFocusInputValues}
            />
          </div>
          <div className={classes.buttonsRow}>
            <ConstantSelectField
              items={clientProcessStatuses}
              size="small"
              value={inputValues.processStatus}
              onChange={onChangeInputValues}
              name="processStatus"
              label="Статус обработки"
              isControlled
              onBlur={onBlurInputValues}
              onFocus={onFocusInputValues}
            />
          </div>
          <div className={classes.buttonsRow}>
            <DateTimePickerInput
              source="deferredEventDate"
              label="Дата запланированного звонка"
              pickerType="date"
              isRA={false}
              size="small"
              onBlur={onBlurInputValues}
              onFocus={onFocusInputValues}
              name="deferredEventDate"
              onChange={onChangeInputValues}
              defaultValue={inputValues.deferredEventDate}
            />
          </div>
          <div className={classes.buttonsRow}>
            <AutocompleteDynamic
              filterSelectedOptions
              resource="Peoples"
              fullDynamic="AllNames"
              label="Эксперт"
              name="expert"
              onChange={onChangeInputValues}
              size="small"
              valueProp={inputValues.expert}
              onBlur={onBlurInputValues}
              onFocus={onFocusInputValues}
              getOptionLabel={(option) => (!isObject(option) ? '' : `${getFullNameWithId(option)}`)}
              renderOption={(option) => <PeopleOption option={option} />}
              startAdornment={(value) => {
                return (
                  value && <Avatar option={value} source="photo" className={classes.inputAvatar} />
                );
              }}
              providerParams={{ filter: { isExpert: true } }}
            />
          </div>
          <div className={classes.buttonsRow}>
            <AutocompleteDynamic
              filterSelectedOptions
              resource="Peoples"
              fullDynamic="AllNames"
              label="Сотрудник ОДП"
              name="odp"
              onChange={onChangeInputValues}
              size="small"
              valueProp={inputValues.odp}
              onBlur={onBlurInputValues}
              onFocus={onFocusInputValues}
              getOptionLabel={(option) => (!isObject(option) ? '' : `${getFullNameWithId(option)}`)}
              renderOption={(option) => <PeopleOption option={option} />}
              startAdornment={(value) => {
                return (
                  value && <Avatar option={value} source="photo" className={classes.inputAvatar} />
                );
              }}
              providerParams={{ filter: { isOdp: true } }}
            />
          </div>
          <div className={classes.buttonsRow}>
            <CreateContractButton record={record} size="small" />
          </div>
        </div>
      </Collapse>
    </>
  );
};
