import React from 'react';
import { makeStyles } from '@material-ui/core';
import { DynamicList } from '../../additionalLists';
import { CreateTaskDialogButton } from '../../buttons';
import { List } from '..';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    padding: '0',
    boxSizing: 'border-box',
  },
  content: {
    flex: '1 1 0',
    minHeight: 0,
    overflow: 'auto',
    position: 'relative',
  },
  footer: {
    padding: '16px 16px 0 16px',
    flex: '0 0 auto',
  },
}));

export const TasksTab = ({ type, record }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <DynamicList
          fetchOptions={{
            resource: 'Taasks/ForObject',
            filter: { taaskObjectId: record.id, taaskObjectType: type, SortBy: 401 },
          }}
          contentOptions={{
            type: 'task',
            hasButtons: false,
            depthKey: 'taask',
            isNewItem: true,
          }}
          contentElement={List}
        />
      </div>
      <div className={classes.footer}>
        <CreateTaskDialogButton type={type} defaultItem={record} />
      </div>
    </div>
  );
};
