import React, { useMemo } from 'react';

import { Button as MaterialButton, CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  startIcon: {
    marginRight: 0,
    marginLeft: 0,
  },
  spinner: {
    marginRight: '10px',
    color: 'grey',
  },
}));

export const Button = (props) => {
  const classNames = useStyles();
  const { loading, icon, classes: classesProp, disabled } = props;

  const iconElement = useMemo(() => {
    if (icon) {
      return icon;
    }

    return null;
  }, [icon]);

  return (
    <MaterialButton
      variant="contained"
      color="primary"
      size="medium"
      {...props}
      disabled={loading || disabled}
      classes={{
        startIcon: classNames.startIcon,
        ...classesProp,
      }}
      startIcon={
        loading ? <CircularProgress className={classNames.spinner} size={20} /> : iconElement
      }
    />
  );
};
