import React from 'react';
import { PriceField } from '..';

export const DiscountValueField = ({ record }) => {
  const { amount, isPercents } = record;

  return (
    <>{isPercents ? `${amount} %` : <PriceField source="amount" label="Цена" record={record} />}</>
  );
};
