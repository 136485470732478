import React, { useCallback, useRef, useState } from 'react';
import { ImageFullDialog } from '../../dialogs';

export const ImageFullDialogContext = React.createContext();

export const ImageFullDialogProvider = ({ children }) => {
  const changeOpenRef = useRef();
  const [imagePath, setImagePath] = useState('');

  const handleOpenDialog = useCallback((currentImagePath) => {
    if (changeOpenRef.current) {
      changeOpenRef.current(true);
      setImagePath(currentImagePath);
    }
  }, []);

  return (
    <ImageFullDialogContext.Provider value={handleOpenDialog}>
      {children}
      <ImageFullDialog changeOpenRef={changeOpenRef} imagePath={imagePath} />
    </ImageFullDialogContext.Provider>
  );
};
