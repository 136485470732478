import React, { useRef, useMemo } from 'react';

import { MenuCell } from '../../../tables';
// eslint-disable-next-line no-unused-vars
import { DeleteMenuItem, EditMenuItem } from '../../../menuItems';

import { checkRoleAdmin } from '../../../../utils';
import { currentProfile } from '../../../../authProvider';

export const UsersMenu = ({ record, resource, permissions }) => {
  const handleCloseRef = useRef();

  const hasRoleAdmin = useMemo(() => {
    return checkRoleAdmin(permissions);
  }, [permissions]);

  // eslint-disable-next-line no-unused-vars
  const showDelete = useMemo(() => {
    const isCurrentUser = currentProfile.id === record.authId;
    if (hasRoleAdmin || isCurrentUser) {
      return true;
    }

    return false;
  }, [hasRoleAdmin, record]);

  return (
    <MenuCell handleCloseRef={handleCloseRef}>
      <EditMenuItem resource={resource} record={record} />
      {/* {showDelete && (
        <DeleteMenuItem resource={resource} record={record} handleCloseRef={handleCloseRef} />
      )} */}
    </MenuCell>
  );
};
